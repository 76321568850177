import { useMemo } from 'react';

import { useEntity, useAllEntities } from '../../entities/hooks';

export const useOwnerSharesStoryShares = id => {
  const { story, currentMemberId } = useEntity({ id, type: 'stories' });

  const allStoryShares = useAllEntities('story-shares');
  const allMembers = useAllEntities('members');

  return useMemo(() => {
    return (story['story-shares'] || [])
      .filter(share => {
        const { 'with-contact': withContact } = allStoryShares[share.id];
        return withContact.id !== currentMemberId;
      })
      .sort((a, b) => {
        const { 'with-contact': withContactA } = allStoryShares[a.id];
        const { 'with-contact': withContactB } = allStoryShares[b.id];

        const { 'display-name': nameA } = allMembers[withContactA.id];
        const { 'display-name': nameB } = allMembers[withContactB.id];

        return (nameA || '').toLowerCase() < (nameB || '').toLowerCase() ? -1 : 1;
      });
  }, [story, allStoryShares, currentMemberId, allMembers]);
};

export const useOwnerSharesConnections = id => {
  const { currentMemberId } = useEntity({ id, type: 'stories' });
  const storyShares = useOwnerSharesStoryShares(id);

  const allStoryShares = useAllEntities('story-shares');
  const allMembers = useAllEntities('members');
  const allConnections = useAllEntities('connections');

  const storyShareMembers = storyShares.map(share => {
    const { 'with-contact': withContact } = allStoryShares[share.id];
    return withContact.id;
  });

  // don't show connection if already shared on the story
  const formConnections = Object.keys(allConnections).filter(
    connectionId => !storyShareMembers.includes(allConnections[connectionId].contact.id)
  );

  return useMemo(() => {
    return formConnections
      .filter(connectionId => {
        const { contact: member } = allConnections[connectionId];
        return member.id !== currentMemberId;
      })
      .sort((a, b) => {
        const { contact: memberA } = allConnections[a];
        const { contact: memberB } = allConnections[b];

        const { 'display-name': nameA } = allMembers[memberA.id];
        const { 'display-name': nameB } = allMembers[memberB.id];

        return (nameA || '').toLowerCase() < (nameB || '').toLowerCase() ? -1 : 1;
      });
  }, [formConnections, allConnections, currentMemberId, allMembers]);
};

export const useAllDirectStoryShares = id => {
  const { story, currentMemberId } = useEntity({ id, type: 'stories' });

  const allStoryShares = useAllEntities('story-shares');

  return useMemo(() => {
    const storyShareIds = story['story-shares'] && story['story-shares'].map(share => share.id);
    const storyShares = Object.values(allStoryShares).filter(share => {
      return storyShareIds && storyShareIds.includes(share.id);
    });
    return (storyShares || []).filter(share => {
      const { 'with-contact': withContact } = allStoryShares[share.id];
      const { 'shared-direct': sharedDirect } = allStoryShares[share.id];
      return withContact.id !== currentMemberId && sharedDirect;
    });
  }, [story, allStoryShares, currentMemberId]);
};
