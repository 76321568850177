
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

import useStoryPageGalleryMedia from './useStoryPageGalleryMedia';
import GalleryMedium from './gallery-medium';
import GalleryCaption from './gallery-caption';
import { GalleryOuter, GalleryInner, GalleryCloseButton, GalleryButton } from './styled';

const StoryDetailPageGallery = () => {
  const { id, pageId } = useParams();
  const galleryMedia = useStoryPageGalleryMedia(pageId);
  const [[page, direction], setPage] = useState([0, 0]);
  // const containerLeft = currentPosition && rect ? currentPosition * rect.width * -1 : 0;
  const paginate = nextDirection => {
    setPage([page + nextDirection, nextDirection]);
  };

  return (
    <GalleryOuter>
      <GalleryInner>
        {galleryMedia[page] && (
          <GalleryMedium
            key={`gallery${page}`}
            direction={direction}
            id={galleryMedia[page].id}
            type={galleryMedia[page].type}
            caption={galleryMedia[page].caption}
          />
        )}
        {galleryMedia[page] && (
          <GalleryCaption key={`caption-${page}`} caption={galleryMedia[page].caption} />
        )}
      </GalleryInner>
      {page !== 0 && (
        <GalleryButton
          left={[16, 32]}
          top={['auto', '50%']}
          bottom={[60, 'auto']}
          onClick={() => paginate(-1)}
          icon={IoIosArrowBack}
          iconSize={5}
        />
      )}
      {page !== galleryMedia.length - 1 && (
        <GalleryButton
          right={[16, 32]}
          top={['auto', '50%']}
          bottom={[60, 'auto']}
          onClick={() => paginate(1)}
          icon={IoIosArrowForward}
          iconSize={5}
        />
      )}
      <GalleryCloseButton to={`/story/${id}`} iconSize={5} top={[16, 32]} right={[16, 32]} />
    </GalleryOuter>
  );
};

export default StoryDetailPageGallery;
