
import React from 'react';
import PropTypes from 'prop-types';
import { Card, Image, Flex, Text } from 'rebass';
import { FaImage, FaPen } from 'react-icons/fa';

export const AvatarImageOrPlaceholder = ({ coverUrl, borderSize, size, showEdit, ...props }) => (
  <Card
    bg="cyan-050"
    mr={3}
    borderSize={borderSize}
    borderStyle="solid"
    borderRadius={3}
    borderColor="cyan-050"
    {...props}
    css={`
      height: ${size + 8}px;
      width: ${size + 8}px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    `}
  >
    {showEdit && (
      <Flex
        bg="cyan-050"
        p={1}
        css="position: absolute; bottom: 0; right: 0; z-index: 1; opacity: .9;"
      >
        <Text fontSize={0} color="cyan-300" fontWeight="bold" pr={1}>
          Edit
        </Text>
        <Text color="cyan-200" size={12} as={FaPen} />
      </Flex>
    )}
    {coverUrl && (
      <Image height={size} width={size} src={coverUrl} css="object-fit: cover; display: block;" />
    )}
    {!coverUrl && <Text as={FaImage} size={size * 0.6} color="cyan-300" />}
  </Card>
);
AvatarImageOrPlaceholder.propTypes = {
  coverUrl: PropTypes.string,
  size: PropTypes.number,
  borderSize: PropTypes.number,
  showEdit: PropTypes.bool,
};
AvatarImageOrPlaceholder.defaultProps = {
  coverUrl: null,
  size: 70,
  borderSize: 4,
  showEdit: false,
};

export default AvatarImageOrPlaceholder;
