import { UserAgent } from '@quentin-sommer/react-useragent';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Lazyload from 'react-lazyload';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import ReactPlayer from 'react-player';
import isNumber from 'lodash/isNumber';
import { Flex, Text } from 'rebass';
import { FaVideoSlash } from 'react-icons/fa';

import { Wrap } from './styled';
import { selectVideo } from '../selectors';
import { StoryPageContext } from '../../story-pages/item/context';
import { useIsSafariUser } from 'story-media/hooks';

const VideoMedium = ({ mediumLoading, medium, height, editing, isCarousel, className }) => {
  const { currentMedia, setCurrentMedia } = useContext(StoryPageContext);
  const isSafariUser = useIsSafariUser();

  if (medium.state === 'pre_uploaded' || (isSafariUser && medium.state !== 'finished')) {
    return (
      <Flex
        bg="border"
        width="100%"
        height={[260, 400]}
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        px={4}
        sx={{ textAlign: 'center' }}
      >
        <Text as={FaVideoSlash} fontSize={96} color="lightText" pb={3} />
        <Text pb={3} color="mutedText">
          This video clip is being processed
        </Text>
        <Text pb={3} color="mutedText">
          In the meanwhile, feel free to add more to the story
        </Text>
        <Text pb={3} color="mutedText">
          ... or create a new story while you wait &#128516;
        </Text>
      </Flex>
    );
  }

  return (
    <UserAgent mobile>
      {isMobile => (
        <Wrap editing={editing} mobile={isMobile} uploading={mediumLoading}>
          <Lazyload height={height} offset={600} once>
            <ReactPlayer
              url={isSafariUser ? medium['asset-url'] : (medium.tmpUrl || medium['asset-url'])}
              controls
              width="100%"
              className={className}
              height={isCarousel ? '400px' : '100%'}
              playing={currentMedia === medium.id}
              onPlay={() => setCurrentMedia(medium.id)}
              onEnded={() => setCurrentMedia(null)}
              config={{
                file: {
                  attributes: { poster: medium['preview-asset-url'] || null },
                },
              }}
            />
          </Lazyload>
        </Wrap>
      )}
    </UserAgent>
  );
};

VideoMedium.propTypes = {
  medium: PropTypes.shape({}).isRequired,
  mediumLoading: PropTypes.bool,
  editing: PropTypes.bool,
  height: PropTypes.number,
  isCarousel: PropTypes.bool.isRequired,
};

VideoMedium.defaultProps = {
  mediumLoading: false,
  height: 100,
  editing: false,
};

const mapStateToProps = (state, { id, height, match: { url } }) => ({
  medium: selectVideo(state, id),
  mediumLoading: isNumber(state.storyMedia.uploadBatch.map(item => item.id).includes(id)),
  height: Number((height || '').replace('px', '')),
  editing: url.includes('edit'),
});

export default withRouter(connect(mapStateToProps)(VideoMedium));
