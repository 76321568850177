import { Box, Button, Heading } from 'rebass';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import { Form, Submit, Input } from '../form-styled';

const NewCollectionName = ({ attr, isValid, isSubmitting, label, placeholder }) => (
  <Form height={320} autoComplete="off">
    <Box width="100%" my="auto" pb={50}>
      <Heading textAlign="center" fontSize={24} fontWeight="normal" py={14}>
        {label}
      </Heading>
      <Field
        name={attr}
        render={({ field }) => <Input {...field} autoComplete="false" placeholder={placeholder} />}
      />
    </Box>
    <Submit as={Button} variant="primary" disabled={!isValid && !isSubmitting} fontSize={20}>
      Next
    </Submit>
  </Form>
);

NewCollectionName.propTypes = {
  isValid: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  attr: PropTypes.string,
  placeholder: PropTypes.string,
};

NewCollectionName.defaultProps = {
  attr: 'first-name-noac',
  placeholder: '',
};

export default NewCollectionName;
