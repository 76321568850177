import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';

import { getNotifications, markNotificationsRead } from '../api';
import { selectCurrentMember } from '../../members/selectors';
import { selectMostRecentNotification, selectUnreadNotifications } from '../selectors';
import { useEntityActionCreators } from '../../entities/hooks';

const Button = styled.button`
  margin-left: auto;
`;

const DismissButton = ({
  onClose,
  currentMemberId,
  firstNotificationId,
  hasUnreadNotifications,
}) => {
  const { receive } = useEntityActionCreators();
  const dismiss = () =>
    markNotificationsRead(currentMemberId, firstNotificationId).then(() =>
      getNotifications(currentMemberId).then(payload => receive({ payload }))
    );

  if (!hasUnreadNotifications) return false;
  return (
    <Button
      type="button"
      onClick={() => {
        dismiss();
        onClose();
      }}
    >
      Dismiss All
    </Button>
  );
};

DismissButton.propTypes = {
  onClose: PropTypes.func,
  currentMemberId: PropTypes.string.isRequired,
  firstNotificationId: PropTypes.string.isRequired,
  hasUnreadNotifications: PropTypes.bool.isRequired,
};

DismissButton.defaultProps = {
  onClose: () => {},
};

const mapStateToProps = state => {
  const { id: currentMemberId } = selectCurrentMember(state);
  const firstNotificationId = selectMostRecentNotification({ state });
  const hasUnreadNotifications = !!(selectUnreadNotifications({ state }) || []).length;

  return { currentMemberId, firstNotificationId, hasUnreadNotifications };
};

export default connect(mapStateToProps)(DismissButton);
