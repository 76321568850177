
import React, { useMemo } from 'react';
import { FaAngleLeft } from 'react-icons/fa';
import { DateTime } from 'luxon';
import { Flex, Heading, Card, Button, Text as RebassText } from 'rebass';
import { useHistory } from 'react-router-dom';
import useMemberSubscription from './useMemberSubscription';

const useFormattedDate = date =>
  useMemo(() => (date ? DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED) : null), [date]);

const getSubscriptionText = state => {
  switch (state) {
    case 'expired':
      return ' - Expired';
    case 'expiring':
      return ' - Expiring';
    case 'churned':
      return ' - Payment Missed';
    case 'churning':
      return ' - Payment Failed';
    default:
      return '';
  }
};

const ManageSubscription = () => {
  const { push } = useHistory();
  const { pricingPlan, subscription } = useMemberSubscription();
  const isExpired = subscription && subscription.state === 'expired';
  const expirationDate = useFormattedDate(subscription && subscription['expires-at']);

  return (
    <Flex p={3} flexDirection="column" css="max-width: 1200px;" mx="auto">
      <Button
        variant="transparent"
        onClick={() => push('/profile')}
        mt={3}
        css="display: flex; align-items: center; color: #666;"
        type="button"
      >
        <FaAngleLeft /> Back to Profile
      </Button>
      <Heading my={3} fontSize={4}>
        Manage your subscription
      </Heading>
      <Section>
        <Flex flexDirection={['column', 'row']} mb={3}>
          <Flex flexDirection="column">
            <Subheading>Current Plan</Subheading>
            {!subscription && (
              <>
                <Text>You have no subscription</Text>
                <Button
                  mt={2}
                  onClick={() => console.log('manage subscriptions')}
                  variant="primary"
                  type="button"
                >
                  See Options
                </Button>
              </>
            )}
            {subscription && (
              <>
                <Text>
                  {(pricingPlan && pricingPlan.name) || 'Loading...'}
                  {getSubscriptionText(subscription && subscription.state)}
                </Text>
                <RebassText color="mutedText" mt={2} css="max-width: 400px;">
                  {subscription && subscription['status-message']}
                  {!isExpired && (
                    <>
                      <br />
                      Expires: {expirationDate}
                    </>
                  )}
                </RebassText>
              </>
            )}
          </Flex>
          {/* TODO show button when stripe is available
          <Button
            variant="mutedOutline"
            ml={['unset', 'auto']}
            mr={['auto', 'unset']}
            mt={[2, 0]}
            css="height: 51px;"
          >
            See Plans
          </Button> */}
        </Flex>
        {subscription && subscription['management-url'] && (
          <a href={subscription['management-url']} style={{ color: '#7a69bf' }}>
            Manage your subscription
          </a>
        )}
      </Section>
    </Flex>
  );
};

const Section = ({ ...props }) => (
  <Card bg="white" p={4} mb={3} css="border: 1px solid #e6e6e6;" {...props} />
);

const Subheading = ({ ...props }) => (
  <Heading
    as="h3"
    mb={2}
    color="mutedText"
    fontSize={2}
    fontFamily="sanFrancisco"
    fontWeight="normal"
    {...props}
  />
);

const Text = ({ ...props }) => <RebassText fontSize={3} {...props} />;

export default ManageSubscription;
