import axios from 'axios';
import apiBase from '../utils/api-base';
import { getJsonHeaders } from '../utils/api';

const baseURL = new URL(apiBase).origin;

export const getMemberFromRestore = ({ id, secret }) =>
  axios.get(`${baseURL}/api/v2/restore_tokens/${id}/members`, {
    headers: {
      ...getJsonHeaders(),
      token_secret: secret,
    },
  });

export const createAuthToken = memberId =>
  axios.post(`${baseURL}/api/v2/members/${memberId}/auth_tokens`, {
    headers: {
      ...getJsonHeaders(),
    },
  });

export const getAuthToken = payload => {
  return getMemberFromRestore(payload)
    .then(res => {
      const memberId = res.data.data.id;
      return createAuthToken(memberId);
    })
    .then(res => {
      return `token=${res.data.data.attributes['auth-token']}`;
    });
};
