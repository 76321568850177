import { color, border } from 'styled-system';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { withProps } from 'recompose';

export const EditButtonsWrap = styled.div`
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.57), rgba(255, 255, 255, 0));
  position: absolute;
  top: 0;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 1em;
  z-index: 9;
`;

const moreLinkStyles = css`
  line-height: 29px;
  font-size: 16px;
  border-bottom: 1px solid #333;
  color: #333;
  display: flex;
  align-items: center;
  margin: 0 -0.6rem;
  padding: 0 0.6rem;
  justify-content: flex-start;
  ${color};
  width: calc(100% + 1.2em);

  svg {
    margin-right: 5px;
  }
`;

export const MoreLink = withProps({ type: 'button ' })(styled.button`
  ${moreLinkStyles};
  ${border};
`);

export const MoreLinkAnchor = styled.a`
  ${moreLinkStyles};
  ${border};
  text-decoration: none;
`;

export const StoryMediumWrap = styled.div`
  overflow: hidden;
  position: relative;
  z-index: 0;
  background-color: ${props => props.theme.colours.white};
  min-height: ${props => (props.editing && '90px') || 'none'};
`;

export const Wrap = styled.div`
  margin-bottom: -1em;
  ${props => props.theme.media.phone`margin-bottom: -1.25em;`};
  ${props => props.theme.media.tablet`margin-bottom: -1.5em;`};
`;
