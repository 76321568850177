
import React from 'react';
import { Box } from 'rebass';
import PropTypes from 'prop-types';

const FieldGroup = ({ children, ...props }) => (
  <Box bg="white" p={3} mb={2} {...props}>
    {children}
  </Box>
);

FieldGroup.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FieldGroup;
