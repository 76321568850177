import { singularize } from 'inflected';
import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useCurrentMemberShares } from '../hooks';

export const useNewCollectionPath = type => {
  const shareType = useMemo(() => `${singularize(type)}-shares`, [type]);
  const sharesForType = useCurrentMemberShares({ type: shareType, filterIsOwner: true });

  const hasCollection = useMemo(() => sharesForType.length, [sharesForType]);

  const routeFromType = useMemo(() => {
    if (type === 'other-journals') {
      return 'custom';
    }
    return type;
  }, [type]);

  return useMemo(
    () => (hasCollection ? `/${routeFromType}/new` : `/collections/onboard/${routeFromType}`),
    [hasCollection, routeFromType]
  );
};

const NewCollectionLink = ({ type, children }) => {
  const path = useNewCollectionPath(type);

  return children({
    path,
  });
};

NewCollectionLink.propTypes = {
  hasCollection: PropTypes.bool,
  children: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

NewCollectionLink.defaultProps = {
  hasCollection: false,
};

export default NewCollectionLink;
