import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import store from 'store';

const AuthRedirect = ({ children }) => {
  const { push } = useHistory();

  useEffect(() => {
    const path = store.get('postLoginRedirect');
    if (path) {
      push(path);
      store.remove('postLoginRedirect');
    }
  }, [push]);

  return children;
};
AuthRedirect.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthRedirect;
