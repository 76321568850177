import styled from '@emotion/styled';
import { withProps } from 'recompose';

export const StyledButton = withProps({ type: 'button' })(styled.button`
  display: grid !important; /* header-dropdown a is interfering */
  grid-template-columns: 35px auto 35px;
  grid-gap: 12px;
  align-items: center;
  height: 70px;
  padding: 0 12px;
  text-decoration: none;
  text-align: left;
  cursor: pointer;
`);

export const ContextImage = withProps(props => ({
  style: {
    backgroundImage: `url(${props.background})`,
    display: (props.background && 'block') || 'none',
  },
}))(styled.div`
  background-size: cover;
  background-position: center;
  height: 35px;
  width: 35px;
`);
