import React from 'react';
import { Adopt } from 'react-adopt';
import { Switch, Route } from 'react-router-dom';
import { Mobile, Tablet, DesktopSmall } from '../responsive';
import NavFooter from './footer';
import { noNavPaths } from './utils';

const mapper = {
  mobile: <Mobile />,
  tablet: <Tablet />,
  desktop: <DesktopSmall />,
};

const Navigation = () => (
  <Switch>
    {noNavPaths.map(path => (
      <Route key={`no-nav-${path}`} path={path} />
    ))}
    <Route path="/" component={NavFooter} />
  </Switch>
);

export default () => (
  <Adopt mapper={mapper}>
    {({ mobile, tablet, desktop }) => (mobile || tablet) && !desktop && <Navigation />}
  </Adopt>
);
