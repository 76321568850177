import { Box, Flex } from 'rebass';
import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';

const Wrap = styled.div`
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1em;
`;

const Small = styled.small`
  font-size: 12px;
  color: ${props => props.theme.colours.white};

  &:empty {
    display: none;
  }
`;

const Big = styled.p`
  font-size: 16px;
  color: ${props => props.theme.colours.white};
  margin: 0;
`;

const MediaMoveOverlay = ({ isGrouped, itemPosition }) => (
  <Wrap>
    <Box m="auto">
      <Flex>
        check
        <Box ml={2}>
          <Big>{isGrouped ? 'Group selected' : 'Item selected'}</Big>
          <Small>{isGrouped && itemPosition}</Small>
        </Box>
      </Flex>
    </Box>
    <Small>Scroll up / down to desired location</Small>
  </Wrap>
);

MediaMoveOverlay.defaultProps = {
  itemPosition: '',
  isGrouped: false,
};

MediaMoveOverlay.propTypes = {
  itemPosition: PropTypes.string,
  isGrouped: PropTypes.bool,
};

export default MediaMoveOverlay;
