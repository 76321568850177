import React, { useState } from 'react';
import { UAContext } from '@quentin-sommer/react-useragent';
import styled from '@emotion/styled';
import ActionItems from './ActionItems';
import { FiUpload } from 'react-icons/fi';
import NewMultipleMedia from 'story-media/new/multiple-media/index';
import { useContext } from 'react';

const Label = styled.label`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: ${props => props.theme.fonts.sanFrancisco};
  position: relative;
  color: ${props => props.theme.colours.brand};
  margin-top: 16px;
  cursor: pointer;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 100%;
    width: 100%;
  }
`;

function UploadMedia({ onSubmitMedia, isSelected, onClick, label = "Upload photo(s) / video", type = "image/*,video/*" }) {
    const [initialFiles, setInitialFiles] = useState(false);
    const maxWidth = 500;
    const {
        uaResults: { android },
    } = useContext(UAContext);


    const handleChange = (e) => {
        if (android) return setInitialFiles(e.target.files);
        return onSubmitMedia(e.target.files);
    }

    if (initialFiles)
        return (
            <NewMultipleMedia
                initialFiles={initialFiles}
                maxWidth={maxWidth}
                onSubmitMedia={files => onSubmitMedia(files)}
            />
        );

    return <>
        <Label htmlFor={label}>
            <input
                type="file"
                id={label}
                accept={type}
                onChange={handleChange}
                multiple
            />
            <ActionItems
                onClick={onClick}
                icon={FiUpload}
                nextIcon={isSelected}
                label={label}
                isSelected={isSelected}
            />
        </Label>

    </>
}

export default UploadMedia;