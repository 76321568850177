import { Box, Button, Flex } from 'rebass';
import { FaCheck } from 'react-icons/fa';
import { useWindowSize } from 'the-platform';
import AvatarEditor from 'react-avatar-editor';
import PropTypes from 'prop-types';
import React, { useState, useMemo, useRef } from 'react';

const CoverEditor = ({ src, acceptEdit, exitEditor, editorWidth }) => {
  const [zoom, setZoom] = useState(1);
  const { width: windowWidth } = useWindowSize();
  const editor = useRef(null);
  const [localSrc] = useState(src);

  const handleEdit = async () => {
    const canvas = editor.current.getImage();
    const file = await new Promise(resolve => {
      canvas.toBlob(
        blob => {
          const newImage = blob;
          newImage.name = 'cover-image.png';
          resolve(newImage);
        },
        'image/png',
        100
      );
    });
    acceptEdit(file);
  };

  const handleZoom = e => setZoom(Number(e.target.value));

  const width = useMemo(() => {
    if (editorWidth) return editorWidth;
    return Math.min(windowWidth - 16, 600);
  }, [editorWidth, windowWidth]);

  return (
    <Box>
      <AvatarEditor
        image={localSrc}
        scale={zoom}
        ref={editor}
        width={width - 40}
        height={width / 2 - 20}
        border={20}
        onMouseUp={handleEdit}
      />
      <Flex>
        <input
          type="range"
          min="1"
          max="2"
          step="0.01"
          value={zoom}
          onChange={handleZoom}
          onMouseUp={handleEdit}
        />
        <Button
          variant="darkIconCircle"
          type="button"
          ml="auto"
          fontSize={18}
          onClick={() => {
            handleEdit();
            exitEditor();
          }}
        >
          <FaCheck />
        </Button>
      </Flex>
    </Box>
  );
};

CoverEditor.propTypes = {
  src: PropTypes.string.isRequired,
  acceptEdit: PropTypes.func.isRequired,
  exitEditor: PropTypes.func.isRequired,
  editorWidth: PropTypes.number,
};

CoverEditor.defaultProps = {
  editorWidth: null,
};

export default CoverEditor;
