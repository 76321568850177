import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text, Flex, Button } from 'rebass';
import { FiX as CloseIcon, FiChevronLeft as BackIcon } from 'react-icons/fi';

const ModalTop = React.forwardRef(({ left, center, right, body }, ref) => (
  <Box
    ref={ref}
    p={2}
    sx={{
      boxShadow: '0 0 2px rgba(0,0,0,.05)',
      bg: 'background',
      borderBottom: '1px solid',
      borderColor: 'border',
    }}
    css="position:relative;"
  >
    <Flex justifyContent="stretch" alignItems="center">
      <Flex flex="1" justifyContent="flex-start">
        {left}
      </Flex>
      <Flex flex="4" justifyContent="center">
        {center}
      </Flex>
      <Flex flex="1" justifyContent="flex-end">
        {right}
      </Flex>
    </Flex>
    {body}
  </Box>
));
ModalTop.propTypes = {
  left: PropTypes.node,
  center: PropTypes.node,
  right: PropTypes.node,
  body: PropTypes.node,
};
ModalTop.defaultProps = {
  left: null,
  center: null,
  right: null,
  body: null,
};

const ModalTopButton = props => (
  <Button
    variant="transparent"
    color="mutedText"
    type="button"
    fontWeight="normal"
    css="display: flex; align-items: center;"
    px={0}
    py={0}
    {...props}
  />
);

export const BackButton = ({ onBack, label }) => (
  <ModalTopButton onClick={onBack}>
    <Text size={24} as={BackIcon} pr="2px" />
    {typeof label === 'string' && label}
  </ModalTopButton>
);
BackButton.propTypes = {
  onBack: PropTypes.func.isRequired,
  label: PropTypes.string,
};
BackButton.defaultProps = {
  label: 'Back',
};

export const CloseButton = ({ onClose, label }) => (
  <ModalTopButton onClick={onClose}>
    {typeof label === 'string' && label}
    <Text size={24} as={CloseIcon} pl="2px" />
  </ModalTopButton>
);
CloseButton.propTypes = {
  onClose: PropTypes.func.isRequired,
  label: PropTypes.string,
};
CloseButton.defaultProps = {
  label: null,
};

export default ModalTop;
