import { sortBy } from 'lodash';
import { validateMedia, processFile, formatMediumBody } from './helper';
import * as api from './api';
import promiseChain from '../utils/promise-chain';
import * as types from './types';
import { getStory } from '../stories/actions';

export const clearUploadBatch = () => ({ type: types.CLEAR_UPLOAD_BATCH });

export const createTextMedium = (storyId, values) => ({
  type: types.CREATE_TEXT_MEDIUM,
  promise: api.createStoryMedium(storyId, formatMediumBody(values)),
  meta: { storyId },
});

export const updateTextMedium = (id, values) => ({
  type: types.UPDATE_TEXT_MEDIUM,
  promise: api.updateTextMedium(values.id, formatMediumBody(values)),
});

export const cancelDestroyMediumPrompt = id => ({
  type: types.CANCEL_DESTROY_MEDIUM,
  id,
});

export const promptDestroyMedium = id => ({
  type: types.PROMPT_DESTROY_MEDIUM,
  id,
});

export const destroyMedium = (id, pageId, mediumType, storyId) => ({
  type: types.DESTROY_STORY_MEDIUM,
  promise: api.destroyStoryMedium(id),
  meta: {
    pageId,
    mediumType,
    storyId,
  },
});

const updateUploadProgress = (mediumId, progress) => ({
  type: types.UPDATE_UPLOAD_PROGRESS,
  mediumId,
  progress,
});

export const uploadMediumFile = (_, medium, file) => dispatch => {
  // update this so it doesn't need to have a story id
  const meta = {
    id: medium.id,
    mediumType: `${medium.type}s`.toLowerCase(), // covert 'Image' to 'images' e.g.
    fileSize: file.size,
    fileName: file.name,
    // storyId: id,
  };

  const axiosConfig = {
    onUploadProgress: progressEvent => {
      const progress = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
      dispatch(updateUploadProgress(medium.id, progress));
    },
  };

  dispatch({
    type: types.UPLOAD_MEDIUM_START,
    meta,
  });

  return api
    .uploadMediumFile(medium['upload-url'], file, axiosConfig)
    .then(() =>
      dispatch({
        type: types.UPLOAD_MEDIUM_SUCCESS,
        meta,
      })
    )
    .catch(() =>
      dispatch({
        type: types.UPLOAD_MEDIUM_FAIL,
        meta,
      })
    );
};

export const createStoryMedium = (id, values, file = null) => dispatch => {
  const tmpUrl = URL.createObjectURL(file);
  dispatch({
    type: types.CREATE_STORY_MEDIUM,
    promise: api.createStoryMedium(id, formatMediumBody(values)),
    meta: {
      storyId: id,
      tmpUrl,
      onSuccess: (res, storyId) => {
        const { result, entities } = res.data;
        const mediumType = Object.keys(entities).filter(key => key !== 'pages')[0];
        const medium = entities[mediumType][result[mediumType][0]];
        dispatch(uploadMediumFile(storyId, medium, file));
      },
    },
  });
};

export const createStoryCoverMedium = (storyId, file) => dispatch => {
  const tmpUrl = URL.createObjectURL(file);
  dispatch({
    type: types.CREATE_STORY_MEDIUM_COVER,
    promise: api.createStoryCoverMedium(storyId, file.name),
    meta: {
      storyId,
      tmpUrl,
      onSuccess: res => {
        const { result, entities } = res.data;
        const mediumType = Object.keys(entities).filter(key => key !== 'pages')[0];
        const medium = entities[mediumType][result[mediumType][0]];
        dispatch(uploadMediumFile(storyId, medium, file));
        dispatch(getStory(storyId));
      },
    },
  });
};

export const createMedium = ({ attrs, tmpUrl }) => ({
  type: types.CREATE_MEDIUM,
  promise: api.postMedium({ attrs }),
  meta: { tmpUrl },
});

export const receiveStoryMedium = (storyId, payload, file) => {
  const tmpUrl = URL.createObjectURL(file);
  return {
    type: types.RECEIVE_STORY_MEDIUM,
    payload,
    storyId,
    tmpUrl,
  };
};

export const setRejectedMedia = names => ({
  type: types.SET_REJECTED_MEDIA,
  names,
});

export const createMediaForMemoirRequestFiles = (storyRequestId, files, onSubmit) => async dispatch => {
  if (!files.length) return false;
  const sortedFiles = sortBy(Array.from(files), file => file.lastModified);

  const { invalidMedia, validMedia } = validateMedia(sortedFiles);

  const mediaToCreate = await Promise.all(
    validMedia
      .map(async file => {
        const processedFile = await processFile(file);
        return processedFile;
      })
      .filter(file => !!file)
  );

  const mediaToUpload = await promiseChain(
    mediaToCreate.map((file, i) => async () => {
      const [values, fileBlob] = file;

      const payload = await api.postMedium({
        attrs: {
          ...values,
          ownerId: storyRequestId,
          ownerType: 'MemoirStoryRequest',
        },
      });

      const { result, entities } = payload.data;

      const mediumType = Object.keys(entities).filter(key => key !== 'pages')[0];
      const createdMedium = entities[mediumType][result[mediumType][0]];

      onSubmit({// this will patch the memoir_story_request object, setting ‘video_request_id’ = the video.id
        video_request_id: createdMedium?.id
      })
      return [createdMedium, fileBlob];
    })
  );

  if (invalidMedia.length) {
    dispatch(setRejectedMedia(invalidMedia.map(file => file.name)));
  }

  return mediaToUpload.forEach(([createdMedium, fileBlob]) => {
    dispatch(uploadMediumFile(storyRequestId, createdMedium, fileBlob));
  });
};

export const createMediaForFiles = (storyId, files, position = 0, lastOnthePage = false, transcribe = false) => async dispatch => {
  if (!files.length) return false;
  const sortedFiles = sortBy(Array.from(files), file => file.lastModified);

  const { invalidMedia, validMedia } = validateMedia(sortedFiles);

  const mediaToCreate = await Promise.all(
    validMedia
      .map(async file => {
        const processedFile = await processFile(file);
        return processedFile;
      })
      .filter(file => !!file)
  );

  const mediaToUpload = await promiseChain(
    mediaToCreate.map((file, i) => async () => {
      const [values, fileBlob] = file;
      const payload = await api.createStoryMedium(
        storyId,
        formatMediumBody({
          ...values,
          ...(lastOnthePage ? { position } : { 'page-position': position + i }),
          ...(transcribe ? { transcribe } : {})
        })
      );
      dispatch(receiveStoryMedium(storyId, payload, fileBlob));

      // get created medium
      const { result, entities } = payload.data;
      const mediumType = Object.keys(entities).filter(key => key !== 'pages')[0];
      const createdMedium = entities[mediumType][result[mediumType][0]];

      return [createdMedium, fileBlob];
    })
  );

  if (invalidMedia.length) {
    dispatch(setRejectedMedia(invalidMedia.map(file => file.name)));
  }

  return mediaToUpload.forEach(([createdMedium, fileBlob]) => {
    dispatch(uploadMediumFile(storyId, createdMedium, fileBlob));
  });
};

export const createMediumWithFile = ({ ownerId, file, mediumType }) => async dispatch => {
  const [attributes, blob] = await processFile(file);
  const tmpUrl = URL.createObjectURL(blob);
  return dispatch({
    type: types.CREATE_MEDIUM,
    promise: api.postStoryMedium({ id: ownerId, attributes }),
    //   attrs: { ...values, ownerType, ownerId },
    // }),
    meta: {
      tmpUrl,
      onSuccess: ({ data }) => {
        const medium = Object.values(data.entities[mediumType])[0];
        dispatch(uploadMediumFile(null, medium, blob));
      },
    },
  });
};
