import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FaSpinner as QueuedIcon } from 'react-icons/fa';
import Button from '../../app/buttons';
import { Header, Title, Body } from '../../app/modal/v2/styled-info';

const DownloadConfirm = ({ onDismiss, email }) => (
  <Fragment>
    <Header>
      <QueuedIcon size={52} color="#999" />
      <Title>Download is underway</Title>
    </Header>
    <Body>
      <p>
This story is currently being generated and will be sent to your email
        {email}
      </p>
      <Button
        onClick={onDismiss}
        style={{ width: '100%', marginTop: '20px' }}
        primary
      >
        Got it
      </Button>
    </Body>
  </Fragment>
);

DownloadConfirm.defaultProps = {
  onDismiss: () => {},
  email: '',
};

DownloadConfirm.propTypes = {
  onDismiss: PropTypes.func,
  email: PropTypes.string,
};

export default DownloadConfirm;
