import { Form as FormikForm, Field } from 'formik';
import { FaPaperPlane as SendIcon } from 'react-icons/fa';
import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';

import { AddCommentButton, TextButton } from './styled';
import AutoGrowTextArea from '../../app/form/formik/auto-grow';

const Form = styled(FormikForm)`
  display: grid;
  grid-template-columns: auto max-content;
  grid-gap: 10px;

  textarea {
    width: 100%;
    height: 45px;
    border-radius: 25px;
    border: 1px solid ${props => props.theme.colours.border};
    padding: 0.75em 1em;
    font-size: 1em;
    outline: none;
  }
`;

const CommentForm = ({ errors, touched, isSubmitting, textButton }) => (
  <Form>
    <div>
      <Field
        name="text"
        placeholder="Leave a comment"
        style={{
          minHeight: '45px',
          maxHeight: '120px',
          resize: 'none',
          fontFamily: 'inherit',
        }}
        component={AutoGrowTextArea}
      />
      {errors.text && touched.text && <div>{errors.text}</div>}
    </div>
    {!textButton && (
      <AddCommentButton type="submit" disabled={isSubmitting}>
        <SendIcon size={20} />
      </AddCommentButton>
    )}
    {textButton && (
      <TextButton type="submit" disabled={isSubmitting}>
        Submit
      </TextButton>
    )}
  </Form>
);

CommentForm.defaultProps = {
  errors: {},
  touched: {},
  textButton: false,
};

CommentForm.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  errors: PropTypes.shape({}),
  touched: PropTypes.shape({}),
  textButton: PropTypes.bool,
};

export default CommentForm;
