import React from 'react';
import CollectionOnboardSlide from '../slide';
import bg1 from './assets/bg-1.png';
import bg2 from '../assets/bg-2.png';
import bg3 from '../assets/bg-3.png';
import bg4 from '../assets/bg-4.png';
import img2 from './assets/img-2.png';
import img3 from './assets/img-3.png';
import img4 from './assets/img-4.png';

export const SlideOne = () => (
  <CollectionOnboardSlide
    bg={bg1}
    header="My Stories"
    body="For any of your stories, especially those not in other collections. Trips, resolutions, gratitudes … anything you can imagine and want to save."
  />
);

export const SlideTwo = () => (
  <CollectionOnboardSlide
    bg={bg2}
    header="Flexible Sharing"
    body="Each story starts off private, and can later be shared at any time with anyone you choose."
    image={img2}
    imgAltText="selective sharing"
  />
);

export const SlideThree = () => (
  <CollectionOnboardSlide
    bg={bg3}
    header="Flexible Storytelling"
    body="Create new stories or add to an existing one over time — like adding your best shots to your trip each night."
    image={img3}
    imgAltText="marathon story"
  />
);

export const SlideFour = () => (
  <CollectionOnboardSlide
    bg={bg4}
    header="Super Easy to Organize"
    body="Use tags for easy finding and re-living!"
    image={img4}
    imgAltText="tags"
  />
);
