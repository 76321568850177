import { useMemo } from 'react';

const useParamsFromRoute = ({ type: entityType, id }) => {
  const type = useMemo(() => {
    switch (entityType) {
      case 'custom':
        return 'other-journals';
      case 'how-do-i':
        return 'how-do-i-collections';
      default:
        return entityType;
    }
  }, [entityType]);

  return { type, id };
};

export default useParamsFromRoute;
