
import React, { useState } from 'react';
import { get } from 'lodash';
import { useCurrentMemberId } from 'members/hooks/index';
import { Box, Flex, Text } from 'rebass';
import useSWR from 'swr';
import { updateMemberEmailNotificationSettings } from 'members/api';
import { useEntity, useEntityActionCreators } from 'entities/hooks';
import { SmallerTableCell } from 'members/profile/styled';

const useMemberSettingsSWR = id => useSWR(`/v2/members/${id}/settings`);

const NotificationSettings = () => {
  const [loading, setLoading] = useState(false);
  const currentMemberId = useCurrentMemberId();
  const { receive } = useEntityActionCreators();

  const { data } = useMemberSettingsSWR(currentMemberId);

  const { entity } = useEntity({ type: 'member-settings', id: get(data, 'data.data.id', '') });

  const handleSubmit = notify => {
    setLoading(true);
    updateMemberEmailNotificationSettings(currentMemberId, notify).then(payload => {
      receive({ payload });
      setLoading(false);
    });
  };

  if (!data) return false;

  return (
    <Box bg="white" css="width: 100%;">
      <SmallerTableCell>
        <p>Notifications</p>
      </SmallerTableCell>
      <Flex p={3} css="border: 1px solid #e6e6e6;">
        <Text fontSize={1} color="mutedText" mr="auto">
          Email Notifications
        </Text>
        <input
          type="checkbox"
          checked={entity['email-notifications']}
          disabled={loading}
          onChange={() => handleSubmit(!entity['email-notifications'])}
        />
      </Flex>
    </Box>
  );
};

export default NotificationSettings;
