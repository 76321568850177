
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import { Flex, Button, Text, Image, Card } from 'rebass';
import { get, isEmpty } from 'lodash';
import useSWR from 'swr';
import { useEntity, useEntities } from 'entities/hooks/index';
import Modal from 'app/modal/v3/index';
import { useStoryFromStoryShare, useStoryDate } from 'stories/hooks/index';
import { useImage } from 'entities/hooks/useImage';
import { useCurrentMemberId } from 'members/hooks/index';
import ConnectionStatus from './connection-status';

const FriendProfile = () => {
  const { id } = useParams();
  const { replace, goBack } = useHistory();
  const { entity: member } = useEntity({ type: 'members', id });
  const currentMemberId = useCurrentMemberId();
  const [filter, setFilter] = useState('shared_by');
  const { data } = useSWR(`/v3/members/${id}/stories/${filter}`);
  const shares = get(data, 'data.data', []);
  const allConnections = useEntities('connections');

  if (member.id === currentMemberId) return <Redirect to="/profile" />;

  const connection = Object.values(allConnections).find(elem => elem.contact.id === id) || {};

  const onClose = () => replace('/friends');
  const onBack = () => goBack();

  return (
    <Modal close={{ onClose }} back={{ onBack }} title={`Stories with ${member['first-name']}`}>
      <Flex p={3} flexDirection="column">
        <ConnectionStatus connection={connection} name={member['first-name']} />
        {connection && connection.status === 'approved' && (
          <>
            <Flex justifyContent="space-around">
              <FilterButton onClick={() => setFilter('shared_by')} active={filter === 'shared_by'}>
                Shared by {member['first-name']}
              </FilterButton>
              <FilterButton onClick={() => setFilter('shared_to')} active={filter === 'shared_to'}>
                Shared with {member['first-name']}
              </FilterButton>
            </Flex>
            <Flex flexDirection="column" pt={2}>
              {shares.map(share => (
                <StoryItem key={share.id} id={share.id} type={share.type} />
              ))}
              {isEmpty(shares) && data && (
                <Text fontWeight="bold" my={3} mx="auto">
                  {filter === 'shared_by' &&
                    `${member['first-name']} has not shared any stories with you`}
                  {filter === 'shared_to' &&
                    `You have not shared any stories with ${member['first-name']}`}
                </Text>
              )}
            </Flex>
          </>
        )}
      </Flex>
    </Modal>
  );
};

const StoryItem = ({ id, type }) => {
  const { push } = useHistory();
  const { story } = useStoryFromStoryShare({ id, type });
  const storyDate = useStoryDate(story);
  const coverSrc = useImage({ ...(story['cover-image'] || {}), size: 7 });
  return (
    <Button
      onClick={() => push(`/story/${story.id}`)}
      variant="unset"
      css="cursor: pointer; display: flex; align-items: center; margin-bottom: 8px;"
    >
      {coverSrc ? (
        <Image src={coverSrc} height={60} css="object-fit: cover; width: 60px;" />
      ) : (
        <Card css="width: 60px; height: 60px;" bg="blueBackground" />
      )}
      <Flex flexDirection="column" ml={2}>
        <Text fontWeight="bold">{story.title}</Text>
        <Text>{storyDate}</Text>
      </Flex>
    </Button>
  );
};
StoryItem.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

const FilterButton = ({ onClick, active, children }) => (
  <Button
    onClick={onClick}
    variant="unset"
    style={
      active ? { fontWeight: 'bold', color: '#7a69bf', borderBottom: '4px solid #7a69bf' } : {}
    }
  >
    {children}
  </Button>
);
FilterButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  children: PropTypes.number.isRequired,
};

export default FriendProfile;
