
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Text, Button, Flex } from 'rebass';
import { singularize } from 'inflected';
import Modal from '../modal/v2';
import { CurrentCollectionContext } from '../../collections/current-collection-context';

const ParticipantPaywall = ({ onClose }) => {
  const { currentCollection } = useContext(CurrentCollectionContext);
  const collectionType = currentCollection && currentCollection.type;

  return (
    <Modal onClose={onClose}>
      <Flex p={4} flexDirection="column" css="text-align: center;">
        <Text>
          The owner of the {singularize(collectionType || '')} requires a subscription for this
          action
        </Text>
        <Button onClick={onClose} variant="primary" mt={3} ml="auto">
          Ok
        </Button>
      </Flex>
    </Modal>
  );
};

ParticipantPaywall.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ParticipantPaywall;
