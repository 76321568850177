import React from 'react';

const AddTextIcon = props => (
  <svg viewBox="0 0 22 14" width="1em" height="1em" {...props}>
    <path
      d="M11.374 11.596c.06.156.09.3.09.432 0 .312-.102.567-.306.765a.98.98 0 0 1-.702.297.944.944 0 0 1-.585-.198 1.287 1.287 0 0 1-.405-.54l-.936-2.178H3.166L2.23 12.37a1.107 1.107 0 0 1-.387.531.947.947 0 0 1-.567.189c-.312 0-.549-.084-.711-.252-.162-.168-.243-.396-.243-.684 0-.108.018-.222.054-.342L4.858 1.048c.096-.24.24-.426.432-.558a.943.943 0 0 1 .648-.162 1.066 1.066 0 0 1 1.008.72l4.428 10.548zM4.048 8.104h3.6L5.83 3.892 4.048 8.104zm16.56-4.914c.312 0 .57.102.774.306.204.204.306.468.306.792v7.614c0 .312-.102.573-.306.783-.204.21-.462.315-.774.315a1.02 1.02 0 0 1-.765-.306 1.132 1.132 0 0 1-.315-.774c-.3.348-.705.645-1.215.891a3.662 3.662 0 0 1-1.611.369 4.115 4.115 0 0 1-2.25-.639c-.672-.426-1.203-1.017-1.593-1.773-.39-.756-.585-1.614-.585-2.574 0-.96.192-1.821.576-2.583.384-.762.909-1.356 1.575-1.782a4.009 4.009 0 0 1 2.205-.639 4.17 4.17 0 0 1 1.62.315c.504.21.93.477 1.278.801v-.018c0-.312.102-.573.306-.783.204-.21.462-.315.774-.315zm-3.636 8.01c.78 0 1.416-.285 1.908-.855.492-.57.738-1.287.738-2.151 0-.864-.246-1.584-.738-2.16-.492-.576-1.128-.864-1.908-.864-.768 0-1.398.288-1.89.864-.492.576-.738 1.296-.738 2.16 0 .864.243 1.581.729 2.151.486.57 1.119.855 1.899.855z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default AddTextIcon;
