import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Flex, Box, Image } from 'rebass';
import { FiRotateCw, FiCheckCircle } from 'react-icons/fi';
// import { saveAs } from 'file-saver';
import Grid from 'app/grid/index';
import { checkIsIpadUser, useCheckIsIosUser, useLandScapeCheckHook } from 'story-media/hooks';
import CirclePlayButton from './CirclePlayButton';
import ButtonWithIcon from './ButtonWithIcon';

export const ControlButtons = ({ onReject, rejectLabel = "Reshoot", onAccept, handlePlayPause, isPlaying }) => {

  return <Grid
    gridTemplateColumns={['max-content max-content', '1fr max-content 1fr']}
    gridTemplateRows={['1fr max-content', '1fr']}
    gridAutoFlow="dense"
    gridGap={[2, 3]}
    alignItems="center"
    justifyContent="center"
    p={[2, 3]}
    backgroundColor="rgba(0, 0, 0, 0.33)"
    width="100%"
  >
    <Flex sx={{ gridRowStart: [2, 1] }} justifyContent="flex-end">
      <ButtonWithIcon type="button" icon={FiRotateCw} onClick={onReject}>
        {rejectLabel}
      </ButtonWithIcon>
    </Flex>
    <Flex
      sx={{ gridRowStart: 1, gridColumn: ['1 / span 2', 'inherit'] }}
      justifyContent="center"
    >
      <CirclePlayButton size={72} isPlaying={isPlaying} onClick={handlePlayPause} />
    </Flex>
    <Flex sx={{ gridRowStart: [2, 1] }}>
      <ButtonWithIcon
        type="button"
        icon={FiCheckCircle}
        variant="special"
        onClick={() => {
          onAccept();
        }}
      >
        Upload
      </ButtonWithIcon>
    </Flex>
  </Grid>
}

function VideoPreview({
  blob,
  onReject,
  onAccept,
  height,
  mt,
  ...props
}) {
  const videoRef = useRef(null);
  const [objectURL, setObjectURL] = useState(undefined);
  const [isPlaying, setIsPlaying] = useState(false);
  const [gridHeight, setGridHeight] = useState(height);

  const isIOSUser = useCheckIsIosUser();
  const isIpadUser = checkIsIpadUser();
  const [isLandscape] = useLandScapeCheckHook();

  useEffect(() => {
    if (isLandscape) setGridHeight('100vh');
    else setGridHeight(height);
  }, [isLandscape]);

  useEffect(() => {
    if (!objectURL) {
      setObjectURL(URL.createObjectURL(blob));
    }
    return () => {
      URL.revokeObjectURL(objectURL);
    };
  }, [objectURL, blob]);

  const handlePlayPause = useCallback(() => {
    if (!isPlaying) {
      setIsPlaying(true);
      videoRef.current.play();
    }
    if (isPlaying) {
      setIsPlaying(false);
      videoRef.current.pause();
    }
  }, [isPlaying, videoRef, setIsPlaying]);

  return (
    <Box mt={mt} textAlign="center">
      <Grid {...props} height={gridHeight}>
        <Image
          ref={videoRef}
          as="video"
          src={objectURL}
          // autoPlay={isIOSUser}
          height={isIpadUser || isIOSUser ? '100vh' : '100%'}
          width="100%"
          onPause={() => setIsPlaying(false)}
          onEnded={() => {
            setIsPlaying(false);
            videoRef.current.currentTime = 0;
          }}
          sx={{ objectFit: 'contain', bg: 'black', gridArea: '1 / 1 / 2 / 2' }}
        >
          <source src={objectURL} />
          <p>Your browser cannot preview this video type</p>
          <p>
            Once uploaded, the video will be converted for inclusion with the celebration video.
          </p>
        </Image>
        <Flex
          alignItems="flex-end"
          justifyContent="center"
          sx={{ gridArea: '1 / 1 / 2 / 2', zIndex: 1 }}
          mb={isIOSUser && '64px'}
        >
          <ControlButtons isPlaying={isPlaying} handlePlayPause={handlePlayPause} onReject={onReject} onAccept={onAccept} />
        </Flex>
      </Grid>
    </Box>
  );
}
VideoPreview.propTypes = {
  onAccept: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  blob: PropTypes.shape({ type: PropTypes.string }).isRequired,
  height: PropTypes.string,
};
VideoPreview.defaultProps = { height: 360 };

export default VideoPreview;
