import { Box, Flex } from 'rebass';
import React from 'react';

export default props => (
  <Flex
    justifyContent="center"
  >
    <Box flex="0 1 600px" {...props} />
  </Flex>
);
