import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { receiveEntity } from '../actions';

const useReceiver = () => {
  const dispatch = useDispatch();
  return useCallback(opts => dispatch(receiveEntity(opts)), [dispatch]);
};

export default useReceiver;
