import { Formik } from 'formik';
import { connect } from 'react-redux';
import { object, string } from 'yup';
import PropTypes from 'prop-types';
import React from 'react';
import pick from 'lodash/pick';

import { historyShape } from '../../app/shapes';
import { selectCurrentMember } from '../selectors';
import { updateMember, uploadMemberAvatar } from '../actions';
import Modal from '../../app/modal/v2';
import ProfileForm from './profile-form';

const ProfileEdit = ({ history: { push }, member, ...props }) => (
  <Modal
    onClose={() => push('/profile')}
    closeButton
    style={{ padding: '20px', maxWidth: '450px' }}
  >
    <Formik
      initialValues={{
        'first-name': member['first-name'],
        'last-name': member['last-name'],
        'lock-version': member['lock-version'],
        avatar: '',
      }}
      onSubmit={async ({ avatar, ...values }, { setSubmitting }) => {
        await props.updateMember(member.id, {
          ...pick(values, ['first-name', 'last-name', 'lock-version']),
        });
        if (avatar instanceof Blob) {
          await props.uploadMemberAvatar(member.id, avatar);
        }
        setSubmitting(false);
        push('/profile');
      }}
      validationSchema={object().shape({
        'first-name': string().required('First Name is required'),
        'last-name': string().required('Last Name is required'),
      })}
      render={formikProps => <ProfileForm {...formikProps} avatarId={member['avatar-image'].id} />}
    />
  </Modal>
);

ProfileEdit.propTypes = {
  history: historyShape.isRequired,
  member: PropTypes.shape({
    'first-name': PropTypes.string.isRequired,
    'last-name': PropTypes.string.isRequired,
    'lock-version': PropTypes.number.isRequired,
  }).isRequired,
  updateMember: PropTypes.func.isRequired,
  uploadMemberAvatar: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  member: selectCurrentMember(state),
});

export default connect(
  mapStateToProps,
  { updateMember, uploadMemberAvatar }
)(ProfileEdit);
