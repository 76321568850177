import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Modal from 'app/modal/v3';
import { Flex, Box, Text, Heading, Button, Link } from 'rebass';
import { useEntity } from 'entities/hooks';
import { setCheckmark } from 'ui/actions';
import { useParams } from 'react-router-dom';
import { useClipboard } from 'use-clipboard-copy';
import { FaCopy } from 'react-icons/fa';

const InvitationURLModal = ({ onClose }) => {
  const { meetingId } = useParams();
  const dispatch = useDispatch();
  const { meeting } = useEntity({ type: 'meetings', id: meetingId });
  const clipboard = useClipboard();
  const onCopy = useCallback(
    e => {
      clipboard.copy(meeting['public-url']);
      dispatch(setCheckmark({ message: 'Link Copied!', top: e.clientY - 75 }));
      onClose();
    },
    [clipboard, dispatch, meeting, onClose]
  );

  return (
    <Modal title="Chat Link" close={{ onClose }}>
      <Flex flexDirection="column" alignItems="center" p={3}>
        <Text textAlign>Copy and send link to a friend</Text>
        <Button
          py={3}
          px={4}
          variant="muted"
          bg="brand"
          color="white"
          fontSize={2}
          type="button"
          onClick={onCopy}
          my={2}
          sx={{ display: 'flex' }}
        >
          <Text as={FaCopy} mr={1} />
          <Text ml={1}>Copy</Text>
        </Button>
        <Link fontSize={1} color="mutedText" href={meeting['public-url']}>
          {meeting['public-url']}
        </Link>
      </Flex>
    </Modal>
  );
};

InvitationURLModal.propTypes = { onClose: PropTypes.func.isRequired };

export default InvitationURLModal;
