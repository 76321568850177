import { bottom, left, right, top, display } from 'styled-system';
import styled from '@emotion/styled';

export default styled.div`
  position: absolute;
  ${right};
  ${left};
  ${top};
  ${bottom};
  ${display};
`;
