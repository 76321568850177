import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Wrapper, HeaderLabel, AvatarContainer } from './styled';
import Avatar from '../../../members/avatar';
import Modal from '../index';

const NotificationModal = ({ member }) => (
  <Wrapper>
    <Modal
      closeUrl="/friends"
      footer="Got It"
    >
      <HeaderLabel>
        You are now connected to <br />{member['full-name']}!
      </HeaderLabel>
      <AvatarContainer>
        <Avatar id={member.id} size="100" />
      </AvatarContainer>
    </Modal>
  </Wrapper>
);

NotificationModal.propTypes = {
  member: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const { connectionId } = ownProps.match.params;
  const { [connectionId]: connection } = state.entities.connections;
  const { [connection.contact.id]: member } = state.entities.members;

  return { member };
};

export default connect(mapStateToProps)(NotificationModal);
