import { useContext } from 'react';
import { CurrentCollectionContext } from 'collections/current-collection-context';
import { useRouteFromEntityParams } from './index';

const useCurrentCollectionRoute = () => {
  const { currentCollection } = useContext(CurrentCollectionContext);
  return useRouteFromEntityParams(currentCollection);
};

export default useCurrentCollectionRoute;
