import { Formik } from 'formik';
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import Markdown from 'markdown-to-jsx';
import { motion } from 'framer-motion';

import FriendProfileLink from 'friends/profile/link';
import { useScrollToItem } from 'contexts/scroll';
import { CommentOuter, IconButton, Body, Time, Author, ByLine } from './styled';
import { receiveMediumActivity, removeComment } from '../actions';
import { updateMediumActivity } from '../api';
import { useCurrentMemberId } from '../../members/hooks';
import { useEntity } from '../../entities/hooks';
import { useToggle } from '../../app/hooks';
import Form from './form';
import MemberAvatar from '../../members/avatar';
import MemberName from '../../members/name';
import TimeAgo from '../../app/time-ago';
import { H1, H2, H3, H4, H5, H6 } from '../../story-media/medium-types/styled';

const StoryPageComment = ({ id, storyId, ...props }) => {
  const [on, toggle] = useToggle();
  const currentMemberId = useCurrentMemberId();
  const { entity: comment } = useEntity({ type: 'comments', id });
  const [scrollRef, shouldScroll] = useScrollToItem(id);

  if (!comment.member) return false;

  const memberIsOwner = comment.member.id === currentMemberId;

  return (
    <CommentOuter
      as={motion.div}
      initial={shouldScroll ? { y: -1, background: '#fffbdd' } : {}}
      animate={{ background: '#fff', y: 0 }}
      transition={{ duration: 2 }}
      py={2}
      px={[2, 3]}
      gridGap={[2, 3]}
      ref={scrollRef}
    >
      <FriendProfileLink id={comment.member.id}>
        <MemberAvatar id={comment.member.id} />
      </FriendProfileLink>
      <div>
        <ByLine>
          <Author>
            <MemberName id={comment.member.id} />
          </Author>
          <Time>
            <TimeAgo date={comment['created-at']} />
          </Time>
        </ByLine>
        <Body>
          <Markdown
            options={{
              overrides: {
                h1: H1,
                h2: H2,
                h3: H3,
                h4: H4,
                h5: H5,
                h6: H6,
              },
            }}
          >
            {comment.text}
          </Markdown>
        </Body>
        {on && (
          <Formik
            initialValues={{ text: comment.text }}
            onSubmit={({ text }, actions) => {
              updateMediumActivity(comment.id, {
                data: {
                  id: comment.id,
                  attributes: { 'lock-version': comment['lock-version'], text },
                },
              })
                .then(res => {
                  props.receiveMediumActivity(res);
                  actions.setSubmitting(false);
                  toggle();
                })
                .catch(() => actions.setSubmitting(false));
            }}
            render={formikProps => <Form {...formikProps} textButton />}
          />
        )}
      </div>
      {memberIsOwner && (
        <div>
          <IconButton onClick={toggle}>
            <FontAwesome name="pencil" />
          </IconButton>
          <IconButton onClick={() => props.removeComment(comment.id, storyId)}>
            <FontAwesome name="trash-o" />
          </IconButton>
        </div>
      )}
    </CommentOuter>
  );
};

StoryPageComment.propTypes = {
  storyId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  removeComment: PropTypes.func.isRequired,
  receiveMediumActivity: PropTypes.func.isRequired,
};

export default connect(null, { removeComment, receiveMediumActivity })(StoryPageComment);
