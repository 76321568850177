import { Route, Switch } from 'react-router-dom';
import React, { useCallback } from 'react';

import { useMatch } from '../../app/hooks';
import { useHistory } from 'react-router-dom';
import { useParamsFromRoute, useRouteFromEntityParams } from '../hooks';
import EditBasicJournal from './basic';
import EditChildJournal from './child-journal';
import EditFamilyJournal from './family-journal';
import EditPersonalJournal from '../personal-journals/edit';
import EditMemoir from './memoir';
import Modal from '../../app/modal/v2';

const EditCollection = React.memo(() => {
  const {
    params: { id, type },
  } = useMatch();
  const route = useRouteFromEntityParams({ type, id });
  const entityParams = useParamsFromRoute({ type, id });
  const { replace } = useHistory();
  const onClose = useCallback(() => replace(route), [replace, route]);

  if (type === 'personal-journals') return <EditPersonalJournal />;

  return (
    <Modal closeButton onClose={onClose}>
      <Modal.InnerWrap>
        <Modal.Title>Memoir Settings</Modal.Title>
        <Switch>
          <Route path="/memoirs/:id/edit" component={EditMemoir} />
          <Route path="/family-journals/:id/edit" component={EditFamilyJournal} />
          <Route
            path="/child-journals/:id/edit"
            render={() => (
              <EditChildJournal type={entityParams.type} id={entityParams.id} onSuccess={onClose} />
            )}
          />
          <Route
            path="/:type/:id/edit"
            render={() => (
              <EditBasicJournal type={entityParams.type} id={entityParams.id} onSuccess={onClose} />
            )}
          />
        </Switch>
      </Modal.InnerWrap>
    </Modal>
  );
});

export default EditCollection;
