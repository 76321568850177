import React from 'react';
import PropTypes from 'prop-types';
import { Button, Text } from 'rebass';
import { IoIosPause, IoIosPlay } from 'react-icons/io';

function CirclePlayButton({ isPlaying, size, sx, ...props }) {
  return (
    <Button
      type="button"
      p={0}
      sx={{
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: size,
        width: size,
        outline: 'none',
        ...sx,
      }}
      {...props}
    >
      <Text color="white" as={isPlaying ? IoIosPause : IoIosPlay} size={size * 0.6} ml="2px" />
    </Button>
  );
}

CirclePlayButton.propTypes = {
  size: PropTypes.number,
  isPlaying: PropTypes.bool.isRequired,
  sx: PropTypes.shape({}),
};
CirclePlayButton.defaultProps = {
  size: 50,
  sx: {},
};

export default CirclePlayButton;
