import { Formik } from 'formik';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { object, string } from 'yup';
import PropTypes from 'prop-types';
import React from 'react';
import store from 'store';

import { getMember, useGetMemberCollectionSWR } from '../api';
import { updateMember } from '../actions';
import { useCurrentMember } from '../hooks';
import Form from './form';
import FormPage, { Title } from './form-page';
import useSendToGTM from 'utils/googleTagManager';

const CompleteProfile = ({ label, ...props }) => {
  const { push } = useHistory();
  useGetMemberCollectionSWR();
  const member = useCurrentMember();
  const sendToGTM = useSendToGTM()
  return (
    <FormPage>
      <Title>{label || 'Almost There!'}</Title>
      <Formik
        initialValues={{
          'first-name': member['first-name'] || '',
          'last-name': member['last-name'] || '',
          'lock-version': member['lock-version'],
        }}
        onSubmit={async ({ ...values }, { setSubmitting }) => {
          const res = await getMember('me');
          const { 'lock-version': lockVersion, id } = Object.values(res.data.entities.members)[0];
          await props.updateMember(member.id, { ...values, 'lock-version': lockVersion });
          const gtmObject = {
            event: 'memoirs.registered',
            firstName: values['first-name'],
            lastName: values['last-name'],
          };
          gtmObject.User_Id = id;

          sendToGTM(gtmObject);
          setSubmitting(false);
          if (store.get('postLoginRedirect')) return false;
          return push('/shared-with-me');
        }}
        validationSchema={object().shape({
          'first-name': string()
            .required('First Name is required')
            .nullable(),
          'last-name': string()
            .required('Last Name is required')
            .nullable(),
        })}
        component={Form}
      />
    </FormPage>
  );
};

CompleteProfile.propTypes = {
  updateMember: PropTypes.func.isRequired,
};

export default connect(null, { updateMember })(CompleteProfile);
