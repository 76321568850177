import styled from '@emotion/styled';
import {
  maxHeight,
  maxWidth,
  grid,
  alignItems,
  space,
  border,
  color,
  layout,
  justifyContent,
} from 'styled-system';

const Grid = styled('div')`
  ${layout};
  ${space};
  ${maxHeight};
  ${maxWidth};
  ${grid};
  ${border};
  ${color};
  ${alignItems};
  ${justifyContent}
  display: grid;
`;

export default Grid;
