import React, { useRef, useMemo, useEffect, createContext, useState, useContext } from 'react';

export const ScrollContext = createContext({});

export const ScrollProvider = props => {
  const [scrollItemId, setScrollItemId] = useState('');

  return <ScrollContext.Provider value={{ scrollItemId, setScrollItemId }} {...props} />;
};

// id can be a string or an array of strings
export const useScrollToItem = id => {
  const ref = useRef(null);
  const { scrollItemId, setScrollItemId } = useContext(ScrollContext);
  const scrollItems = Array.isArray(id) ? id : [id];

  const shouldScroll = useMemo(() => scrollItems.includes(scrollItemId), [
    scrollItems,
    scrollItemId,
  ]);

  useEffect(() => {
    if (shouldScroll && ref) {
      ref.current.scrollIntoView({ block: 'center' });
      setScrollItemId('');
    }
  }, [shouldScroll, ref, setScrollItemId]);

  return [ref, shouldScroll];
};
