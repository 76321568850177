import FontAwesome from 'react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from '@tippy.js/react';

import { IoIosMore } from 'react-icons/io';
import CollectionPermission from 'collections/permissions/index';
import { TooltipWrap, TooltipButton } from '../../../stories/story-detail-header/styled';
import { useValue } from '../../../app/hooks';
import { useHistory } from 'react-router-dom';
import { useRouteFromEntityParams } from '../../hooks';
import DetailHeaderButton from '../../../app/detail/button';

const ChronicleOptions = ({ type, id, isOwner }) => {
  const { replace } = useHistory();
  const [on, set] = useValue(false);
  const route = useRouteFromEntityParams({ type, id });

  const handleEditMemoirClick = () => {
    set(false);
    replace(`${route}/edit`);
  };

  return (
    <Tooltip
      placement="bottom right"
      theme="light"
      trigger="manual"
      arrow="small"
      interactive
      isVisible={on}
      onHidden={() => on && set(false)}
      content={
        <TooltipWrap>
          <CollectionPermission feature="EDIT_COLLECTION" id={id} type={type}>
            <TooltipButton onClick={handleEditMemoirClick}>
              <FontAwesome name="pencil" /> Edit
            </TooltipButton>
          </CollectionPermission>
          <CollectionPermission feature="EXPORT_COLLECTION" id={id} type={type}>
            <TooltipButton
              onClick={() => {
                set(false);
                replace(`${route}/export`);
              }}
            >
              <FontAwesome name="cloud-download" /> Export
            </TooltipButton>
          </CollectionPermission>
          <CollectionPermission feature="DELETE_COLLECTION" id={id} type={type}>
            <TooltipButton
              onClick={() => {
                set(false);
                replace(`${route}/delete`);
              }}
              destructive
            >
              <FontAwesome name="trash" /> Delete
            </TooltipButton>
          </CollectionPermission>
          <CollectionPermission feature="LEAVE_COLLECTION" id={id} type={type}>
            <TooltipButton
              onClick={() => {
                set(false);
                replace(`${route}/delete-share`);
              }}
            >
              Leave
            </TooltipButton>
          </CollectionPermission>
        </TooltipWrap>
      }
    >
      <DetailHeaderButton
        icon={IoIosMore}
        onClick={() => (on ? set(false) : set(true))}
        borderColor="cyan-200"
        bg="cyan-800"
      />
    </Tooltip>
  );
};

ChronicleOptions.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  isOwner: PropTypes.bool.isRequired,
};

export default ChronicleOptions;
