import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import store from 'store';
import { Flex } from 'rebass';
import { useDispatch } from 'react-redux';
import MemberOnboardLoading from 'members/complete-profile/onboarding-loading';
import { useGetMemberSWR } from '../members/api';
import { setAuth } from './actions';
import CompleteProfile from '../members/complete-profile';
import Loading from '../app/loading/index';
import { useEntity } from '../entities/hooks/index';
import { useCurrentMemberId } from '../members/hooks';
import { useCreateNewAccount } from './hooks';
import LoginScreen from 'memoirs/new/LoginScreen';
import Header from 'app/navigation/header/index';
// import authURL from '../utils/auth-url';

const AuthMember = ({ children }) => {
  const { push } = useHistory();

  const dispatch = useDispatch();
  const createNewUser = useCreateNewAccount();
  const { data, error, revalidate } = useGetMemberSWR('me', {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  });
  const { pathname } = useLocation();

  const isPublicRoute = pathname.includes('/tell_a_story') || pathname.includes('/get-started')
  const currentMemberId = useCurrentMemberId();
  const { member } = useEntity({
    type: 'members',
    id: currentMemberId || '',
  });

  useEffect(() => {
    if (data) {
      const storeToken = store.get('auth_token');
      const { id: memberId } = data.data.data;
      const token = `Token ${storeToken}`;
      dispatch(setAuth({ token, memberId }));
    }
  }, [data, dispatch]);

  async function createNewUserRequest() {
    await createNewUser();
    revalidate()
  }

  useEffect(() => {
    if (pathname.includes('/get-started') && member?.['onboarded'] === true)
      push('/')
  }, [pathname, member?.['onboarded']])


  useEffect(() => {
    if (isPublicRoute && !store.get('auth_token'))
      createNewUserRequest()
  }, [isPublicRoute])

  useEffect(() => {
    if (error) {
      store.set('postLoginRedirect', pathname);
    }
  }, [error, pathname, isPublicRoute]);

  if ((error || member?.email === null) && !isPublicRoute) return <Flex flexDirection="column">
    <Header publicHeader />
    <LoginScreen />;
  </Flex>;

  if (!currentMemberId) return <Loading />;
  if (!isPublicRoute) {
    if (!member['first-name'] || !member['last-name']) return <CompleteProfile />;

    if (!member.onboarded) return <MemberOnboardLoading />;
  }

  if (currentMemberId) return children;

  return false;
};
AuthMember.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthMember;
