import React from 'react';
import CollectionOnboardSlide from '../../collections/onboard/slide';
import bg1 from './assets/bg-1.png';
import bg2 from './assets/bg-2.png';
import bg3 from '../../collections/onboard/assets/bg-1.png';
import img3 from './assets/img-3.png';
import img4 from './assets/img-4.png';

export const SlideOne = () => (
  <CollectionOnboardSlide
    header="Private. Secure. Beautiful."
    body={
      <p style={{ color: 'white' }}>
        Share the <strong>whole</strong> story, effortlessly with friends and family - privately and
        beautifully.
      </p>
    }
    bg={bg1}
    style={{ backgroundPosition: 'top', color: 'white' }}
  />
);

export const SlideTwo = () => (
  <CollectionOnboardSlide
    header={
      <h1 style={{ marginTop: 'auto', color: 'white' }}>
        Protect
        <br />
        <span style={{ fontSize: '22px', fontWeight: 'normal' }}>
          the privacy of your little ones
        </span>
      </h1>
    }
    body={
      <p style={{ marginBottom: '60px', color: 'white' }}>
        You have complete <strong>ownership</strong> & <strong>control</strong> of your data.
      </p>
    }
    bg={bg2}
  />
);

export const SlideThree = () => (
  <CollectionOnboardSlide
    header="Share. Keep. Enjoy."
    body="Your family's daily lives are full of wonderful memories worth keeping, sharing, and enjoying over and over."
    bg={bg3}
    image={img3}
    imgAltText="story about family visiting a farm"
  />
);

export const SlideFour = () => (
  <CollectionOnboardSlide
    header="Easily Organized"
    body="Share and preserve those precious moments. Stories are easily organized on LifeTales. Enjoy them with the whole family!"
    bg={bg3}
    image={img4}
    imgAltText="stories organized into collections"
  />
);
