import React, { createContext } from 'react';

export const MoveContext = createContext({});

class Menu extends React.Component {
  static Consumer = MoveContext.Consumer;

  state = {
    moveStoryPageId: undefined,
    moveStoryPagePosition: undefined,
    setMoveStoryPage: (id, position) => {
      this.setState({ moveStoryPageId: id, moveStoryPagePosition: position });
    },
    resetMoveStoryPage: () => {
      this.setState({ moveStoryPageId: undefined, moveStoryPagePosition: undefined });
    },
  }

  render() {
    return (
      <MoveContext.Provider value={{ ...this.state }} {...this.props} />
    );
  }
}

export default Menu;
