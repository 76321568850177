import { Formik } from 'formik';
import { isEmpty, omit } from 'lodash';
import { object, string } from 'yup';
import PropTypes from 'prop-types';
import React from 'react';

import { patchCollection } from '../../api';
import { prepareAttrs } from '../../../utils/api';
import { useCreateCover } from '../../hooks';
import { useEntity, useEntityActionCreators } from '../../../entities/hooks';
import { useJsDate, useMatch } from '../../../app/hooks';
import FormBody from './form';

const EditChildJournal = ({ onSuccess }) => {
  const {
    params: { id },
  } = useMatch();
  const type = 'child-journals';
  const { receive } = useEntityActionCreators();
  const { entity: collection } = useEntity({ type, id });
  const initialDate = useJsDate(collection['date-of-birth'] || collection['created-at']);
  const createCover = useCreateCover();

  if (isEmpty(collection)) return false;

  return (
    <Formik
      initialValues={{
        'first-name-noac': collection['first-name'],
        gender: collection.gender,
        'date-of-birth': initialDate,
        cover: '',
      }}
      onSubmit={async ({ cover, ...values }, { setSubmitting }) => {
        const attrs = prepareAttrs(values);
        if (cover instanceof Blob) {
          await createCover({ id, type, cover });
        }
        const payload = await patchCollection({
          type,
          id,
          attributes: { id: collection.id, ...attrs },
        });
        // omit cover-image from patch collection response
        const updatedChildJournal = Object.values(payload.data.entities['child-journals'])[0];
        payload.data.entities['child-journals'][updatedChildJournal.id] = omit(
          updatedChildJournal,
          ['subject-avatar-id', 'subject-avatar']
        );
        receive({ payload });
        setSubmitting(false);
        onSuccess();
      }}
      validationSchema={object().shape({
        'first-name-noac': string().required('First Name is required'),
      })}
      render={formikProps => <FormBody {...formikProps} collection={collection} type={type} />}
    />
  );
};

EditChildJournal.propTypes = {
  onSuccess: PropTypes.func,
};

EditChildJournal.defaultProps = {
  onSuccess: () => {},
};

export default EditChildJournal;
