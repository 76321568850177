import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Box } from 'rebass';
import React, { useContext } from 'react';
import { pick, omit } from 'lodash';
import { object, string } from 'yup';
import Modal from '../../app/modal/v3';
import { useEntity } from '../../entities/hooks';
import SettingsForm from './form';
import { requestEntities } from '../../entities/actions';
import { updateStory, getStory } from '../api';
import { createStoryCoverMedium } from '../../story-media/actions';
import { useStoryWithJournalId } from '../hooks/index';
import { StoryOptionsContext } from '../options/index';

const getLockVersion = async ({ promise, entityType, id }) => {
  const {
    data: { entities },
  } = await promise();
  return entities[entityType][id]['lock-version'];
};

const StorySettings = ({ onBack }) => {
  const { storyId, onClose } = useContext(StoryOptionsContext);
  const { entity } = useEntity({ id: storyId, type: 'stories' });
  const story = useStoryWithJournalId(entity);
  const { id } = story;
  const dispatch = useDispatch();
  const initialValues = pick(story, ['title', 'began-on', 'ended-on', 'journal-id']);

  return (
    <Modal
      back={{ onBack }}
      close={{ onClose }}
      title="Story Settings"
      footerOffset={40}
      zIndex={10001}
    >
      <Box p={3}>
        <Formik
          initialValues={initialValues}
          validationSchema={object().shape({
            title: string().required(),
          })}
          onSubmit={async values => {
            const lockVersion = await getLockVersion({
              promise: () => getStory(id),
              entityType: 'stories',
              id,
            });

            dispatch(
              requestEntities(
                updateStory(story.id, {
                  'lock-version': lockVersion,
                  ...omit(values, 'cover'),
                })
              )
            );
            if (values.cover) {
              const fileBlob = await fetch(values.cover).then(res => res.blob());
              fileBlob.name = 'story-cover.png';
              await dispatch(createStoryCoverMedium(id, fileBlob));
            }
            onClose();
          }}
          render={formikProps => (
            <SettingsForm {...formikProps} handleCancel={onClose} storyId={id} />
          )}
        />
      </Box>
    </Modal>
  );
};
StorySettings.propTypes = {
  onBack: PropTypes.func,
};
StorySettings.defaultProps = {
  onBack: null,
};

export default StorySettings;
