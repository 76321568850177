import React from 'react';
import PropTypes from 'prop-types';

import StoryMutePopup from 'stories/mute/popup';
import DeleteModal from '../delete/modal';
import DownloadStory from '../download';
import StoryAlias from './alias';
import StoryCollectionModal from '../journal';
import StorySettings from '../settings';
import StoryTagsModal from '../tags/modal';
import StoryShareOptions from './share-options';
import StoryShareInfo from './share-info';
import OptionsHome from './home';

const StoryOptionSubModals = ({ goHome, storyId, currentModal }) => {
  switch (currentModal) {
    case 'delete':
      return <DeleteModal />;
    case 'copy':
      return <StoryCollectionModal onBack={goHome} />;
    case 'download':
      return <DownloadStory storyId={storyId} onClose={goHome} />;
    case 'storyForm':
      return <StorySettings onBack={goHome} />;
    case 'tags':
      return <StoryTagsModal onBack={goHome} storyId={storyId} />;
    case 'shareOptions':
      return <StoryShareOptions storyId={storyId} onBack={goHome} />;
    case 'shareInfo':
      return <StoryShareInfo onBack={goHome} />;
    case 'collectionShares':
      return <StoryAlias storyId={storyId} onBack={goHome} onSuccess={goHome} />;
    case 'mute':
      return <StoryMutePopup storyId={storyId} onBack={goHome} />;
    default:
      return <OptionsHome />;
  }
};
StoryOptionSubModals.propTypes = {
  storyId: PropTypes.string.isRequired,
  currentModal: PropTypes.string.isRequired,
  goHome: PropTypes.func.isRequired,
};

export default StoryOptionSubModals;
