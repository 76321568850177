import useSWR from 'swr';
import normalize from 'jsonapi-normalizer';

const getName = member => member['full-name'] || '';

export const useSortedStoryShares = storyId => {
  const { data } = useSWR(`/v3/stories/${storyId}/story_shares`);

  if (!data) return [];
  const { entities, result } = normalize(data.data);
  const alphaSortedShares = result['story-shares'].sort((a, b) => {
    const shareA = entities['story-shares'][a];
    const shareB = entities['story-shares'][b];
    const memberA = entities.members[shareA['with-contact'].id];
    const memberB = entities.members[shareB['with-contact'].id];

    return getName(memberA).toLowerCase() < getName(memberB).toLowerCase() ? -1 : 1;
  });
  const collaborators = alphaSortedShares.filter(
    share => entities['story-shares'][share]['contact-role'] === 'collaborator'
  );
  const viewers = alphaSortedShares.filter(
    share => entities['story-shares'][share]['contact-role'] === 'viewer'
  );
  return [...collaborators, ...viewers];
};

export default useSortedStoryShares;
