import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';

import SelectCollectionItem from './item';

const AliasCollection = ({ collections, selected, setSelected, error, setError }) => {
  const handleClick = collection => {
    if (selected.find(selectedCollection => selectedCollection.id === collection.id)) {
      setSelected([
        ...selected.filter(selectedCollection => selectedCollection.id !== collection.id),
      ]);
    } else {
      setSelected([...selected, { id: collection.id, type: collection.type }]);
    }
    if (error) {
      setError(false);
    }
  };

  return (
    <>
      {error && <Text error>You must select at least one collection to share this story to.</Text>}
      {collections.map(collection => (
        <SelectCollectionItem
          selected={
            selected &&
            !!selected.find(selectedCollection => selectedCollection.id === collection.id)
          }
          collection={collection}
          setSelected={handleClick}
          key={collection.id}
          // tagClicked={() => push(`/stories/new/${id}/journal/tags`, collectionShare)}
        />
      ))}
    </>
  );
};

AliasCollection.propTypes = {
  collections: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selected: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  setSelected: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  setError: PropTypes.func.isRequired,
};

const Text = styled.p`
  margin-left: 8px;
  ${props =>
    props.error &&
    `
    font-weight: bold;
    margin-top: 0;
    color: ${props.theme.colours.red};
  `};
`;

export default AliasCollection;
