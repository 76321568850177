
import { FiX as CloseIcon } from 'react-icons/fi';
import { PoseGroup } from 'react-pose';
import { Button, Text } from 'rebass';
import { UserAgent } from '@quentin-sommer/react-useragent';
// import { ref } from 'yup';
import PropTypes from 'prop-types';
import React, { useRef, useEffect } from 'react';
import ScrollLock from 'react-scrolllock';
import useOutsideClick from 'use-onclickoutside';
import { IoIosArrowBack } from 'react-icons/io';

import { Shade, ShadeInner, Wrapper, Title, InnerWrap, CloseButton } from './styled';
import { useKeyPress } from '../../hooks';

const Modal = ({
  aboveTooltip,
  onClose,
  closeButton,
  closeButtonLeft,
  children,
  fullscreenMobile,
  closeOnOutsideClick,
  onBack,
  backButton,
  backLabel,
  ...props
}) => {
  const wrapper = useRef(null);

  useOutsideClick(wrapper, () => {
    if (closeOnOutsideClick && !fullscreenMobile) {
      onClose();
    }
  });

  const escPress = useKeyPress('Escape');

  useEffect(() => {
    if (escPress) {
      onClose();
    }
  }, [escPress, onClose]);

  return (
    <UserAgent ios>
      {isIOS => (
        <>
          <PoseGroup animateOnMount preEnterPose="pre">
            <Shade aboveTooltip={aboveTooltip} key="shade" />
            <ScrollLock key="lock">
              <ShadeInner
                isIOS={isIOS}
                fullscreenMobile={fullscreenMobile}
                aboveTooltip={aboveTooltip}
                key="modal"
              >
                <Wrapper {...props} fullscreenMobile={fullscreenMobile} ref={wrapper}>
                  {backButton && (
                    <Button
                      variant="unset"
                      type="button"
                      onClick={onBack}
                      css="position: absolute; top: 5px; left: 5px; display: flex; align-items: center;"
                    >
                      <Text as={IoIosArrowBack} color="mutedText" pr={1} />
                      {backLabel}
                    </Button>
                  )}
                  {(closeButton || closeButtonLeft) && (
                    <CloseButton left={closeButtonLeft} onClick={onClose}>
                      <CloseIcon size={24} color="inherit" />
                    </CloseButton>
                  )}
                  {children}
                </Wrapper>
              </ShadeInner>
            </ScrollLock>
          </PoseGroup>
        </>
      )}
    </UserAgent>
  );
};

Modal.Title = Title;
Modal.InnerWrap = InnerWrap;

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  closeOnOutsideClick: PropTypes.bool,
  aboveTooltip: PropTypes.bool,
  closeButton: PropTypes.bool,
  closeButtonLeft: PropTypes.bool,
  fullscreenMobile: PropTypes.bool,
  backButton: PropTypes.bool,
  backLabel: PropTypes.string,
  onBack: PropTypes.func,
};

Modal.defaultProps = {
  onClose: () => {},
  onBack: () => {},
  closeOnOutsideClick: true,
  aboveTooltip: false,
  closeButton: false,
  backButton: false,
  closeButtonLeft: false,
  fullscreenMobile: false,
  backLabel: 'Back',
};

export default Modal;
