import { post } from '../../utils/api';

/* eslint-disable import/prefer-default-export */
export const postPushSubscription = (endpoint, p256dh, auth) => (
  post(
    '/v3/web_push_subscriptions',
    {
      data: {
        type: 'web-push-subscriptions',
        attributes: {
          endpoint,
          p256dh,
          auth,
          state: 'subscribed',
        },
      },
    },
  )
);
