import { useMemo } from 'react';

export const getRouteFromEntityParams = ({ type, id }) => {
  const typeRouteName = (() => {
    switch (type) {
      case 'other-journals':
        return 'custom';
      case 'how-do-i-collections':
        return 'how-do-i';
      case 'stories':
        return 'story';
      default:
        return type;
    }
  })();
  // const typeRouteName = getTypeRouteName();

  if (!(id && type)) return null;

  return `/${typeRouteName}/${id}`;
};

const useRouteFromEntityParams = ({ type, id } = {}) =>
  useMemo(() => {
    if (!type || !id) return null;
    return getRouteFromEntityParams({ type, id });
  }, [type, id]);

export default useRouteFromEntityParams;
