import styled from '@emotion/styled';
import { css } from '@emotion/core';

export const Avatars = styled.div`
  display: flex;
  margin-left: auto;

  & > span {
    margin-left: auto;
    padding-left: 1em;
    line-height: 36px;
  }
`;

export const CommentsIcon = styled.img`
  margin-right: .5em;
  width: 17px;
`;

export const Separator = styled.div`
  margin: auto 0;
  padding: 0 1em;
  color: #f3f3f3;
`;

const ButtonStyles = css`
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  font-family: "SF UI Text", "Roboto", sans-serif;
  align-items: center;
  display: flex;
  white-space: nowrap;
`;

export const ActivityCommentsButton = styled.button`
  ${ButtonStyles};
  color: ${props => props.theme.colours.lightText};
  width: 100%;
  font-size: 0.9em;

  & > span {
    padding-left: 1em;
    line-height: 36px;
    ${props => props.theme.media.tablet`
      padding-left: 2em;
    `};
  }
`;

export const LikesButton = styled.div`
  ${ButtonStyles};

  & > img {
    width: 17px;
  }

  & > button {
    font-size: 0.9em;
  }

  div {
    font-family: "SF UI Text", "Roboto", sans-serif;
    color: ${props => props.theme.colours.lightText};
    margin: auto;
    margin-left: 0.5em;
  }
`;

export const LikesCommentsAvatars = styled.div`
  display: flex;
  min-height: 36px;
  padding: 1em;

  ${props => props.theme.media.phone`padding: 1em 1.25em;`};
  ${props => props.theme.media.tablet`padding: 1em 1.5em;`};
`;

export const Activity = styled.div`
  max-width: 600px;
  width: 100%;
  margin: auto;
  background-color: ${props => props.theme.colours.white};
`;

export const LikesAvatarsWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  height: 36px;
  max-width: 220px; /* displays 5.5 avatars */
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
`;
