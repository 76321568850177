import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FaLink } from 'react-icons/fa';
import { Button } from '../button-bar/styled';
import { unlinkStoryPages, getStoryPages } from '../actions';

const showButton = (mediaAfter = []) => {
  if (mediaAfter.length > 0) return true;
  return false;
};

const StyledButton = styled(Button)`
  background-color: ${props => props.theme.colours.brand};
  border-color: ${props => props.theme.colours.brand};
  &:hover {
    background: ${props => props.theme.colours.brand};
    border-color: ${props => props.theme.colours.white};
  }
`;

const UnlinkButton = ({ page, ...props }) =>
  showButton(props.mediaAfter) && (
    <StyledButton
      disabled={props.loading}
      onClick={() => {
        props
          .unlinkStoryPages(
            props.storyId,
            { position: page.position + 1, media: props.mediaAfter },
            page.id
          )
          .then(() => props.getStoryPages(props.storyId));
      }}
      linked
      p={0}
      left={10}
      zIndex={1}
      my={-22}
    >
      <FaLink style={{ transform: 'rotate(-45deg)' }} color="white" />
    </StyledButton>
  );

UnlinkButton.propTypes = {
  mediaAfter: PropTypes.arrayOf(PropTypes.shape({})),
  storyId: PropTypes.string.isRequired,
  page: PropTypes.shape({
    id: PropTypes.string,
    position: PropTypes.number,
  }).isRequired,
  unlinkStoryPages: PropTypes.func.isRequired,
  getStoryPages: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

UnlinkButton.defaultProps = {
  mediaAfter: [],
};

const mapStateToProps = state => ({ loading: state.storyPages.loading });

export default connect(mapStateToProps, { unlinkStoryPages, getStoryPages })(UnlinkButton);
