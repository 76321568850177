export const sortPages = (pages, state) => (
  pages.sort((a, b) => {
    const pageA = state.pages[a.id];
    const pageB = state.pages[b.id];
    return (pageA.position - pageB.position)
      || (new Date(pageB['updated-at']) - new Date(pageA['updated-at']));
  })
);

export default sortPages;
