import React, { useCallback, useContext } from 'react';
import normalize from 'jsonapi-normalizer';
import store from 'store';
import { SWRConfig } from 'swr';
import apiBase from '../../utils/api-base';
import JSONAPIClient from '.';
import { getJsonHeaders, getSWR } from '../../utils/api';
import { ServerError } from '../server-error-popup';
import { useEntityActionCreators } from '../../entities/hooks';

export default props => {
  const token = store.get('auth_token');
  const { setError } = useContext(ServerError);
  const { receive } = useEntityActionCreators();

  const handleSuccess = useCallback(
    payload => {
      if (payload && payload.data) {
        const nextPayload = {
          ...payload,
          data: normalize(payload.data),
        };
        receive({ payload: nextPayload });
      }
    },
    [receive]
  );

  return (
    <SWRConfig value={{ onSuccess: handleSuccess, fetcher: getSWR, shouldRetryOnError: false }}>
      <JSONAPIClient
        baseURL={apiBase.replace('/v2', '')}
        // baseURL="https://httpstat.us/"
        onSuccess={handleSuccess}
        onError={(error, showError = true) => showError && setError(error)}
        config={{
          headers: {
            ...getJsonHeaders,
            'Content-Type': 'application/json',
            Authorization: `Token ${token}`,
          },
        }}
        {...props}
      />
    </SWRConfig>
  );
};
