import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';

import { ReactComponent as MoveIcon } from '../../stories/edit-story/move/move-arrow.svg';
import { getStoryPages, moveStoryPage } from '../actions';
import MoveStoryPage from '../../stories/edit-story/move';

const Wrap = styled.button`
  background: #f2eeff;
  border: 2px dashed #7a69bf;
  box-sizing: border-box;
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.1);
  height: 60px;
  margin: 0 auto;
  max-width: 600px;
  width: 100%;
  color: ${props => props.theme.colours.brand};
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    display: none;
  }

  svg {
    margin-right: 0.5em;
  }
`;
const MoveBar = ({ pageId, storyId, position, prevPageId, ...props }) => (
  <MoveStoryPage.Consumer>
    {({ moveStoryPageId, moveStoryPagePosition, resetMoveStoryPage }) => (
      <Wrap
        type="button"
        onClick={() => {
          const nextPosition = moveStoryPagePosition < position ? position - 1 : position;

          props
            .moveStoryPage(storyId, moveStoryPageId, nextPosition)
            .then(() => props.getStoryPages(storyId))
            .then(() => resetMoveStoryPage());
        }}
        disabled={pageId === moveStoryPageId || prevPageId === moveStoryPageId}
      >
        <MoveIcon />
        <span>Move to Here</span>
      </Wrap>
    )}
  </MoveStoryPage.Consumer>
);

MoveBar.propTypes = {
  position: PropTypes.number.isRequired,
  storyId: PropTypes.string.isRequired,
  pageId: PropTypes.string,
  moveStoryPage: PropTypes.func.isRequired,
  getStoryPages: PropTypes.func.isRequired,
  prevPageId: PropTypes.string.isRequired,
};

MoveBar.defaultProps = {
  pageId: '',
};

export default connect(null, { moveStoryPage, getStoryPages })(MoveBar);
