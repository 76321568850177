

import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { FaCheck as CheckIcon } from 'react-icons/fa';
import { Field } from 'formik';
import { Text, Flex } from 'rebass';
import { withProps } from 'recompose';
import { useToggle } from '../../../app/hooks';
import TextField from '../../../app/form/formik/text';
import DateField from '../../../app/form/formik/date';
import CoverField from '../../../app/form/formik/cover';
import FieldGroup from './field-group';

const MilestoneForm = ({ currentCoverId, destroyCover, titlePlaceholder }) => {
  const [hasEndDate, toggleEndDate] = useToggle(false);

  return (
    <Flex
      alignItems="stretch"
      justifyContent="center"
      flexDirection="column"
      css="max-height: 100%;"
    >
      <FieldGroup>
        <Field
          name="title-noac"
          component={TextField}
          label="Title"
          showError
          placeholder={titlePlaceholder}
          mb={3}
        />
        <Field
          name="location-noac"
          component={TextField}
          label="Location"
          placeholder="Event Location"
        />
      </FieldGroup>
      <FieldGroup>
        <Field name="start-date" component={DateField} label="Date" mb={3} />
        <Field
          name="end-date"
          render={({ field }) =>
            (hasEndDate || field.value) && (
              <Field name="end-date" component={DateField} label="End Date" mb={3} />
            )
          }
        />
        <div>
          <Text mb={2}>Has End Date</Text>
          <Button onClick={toggleEndDate} selected={hasEndDate}>
            <CheckIcon size={16} />
          </Button>
        </div>
      </FieldGroup>
      <FieldGroup>
        <Field name="note-noac" component={TextField} label="Note" mb={3} />
        <Field
          name="cover"
          component={CoverField}
          hasEditButton={false}
          currentCover={currentCoverId}
          destroyCover={destroyCover}
        />
      </FieldGroup>
    </Flex>
  );
};

MilestoneForm.propTypes = {
  currentCoverId: PropTypes.string,
  titlePlaceholder: PropTypes.string,
  destroyCover: PropTypes.func,
};

MilestoneForm.defaultProps = {
  titlePlaceholder: '',
  currentCoverId: '',
  destroyCover: null,
};

export const Button = withProps({ type: 'button' })(styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 36px;
  border-radius: 3px;
  border: 1px solid
    ${props => (props.selected ? props.theme.colours.brand : props.theme.colours.border)};

  svg {
    fill: ${props => (props.selected ? props.theme.colours.brand : props.theme.colours.border)};
  }
`);

export default MilestoneForm;
