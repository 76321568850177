import React from 'react';

const StoriesIcon = () => (
  <svg width="23" height="22" viewBox="0 0 23 22">
    <g fill="none">
      <g fill="currentColor">
        <path d="M11.5 0C11.4 0 11.3 0 11.2 0.1L0.2 8.7C0 8.9-0.1 9.2 0.1 9.4 0.3 9.6 0.6 9.6 0.8 9.5L1.4 8.9 1.4 21.5C1.4 21.8 1.7 22 1.9 22L8.6 22 8.6 13.4 14.4 13.4 14.4 22 21.1 22C21.3 22 21.5 21.8 21.5 21.5L21.5 8.9 22.2 9.5C22.3 9.5 22.4 9.6 22.5 9.6 22.6 9.6 22.8 9.5 22.9 9.4 23 9.2 23 8.9 22.8 8.7L11.8 0.1C11.7 0 11.6 0 11.5 0ZM16.3 1.9L16.3 2.4 19.2 4.6 19.2 1.9 16.3 1.9Z" />
      </g>
    </g>
  </svg>
);

export default StoriesIcon;
