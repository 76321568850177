import { get, post, patch, destroy } from '../../utils/api';

export const getMilestone = ({ memoirId, milestoneId }) =>
  get(`/v3/memoirs/${memoirId}/milestones/${milestoneId}`);
export const postMilestone = ({ memoirId, attributes, relationships }) =>
  post(`/v3/memoirs/${memoirId}/milestones`, {
    data: {
      type: 'milestones',
      attributes,
      relationships,
    },
  });
export const patchMilestone = ({ memoirId, milestoneId, attributes, relationships }) =>
  patch(`/v3/memoirs/${memoirId}/milestones/${milestoneId}`, {
    data: {
      type: 'milestones',
      attributes,
      relationships,
    },
  });
export const destroyMilestone = ({ memoirId, milestoneId }) =>
  destroy(`/v3/memoirs/${memoirId}/milestones/${milestoneId}`);
