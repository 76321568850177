import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { height as sysHeight } from 'styled-system';

const NavOuter = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  ${sysHeight};
`;

const NavInner = styled.div`
  position: relative;
  display: flex;
  padding: 0;
  align-items: center;
  list-style-type: none;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  height: 120%;
`;

const MobileSliderNav = ({ children, height }) => (
  <NavOuter height={height}>
    <NavInner height={height}>{children}</NavInner>
  </NavOuter>
);

MobileSliderNav.propTypes = {
  height: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export default MobileSliderNav;
