import React from 'react';
import Component from '@reach/component-component';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { selectIsUploadUnderway } from '../../story-media/selectors';

const UnloadWarning = ({ uploadUnderway }) => (
  <Component
    didUpdate={() => {
      if (uploadUnderway) {
        window.onbeforeunload = (e) => {
          const dialogText = 'There is an upload underway, are you sure you want to leave?';
          e.returnValue = dialogText;
          return dialogText;
        };
      } else {
        window.onbeforeunload = () => undefined;
      }
    }}
  />
);

UnloadWarning.propTypes = {
  uploadUnderway: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  uploadUnderway: selectIsUploadUnderway(state),
});

export default connect(mapStateToProps)(UnloadWarning);
