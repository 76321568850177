import { Text } from 'rebass';
import React from 'react';

const SmallText = props => (
  <Text
    textAlign="center"
    color="mutedText"
    fontSize={16}
    px={20}
    lineHeight="23px"
    fontWeight="100"
    as="p"
    {...props}
  />
);

export default SmallText;
