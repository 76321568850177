import { Button } from 'rebass';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import uniq from 'lodash/uniq';
import { trigger } from 'swr';

import { CurrentCollectionContext } from '../../collections/current-collection-context';
import { Body } from '../../app/modal/v2/styled-info';
import { addStoryTag, removeStoryTag, getStory } from '../api';
import { useEntity, useEntityActionCreators } from '../../entities/hooks';
// import ManageTagsButton from '../../collections/detail/tag-management/manage-tags-button';
// import NewTag from '../../collections/detail/tag-management/new-tag';
import TagOption from './tag-option';
import toggleInArray from '../../utils/toggleInArray';
import ModalBottom from '../../app/modal/v3/bottom';

const StoryTags = ({ onSuccess, storyId }) => {

  // const StoryTags = ({ setNewTagDirty, onSuccess, onToggle, storyId }) => {
  const { currentCollection } = useContext(CurrentCollectionContext);
  // const { setCurrentModal } = useContext(StoryOptionsContext);
  const { isOwner: isCollectionOwner, entity: collection } = useEntity(currentCollection);
  const { story } = useEntity({ id: storyId, type: 'stories' });
  const { 'tag-names': storyTags = [] } = story;
  const { receive } = useEntityActionCreators();

  const [tagAdditions, setTagAdditions] = useState([]); // list of tag names
  const [tagDeletions, setTagDeletions] = useState([]); // list of tag names
  const [submitting, setSubmitting] = useState(false);

  const chronicleTags = collection['tag-names'] || [];

  if (!chronicleTags) return <p>Loading...</p>;

  const getSelected = name => {
    if (tagDeletions.includes(name)) return false;
    if (tagAdditions.includes(name)) return true;
    if (storyTags.includes(name)) return true;
    return false;
  };

  const handleClick = name => {
    if (storyTags.includes(name)) {
      return setTagDeletions(state => toggleInArray(state, name));
    }
    return setTagAdditions(state => toggleInArray(state, name));
  };

  return (
    <>
      {/* {isCollectionOwner && (
        <Flex p={3} justifyContent="flex-end">
          <ManageTagsButton onClick={() => onToggle()} />
        </Flex>
      )} */}
      {/* <NewTag
        collection={currentCollection}
        storyId={storyId}
        onCreate={tag => setTagAdditions(state => [...state, tag])}
        setNewTagDirty={setNewTagDirty}
      /> */}
      <Body>
        {uniq([...(storyTags || []), ...chronicleTags]).map(name => (
          <TagOption
            key={`tag-option-${name}`}
            isOwner
            name={name}
            selected={getSelected(name)}
            onClick={() => handleClick(name)}
          />
        ))}
      </Body>
      <ModalBottom>
        <Button
          flex={1}
          variant="primary"
          disabled={submitting}
          loading={submitting}
          type="submit"
          onClick={async () => {
            setSubmitting(true);
            await Promise.all(
              tagAdditions.map(name =>
                addStoryTag({ journalId: currentCollection.id, storyId, name })
              )
            );
            await Promise.all(
              tagDeletions.map(name =>
                removeStoryTag({ journalId: currentCollection.id, storyId, name })
              )
            );
            const payload = await getStory(storyId);
            trigger(`/v3/stories/${storyId}/story_share`);
            receive({ payload });
            onSuccess();
          }}
        >
          Done
        </Button>
      </ModalBottom>
    </>
  );
};

StoryTags.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  storyId: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
  setNewTagDirty: PropTypes.func.isRequired,
};

export default StoryTags;
