import { Box, Button } from 'rebass';
import { FaHeart, FaRegHeart } from 'react-icons/fa';
import { State } from 'react-powerplug';
import PropTypes from 'prop-types';
import React from 'react';

import { Entity } from '../../../entities';
import { jsonApiReferenceShape } from '../../../app/shapes';
import { patchCollectionTag } from '../../api';

const FavouriteButton = ({ id, collection }) => (
  <State initial={{ loading: false }}>
    {({ state: { loading }, setState }) => (
      <Entity
        id={id}
        type="tags"
      >
        {({ entity: tag, updateEntity }) => (
          <Button
            variant="transparent"
            disabled={loading}
            onClick={() => {
              const favourite = !tag.favourite;
              setState({ loading: true });
              patchCollectionTag({ ...collection, attributes: { name: tag.name, favourite } })
                .then(() => {
                  updateEntity({ favourite });
                  setState({ loading: false });
                });
            }}
          >
            <Box color="brand">{tag.favourite ? <FaHeart /> : <FaRegHeart />}</Box>
          </Button>
        )}
      </Entity>
    )}
  </State>
);

FavouriteButton.propTypes = {
  collection: jsonApiReferenceShape.isRequired,
  id: PropTypes.string.isRequired,
};

export default FavouriteButton;
