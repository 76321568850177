// import { Flex } from 'rebass';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

import { Wrap, Label } from './styled';
import { selectMemberIdsforStory } from '../../story-shares/selectors';
// import AvatarList from '../../members/avatar-list';

const SharedWithAvatars = ({ storyMemberIds }) => {
  return (
    <Wrap>
      <Label fontSize={12} mb={10} color="mutedText">
        Shared with {`(${storyMemberIds.length})`}
      </Label>
      {/* <Flex>
        <AvatarList
          memberIds={storyMemberIds}
          truncateAt={4}
          avatarSize={36}
          keyPrefix={storyId}
          overlayTitle="Story Participants"
        />
      </Flex> */}
    </Wrap>
  );
};

SharedWithAvatars.propTypes = {
  storyId: PropTypes.string.isRequired,
  storyMemberIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = (
  state,
  {
    match: {
      params: { id: storyId },
    },
  }
) => {
  const storyMemberIds = selectMemberIdsforStory(state, storyId || []);

  return {
    storyId,
    storyMemberIds,
  };
};

export default withRouter(connect(mapStateToProps)(SharedWithAvatars));
