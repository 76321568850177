import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box, Text } from 'rebass';

export const ShareRowContainer = props => (
  <Box sx={{ borderTop: '1px solid', borderColor: 'border', bg: 'white' }} p={2} {...props} />
);

export const ShareRow = ({ icon, onClick, renderIcon, label, sublabel, children, ...props }) => (
  <ShareRowContainer {...props} role={onClick ? 'button' : null} onClick={onClick || null}>
    <Flex alignItems="center">
      {icon && <Box as={icon} mr={2} />}
      {renderIcon && (
        <Box as={icon} mr={2}>
          {renderIcon()}
        </Box>
      )}
      {(label || sublabel) && (
        <Box mr="auto">
          <Text color="black">{label}</Text>
          {sublabel && (
            <Text fontSize={0} color="mutedText">
              {sublabel}
            </Text>
          )}
        </Box>
      )}
      {children}
    </Flex>
  </ShareRowContainer>
);
ShareRow.propTypes = {
  label: PropTypes.string,
  sublabel: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.object,
  renderIcon: PropTypes.func,
  onClick: PropTypes.func,
};
ShareRow.defaultProps = {
  label: null,
  sublabel: null,
  icon: null,
  renderIcon: null,
  onClick: null,
  children: null,
};
