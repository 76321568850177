import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Text, Flex, Button } from 'rebass';
import { useUserStoryShareFromStory } from 'story-shares/hooks/index';
import Modal from 'app/modal/v3/index';
import { unmuteStoryShare, muteStoryShare } from 'story-shares/api';
import { setCheckmark } from 'ui/actions';
import { useEntityActionCreators } from 'entities/hooks/index';
import { useIsStoryMuted } from 'stories/hooks';

const StoryMutePopup = ({ storyId, onClose, onBack }) => {
  const dispatch = useDispatch();
  const { receive } = useEntityActionCreators();
  const storyShare = useUserStoryShareFromStory(storyId) || {};
  const isMuted = useIsStoryMuted(storyId);

  const onSuccess = onBack || onClose;

  const handleClick = async () => {
    if (isMuted) {
      const payload = await unmuteStoryShare(storyShare.id);
      receive({ payload });
      onSuccess();
      return dispatch(setCheckmark({ message: 'Notifications Enabled' }));
    }

    const payload = await muteStoryShare(storyShare.id);
    receive({ payload });
    onSuccess();
    return dispatch(setCheckmark({ message: 'Notifications Disabled' }));
  };

  return (
    <Modal back={{ onBack }} close={{ onClose }} title="Story Notification Settings">
      <Flex p={3} pt={4} flexDirection="column" alignItems="center" bg="white">
        <Text fontSize={2}>
          Notifications are {isMuted ? 'disabled' : 'enabled'} for this story.
        </Text>
        <Button variant="primary" type="button" onClick={handleClick} mt={3}>
          {isMuted ? 'Enable ' : 'Disable '}
          Notifications
        </Button>
      </Flex>
    </Modal>
  );
};

StoryMutePopup.propTypes = {
  storyId: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  onBack: PropTypes.func,
};

StoryMutePopup.defaultProps = {
  onClose: null,
  onBack: null,
};

export default StoryMutePopup;
