import { Form, Field } from 'formik';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import styled from '@emotion/styled';

import { CurrentCollectionContext } from '../../../collections/current-collection-context';
import Button from '../../../app/buttons';
import DateField from '../../../app/form/formik/date';
import StoryTagsManager from '../../tags/manager';
import TagList from '../../tags/tag-list';
import TextField from '../../../app/form/formik/text';

const ButtonsWrap = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: max-content max-content;
  justify-content: flex-end;
`;

const FieldsWrap = styled.div`
  display: grid;
  grid-gap: ${props => props.theme.gutter};
  align-items: stretch;
  padding: ${props => props.theme.gutter};

  input {
    width: 100%;
  }
`;

const DatesWrap = styled.div`
  display: flex;
  flex-direction: column;
  ${props => props.theme.media.phone`
    grid-template-columns: repeat(2, 1fr);
  `};
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__input-container {
    width: 100%;
  }
`;

const StoryForm = ({ values, handleCancel, toggleTags, isShowingTags, hasTags }) => {
  const { currentCollection: collection } = useContext(CurrentCollectionContext);
  if (isShowingTags) {
    return (
      <StoryTagsManager
        collection={collection}
        storyId={values.id}
        onSuccess={toggleTags}
        onClose={toggleTags}
      />
    );
  }

  return (
    <Form autoComplete="off">
      <FieldsWrap>
        <Field
          placeholder="Christmas Dinner, Trip to Cuba, Baking Contest"
          name="title"
          label="Story Title"
          showError
          component={TextField}
        />
        <DatesWrap>
          <Field
            name="began-on"
            label="Story Start Date"
            placeholder="Start Date"
            maxDate={values['ended-on']}
            component={DateField}
            width="auto"
          />
          <Field
            name="ended-on"
            label="Story End Date"
            minDate={values['began-on']}
            component={DateField}
            width="auto"
          />
        </DatesWrap>
        {hasTags && <TagList id={values.id} onClick={toggleTags} />}
        <ButtonsWrap>
          <Button type="button" onClick={handleCancel}>
            Cancel
          </Button>
          <Button primary type="submit">
            Save
          </Button>
        </ButtonsWrap>
      </FieldsWrap>
    </Form>
  );
};

StoryForm.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  toggleTags: PropTypes.func.isRequired,
  isShowingTags: PropTypes.bool,
  hasTags: PropTypes.bool,
  values: PropTypes.shape({}),
  collection: PropTypes.shape({
    type: PropTypes.string,
    id: PropTypes.string,
  }),
};

StoryForm.defaultProps = {
  isShowingTags: false,
  hasTags: false,
  values: {},
  collection: {
    type: '',
    id: '',
  },
};

export default withRouter(StoryForm);
