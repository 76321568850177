import { useMemo } from 'react';
import { get } from 'lodash';
import useEntity from './use-entity';

export const useImage = ({ id, size = 0 }) => {
  const { image } = useEntity({ type: 'images', id });

  return useMemo(() => {
    if (image.tmpUrl) return image.tmpUrl;
    return get(image, `["image-versions"][${size}].url`, '') || image['asset-url'];
  }, [image, size]);
};

export default useImage;
