import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Button } from 'rebass';
import { useEntityActionCreators } from 'entities/hooks/index';
import { deleteConnection } from '../../../members/connections/api';
import { ConfirmText } from '../../../app/modal/v2/styled';
import Modal from '../../../app/modal/v3';
import ModalBottom from '../../../app/modal/v3/bottom';

const DeleteModal = () => {
  const { id } = useParams();
  const { replace } = useHistory();
  const { receiveDeletion } = useEntityActionCreators();
  return (
    <Modal footerOffset={55}>
      <ConfirmText>Are you sure you want to delete?</ConfirmText>
      <ModalBottom alignItems="flex-end">
        <Button variant="muted" onClick={() => replace('/friends')} ml="auto" mr={2}>
          Cancel
        </Button>
        <Button
          type="button"
          variant="destructive"
          ml={2}
          mr="auto"
          onClick={() =>
            deleteConnection(id).then(() => {
              receiveDeletion({ entityType: 'connections', id });
              replace('/friends');
            })
          }
        >
          Delete
        </Button>
      </ModalBottom>
    </Modal>
  );
};

export default DeleteModal;
