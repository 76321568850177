import { Card, Text } from 'rebass';
import PropTypes from 'prop-types';
import React from 'react';
import ReactPlayer from 'react-player';

import { getStoryRequest } from '../api';
import { useEntity, useQueryEntities } from '../../entities/hooks';
import { useHistory, useMatch } from '../../app/hooks';
import { useMemberAvatarSrc } from '../../members/hooks';
import { useVideoMediumFromStoryRequest } from '../../collections/detail/story-requests/hooks';
import PlayButton from './play-button';
import TimeAgo from '../../app/time-ago';

const Request = ({ request }) => {
  const { push } = useHistory();
  const {
    params: { id, type },
  } = useMatch();
  const { entity: share, isPresent } = useEntity({ ...request });
  useQueryEntities({ query: () => getStoryRequest(share['story-request'].id) });
  const imageSrc = useMemberAvatarSrc({ ...share.member });

  const video = useVideoMediumFromStoryRequest(share['story-request'].id);

  if (!isPresent) return false;

  return (
    <button
      onClick={() => push(`/${type}/${id}/story-requests/${share['story-request'].id}`)}
      type="button"
      style={{ display: 'flex', flexDirection: 'column', padding: 0 }}
    >
      <Card
        backgroundImage={video ? '' : `url(${imageSrc})`}
        width="124px"
        borderRadius="12px"
        mr="10px"
        css="
          height: 162px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-position: center;
          background-position: cover;
          position: relative;
        "
      >
        {video && (
          <ReactPlayer url={video.tmpUrl || video['asset-url']} width="100%" height="100%" />
        )}
        <PlayButton style={{ position: 'absolute' }} />
      </Card>
      <Text color="#999" mt="8px" fontSize="12px">
        <TimeAgo date={share['created-at']} />
      </Text>
    </button>
  );
};

Request.propTypes = {
  request: PropTypes.shape({
    type: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default Request;
