import { getMemoirPaymentUrl, getMemoir } from 'memoirs/api';
import { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

const SEVEN_DAYS_IN_HOURS = 168;

export const usePaymentBanner = (memoir) => {
  const [isUnderTrial, setIsUnderTrial] = useState(null);

  useEffect(() => {
    if (memoir && memoir['created-at']) {
      const memoirCreationTime = new Date(memoir['created-at']);
      const currentTime = new Date();
      const timeDifferenceInHours = (currentTime - memoirCreationTime) / (1000 * 60 * 60);

      if (timeDifferenceInHours >= SEVEN_DAYS_IN_HOURS) {
        setIsUnderTrial(false);
      } else {
        setIsUnderTrial(true);
      }
    }
  }, [memoir]);

  return isUnderTrial;
};

export const usePaymentPolling = (memoirId, stripeUrl, memoir) => {
  const [polling, setPolling] = useState(false);
  const history = useHistory();

  const isUnderTrial = usePaymentBanner(memoir);
  useEffect(() => {
    let interval;
    if (polling && stripeUrl && !isUnderTrial) {
      const checkPaymentStatus = async () => {
        try {
          const response = await getMemoir(memoirId);
          if (response.data?.initial?.data?.attributes?.paid) {
            setPolling(false);
            history.push(`/memoirs/${memoirId}/milestones`);
            clearInterval(interval);
          }
        } catch (error) {
          console.error('Error checking payment status:', error);
        }
      };

      interval = setInterval(checkPaymentStatus, 5000); // Check every 5 seconds
    }

    return () => clearInterval(interval);
  }, [polling, stripeUrl, memoirId, history, isUnderTrial]);

  return [setPolling];
};


export const usePaymentUrl = (memoirId, memoir) => {
  const [stripeUrl, setStripeUrl] = useState(null);
  const isUnderTrial = usePaymentBanner(memoir);

  useEffect(() => {
    if (memoir?.['stripe-hosted-payment-url'] && !isUnderTrial)
      setStripeUrl(memoir?.['stripe-hosted-payment-url']);

  }, [memoir, isUnderTrial]);

  const fetchPaymentUrl = useCallback(async () => {
    if (isUnderTrial) return;

    try {
      const response = await getMemoirPaymentUrl({ id: memoirId });
      const url = response.data?.initial?.data?.attributes?.['stripe-hosted-payment-url'];
      setStripeUrl(url);
    } catch (error) {
      console.error('Error fetching payment URL:', error);
    }
  }, [memoirId, isUnderTrial]);

  return [stripeUrl, fetchPaymentUrl];
};
