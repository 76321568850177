import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';

import { EntityAttr } from '../../entities';
import { Header, Title } from '../../app/modal/v2/styled-info';
import AddFriendButton from '../../friends/add-button';
import MemberAvatar from '../avatar';
import Modal from '../../app/modal/v2';

const ListItem = styled.div`
  display: grid;
  grid-template-columns: max-content auto max-content;
  grid-gap: 10px;
  align-items: center;
  padding: 10px;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
  transition: 0.3s ease opacity;
  background: ${props => props.theme.colours.white};

  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

const MembersOverlay = ({ memberIds, onClose, title }) => (
  <Modal onClose={onClose} closeButton>
    <Header>
      <Title style={{ margin: '0 auto' }}>{title}</Title>
    </Header>
    {memberIds.map(memberId => (
      <ListItem key={`members-overlay-${memberId}`}>
        <MemberAvatar id={memberId} />
        <EntityAttr type="members" id={memberId} attr="full-name" />
        <AddFriendButton memberId={memberId} />
      </ListItem>
    ))}
  </Modal>
);

MembersOverlay.propTypes = {
  memberIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
};

MembersOverlay.defaultProps = {
  title: 'Members',
};

export default MembersOverlay;
