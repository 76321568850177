const getMaleOption = chronicleType => (
  { label: chronicleType === 'child-journals' ? 'Boy' : 'Male', value: 'male' }
);

const getFemaleOption = chronicleType => (
  { label: chronicleType === 'child-journals' ? 'Girl' : 'Female', value: 'female' }
);

const getNaOption = chronicleType => (
  { label: chronicleType === 'child-journals' ? 'Prefer not to say' : 'N/A', value: 'prefer_not_to_say' });

const getDontKnowOption = chronicleType => (chronicleType === 'child-journals' ? ({ label: 'Don\'t know yet', value: 'other' }) : null);

export default chronicleType => [
  getMaleOption(chronicleType),
  getFemaleOption(chronicleType),
  getNaOption(chronicleType),
  getDontKnowOption(chronicleType),
].filter(el => !!el);
