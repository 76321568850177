import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { withProps } from 'recompose';

export const SummaryWrap = styled.div`
  display: flex;
  box-shadow: 0 1px 1px 0 #ccc;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15);
  border-radius: ${props => props.theme.borderRadii.large};
  height: 100%;
  min-height: 200px;
  box-shadow: ${props => props.theme.shadows.small};
  transition: 0.3s;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 16px rgba(0, 20, 80, 0.02), 0 4px 16px rgba(0, 0, 0, 0.08);
  }
`;

export const UnreadIndicator = styled.span`
  position: absolute;
  top: -4px;
  right: -4px;
  background: ${props => props.theme.colours.red};
  border-radius: 50%;
  opacity: 0.8;
  width: 24px;
  height: 24px;
  display: inline-block;
  z-index: 1;
`;

export const LinkWrap = withProps(props => ({
  type: 'button',
  style: {
    backgroundImage: `linear-gradient(rgba(000, 000, 000, 0.01),rgba(0,0,0,0.4)), url('${props.background}')`,
  },
}))(styled.button`
  flex: 1;
  background-size: cover;
  background-position: center;
  border-radius: ${props => props.theme.borderRadii.large};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  text-decoration: none;
  cursor: pointer;
  height: 100%;
  width: 100%;
`);

export const Title = styled.h4`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colours.white};
  text-align: center;
  font-size: 24px;
  ${props =>
    props.small &&
    css`
      font-size: 20px;
      ${props.theme.media.tablet`
      font-size: 24px;
    `};
    `};
  padding: 0 1em;
  font-weight: normal;
  position: relative;
  margin: auto;
  word-break: break-word;
`;

export const StatusBarWrap = styled.div`
  box-sizing: border-box;
  display: flex;
  padding: calc(${props => props.theme.borderRadii.large} * 2);
  width: 100%;
  position: absolute;
  font-size: 16px;
  bottom: 0;
`;

export const ActivityCount = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colours.white};
  font-size: 0.9em;

  & + & {
    margin-left: 1em;
  }

  img {
    width: 16px;
    height: 16px;
    margin-right: 0.5em;
  }
`;

export const StoryActivityCount = styled(ActivityCount)`
  color: ${props => props.theme.colours.white};
`;

export const AvatarWrap = styled.div`
  margin-left: auto;
`;

export const MoreActionsLink = styled.div`
  background: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
`;

export const List = styled.div``;

export const ListItem = styled.a`
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    margin-right: 5px;
  }
`;

export const DotsWrap = styled.div`
  position: absolute;
  top: calc(${props => props.theme.borderRadii.large} * 2);
  right: calc(${props => props.theme.borderRadii.large} * 2);
  z-index: 2;
`;
