import React, { useMemo } from 'react';
import { Flex, Box, Text } from 'rebass';
import { DateTime } from 'luxon';

const RulerSeconds = ({ seconds, pixelsPerSecond, interval }) => {
  const formattedSeconds = useMemo(() => {
    return DateTime.local()
      .startOf('day')
      .plus({ seconds })
      .toFormat("m':'ss");
  }, [seconds]);
  return (
    <Flex
      flexDirection="column"
      width={pixelsPerSecond * interval}
      key={`timeline-${seconds}`}
      sx={{
        borderLeft: '1px solid',
        borderColor: '#3A3A3A',
        position: 'relative',
        userSelect: 'none',
      }}
      p={1}
      pt={0}
      unselectable="on"
    >
      <Box
        width="1px"
        height={12}
        bg="#3A3A3A"
        sx={{ transform: `translateX(${(pixelsPerSecond * interval) / 2 - 1}px)` }}
      />
      <Text color="mutedText" fontSize={1}>
        {formattedSeconds}
      </Text>
    </Flex>
  );
};

export default RulerSeconds;
