import styled from '@emotion/styled';
import { flexCentre } from '../../app/styles/utils';

export const OuterWrap = styled.div`
  background: rgb(51, 51, 51);

  & > div {
    min-height: 200px;
  }
`;

export const Blur = styled.div`
  background: white;
  filter: blur(3px);
  opacity: 0.5;
`;

export const InnerWrap = styled.div`
  ${flexCentre()}
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100%;
  width: 100%;
`;

export const Message = styled.div`
  display: grid;
  grid-gap: 10px;
  justify-items: center;
  text-align: center;
  color: white;

  p {
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    margin: 0 0 10px;
    max-width: 307px;
  }

  button {
    position: relative;
    z-index: 2;
    margin: 0 5px;
  }
`;

export const ButtonWrap = styled.div`
  ${flexCentre()}
`;

export const Title = styled.h3`
  display: flex;
  align-items: center;
  height: 35px;
  justify-content: center;
  font-size: 28px;
  margin: 0;
  line-height: 1;

  svg {
    fill: ${props => props.theme.colours.accent};
    margin-right: 5px;
  }
`;
