import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Text, Flex, Button } from 'rebass';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

import { IoIosArrowUp } from 'react-icons/io';
import { useCaptionText } from 'story-media/hooks';

const TextCaption = ({ text, mediumType }) => {
  const [isTruncated, setIsTruncated] = useState(true);
  const captionText = useCaptionText(text, isTruncated, 60);

  return (
    <Box
      p={3}
      bg="photoGradientEnd"
      css="position: absolute; bottom: 0; width: 100%;"
      mediumType={mediumType}
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
        sx={{
          position: 'relative',
          '&:hover': { visibility: mediumType === 'videos' ? 'invisible' : 'visible' },
        }}
      >
        <Text color="white" lineHeight={1.5} className="quill ql-lifetales">
          <ReactQuill theme="bubble" value={captionText} readOnly />
        </Text>
        {isTruncated && (
          <Button ml="auto" variant="unset" onClick={() => setIsTruncated(false)} type="button">
            <Text as={IoIosArrowUp} color="white" size={20} />
          </Button>
        )}
      </Flex>
    </Box>
  );
};

TextCaption.propTypes = {
  text: PropTypes.string.isRequired,
  mediumType: PropTypes.string.isRequired,
};

export default TextCaption;
