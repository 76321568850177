import { Button } from 'rebass';
import { Field, Form } from 'formik';
import { useRect } from '@reach/rect';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import styled from '@emotion/styled';

import { relationshipOptions } from '../../../memoirs/new/relationship/options';
import CoverField from '../../../app/form/formik/cover';
import DateField from '../../../app/form/formik/date';
import SelectField from '../../../app/form/formik/select';
import TextField from '../../../app/form/formik/text';

const StyledForm = styled(Form)`
  display: grid;
  grid-gap: 8px;
`;

const MemoirFormBody = ({ isSubmitting, isValid, collection }) => {
  const ref = useRef();
  const rect = useRect(ref);
  const { id: currentCoverId } = collection['cover-image'] || collection['subject-avatar'] || {};

  return (
    <div ref={ref}>
      <StyledForm>
        <Field
          name="cover"
          component={CoverField}
          currentCover={currentCoverId}
          width={rect && rect.width}
        />
        <Field component={TextField} name="name-noac" label="Memoir name" showError width="100%" />
        <Field
          component={TextField}
          name="first-name-noac"
          label="Subject name"
          showError
          width="100%"
        />
        <Field
          label="Subject relationship to you"
          component={SelectField}
          options={relationshipOptions}
          name="subject-relation-noac"
          width="100%"
        />
        <Field
          component={DateField}
          name="date-of-birth"
          label="Birthday"
          placeholder="Birthday"
          width="100%"
        />
        {collection['subject-relation'] !== 'me' && (
          <Field
            label="Are they still with us?"
            component={SelectField}
            name="subject-alive-noac"
            options={[
              { label: 'Yes ❤️', value: true },
              { label: 'No 🙏', value: false },
            ]}
          />
        )}
        <Button variant="primary" type="submit" primary disabled={!isValid || isSubmitting} mt={4}>
          Save
        </Button>
      </StyledForm>
    </div>
  );
};

MemoirFormBody.propTypes = {
  isValid: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  collection: PropTypes.shape({}),
};

MemoirFormBody.defaultProps = {
  collection: {},
};

export default MemoirFormBody;
