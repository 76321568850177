import styled from '@emotion/styled';

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: ${props => props.theme.colours.defaultText};
`;

export const Label = styled.p`
  text-align: center;
`;

export const UrlButtonWrap = styled.div`
  display: inline-flex;
  width: 100%;
  padding: 0 ${props => props.theme.gutter};
`;
