export const CREATE_STORY_MEDIUM = 'CREATE_STORY_MEDIUM';
export const CREATE_STORY_MEDIUM_COVER = 'CREATE_STORY_MEDIUM_COVER';
export const UPLOAD_MEDIUM_FILE = 'UPLOAD_MEDIUM_FILE';
export const PROMPT_DESTROY_MEDIUM = 'PROMPT_DESTROY_MEDIUM';
export const CANCEL_DESTROY_MEDIUM = 'CANCEL_DESTROY_MEDIUM';
export const DESTROY_STORY_MEDIUM = 'DESTROY_STORY_MEDIUM';
export const UPDATE_UPLOAD_PROGRESS = 'UPDATE_UPLOAD_PROGRESS';
export const UPLOAD_MEDIUM_SUCCESS = 'UPLOAD_MEDIUM_SUCCESS';
export const UPLOAD_MEDIUM_START = 'UPLOAD_MEDIUM_START';
export const UPLOAD_MEDIUM_FAIL = 'UPLOAD_MEDIUM_FAIL';
export const CREATE_TEXT_MEDIUM = 'CREATE_TEXT_MEDIUM';
export const UPDATE_TEXT_MEDIUM = 'UPDATE_TEXT_MEDIUM';
export const RECEIVE_STORY_MEDIUM = 'RECEIVE_STORY_MEDIUM';
export const CREATE_MEDIUM = 'CREATE_MEDIUM';
export const SET_REJECTED_MEDIA = 'SET_REJECTED_MEDIA';
export const CLEAR_UPLOAD_BATCH = 'CLEAR_UPLOAD_BATCH';
