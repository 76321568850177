import { Route, Switch } from 'react-router-dom';
import React from 'react';
import ProfileDetail from './profile-detail';
import ManageSubscription from './subscription/manage-subscription';

const Profile = () => (
  <Switch>
    <Route path="/profile/manage-subscription" component={ManageSubscription} />
    <Route path="/profile" component={ProfileDetail} />
  </Switch>
);

export default Profile;
