import React from 'react';
import PropTypes from 'prop-types';
import { Button, Text } from 'rebass';

function RecordButton({ isRecording, isDisabled, isIOSUser, ...props }) {
  return (
    <Button
      variant="transparent"
      sx={{
        borderRadius: '50%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        opacity: isDisabled ? 0.5 : 1,
        transition: 'opacity .17s ease',
      }}
      type="button"
      disabled={isDisabled}
      {...props}
    >
      <div
        style={{
          width: '50px',
          height: '50px',
          border: '2px solid white',
          borderRadius: '50%',
        }}
      >
        <div
          style={{
            background: '#EE0000',
            height: '100%',
            width: '100%',
            borderRadius: isRecording ? '8px' : '50%',
            transform: isRecording ? 'scale(0.6)' : 'scale(1.0)',
            transition: 'all .5s ease',
          }}
        />
      </div>
      {!isIOSUser && (
        <Text color="white" mt={1} bg="rgba(0,0,0,0.6)" p={1} sx={{ borderRadius: '8px' }}>
          Tap to {isRecording ? 'end' : 'start'} recording
        </Text>
      )}
    </Button>
  );
}

RecordButton.propTypes = { isRecording: PropTypes.bool.isRequired };

export default RecordButton;
