import { postStoryPageMeeting } from 'chats/api';
import { createStoryPage } from 'story-pages/api';
import { useCreateStory } from 'stories/hooks';
import { useEntityActionCreators } from 'entities/hooks/index';

export const usePostNewMeeting = (storyId, position = 0) => {
  const createStory = useCreateStory();
  const { receive } = useEntityActionCreators();

  return async () => {
    let newStoryId = storyId;
    if (!newStoryId) {
      const { payload: storyResponse } = await createStory();
      [newStoryId] = storyResponse.data.result.stories;
    }
    const pageResponse = await createStoryPage(newStoryId, {
      data: { attributes: { position } },
    });
    const [pageId] = pageResponse.data.result.pages;
    const payload = await postStoryPageMeeting(newStoryId, pageId);
    if (payload.data) {
      receive({ payload });
    }
    return [payload, newStoryId];
  };
};

export default usePostNewMeeting;
