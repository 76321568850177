import { handle } from 'redux-pack';

import { LOGOUT } from '../auth/types';
import * as types from './types';

const addOrUpdateItemInBatch = (batch, item) => {
  const batchItem = batch.find(elem => elem.id === item.id);
  if (batchItem) {
    return batch.map(elem => {
      if (elem.id === item.id) {
        return { ...batchItem, ...item };
      }
      return elem;
    });
  }
  return [...batch, item];
};

const uploadItemInBatch = (batch, id, attrs) =>
  batch.map(item => {
    if (item.id === id) {
      return {
        ...item,
        ...attrs,
      };
    }
    return item;
  });

const initialState = {
  loading: '', // story page id
  destroyPrompts: [],
  rejectedMedia: [],
  uploadBatch: [],
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.CANCEL_DESTROY_MEDIUM:
      return {
        ...state,
        destroyPrompts: state.destroyPrompts.filter(id => id !== action.id),
      };
    case types.PROMPT_DESTROY_MEDIUM:
      return {
        ...state,
        destroyPrompts: [...state.destroyPrompts, action.id],
      };
    case types.DESTROY_STORY_MEDIUM:
      return handle(state, action, {
        success: prevState => {
          const destroyedMediumId = payload.config.url.split('/').reverse()[0];
          const prompts = new Set(prevState.destroyPrompts);
          prompts.delete(destroyedMediumId);

          const destroyPrompts = Array.from(prompts);

          return {
            ...prevState,
            destroyPrompts,
          };
        },
      });
    case types.RECEIVE_STORY_MEDIUM: {
      const { entities, result } = payload.data;
      const mediumType = Object.keys(entities).filter(key => key !== 'pages')[0];
      if (mediumType === 'texts') return state;

      const [mediumId] = result[mediumType];
      return {
        ...state,
        uploadBatch: addOrUpdateItemInBatch(state.uploadBatch, {
          id: mediumId,
          percentComplete: 0,
          status: 'uploading',
          type: mediumType,
        }),
      };
    }
    case types.UPLOAD_MEDIUM_START: {
      return {
        ...state,
        uploadBatch: addOrUpdateItemInBatch(state.uploadBatch, {
          percentComplete: 0,
          status: 'uploading',
          id: action.meta.id,
          type: action.meta.mediumType,
          fileSize: action.meta.fileSize,
          fileName: action.meta.fileName,
        }),
      };
    }
    case types.UPDATE_UPLOAD_PROGRESS:
      return {
        ...state,
        uploadBatch: uploadItemInBatch(state.uploadBatch, action.mediumId, {
          percentComplete: action.progress,
        }),
      };
    case types.UPLOAD_MEDIUM_FAIL:
      return {
        ...state,
        uploadBatch: uploadItemInBatch(state.uploadBatch, action.meta.id, { status: 'error' }),
      };
    case types.UPLOAD_MEDIUM_SUCCESS:
      return {
        ...state,
        uploadBatch: uploadItemInBatch(state.uploadBatch, action.meta.id, {
          status: 'success',
          percentComplete: 100,
        }),
      };
    case types.CLEAR_UPLOAD_BATCH:
      return { ...state, uploadBatch: [] };
    case types.SET_REJECTED_MEDIA:
      return {
        ...state,
        rejectedMedia: action.names,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};
