import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url('${props => props.bg}');
  background-position: center;
  background-size: cover;
  height: 75vh;
  padding-top: 30px;
  text-align: center;

  h1 {
    margin-top: 0;
  }

  p {
    margin: 0px 20px auto 20px;
    color: ${props => props.theme.colours.mutedText};
    letter-spacing: 0.46px;
  }

  img {
    margin-top: -15px;
    margin-bottom: 40px;
    height: 80%;
    max-height: 410px;
  }
`;

const CollectionOnboardSlide = ({ header, body, image, topImage, bg, imgAltText, ...props }) => (
  <Wrap bg={bg} {...props}>
    {topImage && (
      <img src={topImage} alt={imgAltText} style={{ marginBottom: '8px', marginTop: '-30px' }} />
    )}
    {typeof header === 'string' ? <h1>{header}</h1> : header}
    {typeof body === 'string' ? <p>{body}</p> : body}
    {image && <img src={image} alt={imgAltText} />}
  </Wrap>
);

CollectionOnboardSlide.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  image: PropTypes.string,
  topImage: PropTypes.string,
  bg: PropTypes.string.isRequired,
  imgAltText: PropTypes.string,
};

CollectionOnboardSlide.defaultProps = {
  image: '',
  imgAltText: '',
  topImage: '',
};

export default CollectionOnboardSlide;
