import styled from '@emotion/styled';
import { zIndex, space } from 'styled-system';
import { withProps } from 'recompose';
import { buttonStyles } from '../../stories/new-story-popup/styled';

export const ButtonBar = styled.div`
  ${props => props.theme.utils.flexCentre()};

  background: #f0f0f0;
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.2);
  height: 60px;
  position: relative;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 600px) {
    max-width: 100%;
  }
`;

export const Button = withProps({ type: 'button' })(styled.button`
  ${props => props.theme.utils.flexCentre()};
  ${buttonStyles};

  color: ${props => props.theme.colours.brand};
  height: 44px;
  width: 44px;
  background: ${props => props.theme.colours.white};
  margin: 0 calc(${props => props.theme.gutter} / 2);
  cursor: pointer;
  padding: 8px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  ${zIndex};
  ${space};

  svg {
    margin: auto;
  }
`);
