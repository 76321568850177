import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Box } from 'rebass';

const AlignStyle = Quill.import('attributors/style/align');
Quill.register(AlignStyle, true);

// const IndentStyle = Quill.import('attributors/style/indent');
// Quill.register(IndentStyle, true);

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ align: '' }, { align: 'center' }, { align: 'right' }],
    [{ header: [1, 2, 3, 4, false] }],
    [{ color: [] }, { background: [] }],
    ['link'],
  ],
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'list',
  'bullet',
  // 'indent',
  'link',
  'image',
  'color',
  'background',
  'blockquote',
  'align',
];

const MarkdownEditor = ({ field: { value, ...field }, form: { setFieldValue } }) => {
  const [editorState, setEditorState] = useState(value);
  const ref = useRef(null);
  const boundsRef = useRef(null);

  useEffect(() => {
    if (ref) {
      ref.current.focus();
    }
  }, [ref]);

  const onChange = useCallback(
    value => {
      setEditorState(value);
      setFieldValue(field.name, value.toString('html'));
    },
    [field.name, setFieldValue]
  );

  return (
    <Box id="quill_bounds">
      <ReactQuill
        className="ql-lifetales"
        ref={ref}
        formats={formats}
        modules={modules}
        theme="snow"
        onChange={onChange}
        value={editorState}
        bounds="#quill_bounds"
      />
    </Box>
  );
};

MarkdownEditor.propTypes = {
  field: PropTypes.shape({}).isRequired,
  form: PropTypes.shape({}).isRequired,
};

export default MarkdownEditor;
