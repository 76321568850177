import { singularize, underscore } from 'inflected';
import { stringify } from 'query-string';
// import normalize from 'jsonapi-normalizer';

import useSWR, { trigger } from 'swr';
import { getSWR, get, patch, post, destroy } from '../utils/api';

export const getResourcePath = ({ id, type, queryParams }) => {
  const basePath = `/v3/${underscore(type)}`;
  if (!id) return basePath;
  return `${basePath}/${id}${queryParams ? `?${stringify(queryParams)}` : ''}`;
};

export const getCollections = ({ type }) =>
  get(`/v3/my/${singularize(underscore(type))}_collection`);
export const getCollection = ({ id, type }, opts) => get(getResourcePath({ id, type }), opts);
export const patchCollection = ({ id, type, attributes }) =>
  patch(getResourcePath({ id, type }), { data: { attributes } });
export const postCollection = ({ id, type, attributes }) =>
  post(getResourcePath({ id, type }), { data: { attributes } });
export const destroyCollection = ({ id, type }) => destroy(getResourcePath({ id, type }));

export const getCollectionTags = ({ id }) => get(`/v3/journals/${id}/tags`);
export const postCollectionTag = ({ id, attributes }) =>
  post(`/v3/journals/${id}/tags`, attributes, { normalize: false });
export const patchCollectionTag = ({ id, attributes, prevName }) =>
  patch(`/v3/journals/${id}/tags/${prevName || attributes.name}`, attributes);
export const destroyCollectionTag = ({ id, tag }) => destroy(`/v3/journals/${id}/tags/${tag}`);
export const copyStoryToCollection = ({ id, type, storyId }) =>
  post(`${getResourcePath({ id, type })}/copy_story/${storyId}`, {});
export const aliasStoryToCollection = ({ id, type, storyId }) =>
  post(`${getResourcePath({ id, type })}/alias_story/${storyId}`, {});
export const removeStoryFromCollection = ({ id, type, storyId }) =>
  post(`${getResourcePath({ id, type })}/remove_story/${storyId}`, {});

export const getCollectionCompleteOMeter = ({ id }) => get(`/v3/journals/${id}/complete_o_meters`);
export const destroyCollectionShare = ({ id, type, shareId }) =>
  destroy(`${getResourcePath({ id, type })}/${singularize(underscore(type))}_shares/${shareId}`);
export const postCollectionShare = ({ id, type, attributes }) =>
  post(`${getResourcePath({ id, type })}/${singularize(underscore(type))}_shares`, {
    data: { attributes },
  });
export const patchCollectionShare = ({ id, type, shareId, attributes }) =>
  destroyCollectionShare({ id, type, shareId }).then(() =>
    postCollectionShare({ id, type, attributes })
  );

export const exportCollection = ({ type, id }) =>
  patch(`${getResourcePath({ type, id })}/export`, {});

export const getAllCollections = () => get('/v3/my/chronicle_collection');

// SWR
export const useChronicleCollectionSWR = () =>
  useSWR('/v3/memoirs', getSWR, {
    revalidateOnFocus: false,
  });
export const triggerChronicleCollection = () => trigger('/v3/my/chronicle_collection');
export const useGetCollectionSWR = ({ type, id }) => {
  const path = getResourcePath({ type, id });
  return useSWR(path, getSWR);
};
export const triggerGetCollection = ({ type, id }) => {
  const path = getResourcePath({ type, id });
  return trigger(path);
};
