
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Image, Flex, Text } from 'rebass';
import { FiX as CloseIcon } from 'react-icons/fi';

const FileRow = React.memo(({ file, index, handleRemoveMedia }) => {
  const [fileUrl, setFileUrl] = useState(URL.createObjectURL(file));

  useEffect(() => {
    return () => {
      setFileUrl(null);
      URL.revokeObjectURL(fileUrl);
    };
  }, [fileUrl]);

  return (
    <Flex mb={2} alignItems="center" key={fileUrl}>
      <Image
        src={fileUrl}
        width="50px"
        height="50px"
        css="object-fit: cover;"
        alt={`new upload number ${index + 1}`}
      />
      <Flex flexDirection="column" ml={2}>
        <Text>{file.type}</Text>
        <Text>{(file.size / 1024 / 1024).toFixed(2)}mb</Text>
      </Flex>
      <Button onClick={() => handleRemoveMedia(index)} variant="darkIconCircle" ml="auto">
        <CloseIcon />
      </Button>
    </Flex>
  );
});

FileRow.propTypes = {
  index: PropTypes.number.isRequired,
  file: PropTypes.shape({
    type: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
  }).isRequired,
  handleRemoveMedia: PropTypes.func.isRequired,
};

export default FileRow;
