import { useState } from 'react';

import { useDeepCompareEffect } from '../../app/hooks';
import { useEntities } from '../../entities/hooks';

const useCollectionCollection = () => {
  const collections = useEntities('collections');

  const getCollection = () => {
    const collection = Object.values(collections).find(c => !!c['journal-shares']);
    return collection || {};
  };

  const [state, setState] = useState(getCollection());

  useDeepCompareEffect(() => {
    setState(getCollection());
  }, [collections]);

  return state;
};

export default useCollectionCollection;
