import { Flex, Box } from 'rebass';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import useSWR, { useSWRPages } from 'swr';
// import { get } from 'lodash';

import { useCurrentCollectionTag } from 'collections/hooks/index';
import Loading from '../../../app/loading';
import TagsEmpty from './tags-empty';
import CollectionStoryOptions from './options';
import StoryListItem from '../../../stories/list/item';
import { getResourcePath } from '../../api';
import getNextPaginatedPage from '../../../app/pagination/getNextPaginatedPage';
import LoadMoreButton from '../../../app/pagination/load-more-button';

const CollectionStoriesBody = ({ data }) => {
  if (!data) {
    return (
      <Box p={4} css="width: 100%;">
        <Loading local />
      </Box>
    );
  }
  const { data: shares } = data.data.data.relationships['story-shares'] || { data: [] };

  if (!shares.length) {
    return <TagsEmpty />;
  }
  return shares.map(share => (
    <StoryListItem hasTags key={share.id} id={share.id} type={share.type}>
      <CollectionStoryOptions
        id={share.id}
        type={share.type}
        collectionParams={{ id: data.data.data.id, type: data.data.data.type }}
      />
    </StoryListItem>
  ));
};
CollectionStoriesBody.defaultProps = {
  data: null,
};
CollectionStoriesBody.propTypes = {
  data: PropTypes.shape({
    data: PropTypes.shape({
      data: PropTypes.shape({
        relationships: PropTypes.shape({
          'story-shares': PropTypes.shape({
            data: PropTypes.arrayOf(
              PropTypes.shape({
                id: PropTypes.string,
                type: PropTypes.string,
              })
            ),
          }),
        }),
      }),
    }),
  }),
};

const PaginatedCollectionStoriesBody = ({ id, type, tag }) => {
  const key = `${type}-${id}-${tag || 'recent'}`;
  const { pages, loadMore, isReachingEnd, isLoadingMore } = useSWRPages(
    key,
    ({ offset, withSWR }) => {
      const queryParams = { page: offset || 1, per: 10 };
      if (tag) {
        queryParams.tag_name = tag;
      }
      const path = getResourcePath({
        id,
        type,
        queryParams,
      });
      /* eslint-disable */
      const { data } = withSWR(useSWR(path));
      /* eslint-enable */
      return <CollectionStoriesBody key={key} data={data} />;
    },
    getNextPaginatedPage,
    [id]
  );
  return (
    <Flex flexWrap="wrap" px={3} pt={2}>
      {pages}
      {!isLoadingMore && !isReachingEnd && (
        <LoadMoreButton disabled={isLoadingMore || isReachingEnd} loadMore={loadMore} />
      )}
    </Flex>
  );
};

const CollectionStories = ({ id, type }) => {
  const tag = useCurrentCollectionTag();

  const queryParams = { page: 1, per: 10 };

  if (tag) {
    queryParams.tag_name = tag;
  }

  const path = getResourcePath({
    ...{ id, type },
    queryParams,
  });
  const { data } = useSWR(path);

  const itemCount = useMemo(() => {
    if (!data) return undefined;
    return data.data.data.meta['item-count'];
  }, [data]);

  if (itemCount > 10) return <PaginatedCollectionStoriesBody id={id} type={type} tag={tag} />;

  return (
    <Flex flexWrap="wrap" px={3} pt={2}>
      <CollectionStoriesBody data={data} />
    </Flex>
  );
};

CollectionStories.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default CollectionStories;
