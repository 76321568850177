import { Button, Card, Flex, Box } from 'rebass';
import { FaPen, FaTrash } from 'react-icons/fa';
import PropTypes from 'prop-types';
import React from 'react';

import { destroyCollectionTag, patchCollectionTag, getCollection } from '../../api';
import { getStory } from '../../../stories/api';
import { useEntityActionCreators } from '../../../entities/hooks';
import { useSpreadState } from '../../../app/hooks';
import CollectionTags from '../../tags';
import ConfirmDelete from './confirm-delete';
import FavouriteButton from './favourite-button';
import NewTag from './new-tag';

const TagManagement = ({ onClickTag, collection, storyId }) => {
  const [{ editing, editedTagName, confirmingDelete }, setState] = useSpreadState({
    editing: '',
    editedTagName: '',
    confirmingDelete: '',
  });
  const { receive: receiveEntity } = useEntityActionCreators();

  const handleTag = fn =>
    fn()
      .then(() => getCollection(collection))
      .then(async payload => {
        receiveEntity({ payload });
        if (storyId) {
          const storyRes = await getStory(storyId);
          receiveEntity({ payload: storyRes });
        }
        setState({ confirmingDelete: '', editing: '' });
      });

  if (confirmingDelete) {
    return (
      <ConfirmDelete
        onDelete={() =>
          handleTag(() => destroyCollectionTag({ ...collection, tag: confirmingDelete.name }))
        }
        onCancel={() => setState({ confirmingDelete: '' })}
        tag={confirmingDelete}
      />
    );
  }

  return (
    <>
      <NewTag collection={collection} storyId={storyId} />
      <Box mt={2}>
        <CollectionTags {...collection}>
          {({ tags }) =>
            tags
              .filter(t => t['taggable-type'] === 'Container')
              .map(tag => {
                const isEditingCurrent = editing === tag.name;
                return (
                  <Card bg="white" borderTop="1px solid" borderColor="border">
                    <Flex p={3} alignItems="center" key={tag.name} py={isEditingCurrent ? 2 : 3}>
                      {editing !== tag.name && (
                        <Button
                          color={editing && !isEditingCurrent ? 'lightText' : 'defaultText'}
                          bg="transparent"
                          fontWeight="normal"
                          onClick={() => onClickTag(tag.name)}
                          px={0}
                          py={0}
                        >
                          {tag.name}
                        </Button>
                      )}
                      {!editing && (
                        <>
                          <button
                            onClick={() => {
                              setState({
                                editing: tag.name,
                                editedTagName: tag.name,
                              });
                            }}
                            type="button"
                            style={{ marginLeft: 'auto' }}
                          >
                            <FaPen />
                          </button>
                          <FavouriteButton collection={collection} id={tag.id} />
                        </>
                      )}
                      {editing === tag.name && (
                        <>
                          <input
                            type="text"
                            value={editedTagName}
                            onChange={e => setState({ editedTagName: e.target.value })}
                            style={{
                              fontSize: '16px',
                              border: 'none',
                            }}
                            autoFocus
                          />
                          <Button
                            type="button"
                            bg="brand"
                            border="1px solid"
                            borderColor="brand"
                            fontWeight="normal"
                            onClick={() =>
                              handleTag(() =>
                                patchCollectionTag({
                                  ...collection,
                                  prevName: tag.name,
                                  attributes: { name: editedTagName },
                                })
                              )
                            }
                            ml="auto"
                            mr={1}
                            fontSize={14}
                          >
                            Done
                          </Button>
                          <Button
                            type="button"
                            variant="outline"
                            onClick={() => setState({ editedTagName: '', editing: '' })}
                            fontSize={14}
                          >
                            Cancel
                          </Button>
                        </>
                      )}
                      {!editing && (
                        <Button
                          variant="transparent"
                          onClick={() =>
                            setState({
                              confirmingDelete: { name: tag.name, count: tag.count },
                            })
                          }
                          ml={2}
                        >
                          <FaTrash />
                        </Button>
                      )}
                    </Flex>
                  </Card>
                );
              })
          }
        </CollectionTags>
      </Box>
    </>
  );
};

TagManagement.propTypes = {
  collection: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  storyId: PropTypes.string,
  onClickTag: PropTypes.func,
};

TagManagement.defaultProps = {
  storyId: '',
  onClickTag: () => { },
};

export default TagManagement;
