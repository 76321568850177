import useSWR from 'swr';
import { post, destroy, patch } from 'utils/api';

export const postStoryPageMeeting = (id, pageId) =>
  post(`/v3/stories/${id}/pages/${pageId}/meetings`, {});
export const postStoryMeeting = id => post(`/v3/stories/${id}/meetings`, {});
export const destroyMeeting = id => destroy(`/v3/meetings/${id}`);
export const useMeetingSWR = meetingId => useSWR(`/v3/meetings/${meetingId}`);
export const useClipsSWR = meetingId => useSWR(`/v3/meetings/${meetingId}/clips`);
export const startClip = ({ meetingId }) =>
  post(
    `/v3/meetings/${meetingId}/clips/start`,
    { data: { attributes: { 'meeting-id': meetingId } } },
    { normalize: false }
  );
export const endClip = ({ clipId, meetingId }) =>
  patch(
    `/v3/meetings/${meetingId}/clips/${clipId}/end`,
    { data: { attributes: { 'meeting-id': meetingId } } },
    { normalize: false }
  );
