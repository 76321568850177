import React from 'react';
import PropTypes from 'prop-types';
import { useImage } from 'entities/hooks/useImage';
import { Box, Image } from 'rebass';
import { useEntity } from 'entities/hooks';

export const ImagePlaceholder = () => <Box width="100%" height={190} bg="blueBackground" />;

const StoryListItemCover = ({ id }) => {
  const { story } = useEntity({ type: 'stories', id });
  const coverSrc = useImage({ ...(story['cover-image'] || {}), size: 5 });

  if (!coverSrc) return <ImagePlaceholder />;
  return <Image height={190} src={coverSrc} css="object-fit: cover; width: 100%;" />;
};

StoryListItemCover.propTypes = { id: PropTypes.string.isRequired };

export default StoryListItemCover;
