import styled from '@emotion/styled';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Box, Text, Flex, Button } from 'rebass';
import { FaRegComment, FaRegHeart } from 'react-icons/fa';
import isEmpty from 'lodash/isEmpty';
import MemberAvatar from 'members/avatar';
import { useStoryDate, useStoryFromStoryShare, useIsStoryUnread } from '../../hooks';
import TextList from './text-list';

import StoryListItemCover from './cover';

const ChildrenWrap = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const Badge = () => (
  <Box
    bg="accent"
    py={1}
    px={2}
    color="defaultText"
    fontSize={0}
    css="border-radius: 25px; position: absolute; top: 16px; left: 16px;"
  >
    Updated
  </Box>
);

const StoryListItem = ({ id, type, collectionsList, children }) => {
  const { push } = useHistory();
  const { story } = useStoryFromStoryShare({ id, type });
  const storyDate = useStoryDate(story);
  const hasBadge = useIsStoryUnread(story.id);

  const onClick = useCallback(() => {
    const pathname = story && `/story/${story.id}`;
    push({ pathname });
  }, [push, story]);

  if (!story || isEmpty(story)) return false;

  return (
    <Box
      width="100%"
      sx={{
        position: 'relative',
        p: 2,
        maxWidth: ['50%', '33%', '33%', '33%', '270px'],
        minWidth: ['50%', '33%', '33%', '240px', '300px'],
      }}
      p={2}
    >
      {hasBadge && <Badge />}
      <Button
        type="button"
        onClick={onClick}
        variant="unset"
        css="overflow: hidden; height: 100%; width: 100%; text-align: left;"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          borderRadius: 12,
          boxShadow: 'small',
          bg: 'white',
        }}
      >
        <StoryListItemCover id={story.id} />
        <Flex flexDirection="column" p={12} flex="1">
          <Text color="mutedText" pb={1}>
            {story.title}
          </Text>
          <Text color="lightText" fontSize={0}>
            {storyDate}
          </Text>
          {!!collectionsList.length && <TextList title="in: " items={collectionsList} />}
          <Flex pt={3} alignItems="center" mt="auto">
            <MemberAvatar id={story.member && story.member.id} size={24} />
            <Text as={FaRegHeart} color="grey60" ml="auto" />
            <Text color="grey60" fontSize={0} ml={1}>
              {story['like-count']}
            </Text>
            <Text as={FaRegComment} color="grey60" ml={2} />
            <Text color="grey60" fontSize={0} ml={1}>
              {story['comment-count']}
            </Text>
          </Flex>
        </Flex>
        <ChildrenWrap>{children}</ChildrenWrap>
      </Button>
    </Box>
  );
};

StoryListItem.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  children: PropTypes.node,
  collectionsList: PropTypes.arrayOf(PropTypes.string),
};
StoryListItem.defaultProps = {
  children: null,
  collectionsList: [],
};

export default StoryListItem;
