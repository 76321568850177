import React from 'react';
import PropTypes from 'prop-types';
import { IoIosMore } from 'react-icons/io';
import Tippy from '@tippy.js/react';
import { Button as RebassButton, Box, Text, Flex } from 'rebass';
import { FaYoutube, FaVimeoV, FaSoundcloud, FaUsers } from 'react-icons/fa';
import ButtonLabelWrap from './button-label-wrap';
import { Button } from './styled';

const NewMediaPopupMoreButton = ({ onLinkClick }) => (
  <Tippy
    content={
      <Box>
        <Flex flexDirection="column">
          <MediaButton onClick={() => onLinkClick('externalURLForm-YouTube')}>
            <Text size={18} as={FaYoutube} mr={2} />
            <Text fontSize={2}>YouTube Video</Text>
          </MediaButton>
          <MediaButton onClick={() => onLinkClick('externalURLForm-Vimeo')}>
            <Text size={18} as={FaVimeoV} mr={2} />
            <Text fontSize={2}>Vimeo Video</Text>
          </MediaButton>
          <MediaButton onClick={() => onLinkClick('externalURLForm-SoundCloud')}>
            <Text size={18} as={FaSoundcloud} mr={2} />
            <Text fontSize={2}>SoundCloud Audio</Text>
          </MediaButton>
        </Flex>
      </Box>
    }
    trigger="click"
    arrow
    hideOnClick
    interactive
    theme="light"
    size="big"
    zIndex={10003}
    placement="top-right"
  >
    <ButtonLabelWrap label="More">
      <Button>
        <IoIosMore />
      </Button>
    </ButtonLabelWrap>
  </Tippy>
);
NewMediaPopupMoreButton.propTypes = { onLinkClick: PropTypes.func.isRequired };

const MediaButton = ({ children, onClick }) => (
  <RebassButton variant="unset" as="button" type="button" onClick={onClick} css="padding: 8px;">
    <Flex alignItems="center">{children}</Flex>
  </RebassButton>
);
MediaButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default NewMediaPopupMoreButton;
