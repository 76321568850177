import { useMemo } from 'react';

import { useEntity } from '../../entities/hooks';
import { useImage } from '../../entities/hooks/useImage';

const getCoverUrl = (image = {}, version = 2) => {
  if (image.tmpUrl) return image.tmpUrl;
  if (image['image-versions']) return image['image-versions'][version].url;
  if (image['asset-url']) return image['asset-url'];
  return '';
};

const useCollectionCover = (params, version) => {
  const { entity: collection } = useEntity(params);
  const { entity: image } = useEntity(collection['cover-image'] || collection['subject-avatar']);

  return useMemo(() => {
    return getCoverUrl(image, version);
  }, [image, version]);
};

export const useCollectionCoverImage = ({ id, type, version }) => {
  const { entity: collection } = useEntity({ id, type });
  const imageParams = collection['cover-image'] ||
    collection['subject-avatar'] || { type: 'images ', id: '' };
  return useImage({ version: version || 2, ...imageParams });
};

export default useCollectionCover;
