
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Flex, Heading, Button } from 'rebass';
import { useDispatch } from 'react-redux';
import Modal from '../modal/v2';
import FeatureList from './feature-list';
import { GradientHeader } from './styled';
import LockIcon from '../../assets/svg/lock';
import { useJSONAPIRequest } from '../json-api-client';
import { useHistory } from 'react-router-dom';
import { setCheckmark } from '../../ui/actions';
import useMemberSubscription from '../../members/profile/subscription/useMemberSubscription';
import FreeTrial from './free-trial';
import ParticipantPaywall from './participant';

const Paywall = ({ errorData, onClose }) => {
  const { push } = useHistory();
  const {
    'product-identifier': productIdentifier,
    'subscription-url': subscriptionUrl,
    'object-owner-failure': collectionOwner,
  } = errorData;
  const { allPlans, isTrialEligible, trialUrl } = useMemberSubscription(productIdentifier);
  const dispatch = useDispatch();

  const [canHasTrial, setCanHasTrial] = useState(null);

  useEffect(() => {
    if (isTrialEligible) {
      setCanHasTrial(true);
    }
  }, [setCanHasTrial, isTrialEligible]);

  const { data, mutate } = useJSONAPIRequest({
    method: 'post',
  });

  useEffect(() => {
    if (data) {
      push('/memoirs');
      dispatch(setCheckmark({ message: 'Trial Started' }));
    }
  }, [data, dispatch, push]);

  const handleClick = plan => {
    if (!plan['payment-required']) {
      mutate({}, trialUrl);
    } else if (subscriptionUrl) {
      window.location.href = subscriptionUrl;
    }
  };

  if (collectionOwner === false) return <ParticipantPaywall onClose={onClose} />;

  if (canHasTrial) return <FreeTrial onClose={onClose} productIdentifier={productIdentifier} />;

  return (
    <Modal onClose={onClose} closeButtonLeft aboveTooltip style={{ color: 'white' }}>
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        bg="white"
        css="text-align: center; color: #333; border-radius: 12px;"
      >
        <GradientHeader>
          <LockIcon />
          <Heading mt={3}>Unlock all of the premium features!</Heading>
        </GradientHeader>
        <Flex flexDirection="column" alignItems="center" justifyContent="center" my={3} p={3}>
          <FeatureList />
          {Object.values(allPlans).map(plan => {
            if (!plan['payment-required'] && !isTrialEligible) return false;
            return (
              <Button
                type="button"
                onClick={() => handleClick(plan)}
                key={plan.id}
                mb={2}
                variant={plan['small-title'] === 'YEARLY' ? 'primary' : 'outline'}
                css="width: 100%; border-radius: 25px; display: flex; flex-direction: column; align-items: center;"
              >
                {plan.cta}
                <small>{plan['cta-short-text']}</small>
              </Button>
            );
          })}
        </Flex>
      </Flex>
    </Modal>
  );
};

Paywall.propTypes = {
  onClose: PropTypes.func.isRequired,
  errorData: PropTypes.shape({
    title: PropTypes.string,
    detail: PropTypes.string,
    'subscription-url': PropTypes.string,
    'product-identifier': PropTypes.string,
  }).isRequired,
};

export default Paywall;
