import styled from '@emotion/styled';

export const ConnectionsWrap = styled.div`
  display: grid;
  grid-gap: ${props => props.theme.gutter};
  grid-template-columns: 1fr 1fr;
  ${props => props.theme.media.smallPhone`
    grid-template-columns: repeat(auto-fill, 175px);
  `};
  height: 222px;
  margin-bottom: 40px;
  overflow: hidden;
`;

export default ConnectionsWrap;
