import { Box, Button, Flex, Text } from 'rebass';
import { MdPersonAdd } from 'react-icons/md';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';

import { trigger } from 'swr';
import { useCurrentMemberId } from 'members/hooks/index';
import { addMemberConnection } from '../../members/connections/api';
import { setCheckmark } from '../../ui/actions';

const AddFriendButton = ({ canAddFriend, memberId, ...props }) => {
  const currentMemberId = useCurrentMemberId();

  if (memberId === currentMemberId) return false;

  if (canAddFriend) {
    return (
      <Button
        type="button"
        variant="outline"
        fontSize={1}
        borderRadius={15}
        py="6px"
        px="12px"
        onClick={() => {
          addMemberConnection(memberId).then(() => {
            trigger(`/v2/members/${currentMemberId}/connections`);
            props.setCheckmark({ message: 'request sent!' });
          });
        }}
      >
        <Flex as="span" alignItems="center">
          <Box as={MdPersonAdd} ml={-1} />
          <Box as="span" pl={1}>
            Add Friend
          </Box>
        </Flex>
      </Button>
    );
  }

  return (
    <Text fontSize={1} color="lightText" pr={12}>
      Connected
    </Text>
  );
};

AddFriendButton.propTypes = {
  memberId: PropTypes.string.isRequired,
  addMemberConnection: PropTypes.func.isRequired,
  setCheckmark: PropTypes.func.isRequired,
  canAddFriend: PropTypes.bool.isRequired,
};

const selectCanAddFriend = ({ state, memberId }) => {
  const { currentMemberId } = state.auth;
  if (memberId === currentMemberId) {
    return false;
  }
  const members = Object.values(state.entities.connections).map(
    connection => connection.contact && connection.contact.id
  );
  return !members.includes(memberId);
};

const mapStateToProps = (state, { memberId }) => ({
  canAddFriend: selectCanAddFriend({ state, memberId }),
});

export default connect(mapStateToProps, { addMemberConnection, setCheckmark })(AddFriendButton);
