import { useEffect, useCallback } from 'react';
import { v4 } from 'uuid';
import { useImmer } from 'use-immer';
import { useRect } from '@reach/rect';

import { DateTime } from 'luxon';
import { post } from 'utils/api';
import { produceWithPatches, applyPatches } from 'immer';
import promiseChain from 'utils/promise-chain';
import { useWindowSize } from 'the-platform';

/* eslint-disable default-case */
/* eslint-disable no-param-reassign */
const clipsReducer = (draft, action) => {
  switch (action.type) {
    case 'makeClip': {
      // take all clips, find endAt closest to clip line position
      const clipId = v4();
      if (!draft.length) {
        draft.push({ id: clipId, startAt: 0, endAt: action.endAt, label: 'Page 1' });
        break;
      }

      const clipToSliceIndex = draft.findIndex(
        clip => clip.startAt < action.endAt && clip.endAt > action.endAt
      );
      if (clipToSliceIndex === -1) {
        break;
      }

      const clipToSlice = draft[clipToSliceIndex];

      draft.splice(
        clipToSliceIndex,
        1,
        ...[
          {
            ...clipToSlice,
            endAt: action.endAt,
          },
          {
            id: clipId,
            startAt: action.endAt,
            endAt: clipToSlice.endAt,
          },
        ]
      );
      draft
        .filter(clip => !clip.isSelected)
        .forEach((clip, index) => {
          clip.label = `Page ${index + 1}`;
        });
      draft
        .filter(clip => clip.isSelected)
        .forEach(clip => {
          clip.label = 'Deleted';
        });
      break;
    }
    case 'applyPatches':
      return applyPatches(draft, action.patches);
    case 'selectClip': {
      draft[action.clipIndex].isSelected = !draft[action.clipIndex].isSelected;
      draft
        .filter(clip => !clip.isSelected)
        .forEach((clip, index) => {
          clip.label = `Page ${index + 1}`;
        });
      draft
        .filter(clip => clip.isSelected)
        .forEach(clip => {
          clip.label = 'Deleted';
        });
      break;
    }
  }
};

/* eslint-enable no-param-reassign */
/* eslint-enable default-case */
export const patchGeneratingClipsReducer = produceWithPatches(clipsReducer);

export const useSubmitClips = (videoId, clips, { onStart, onFinish }) => {
  const dateTime = DateTime.local().startOf('day');
  const format = "HH':'mm':'ss'.'SSS";
  return useCallback(() => {
    onStart();
    const promises = clips
      .filter(clip => !clip.isSelected)
      .map(clip => {
        const attributes = {};
        attributes['start-at'] = dateTime.plus({ seconds: clip.startAt }).toFormat(format);
        attributes['end-at'] = dateTime.plus({ seconds: clip.endAt }).toFormat(format);
        attributes['original-video-id'] = videoId;
        return () => post(`/v3/videos/${videoId}/clips`, { data: { attributes } });
      });
    promises.push(() => post(`/v3/videos/${videoId}/clips/process`, {}));
    promises.push(async () => onFinish());
    promiseChain(promises);
  }, [clips, dateTime, onFinish, onStart, videoId]);
};

export const useVisibleTrackRange = ref => {
  const rect = useRect(ref);
  const { width } = useWindowSize();

  const [range, updateRange] = useImmer({
    left: 0,
    right: 0,
  });

  useEffect(() => {
    if (rect) {
      updateRange(draft => {
        draft.left = rect.left ? rect.left * -1 : 0;
        draft.right = rect.left * -1 + Math.min(width, rect.width);
      });
    }
  }, [width, rect, updateRange]);

  return range;
};
