
import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Text, Button, Flex } from 'rebass';
import { usePaymentPolling, usePaymentUrl } from 'memoirs/new/hooks';
import Modal from 'app/modal/v2/index';
import { useHistory } from 'react-router-dom';
import { CurrentCollectionContext } from 'collections/current-collection-context';

const TrialEnded = ({ memoir }) => {
  const { push } = useHistory();
  const { setCurrentCollection } = useContext(CurrentCollectionContext);

  const [stripeUrl, fetchPaymentUrl] = usePaymentUrl(memoir?.id, memoir)
  const [setPolling] = usePaymentPolling(memoir?.id, stripeUrl, memoir)

  useEffect(() => {
    if (memoir?.paid === false) {
      if (!memoir?.['stripe-hosted-payment-url'])
        fetchPaymentUrl()
      setPolling(true);
    }
  }, [memoir?.paid, stripeUrl, setPolling]);

  const handleSubmit = async (url) => {
    if ((url || stripeUrl) && !memoir?.paid) {
      window.open((url || stripeUrl), '_blank');
    }
  };

  return (
    <Modal onClose={() => {
      setCurrentCollection(null)
      push('/');
    }} >
      <Flex p={4} flexDirection="column" css="text-align: center;">
        <Text>
          We hope you enjoyed your free trial. This will be an amazing family treasure!
        </Text>
        <Button
          disabled={!memoir?.paid && !stripeUrl}
          onClick={() => handleSubmit(stripeUrl)}
          variant="primary"
          mt={3}
          ml="auto"
        >
          Purchase your memoir
        </Button>
      </Flex>
    </Modal>
  );
};

TrialEnded.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default TrialEnded;
