import { useMemo } from 'react';
import { isEmpty } from 'lodash';

const useCollectionTypeFromCollection = collection =>
  useMemo(() => {
    if (isEmpty(collection)) return '';

    return (
      collection['journal-shares'] && collection['journal-shares'][0].type.replace('-share', 's')
    );
  }, [collection]);

export default useCollectionTypeFromCollection;
