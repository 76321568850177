import get from 'lodash/get';
import { createSelector } from 'reselect';

export const selectMember = (state, memberId) =>
  state.entities.members && state.entities.members[memberId];

export const selectCurrentMember = state => state.entities.members[state.auth.currentMemberId];

export const selectIdentifiers = state => state.entities.identifiers;

export const selectCurrentMemberEmailIdentifier = createSelector(
  [selectIdentifiers],
  identifiers => {
    const emailIdentifierId = Object.keys(identifiers).find(id => identifiers[id].email);
    return identifiers[emailIdentifierId];
  }
);

export const selectCurrentMemberEmail = createSelector([selectIdentifiers], identifiers => {
  const emailIdentifierId = Object.keys(identifiers).find(id => identifiers[id].email);
  if (!identifiers[emailIdentifierId]) {
    return '';
  }
  return identifiers[emailIdentifierId].email;
});

export const selectMemberFromShareId = (state, publicShareId) =>
  state.entities.members[state.member.byPublicShareId[publicShareId]] || {};

export const selectMemberAvatar = (state, memberId) => {
  const member = selectMember(state, memberId);
  if (!member) {
    return '';
  }

  const { id: imageId } = member['avatar-image'];
  const imageMedium = get(state.entities, `images[${imageId}]`);
  if (!imageMedium) {
    return member['avatar-url'] || '';
  }

  const versionedAvatar = get(imageMedium, '["image-versions"][5].url', '');
  if (versionedAvatar) {
    return versionedAvatar;
  }

  return imageMedium.tmpAvatar || imageMedium['asset-url'] || '';
};

export const selectMemberAvatarMedium = (state, memberId) => {
  const member = selectMember(state, memberId);
  if (!member) {
    return undefined;
  }

  const { id } = member['avatar-image'];
  return get(state.entities, `images[${id}]`, undefined);
};

export const selectMemberName = (state, memberId) => {
  const member = selectMember(state, memberId);

  return (member && member['full-name']) || '';
};

export const selectMemberInitials = (state, memberId) => {
  const memberName = selectMemberName(state, memberId);
  return memberName
    .split(' ')
    .map(name => name[0])
    .join('');
};

export const selectMemberConnection = (state, memberId) => {
  const connectionId = state.connections.byMember[memberId];
  if (!connectionId) return undefined;
  return state.entities.connections[connectionId];
};

export const selectFetchedTrackingEvents = state => state.member.fetchedTrackingEvents;

export const selectMemberTrackingEvent = (state, eventType) => {
  if (!selectFetchedTrackingEvents(state)) return undefined;
  const eventTopics = state.entities['tracker-event-topics'];
  return Object.keys(eventTopics)
    .map(id => eventTopics[id].name)
    .includes(eventType);
};

export const selectMemberCollectionShares = (state, filter = '') => {
  const { shares } = Object.values(state.entities.collections)[0] || { shares: [] };

  if (filter === 'archived') {
    return shares.filter(share => {
      const entity = state.entities[share.type][share.id];
      return entity.archived;
    });
  }

  switch (filter) {
    case 'my-stories':
      return shares.filter(share => {
        const entity = state.entities[share.type][share.id];
        return entity['contact-role'] === 'owner';
      });
    case 'shared-with-me':
      return shares.filter(share => {
        const entity = state.entities[share.type][share.id];
        return entity['contact-role'] !== 'owner';
      });
    default:
      return shares.filter(share => {
        const entity = state.entities[share.type][share.id];
        return !entity.archived;
      });
  }
};

export default selectMember;
