import { Box } from 'rebass';
import { Route, Switch, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { isEmpty } from 'lodash';
import React, { useCallback, useContext, useEffect } from 'react';

import { setCurrentCollectionTag } from 'collections/actions';
import { useDispatch } from 'react-redux';
import { useCollectionTagFromParam } from 'collections/tags/index';
import { useCurrentCollectionTag } from 'collections/hooks/index';
import StoryMutePopup from 'stories/mute/popup';
import { CollectionTooltipProvider } from '../collection-tooltip-context';
import { CurrentCollectionContext } from '../current-collection-context';
import { useCollectionStoryRequests } from './story-requests/hooks';
import { useEntity } from '../../entities/hooks';
import { useParamsFromRoute } from '../hooks';
import AddStory from '../../stories/new-story-popup';
import AnimatedNavWrap from '../../app/navigation/animated-nav-wrap';
import CollectionDetailHeader from './header';
import CollectionMilestonesList from '../../memoirs/milestones/list';
import CollectionStories from './stories';
import CollectionStoryOptions from './story-options';
import CollectionTagFilterNav from './tag-filter-nav';
import Delete from './delete';
import DeleteShare from './delete/delete-share';
import EditCollection from '../edit';
import ExportCollection from './export';
import Milestones from '../../memoirs/milestones';
import NewStoryRequestsModal from './story-requests/new';
import Participants from './participants';
import PendingRequests from '../../story-requests/pending-requests';
import StoryRequestDetail from './story-requests/detail';
import StoryRequestResponse from './story-requests/response';
import StoryRequestDelete from './story-requests/delete';
import StoryRequestsModal from './story-requests';
import SubscriptionBanner from '../../members/profile/subscription/banner';
import TagManagement from './tag-management/collection';
import StoryTagsModal from '../../stories/tags/modal';
import RemoveStoryFromCollection from './remove-story-from-collection';
import { usePaymentBanner } from 'memoirs/new/hooks';
import TrialEnded from 'app/paywall/trial-end';
import NewStoryRequest from './story-requests/new/new-story-request';

const CollectionDetail = () => {
  const dispatch = useDispatch();
  const { id, type } = useParams();
  const { replace } = useHistory();
  const params = useParamsFromRoute({ id, type });
  const onClose = useCallback(() => replace(`/${type}/${id}`), [replace, type, id]);
  const { setCurrentCollection } = useContext(CurrentCollectionContext);
  // const { setCurrent } = useContext(TagContext);
  // const milestonesMatch = useRouteMatch({ path: '/:type/:id/milestones', exact: true });
  const {
    params: { tag },
  } = useRouteMatch('/:type/:id/:tag?');
  const milestonesMatch = tag === 'milestones';
  const paramTag = useCollectionTagFromParam({ ...params, paramTag: tag });
  const currentTag = useCurrentCollectionTag();

  useEffect(() => {
    if (paramTag && currentTag !== paramTag.name) {
      dispatch(setCurrentCollectionTag(paramTag.name));
    }
    return () => {
      setCurrentCollectionTag('');
    };
  }, [currentTag, dispatch, paramTag]);

  const { entity, isPresent } = useEntity(params);
  const isUnderTrial = usePaymentBanner(entity)
  const location = useLocation();
  const pendingRequests = useCollectionStoryRequests(params, 'sharedWithMe');
  // TODO add logic for showing on mobile

  useEffect(() => {
    setCurrentCollection(params);
    // eslint-disable-next-line
  }, []);

  if (isPresent)
    return (
      <Box>
        {!entity?.paid && isUnderTrial === false && !location?.pathname.includes('/delete') && <TrialEnded memoir={entity} />}
        <CollectionTooltipProvider>
          <CollectionDetailHeader id={params.id} type={params.type} />
          <CollectionTagFilterNav collection={params} />
          <SubscriptionBanner />
          {!isEmpty(pendingRequests) && <PendingRequests />}
          {milestonesMatch && <CollectionMilestonesList />}
          {!milestonesMatch && <CollectionStories {...params} />}
          <AnimatedNavWrap location={location}>
            <Route path="/:type/:id/edit" component={EditCollection} key="edit-collection" />
            <Route
              path="/:type/:id/add-story"
              render={props => {
                return (
                  <AddStory
                    {...props}
                    handleClose={onClose}
                    collection={{ type: params.type, id: params.id }}
                  />
                );
              }}
              key="add-story"
            />
            <Route path="/:type/:id/delete-share" component={DeleteShare} key="delete-share" />
            <Route path="/:type/:id/delete" component={Delete} key="delete" />
            <Route
              path="/:type/:id/manage/tags"
              render={() => <TagManagement collection={params} />}
              key="tag-management"
            />
            <Route path="/:type/:id/participants" component={Participants} key="participants" />
            <Route path="/:type/:id/export" component={ExportCollection} key="export-collection" />
            <Route path="/memoirs/:id/life-events" component={Milestones} />
            <Route path="/:type/:id/story-options/:storyId" component={CollectionStoryOptions} />
            <Route path="/:type/:id/memoir-request-story" component={NewStoryRequest} />
            <Route path="/:type/:id/memoir-request-story/:created_memoir_story_request_id" component={NewStoryRequest} />
            <Route path="/:type/:id/remove-story/:storyId" component={RemoveStoryFromCollection} />
            <Route
              path="/:type/:id/story-tags/:storyId"
              render={routeProps => {
                const {
                  match: { params: routeParams },
                  history,
                } = routeProps;
                return (
                  <StoryTagsModal
                    storyId={routeParams.storyId}
                    onBack={() => history.replace(`/${routeParams.type}/${routeParams.id}`)}
                    onClose={() => history.replace(`/${routeParams.type}/${routeParams.id}`)}
                    {...routeProps}
                  />
                );
              }}
            />
            <Route
              path="/:type/:id/mute/:storyId"
              render={routeProps => {
                const {
                  match: { params: routeParams },
                  history,
                } = routeProps;
                return (
                  <StoryMutePopup
                    storyId={routeParams.storyId}
                    onClose={() => history.replace(`/${routeParams.type}/${routeParams.id}`)}
                    {...routeProps}
                  />
                );
              }}
            />
          </AnimatedNavWrap>
          <Switch>
            <Route path="/:type/:id/story-requests/new" component={NewStoryRequestsModal} />
            <Route
              path="/:type/:id/story-requests/:storyRequestId/response"
              component={StoryRequestResponse}
            />
            <Route
              path="/:type/:id/story-requests/:storyRequestId/delete"
              component={StoryRequestDelete}
            />
            <Route
              path="/:type/:id/story-requests/:storyRequestId"
              component={StoryRequestDetail}
            />
            <Route path="/:type/:id/story-requests" component={StoryRequestsModal} />
          </Switch>
        </CollectionTooltipProvider>
      </Box>
    );

  return false;
};

export default CollectionDetail;
