import { useMemo } from 'react';

export { default as useToggle } from './toggle';
export { default as useValue } from './value';
export { default as useSpreadState } from './spread-state';
export { default as useTimeFromNow } from './time-from-now';
export { default as useJsDate } from './js-date';
export { default as useKeyPress } from './key-press';
export { default as useMobile } from './mobile';
export { default as useDeepCompareEffect } from './deep-compare-effect';
export { default as useHistory } from './history';
export { default as useMatch } from './match';
export { default as useLocation } from './location';
export { default as useBuildTarget } from './build-target';

export const useStripHTML = (text = '') => useMemo(() => text.replace(/(<([^>]+)>)/gi, ''), [text]);
