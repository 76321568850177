import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Text, Box, Button } from 'rebass';
import PreviewSlider from './PreviewSlider';
import Grid from 'app/grid/index';
import { useFormattedSeconds } from './FormattedTime';

export function CircleButton(props) {
  return (
    <Button
      p={0}
      type="button"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 30,
        width: 30,
        borderRadius: '50%',
        outline: 'none',
      }}
      bg="purples.7"
      {...props}
    />
  );
}

function FormattedSeconds({ seconds }) {
  const formatted = useFormattedSeconds(seconds);
  return (
    <Text fontSize={0} color="white">
      {formatted}
    </Text>
  );
}

function AudioPlayer({ src, isPlaying, setIsPlaying }) {
  const rafRef = useRef();
  const audioRef = useRef();
  const [canPlay, setCanPlay] = useState(true);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(null);

  const handlePlayPause = useCallback(() => {
    if (isPlaying) {
      audioRef.current.play();
      setIsPlaying(true);
    } else {
      audioRef.current.pause();
      setIsPlaying(null);
    }
  }, [isPlaying, setIsPlaying]);

  const handleScrub = useCallback(
    (position) => {
      audioRef.current.currentTime = position;
      setCurrentTime(position);
    },
    [setCurrentTime]
  );

  const handleEnded = useCallback(() => {
    audioRef.current.currentTime = 0;
    setCurrentTime(0);
    setIsPlaying(null);
  }, [setCurrentTime]);

  const updateTimer = () => {
    setCurrentTime(audioRef.current.currentTime);
    rafRef.current = requestAnimationFrame(updateTimer);
  };

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play();
      rafRef.current = requestAnimationFrame(updateTimer);
    }
    if (!isPlaying) {
      cancelAnimationFrame(rafRef.current);
      rafRef.current = undefined;
      audioRef.current.pause();
    }
    return () => {
      cancelAnimationFrame(rafRef.current);
      rafRef.current = undefined;
    };
  }, [isPlaying]);

  return (
    <Box bg="#222333" p={4} sx={{ borderRadius: '4px', width: '100%' }}>
      <audio
        src={src}
        ref={audioRef}
        onEnded={handleEnded}
        onCanPlay={() => setCanPlay(true)}
        onLoadedMetadata={(e) => {
          setDuration(e.target.duration);
        }}
      />
      <Grid gridTemplateColumns="max-content 1fr" gridGap={2} alignItems="center">
        {/* <CircleButton onClick={handlePlayPause} disabled={!canPlay} bg="white">
          <Text color="#3B3F85" as={isPlaying ? IoIosPause : IoIosPlay} />
        </CircleButton> */}
        <Box>
          {duration && (
            <Grid gridTemplateColumns="1fr max-content 1fr" gridGap={1}>
              <FormattedSeconds seconds={currentTime} />
              <Text fontSize={0} color="white">
                /
              </Text>
              <FormattedSeconds seconds={duration} />
            </Grid>
          )}
        </Box>
        <PreviewSlider
          disabled={!duration}
          value={currentTime}
          min={0}
          max={duration}
          onAfterChange={handleScrub}
          bg="white"
        />
      </Grid>
    </Box>
  );
}

export default AudioPlayer;
