

import { Flex, Text } from 'rebass';
import React from 'react';

import { useCollectionStoryRequests } from '../../collections/detail/story-requests/hooks';
import { useMatch } from '../../app/hooks';
import Request from './request';
import SliderWrap from '../../collections/detail/tag-filter-nav/slider-wrap';

const PendingRequests = () => {
  const { params } = useMatch();
  const pendingRequests = useCollectionStoryRequests(params, 'sharedWithMe');

  return (
    <Flex
      flexDirection="column"
      css="overflow: auto; white-space: nowrap; max-width: 1200px;"
      mx="auto"
      pt="20px"
      px={[20, 20, 20, 20, 0]}
    >
      <Text pb="8px" fontWeight="bold" color="#666" fontSize="18px">
        Story Requests Pending
      </Text>
      <SliderWrap height={[188]} width="50px" bg="background">
        <Flex>
          {pendingRequests.map(request => (
            <Request request={request} key={request.id} />
          ))}
        </Flex>
      </SliderWrap>
    </Flex>
  );
};

export default PendingRequests;
