import { UserAgent } from '@quentin-sommer/react-useragent';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import { FaLink } from 'react-icons/fa';

import { Button } from '../button-bar/styled';
import { linkStoryPages, getStoryPages } from '../actions';
import { postMemberEvent } from '../../members/actions';
import { selectEntity } from '../../entities/selectors';
import { selectFetchedTrackingEvents, selectMemberTrackingEvent } from '../../members/selectors';
import Absolute from '../../app/layout/absolute';
import EventTooltip from '../../members/events/tooltip';

const LinkButton = ({
  position,
  fromPage,
  destPage,
  storyId,
  shouldShowLinkButtonTooltip,
  ...props
}) => {
  const handleClick = () => {
    // const newPageId = props.newPageId ? props.newPageId : props.pageIdBefore;
    props.postMemberEvent('linked_media_interaction');
    props
      .linkStoryPages({
        destPage,
        fromPage,
      })
      .then(() => props.getStoryPages(storyId));
  };

  if (props.pageIdBefore && props.pageIdAfter) {
    return (
      <UserAgent mobile>
        {isMobile => (
          <Absolute left={10}>
            <EventTooltip
              title="Create sections by linking media"
              placement="top"
              isVisible={shouldShowLinkButtonTooltip}
              isEnabled={!isMobile}
            >
              <Button disabled={props.loading} onClick={handleClick} style={{ padding: 0 }}>
                <FaLink />
              </Button>
            </EventTooltip>
          </Absolute>
        )}
      </UserAgent>
    );
  }
  return false;
};

LinkButton.propTypes = {
  newPageId: PropTypes.string,
  pageIdBefore: PropTypes.string,
  pageIdAfter: PropTypes.string,
  linkStoryPages: PropTypes.func.isRequired,
  getStoryPages: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  shouldShowLinkButtonTooltip: PropTypes.bool.isRequired,
  postMemberEvent: PropTypes.func.isRequired,
  position: PropTypes.number.isRequired,
};

LinkButton.defaultProps = {
  pageIdBefore: '',
  pageIdAfter: '',
};

const mapStateToProps = (
  state,
  {
    match: {
      params: { id: storyId },
    },
    pageIdBefore,
    pageIdAfter,
  }
) => {
  const destPage = selectEntity({ state, type: 'pages', id: pageIdBefore });
  const fromPage = selectEntity({ state, type: 'pages', id: pageIdAfter });
  const { loading } = state.storyPages;

  const shouldShowLinkButtonTooltip =
    selectFetchedTrackingEvents(state) &&
    !selectMemberTrackingEvent(state, 'linked_media_interaction');

  return {
    destPage,
    fromPage,
    loading,
    storyId,
    shouldShowLinkButtonTooltip,
  };
};

export default withRouter(
  connect(mapStateToProps, { linkStoryPages, postMemberEvent, getStoryPages })(LinkButton)
);
