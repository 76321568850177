import { camelize, singularize, underscore } from 'inflected';
import { get, isEmpty } from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import isEqual from 'fast-deep-equal';
import { selectEntity } from '../selectors';

import { useCurrentMemberId } from '../../members/hooks';

export default ({ type, id } = {}) => {
  const currentMemberId = useCurrentMemberId();
  const typeKey = camelize(underscore(singularize(type)), false);

  const entity = useSelector(state => {
    if (!(id && type)) return undefined;
    return selectEntity({ state, id, type });
  }, isEqual);

  return useMemo(
    () => ({
      entity: entity || {},
      [typeKey]: entity || {},
      isPresent: !isEmpty(entity),
      isOwner: currentMemberId === get(entity, 'member.id', ''),
      currentMemberId,
    }),
    [currentMemberId, entity, typeKey]
  );
};
