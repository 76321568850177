import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { selectCurrentMember } from '../selectors';
import Avatar from '../avatar';
import { ProfileHeaderWrap, EditProfile } from './styled';

const ProfileHeader = props => (
  <ProfileHeaderWrap>
    <EditProfile to="/profile/edit">Edit Profile</EditProfile>
    <Avatar id={props.member.id} size="100" border style={{ marginTop: '-1em' }} />
    <p>{props.member['full-name']}</p>
  </ProfileHeaderWrap>
);

ProfileHeader.propTypes = {
  member: PropTypes.shape({
    id: PropTypes.string,
    'full-name': PropTypes.string,
  }).isRequired,
};

const mapStateToProps = state => ({
  member: selectCurrentMember(state),
});

export default connect(mapStateToProps)(ProfileHeader);
