
import React from 'react';
import Modal from 'app/modal/v3';
import { useSelector } from 'react-redux';
import { Box, Flex } from 'rebass';
import { getResourcePath } from 'collections/api';
import useSWR from 'swr';
import { useEntity } from 'entities/hooks/index';
import MemberName from 'members/name/index';
import MemberAvatar from 'members/avatar/index';
import { singularize } from 'inflected';
import { selectEntity } from 'entities/selectors';

const CollectionMemberShare = ({ id, type }) => {
  const { entity } = useEntity({ id, type });
  const memberId = entity['with-contact'] && entity['with-contact'].id;

  return (
    <Flex alignItems="center" p={2} css="border-bottom: 1px solid #e6e6e6;">
      <MemberAvatar id={memberId} mr={2} />
      <MemberName id={memberId} />
    </Flex>
  );
};

const useSortedShares = (shares = []) =>
  useSelector(state =>
    shares.sort((a, b) => {
      const shareA = selectEntity({ state, id: a.id, type: a.type });
      const shareB = selectEntity({ state, id: b.id, type: b.type });
      const memberA = selectEntity({ state, ...shareA['with-contact'] });
      const memberB = selectEntity({ state, ...shareB['with-contact'] });
      const nameA = (memberA['full-name'] || '').toLowerCase();
      const nameB = (memberB['full-name'] || '').toLowerCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    })
  );

const ModalCollectionMemberList = ({ id, type, back }) => {
  const { error } = useSWR(getResourcePath({ id, type }));
  const { entity } = useEntity({ id, type });

  const sortedShares = useSortedShares(entity[`${singularize(type)}-shares`]);

  return (
    <Modal title="members" back={back}>
      <Box bg="white">
        {error && 'unable to fetch collection members'}
        {sortedShares.map(share => (
          <CollectionMemberShare id={share.id} type={share.type} key={share.id} />
        ))}
      </Box>
    </Modal>
  );
};

export default ModalCollectionMemberList;
