import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { modalOn, modalOff } from './helper';
import { Close } from './styled';
import closeIcon from '../../assets/images/close.svg';
import './style.css';

const handleClose = (onClose, history) => {
  if (!onClose) return history.goBack();
  return onClose();
};

class Modal extends Component {
  componentWillMount() { modalOn(); }
  componentWillUnmount() { modalOff(); }

  render() {
    const { onClose, history, header, footer, children } = this.props;

    return (
      <div className="modal__wrapper">
        <button
          className="modal__shade"
          onClick={() => handleClose(onClose, history)}
        />
        <div className="modal">
          <div className="modal__header">
            <button
              className="modal__on-close-button"
              onClick={() => handleClose(onClose, history)}
            >
              <span className="modal__header-close">
                <img src={closeIcon} alt="" />
              </span>
            </button>
            <div className="modal__header-label">
              {header}
            </div>
          </div>
          <div className="modal__body">
            {children}
          </div>
          {footer && (
            <div className="modal__footer">
              <button onClick={() => handleClose(onClose, history)}>
                <Close>
                  {footer}
                </Close>
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.string,
  footer: PropTypes.string,
  onClose: PropTypes.func,
  history: PropTypes.shape({}).isRequired,
};

Modal.defaultProps = {
  onClose: null,
  footer: '',
  header: '',
};

export default withRouter(Modal);
