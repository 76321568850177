import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import Modal from '../../../app/modal/v2';

const MemoirsVideoModal = ({ onClose }) => (
  <Modal fullscreenMobile closeButton onClose={onClose}>
    <ReactPlayer
      url="https://d1thxwlajbvmyo.cloudfront.net/downloads/ccaf8930-a071-488b-9764-b08175158c9d.m3u8"
      controls
      playing
      width="100%"
      height="100%"
      style={{ padding: '12px 30px' }}
    />
  </Modal>
);

MemoirsVideoModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default MemoirsVideoModal;
