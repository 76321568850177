import { Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import posed, { PoseGroup } from 'react-pose';

const RoutesContainer = posed.div({
  leave: {
    opacity: 0,
    pointerEvents: 'none',
  },
});

const AnimatedNavWrap = ({ location, children }) => (
  <PoseGroup exitPose="leave">
    <RoutesContainer key={location.pathname}>
      <Switch location={location}>{children}</Switch>
    </RoutesContainer>
  </PoseGroup>
);

AnimatedNavWrap.propTypes = {
  location: PropTypes.shape({}).isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object]).isRequired,
};

export default AnimatedNavWrap;
