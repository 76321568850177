import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import styled from '@emotion/styled';

import { toggleNewStoryVisible } from 'stories/actions';
import { CurrentCollectionContext } from 'collections/current-collection-context';
import { useHasCollectionPermission } from 'collections/permissions/index';
import { postMemberEvent } from '../../../members/actions';
import { selectMemberTrackingEvent } from '../../../members/selectors';
import PlusIcon from '../../../assets/svg/plus-icon';

const ButtonWrap = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 64px;
  cursor: pointer;
  background: ${props => props.theme.colours.brand};
  width: 64px;
  border-radius: 50%;
  margin-top: -50px;
  box-shadow: ${props => props.theme.shadows.container};

  ${props => props.theme.media.desktop`
    height: 100%;
    width: 76px;
    border-radius: 0;
    margin-top: 0;
    box-shadow: none;
    margin-left: auto;
  `};

  span {
    font-size: 14px;
    color: ${props => props.theme.colours.white};
    margin-top: 2px;
    font-family: ${props => props.theme.fonts.sanFrancisco};
  }

  svg {
    color: ${props => props.theme.colours.white};
  }
`;

const AddButton = ({ shouldTrackInteraction, showLabel, ...props }) => {
  const {
    currentCollection: { type, id },
  } = useContext(CurrentCollectionContext);
  const canAddStory = useHasCollectionPermission({ feature: 'ADD_STORY', type, id });
  const dispatch = useDispatch();

  if (!canAddStory) return false;

  const handleClick = () => {
    if (shouldTrackInteraction) {
      props.postMemberEvent('get_started_interaction');
    }
    dispatch(toggleNewStoryVisible());
  };

  return (
    <ButtonWrap onClick={handleClick}>
      <PlusIcon size="22px" />
      {showLabel && <span>Add</span>}
    </ButtonWrap>
  );
};

AddButton.propTypes = {
  postMemberEvent: PropTypes.func.isRequired,
  shouldTrackInteraction: PropTypes.bool.isRequired,
  showLabel: PropTypes.bool,
};

AddButton.defaultProps = {
  showLabel: false,
};

const mapStateToProps = state => ({
  shouldTrackInteraction: selectMemberTrackingEvent(state, 'get_started_interaction') === false,
});

export default connect(mapStateToProps, { postMemberEvent })(AddButton);
