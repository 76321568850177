import styled from '@emotion/styled';
import { grid, space } from 'styled-system';
import { withProps } from 'recompose';

export const CommentOuter = styled.div`
  ${grid};
  ${space};
  display: grid;
  grid-template-columns: max-content auto max-content;
  border-bottom: 1px solid ${props => props.theme.colors.border};
  background: #ffffff;
`;

export const Footer = styled.div`
  border-top: 1px solid ${props => props.theme.colours.border};
  display: grid;
  grid-template-columns: max-content auto;
  grid-gap: 10px;
  padding: 1em;

  ${props => props.theme.media.phone`padding: 1em 1.25em;`};
  ${props => props.theme.media.tablet`padding: 1em 1.5em;`};
`;

export const IconButton = styled.button`
  background: none;
  border: none;
  color: ${props => props.theme.colours.mutedText};
  outline: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 1.063em;
  padding: 0;
  padding-top: 0;
  margin-left: 1em;
  margin-bottom: auto;

  &:hover {
    color: ${props => props.theme.colours.mutedText};
  }
`;

export const Time = styled.div`
  color: ${props => props.theme.colours.mutedText};
  font-size: 0.75em;
  padding-left: 0.5em;
  white-space: nowrap;
`;

export const Author = styled.div`
  font-size: 0.938em;
  font-weight: bold;
`;

export const CommentAuthor = styled.div`
  display: flex;
  align-items: center;
  padding-top: 0.25em;
`;

export const ByLine = styled.div`
  display: flex;
  align-items: center;
`;

export const Body = styled.div`
  padding-top: 3px;

  & + form {
    padding-top: 10px;
  }
`;

export const AddCommentButton = styled.button`
  all: unset;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  background: ${props => props.theme.colours.brand};
  color: ${props => props.theme.colours.white};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: 0;
  line-height: 1;
  opacity: ${props => (props.disabled ? '.5' : '1')};
  transition: opacity 200ms;

  svg {
    margin-left: -2px;
  }

  &:hover {
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
      ${props => props.theme.colours.brand};
  }

  &:active {
    box-shadow: inset 0 0 3px ${props => props.theme.colours.black};
  }
`;

export const TextButton = styled.button`
  all: unset;
  border: 1px solid ${props => props.theme.colours.brand};
  color: ${props => props.theme.colours.brand};
  font-weight: bold;
  height: 45px;
  border-radius: 22.5px;
  padding: 0 10px;
  cursor: pointer;
`;

export const MoreCommentsToggle = withProps({ type: 'button' })(styled.button`
  display: flex;
  justify-content: center;
  width: 100%;
  background: ${props => props.theme.colours.background};
  padding: 0.5em;
`);
