import { getSWR, get, patch, post } from '../utils/api';

export const createStoryRequest = ({ memoirId, memoirShareId, attributes }) =>
  post(`/v3/memoirs/${memoirId}/memoir_shares/${memoirShareId}/story_requests`, {
    data: { attributes: { ...attributes, 'memoir-id': memoirId } },
  });

export const createMemoirStoryRequest = ({ memoirId, attributes }) =>
  post(`/v3/memoirs/${memoirId}/memoir_story_requests`, {
    data: { attributes: { ...attributes } },
  });

export const updateMemoirStoryRequest = ({ memoirId, memoirStoryRequestId, attributes }) =>
  patch(`/v3/memoirs/${memoirId}/memoir_story_requests/${memoirStoryRequestId}`, {
    data: { attributes: { ...attributes } },
  });
export const getMemoirStoryRequestQuestionSuggestions = (memoirId, memoirStoryRequestId) => patch(`/v3/memoirs/${memoirId}/memoir_story_requests/${memoirStoryRequestId}/question_suggestions`);

export const getStoryRequest = id => get(`/v2/story_requests/${id}/pages`);
export const getStoryRequestSWR = id => [
  `/story-requests/${id}`,
  () => getSWR(`/v2/story_requests/${id}/pages`),
];
export const getCollectionStoryRequestShares = id => get(`/v3/memoirs/${id}/story_request_shares`);
export const convertStoryRequestToStory = id =>
  patch(`/v2/story_requests/${id}/convert_to_story`, {});
export const archiveStoryRequestShare = id => patch(`/v3/story_request_shares/${id}/archive`);

export default getStoryRequest;
