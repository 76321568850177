import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useEntity } from '../../entities/hooks/index';

export const useCollectionTitle = ({ id, type }) => {
  const { entity } = useEntity({ id, type });
  const title = useMemo(() => {
    if (type === 'child-journals') return entity['subject-name'] || entity['first-name'] || '';
    return entity.name || entity.title || '';
  }, [entity, type]);
  return title;
};

const CollectionTitle = ({ collection, type }) => useCollectionTitle({ id: collection.id, type });

CollectionTitle.propTypes = {
  collection: PropTypes.shape({}).isRequired,
  type: PropTypes.string.isRequired,
};

export default CollectionTitle;
