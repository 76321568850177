import { difference, uniq } from 'lodash';
import React, { createContext, useCallback, useMemo, useReducer } from 'react';

export const QueryContext = createContext({});

const reducer = (state, action) => {
  switch (action.type) {
    case 'ADD_ACTIVE_QUERY':
      return {
        ...state,
        currentQueries: [...state.currentQueries, action.queryName],
        hasQueried: uniq([...state.hasQueried, action.queryName]),
      };
    case 'REMOVE_ACTIVE_QUERY':
      return {
        ...state,
        currentQueries: difference(state.currentQueries, [action.queryName]),
        completedQueries: uniq([...state.completedQueries, action.queryName]),
      };
    default:
      return state;
  }
};

export const QueryContextProvider = props => {
  // key is the query id
  const [state, dispatch] = useReducer(reducer, {
    currentQueries: [],
    hasQueried: [],
    completedQueries: [],
  });

  const addCurrentQuery = useCallback(
    queryName => dispatch({ type: 'ADD_ACTIVE_QUERY', queryName }),
    []
  );
  const removeCurrentQuery = useCallback(
    queryName => dispatch({ type: 'REMOVE_ACTIVE_QUERY', queryName }),
    []
  );

  const value = useMemo(
    () => ({
      state,
      addCurrentQuery,
      removeCurrentQuery,
    }),
    [addCurrentQuery, removeCurrentQuery, state]
  );

  return <QueryContext.Provider value={value} {...props} />;
};
