import React from 'react';
import PropTypes from 'prop-types';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Wrap, Url, Button } from './styled';

const CopyPublicUrl = ({
  text, large, onCopy,
}) => (
  <Wrap large={large}>
    <CopyToClipboard
      {...{ onCopy, text }}
    >
      <Url title={text}><span>{text}</span></Url>
    </CopyToClipboard>
    <CopyToClipboard
      {...{ onCopy, text }}
    >
      <Button>Copy</Button>
    </CopyToClipboard>
  </Wrap>
);

CopyPublicUrl.propTypes = {
  text: PropTypes.string.isRequired,
  large: PropTypes.bool,
  onCopy: PropTypes.func,
};

CopyPublicUrl.defaultProps = {
  large: false,
  onCopy: () => {},
};

export default CopyPublicUrl;
