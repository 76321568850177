import React, { useState, useEffect } from 'react';
import { Box, Text, Flex } from 'rebass';
import Video from 'twilio-video';
import Participants from './Participants';

function VideoRoom({ roomName, token, dispatch }) {
  const [room, setRoom] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const participantConnected = participant => setParticipants(prev => [...prev, participant]);
    const participantDisconnected = participant =>
      setParticipants(prev => prev.filter(p => p !== participant));

    Video.connect(token, { name: roomName })
      .then(currentRoom => {
        setRoom(currentRoom);
        currentRoom.on('recordingStarted', e => console.log(e));
        currentRoom.on('participantConnected', participantConnected);
        currentRoom.on('participantDisconnected', participantDisconnected);
        currentRoom.participants.forEach(participantConnected);
      })
      .catch(err => setError(err));

    return () => {
      setRoom(currentRoom => {
        if (currentRoom && currentRoom.localParticipant.state === 'connected') {
          currentRoom.localParticipant.tracks.forEach(function(trackPublication) {
            trackPublication.track.stop();
          });
          currentRoom.disconnect();
          return null;
        }
        return currentRoom;
      });
    };
  }, [roomName, token]);

  if (error) return <Text color="red">{error.message}</Text>;

  return (
    <Participants localParticipant={room && room.localParticipant} participants={participants} />
  );
}

export default VideoRoom;
