import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';

import { selectCurrentMemberId } from './selectors';

class EntitiesList extends Component {
  shouldComponentUpdate(nextProps) {
    const { entities } = this.props;
    const { nEntities } = nextProps;

    return !isEqual(nEntities, entities);
  }

  render() {
    const { entities, currentMemberId, children } = this.props;

    return children({
      currentMemberId,
      entities,
      isPresent: !!entities,
    });
  }
}

EntitiesList.propTypes = {
  entities: PropTypes.arrayOf(PropTypes.shape),
  children: PropTypes.func.isRequired,
  currentMemberId: PropTypes.string.isRequired,
};

EntitiesList.defaultProps = {
  entities: null,
};

const selectEntitiesList = ({
  state, type,
}) => state.entities[type] && Object.values(state.entities[type]);

const mapStateToProps = (state, { type }) => ({
  entities: selectEntitiesList({
    state, type,
  }),
  currentMemberId: selectCurrentMemberId({ state }),
});

export default connect(mapStateToProps)(EntitiesList);
