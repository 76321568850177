import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { FaTrash as TrashIcon } from 'react-icons/fa';
import { updateMember, destroyIdentifier } from '../actions';
import { selectIdentifiers } from '../selectors';
import {
  ContactMethodsWrap,
  ContactMethod,
  ContactMethodLabel,
  // DeleteButton,
  SmallerTableCell,
} from './styled';

const getContactMethodLabel = identifier => {
  if (identifier.label) return identifier.label;
  if (identifier.email) return 'Email';
  return 'Contact Method';
};

const getContactMethodLabelAndValue = identifier => {
  const label = getContactMethodLabel(identifier);
  const value = identifier.email;
  return [label, value];
};

const ContactMethods = ({ identifiers, ...props }) => {
  const identifierIds = Object.keys(identifiers);
  return (
    <ContactMethodsWrap>
      <SmallerTableCell>
        <p>Sign-In Methods</p>
      </SmallerTableCell>
      {identifierIds.map(id => {
        const identifier = identifiers[id];
        const [label, value] = getContactMethodLabelAndValue(identifier);
        if (label === 'Email')
          return (
            <ContactMethod key={id}>
              <div>
                <ContactMethodLabel>{label}</ContactMethodLabel>
                <p>{value}</p>
              </div>
              {/* {identifierIds.length > 1 && (
                <DeleteButton onClick={() => props.destroyIdentifier(id)}>
                  <TrashIcon />
                </DeleteButton>
              )} */}
            </ContactMethod>
          );
      })}
    </ContactMethodsWrap>
  );
};

ContactMethods.propTypes = {
  destroyIdentifier: PropTypes.func.isRequired,
  identifiers: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => ({
  identifiers: selectIdentifiers(state),
});

export default connect(mapStateToProps, { updateMember, destroyIdentifier })(ContactMethods);
