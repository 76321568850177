import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { useEntity } from '../../entities/hooks';
import Role from './member-role';

const ConnectionRole = ({ id, onClick, shareType }) => {
  const { connection } = useEntity({ id, type: 'connections' });
  const { entity: member } = useEntity(connection.contact);

  const [role, setRole] = useState('');

  return (
    <Role
      memberId={member.id}
      role={role}
      setRole={nextRole => {
        if (nextRole === role) {
          setRole('');
        } else {
          setRole(nextRole);
        }
        onClick({ role: nextRole, id: member.id });
      }}
      disabled={false}
      coOwnerIcon={shareType === 'story-shares' ? 'PENCIL' : 'USER_COG'}
    />
  );
};

ConnectionRole.propTypes = {
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  shareType: PropTypes.string.isRequired,
};
ConnectionRole.defaultProps = {
  shareType: '',
};

export default ConnectionRole;
