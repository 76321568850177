import PropTypes from 'prop-types';
import React from 'react';

import { useEntity } from '../../entities/hooks';
import Avatar from '../../app/avatar';

const MemberAvatar = ({ id, memberId, ...props }) => {
  const { member } = useEntity({ id: memberId || id, type: 'members' });

  return (
    <Avatar
      imageId={member['avatar-image'] && member['avatar-image'].id}
      name={member['full-name']}
      fallbackUrl={member['avatar-url']}
      {...props}
    />
  );
};

MemberAvatar.propTypes = {
  id: PropTypes.string,
  memberId: PropTypes.string,
};

MemberAvatar.defaultProps = {
  memberId: '',
  id: '',
};

export default MemberAvatar;
