import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Flex, Text } from 'rebass';

function Timer({ hideTimer }) {
  const [seconds, setSeconds] = useState(3);

  useEffect(() => {
    if (seconds > 0) setTimeout(() => setSeconds(seconds - 1), 1000);
    else hideTimer();
  });

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      sx={{
        position: 'absolute',
        borderRadius: '50%',
        width: '200px',
        height: '200px',
        left: 0,
        right: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
        top: '50px',
        background: '#3B3F85',
        boxShadow: '1px 4px 4px rgba(0, 0, 0, 0.1)',
        zIndex: 1,
      }}
    >
      <Text
        sx={{
          fontStyle: 'normal',
          fontWeight: 'bold',
          fontSize: '160px',
          textAlign: 'center',
          color: '#FFFFFF',
        }}
      >
        {seconds}
      </Text>
    </Flex>
  );
}

Timer.propTypes = { hideTimer: PropTypes.func.isRequired };

export default Timer;
