import { Button, Flex } from 'rebass';
import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';

import SupportCopy from './copy-wrap';

const AddTitle = ({ onChangeText, onSubmit, disabled }) => {
  return (
    <SupportCopy
      title="Add a title for this request?"
      body="Titles help you find requests more easily. (others will not see this)"
    >
      <Input type="input" onChange={onChangeText} />
      <Flex alignItems="stretch" mt={3} width="100%" style={{ minHeight: '40px' }}>
        <Button variant="mutedOutline" type="button" onClick={onSubmit} flex="1" mr={2}>
          Skip
        </Button>
        <Button
          onClick={onSubmit}
          flex="1"
          variant="outline"
          fontWeight="bold"
          disabled={disabled}
          ml={2}
        >
          Add title
        </Button>
      </Flex>
    </SupportCopy>
  );
};

const Input = styled.input`
  height: 60px;
  border: 1px solid ${props => props.theme.colours.border};
  font-size: 20px;
  text-align: center;
  padding: 20px;
  width: 100%;
`;

AddTitle.propTypes = {
  onChangeText: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default AddTitle;
