import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Component from '@reach/component-component';
import PropTypes from 'prop-types';
import React from 'react';

import { getStoryRequest } from '../api';
import { receiveEntity } from '../../entities/actions';
import Entity from '../../entities/entity';

const StoryRequestDetail = ({
  match: {
    params: { storyRequestId },
  },
  ...props
}) => (
  <Component
    didMount={() => {
      getStoryRequest(storyRequestId).then(payload => props.receiveEntity({ payload }));
    }}
  >
    <Entity type="story-requests" id={storyRequestId}>
      {({ entity: storyRequest, isPresent }) =>
        isPresent && (
          <div>
            {storyRequest.title}
            <div>{storyRequest.pages && storyRequest.pages.length}</div>
            <Link to={`/memoirs/${storyRequest.memoir && storyRequest.memoir.id}`}>
              Back to memoir
            </Link>
          </div>
        )
      }
    </Entity>
  </Component>
);

StoryRequestDetail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      storyRequestId: PropTypes.string,
    }).isRequired,
  }).isRequired,
  receiveEntity: PropTypes.func.isRequired,
};

export default connect(
  null,
  { receiveEntity }
)(StoryRequestDetail);
