import React, { useContext } from 'react';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';
import { Flex, Text } from 'rebass';
import { FaVideoSlash } from 'react-icons/fa';
import { useEntity } from 'entities/hooks';
import { StoryPageContext } from 'story-pages/item/context';
import { useIsSafariUser } from 'story-media/hooks';

const Player = ({ editing, id }) => {
  const { entity: medium } = useEntity({ id, type: 'audios' });
  const { currentMedia, setCurrentMedia } = useContext(StoryPageContext);
  const isSafariUser = useIsSafariUser();

  if (medium.state === 'pre_uploaded' || (isSafariUser && medium.state !== 'finished')) {
    return (
      <Flex
        bg="border"
        width="100%"
        height={[260, 400]}
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        px={4}
        sx={{ textAlign: 'center' }}
      >
        <Text as={FaVideoSlash} fontSize={96} color="lightText" pb={3} />
        <Text pb={3} color="mutedText">
          This audio clip is being processed
        </Text>
        <Text pb={3} color="mutedText">
          In the meanwhile, feel free to add more to the story
        </Text>
        <Text pb={3} color="mutedText">
          ... or create a new story while you wait &#128516;
        </Text>
      </Flex>
    );
  }

  return (
    <Flex justifyContent="center" p={3} pt={editing ? 5 : 3}>
      <ReactPlayer
        url={isSafariUser ? medium['asset-url'] : medium.tmpUrl || medium['asset-url']}
        controls
        height={40}
        onPlay={() => setCurrentMedia(id)}
        onPause={() => setCurrentMedia(null)}
        playing={currentMedia === id}
      />
    </Flex>
  );
};

Player.propTypes = {
  id: PropTypes.string.isRequired,
  editing: PropTypes.bool,
};

Player.defaultProps = { editing: false };

export default Player;
