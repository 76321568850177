import styled from '@emotion/styled';
import { css } from '@emotion/core';

export const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 100vw;
`;

export const Blur = styled.div`
  ${props =>
    props.on &&
    css`
      filter: ${props.theme.blur};
    `};
`;

export const DottedDivider = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colours.mutedText};
  font-size: 14px;
  margin-bottom: ${props => props.theme.gutter};

  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: 2px dashed #dadada;
    height: 2px;
    margin: 0 5px;
  }
`;
