import { useMemo } from 'react';

import { useCurrentMemberId } from '../../members/hooks';
import { useEntities } from '../../entities/hooks';

export const useUserStoryShareFromStory = id => {
  const currentMemberId = useCurrentMemberId();
  const storyShares = useEntities('story-shares');
  // const { story } = useEntity({ type: 'stories', id });

  return useMemo(() => {
    const shareId = Object.keys(storyShares).find(key => {
      const { [key]: share } = storyShares;
      if (!share['with-contact'] || !share.story) return false;
      return share['with-contact'].id === currentMemberId && share.story.id === id;
    });
    return { id: shareId, type: 'story-shares' };
  }, [currentMemberId, id, storyShares]);
};

export default useUserStoryShareFromStory;
