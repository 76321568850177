/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
/* eslint-disable import/prefer-default-export */
export const newStoryRequestReducer = (state, action) => {
  switch (action.type) {
    case 'TOGGLE_SHARE': {
      const shareIndex = state.shares.findIndex(share => share.id === action.share.id);
      if (shareIndex !== -1) {
        state.shares.splice(shareIndex, 1);
        break;
      }
      state.shares.push(action.share);
      break;
    }
    case 'CLEAR_SHARES': {
      state.shares = [];
      break;
    }
    case 'SET_TITLE': {
      state.title = action.title;
      break;
    }
    case 'SET_FILE': {
      state.file = action.file;
      break;
    }
  }
};
