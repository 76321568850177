import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { withProps } from 'recompose';

export const ProfileWrap = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  max-width: 540px;
  margin: 2em auto;
  padding: 1em;
  width: 100%;
  margin-top: 60px;

  & > form {
    width: calc(100% - 200px);
    padding: 0 1em;
  }
`;

export const ContactMethodsWrap = styled.div`
  width: 100%;
  margin-bottom: 1em;
`;

export const ContactMethod = styled.div`
  background: ${props => props.theme.colours.white};
  display: flex;
  align-items: center;
  padding: 1em;
  color: ${props => props.theme.colours.mutedText};
  border: 1px solid ${props => props.theme.colours.border};
  min-height: 75px;

  & + & {
    border-top: none;
  }

  p {
    font-size: 0.9em;
    margin: 0;

    & + p {
      margin-top: 0.5em;
    }
  }
`;

export const ContactMethodLabel = styled.p`
  color: ${props => props.theme.colours.lightText};
`;

export const DeleteButton = styled.button`
  margin-left: auto;
  font-size: 16px;
  background-color: transparent;
`;

export const LinksGroup = styled.div`
  display: flex;
  justify-content: stretch;
  align-items: center;
  border: 2px solid ${props => props.theme.colours.brand};
  border-radius: 6px;
  margin: 0 1em 2em;

  a {
    text-align: center;
    text-decoration: none;
    width: 100%;
    padding: 0.5em;

    &:active {
      color: inherit;
    }

    &.active {
      background: ${props => props.theme.colours.brand};
      color: ${props => props.theme.colours.white};
    }
  }
`;

export const TableHeader = styled.h2`
  font-size: 14px;
  line-height: 18px;
  color: ${props => props.theme.colours.mutedText};
`;

export const StyledLink = withProps({
  target: '_blank',
  rel: 'noopener noreferrer',
})(styled.a`
  color: ${props => props.theme.colours.brand};
  text-decoration: none;
  font-size: 15px;
`);

export const AddContactMethodButton = styled.button`
  background: ${props => props.theme.colours.white};
  color: ${props => props.theme.colours.brand};
  display: flex;
  align-items: center;
  padding: 1em;
  border: 1px solid ${props => props.theme.colours.border};
  min-height: 75px;
  width: 100%;
  font-size: 15px;
`;

export const ProfileHeaderWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${props => props.theme.colours.brand};
  align-items: center;
  border-radius: ${props => props.theme.borderRadii.large} ${props => props.theme.borderRadii.large}
    0px 0px;

  & > p {
    color: ${props => props.theme.colours.white};
    font-size: 20px;
    text-align: center;
    font-weight: bold;
  }
`;

export const EditProfile = styled(Link)`
  text-decoration: none;
  color: ${props => props.theme.colours.white};
  border: 1px solid;
  border-color: ${props => props.theme.colours.white};
  border-radius: ${props => props.theme.borderRadii.large};
  padding: 0.25em 1em;
  display: flex;
  align-self: flex-end;
  margin-right: 1em;
  margin-top: 1em;
`;

export const SmallerTableCell = styled.div`
  background: ${props => props.theme.colours.white};
  display: flex;
  align-items: center;
  padding: 1em;
  color: ${props => props.theme.colours.mutedText};
  border: 1px solid ${props => props.theme.colours.border};
  border-bottom: none;
  height: 50px;

  p {
    font-family: ${props => props.theme.fonts.quicksand};
    font-size: 14px;
    line-height: 18px;
    color: ${props => props.theme.colours.lightText};
  }
`;

export default ProfileWrap;
