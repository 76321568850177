import React, { useEffect, useRef } from 'react';
import { Text, Flex, Image, Button, Box } from 'rebass';
import Footer from 'stories/tell-a-story/Footer';
import { useState } from 'react';
import useEntity from 'entities/hooks/use-entity';
import { FiChevronRight, FiChevronDown } from 'react-icons/fi';
import layer from './assets/layer.png';
import LoadingIcon from 'app/inline-loading/index';
import { capitalizeFirstLetter } from 'stories/helper';

const topicCSS = {
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '19.6px',
    color: '#222222',
    textAlign: 'left',
    letterSpacing: '-2.2%',
    border: 0,
    background: 'transparent',
    p: 0,
    outline: 'none',
    width: '-webkit-fill-available',
};

function RenderQuestionCategories({ index, heading, selectedSuggestion, setQuestion, setSelectedSuggestion, suggestedQuestions }) {
    const [isOpen, setIsOpen] = useState(index === 0);

    let height = '0px';
    if (isOpen) height = `max-content`;

    return (
        <Box
            m={2}
            mb={2}
            sx={{ background: 'linear-gradient(90deg, #9DEFFF 0%, #E3E0FF 105.43%)' }}
        >
            <Flex
                justifyContent="space-between"
                alignItems="center"
                p={2}
                sx={{ borderRadius: '6px 6px 0px 0px' }}
            >
                <Text sx={{
                    fontSize: '16px',
                    lineHeight: '120%',
                    color: 'black',
                    fontWeight: 600,
                    textAlign: 'left',
                }}
                >
                    {heading}
                </Text>
                <Text
                    color="black"
                    as={isOpen ? FiChevronDown : FiChevronRight}
                    size={24}
                    sx={{ textDecorationLine: 'underline', cursor: 'pointer' }}
                    onClick={() => setIsOpen(!isOpen)}
                />
            </Flex>

            <Box sx={{ transition: 'height 0.6s', height, overflowY: 'hidden', bg: 'white' }}>
                {suggestedQuestions.map(({ id, question }) => {
                    const isSelected = selectedSuggestion === id
                    return (
                        <Box
                            sx={{
                                border: `1px solid ${isSelected ? '#554A94' : '#8E8E8E'}`,
                                bg: isSelected ? `rgba(99, 105, 239, 0.3)` : 'white',
                                borderRadius: '6px',
                                p: 1,
                                my: 1,
                            }}
                        >
                            <Text
                                as="textarea"
                                readOnly
                                title={question}
                                sx={{
                                    ...topicCSS,
                                    display: 'inline-block',
                                    width: '-webkit-fill-available',
                                    resize: 'none',
                                    height: 'auto',
                                }}
                                value={question}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    if (isSelected) {
                                        setSelectedSuggestion(null);
                                        setQuestion(null)
                                    }
                                    else {
                                        setSelectedSuggestion(id);
                                        setQuestion(question)
                                        window.scrollTo(0, 0);
                                    }
                                }}
                            />
                        </Box>
                    )
                })}
            </Box>
        </Box>
    );
}

const SelectQuestion = ({ memoirId, isLoading, errorMessage, suggestions, selectedSuggestion, setSelectedSuggestion, loadIdeas, isStillAlive, storyRequestData, onSubmit, isSubmitting, onStepDown, isSubjectAlive, percentComplete }) => {
    const { memoir } = useEntity({ type: 'memoirs', id: memoirId })
    const [question, setQuestion] = useState(storyRequestData?.['question'] || null)

    const outerRef = useRef(null);

    // const contributorExperience = storyRequestData?.['contributor-experience']

    useEffect(() => {
        if (selectedSuggestion && outerRef.current)
            outerRef.current.scroll({ top: 0, behaviour: 'smooth' });
    }, [selectedSuggestion])

    const name = memoir?.['subject-relation'].toLowerCase().includes('other') ? capitalizeFirstLetter(memoir?.['subject-name']) : capitalizeFirstLetter(memoir?.['subject-relation']);

    function getTitle() {
        // if (contributorExperience === 'memoir_subject')
        //     return `Ask ${memoir?.['subject-relation']} a question`
        // if (memoir?.['subject-relation'] === 'me')
        return `What would you like to ask?`
        // return `What would you like to  ask about ${name}?`
    }

    // const placeHolderTitle = memoir?.['subject-relation'] === 'me' ? memoir?.['subject-relation'] : `my ${name}`

    return (
        <Flex
            flexDirection="column"
            m="auto"
            width="100%"
            justifyContent="flex-start"
            alignItems="left"
            pb="100px"
            sx={{
                height: `calc(100vh - 150px)`,
                width: '100%',
                overflowY: 'scroll',
            }}
            ref={outerRef}
        >
            <Text
                px={2}
                textAlign="left"
                fontFamily='Zilla Slab'
                fontSize={30}
                lineHeight="120%"
                letterSpacing='-0.66px'
                my={3}
            >
                {getTitle()}
            </Text>
            <Flex px={2} flexDirection="column">
                <textarea
                    // placeholder={`eg. What was your fondest memory with ${placeHolderTitle}?`}
                    placeholder={`eg. What was your fondest memory?`}
                    type="input"
                    autoComplete="off"
                    value={question}
                    onChange={e => setQuestion(e.target.value)}
                    style={{
                        fontSize: '16px',
                        width: '100%',
                        height: '68px',
                        padding: '12px 42px 12px 20px',
                        border: '1px solid #554A94',
                        borderRadius: '8px',
                        background: 'rgba(255, 255, 255, 0.10)',
                        resize: 'none'
                    }}
                />
            </Flex>
            <Box mx="auto" textAlign="center">
                <Image src={layer} maxWidth={166} m="auto" my={2} />
                <Text
                    px={2}
                    textAlign="center"
                    fontFamily='DM Sans'
                    fontSize={16}
                    lineHeight="120%"
                    letterSpacing='-0.352px'
                    m="auto"
                    mb={2}
                >
                    Not sure what to ask? Here are some ideas...
                </Text>

                {errorMessage &&
                    <Text
                        px={2}
                        textAlign="center"
                        fontFamily='DM Sans'
                        fontSize={16}
                        lineHeight="120%"
                        letterSpacing='-0.352px'
                        m="auto"
                        color='red'
                        mb={2}
                    >
                        {errorMessage}
                    </Text>}
            </Box>
            {isLoading && <LoadingIcon />}
            {suggestions && Object.keys(suggestions).map((heading, index) =>
                <RenderQuestionCategories
                    index={index}
                    key={heading}
                    heading={heading}
                    selectedSuggestion={selectedSuggestion}
                    suggestedQuestions={suggestions[heading]}
                    setSelectedSuggestion={setSelectedSuggestion}
                    setQuestion={setQuestion}
                />
            )}
            <Footer
                onBack={isStillAlive ? onStepDown : undefined}
                disabled={!question || isSubmitting}
                onNextPress={() => {
                    const attributes = { question }
                    onSubmit(attributes)
                }}
                middleButton={<Button
                    height={60}
                    bg="#53F"
                    maxWidth="max-content"
                    disabled={isLoading}
                    py={0}
                    onClick={loadIdeas}
                >
                    Generate {!!suggestions && 'More'} Ideas
                </Button>}
                percentComplete={percentComplete}
                nextButtonText="Next"
            />
        </Flex>
    );
};

export default SelectQuestion;
