import { useMemo } from 'react';

import { useEntity } from '../../entities/hooks';

const getCoverUrl = (story = {}, image = {}) => {
  if (image.tmpUrl) return image.tmpUrl;
  if (image['image-versions']) return image['image-versions'][2].url;
  if (image['asset-url']) return image['asset-url'];
  return story['image-url'] || '';
};

const useStoryCover = params => {
  const { story } = useEntity(params);
  const { image } = useEntity(story['cover-image']);

  return useMemo(() => getCoverUrl(story, image), [image, story]);
};

export default useStoryCover;
