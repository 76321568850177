import { formatShareBody } from './helper';
import * as api from './api';
import * as types from './types';

export const getStoryShares = storyId => ({
  type: types.GET_STORY_SHARES,
  promise: api.getStoryShares(storyId),
  meta: {
    storyId,
  },
});

export const addStoryShare = (storyId, memberId, withContactId, role) => ({
  type: types.ADD_STORY_SHARE,
  promise: api.addStoryShare(storyId, formatShareBody(memberId, withContactId, role)),
  meta: {
    storyId,
    withContactId,
  },
});

export const updateStoryShare = (storyId, withContactId, storyShareId, role) => ({
  type: types.ADD_STORY_SHARE,
  promise: api.patchStoryShare(storyShareId, role),
  meta: {
    storyId,
    withContactId,
  },
});

export const removeStoryShare = (shareId, storyId) => ({
  type: types.REMOVE_STORY_SHARE,
  promise: api.removeStoryShare(shareId),
  meta: {
    storyId,
    shareId,
  },
});

export const createStoryShare = ({ storyShareId }) => ({
  type: types.MAKE_PUBLIC_STORY_SHARE,
  promise: api.postPublicStoryShare(storyShareId),
});

export const receiveStoryShareDeletion = payload => ({
  type: types.RECEIVE_STORY_SHARE_DELETION,
  payload,
});

export const archiveStory = id => ({
  type: types.ARCHIVE_STORY,
  promise: api.archiveStory(id),
});

export const unarchiveStory = id => ({
  type: types.UNARCHIVE_STORY,
  promise: api.unarchiveStory(id),
});
