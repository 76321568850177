import { handle } from 'redux-pack';
import * as types from './types';
import { GET_CURRENT_USER } from '../members/types';

const initialState = {
  token: '',
  currentMemberId: '',
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_AUTH:
      return {
        ...state,
        token: action.token,
        currentMemberId: action.memberId,
      };
    case GET_CURRENT_USER:
      return handle(state, action, {
        start: prevState => ({ ...prevState, loading: true }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prev => ({
          ...prev,
          token: '',
          failedTokenAuth: true,
        }),
        success: prev => ({
          ...prev,
          token: payload.config.headers.Authorization,
          currentMemberId: payload.data.result.members[0],
          failedTokenAuth: false,
        }),
      });
    case types.LOGOUT:
      return initialState;
    default:
      return state;
  }
};
