import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  FaTrash as DeleteIcon,
  FaExclamationCircle as ExclamationIcon,
} from 'react-icons/fa';
import Button from '../../app/buttons';
import { destroyMedium } from '../actions';
import { Message, ButtonWrap, Title } from './styled';

const Processing = ({
  id, pageId, mediumType, storyId, ...props
}) => (
  <Message>
    <Title>
      <ExclamationIcon size="24" />
      <span>
        Processing Failed
      </span>
    </Title>
    <p>
      Please delete this image and reupload it.
      Sorry for the inconvenience!
    </p>
    <ButtonWrap>
      <Button
        onClick={() => (props.destroyMedium(
          id,
          pageId,
          mediumType,
          storyId,
        ))}
        destructive
      >
        <DeleteIcon
          color="white"
          size="24"
        />
        Delete
      </Button>
    </ButtonWrap>
  </Message>
);

Processing.propTypes = {
  id: PropTypes.string.isRequired,
  mediumType: PropTypes.string.isRequired,
  pageId: PropTypes.string.isRequired,
  storyId: PropTypes.string.isRequired,
  destroyMedium: PropTypes.func.isRequired,
};

export default connect(
  null,
  { destroyMedium },
)(Processing);
