import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';
import { withProps } from 'recompose';

import checkmarkSvg from './assets/circle-checkmark.svg';

const Thumbnail = ({ url, selected, onClick }) => (
  <Wrap>
    <ImageButton src={url} selected={selected} onClick={onClick} />
    {selected && <img src={checkmarkSvg} alt="checkmark" />}
  </Wrap>
);

Thumbnail.propTypes = {
  url: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

Thumbnail.defaultProps = {
  selected: false,
};

const Wrap = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 40px);
  margin: 0 4px 0 0;
  border-radius: ${props => props.theme.borderRadii.large};

  img {
    z-index: 1;
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    width: 20px;
    height: 20px;
    justify-self: end;
    align-self: end;
    margin: 0 2px 10px 0;
  }
`;

const ImageButton = withProps(props => ({
  type: 'button',
  style: {
    backgroundImage: `url('${props.src}')`,
  },
}))(styled.button`
  height: 40px;
  width: 80px;
  background-position: center;
  background-size: cover;
  filter: ${props => (props.selected ? 'opacity(50%)' : 'opacity(100%)')};
  grid-column: 1 / 3;
  grid-row: 1 / 2;
  transition: filter 0.3s linear;
  border-radius: ${props => props.theme.borderRadii.large};
  margin-bottom: 8px;
  padding: 0;
`);

export default Thumbnail;
