import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass';

function Progress({ percentComplete, height = 7, ...props }) {
  return (
    <Box bg="#D9D9D9" {...props} sx={{ borderRadius: '12px', overflow: 'hidden' }}>
      <Box
        bg="#16161D"
        height={height}
        sx={{ transition: 'width .374s ease-out' }}
        style={{ width: `${percentComplete}%` }}
      />
    </Box>
  );
}
Progress.defaultProps = { percentComplete: 4 };
Progress.propTypes = { percentComplete: PropTypes.number };

export default Progress;
