import React from 'react';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import { useEntityActionCreators, useEntity } from 'entities/hooks/index';
import { useCurrentMemberId } from 'members/hooks/index';
import { acceptConnection } from '../../members/connections/api';

const getMessageForStatus = (connection = { contact: {} }, currentMemberId) => {
  const { status } = connection;
  if (status === 'requested') return 'Request sent';
  if (status === 'approved') return 'Connected';
  if (connection.contact.id === currentMemberId) return 'Your Profile';
  return false;
};

const ConnectionStatus = ({ connectionId }) => {
  const { connection } = useEntity({ id: connectionId, type: 'connections' });
  const { status } = connection;
  const { receive } = useEntityActionCreators();
  const currentMemberId = useCurrentMemberId();
  if (status === 'pending') {
    return (
      <button
        type="button"
        className="connection-avatar__button"
        onClick={() => acceptConnection(connection).then(payload => receive({ payload }))}
      >
        <FontAwesome name="user-plus" />
        Accept
      </button>
    );
  }

  const statusMessage = getMessageForStatus(connection, currentMemberId);
  if (statusMessage) return <div className="connection-avatar__status">{statusMessage}</div>;

  return false;
};

ConnectionStatus.propTypes = {
  connectionId: PropTypes.string.isRequired,
};

export default ConnectionStatus;
