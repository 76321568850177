export const GET_STORY = 'GET_STORY';
export const FILTER_STORY_LIST = 'FILTER_STORY_LIST';
export const ADD_MEDIUM_ACTIVITY = 'ADD_MEDIUM_ACTIVITY';
export const REMOVE_MEDIUM_ACTIVITY = 'REMOVE_MEDIUM_ACTIVITY';
export const UPDATE_MEDIUM_ACTIVITY = 'UPDATE_MEDIUM_ACTIVITY';
export const DESTROY_STORY = 'DESTROY_STORY';
export const GET_PUBLIC_STORY = 'GET_PUBLIC_STORY';
export const SET_SCROLL_POSITION = 'SET_SCROLL_POSITION';
export const GET_MEMBER = 'GET_MEMBER';
export const EXPORT_STORY = 'EXPORT_STORY';
export const SHARE_STORY = 'SHARE_STORY';
export const TOGGLE_NEW_STORY_VISIBLE = 'TOGGLE_NEW_STORY_VISIBLE';
export const TOGGLE_NEW_STORY_REQUEST_VISIBLE = 'TOGGLE_NEW_STORY_REQUEST_VISIBLE';