import React from 'react';
import Media from 'react-media';
import TouchSlider from './touch-slider';
import MouseSlider from './mouse-slider';

export default props => (
  <Media query="pointer: coarse">
    {matches => (matches
      ? <TouchSlider {...props} /> : <MouseSlider {...props} />)
    }
  </Media>
);
