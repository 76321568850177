import { Formik } from 'formik';
import { humanize } from 'inflected';
import { object, string } from 'yup';
import React, { useMemo } from 'react';

import { patchMemoir, postPrivateEventDetails } from '../api';
import { prepareAttrs } from '../../utils/api';
import { useEntity, useEntityActionCreators } from '../../entities/hooks';
import { useHistory, useMatch } from '../../app/hooks';
import FullPageForm from '../../app/full-page-form';
import NewCollectionName from '../../collections/new/name';
import { useLocation } from 'react-router-dom';

const getSubjectRelation = relation => {
  if (relation === 'other') {
    return `Subject`;
  }
  return `Your ${humanize(relation)}`;
};

const MemoirSubjectName = () => {
  const { params } = useMatch();
  const { push } = useHistory();
  const { entity: memoir } = useEntity({ ...params });
  const { receive } = useEntityActionCreators();
  const { pathname } = useLocation();
  const isPublicRoute = pathname.includes('/get-started')
  const label = useMemo(() => `Name of ${getSubjectRelation(memoir['subject-relation'])}`, [
    memoir,
  ]);
  const initialValues = {
    'first-name-noac': (memoir && memoir['first-name']) || '',
  };
  const validationSchema = object().shape({
    'first-name-noac': string().required(),
  });
  const isInitialValid = validationSchema.isValidSync(initialValues);

  return (
    <FullPageForm onBackClick={() => push(`/memoirs/${isPublicRoute ? 'get-started' : 'new'}/${params.id}`)} title="Create a Memoir">
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        isInitialValid={isInitialValid}
        onSubmit={async values => {
          const body = prepareAttrs({ ...values, 'lock-version': memoir['lock-version'] });

          const memoirRes = await patchMemoir({ id: params.id, ...body });
          const [id] = memoirRes.data.result.memoirs;
          receive({ payload: memoirRes });
          push(`/memoirs/${isPublicRoute ? 'get-started' : 'new'}/${id}/memoir-name`);
          if (isPublicRoute) {
            postPrivateEventDetails('memoir_name_of_subject_clicked_next', {
              'subject-type': 'Memoir',
              'subject-id': id,
            });
          }
        }}
        render={props => (
          <NewCollectionName label={label} placeholder="E.g. Jane or John" {...props} />
        )}
      />
    </FullPageForm>
  );
};

export default MemoirSubjectName;
