import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import store from 'store';
import { getAuthToken } from './api';

const AuthRestore = ({ children }) => {
  const match = useRouteMatch('/token/:restoreId/secret/:restoreSecret');
  const [loading, setLoading] = useState(!!match);
  const { restoreId, restoreSecret } = (match && match.params) || {};

  useEffect(() => {
    const fetchToken = async () => {
      store.remove('auth_token');
      const newToken = await getAuthToken({ id: restoreId, secret: restoreSecret });
      store.set('auth_token', newToken);
      setLoading(false);
    };

    if ((restoreId, restoreSecret)) {
      fetchToken();
      const redirectPath = store.get('postLoginRedirect');
      if (!redirectPath) {
        store.set('postLoginRedirect', '/');
      }
    }
  }, [restoreId, restoreSecret]);

  if (loading) return false;

  return children;
};
AuthRestore.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthRestore;
