// import { ActionCableProvider, ActionCable } from 'actioncable-client-react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
// import store from 'store';

import { getMemberCollection } from '../members/actions';
import { getNotifications } from './api';
import { useCurrentMemberId } from '../members/hooks';
import { useEntityActionCreators, useQueryEntities } from '../entities/hooks';
import apiBase from '../utils/api-base';

// const getActionCableUrl = () => {
//   const authToken = (store.get('auth_token') || '').replace('token=', '');
//   const base = apiBase.replace('/api/v2', '');
//   return `${base}/push/${authToken}`;
// };

const NotificationsWrap = React.memo(({ children }) => {
  const currentMemberId = useCurrentMemberId();
  useQueryEntities({ query: () => getNotifications(currentMemberId) });

  const dispatch = useDispatch();
  const { receive } = useEntityActionCreators();

  const receiveNotification = useCallback(
    notification => {
      receive({
        payload: { data: { entities: { activities: { [notification.id]: notification } } } },
      });
      dispatch(getMemberCollection);
    },
    [receive, dispatch]
  );

  // const url = getActionCableUrl();

  return children
  // return (
  //   <ActionCableProvider url={url}>
  //     <ActionCable channel="ActivityChannel" onReceived={receiveNotification}>
  //       {children}
  //     </ActionCable>
  //   </ActionCableProvider>
  // );
});
NotificationsWrap.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NotificationsWrap;
