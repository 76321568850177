import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text } from 'rebass';

const TextList = ({ title, list, keyId }) => (
  <Box pt={2}>
    <Text color="border" fontSize={1} as="span" p={0} pr="2px" fontWeight="bold">
      {title}
    </Text>
    {list.map((el, i, arr) => (
      <Text p={0} fontSize={1} color="lightText" key={`${el}${keyId}`} as="span">
        {el}
        {i !== arr.length - 1 && ','}{' '}
      </Text>
    ))}
  </Box>
);
TextList.propTypes = {
  title: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(PropTypes.string).isRequired,
  keyId: PropTypes.string.isRequired,
};

export default TextList;
