import { REQUEST_ENTITIES } from '../entities/types';
import { formatPageBody } from './helper';
import { receiveEntity } from '../entities/actions';
import { uploadMediumFile } from '../story-media/actions';
import * as api from './api';
import * as types from './types';

export const getStoryPages = storyId => ({
  type: REQUEST_ENTITIES,
  promise: api.getStoryPages(storyId),
});

export const unlinkStoryPages = (storyId, destPageValues, fromPage) => ({
  type: types.UNLINK_STORY_PAGES,
  promise: api.createStoryPage(storyId, formatPageBody(destPageValues)),
  meta: {
    storyId,
    fromPage,
    destPageValues,
  },
});

export const moveStoryPage = (storyId, pageId, position) => ({
  type: types.MOVE_STORY_PAGE,
  promise: api.moveStoryPage(storyId, pageId, position),
  meta: {
    pageId,
    position,
  },
});

export const linkStoryPages = ({ destPage, fromPage }) => ({
  type: types.LINK_STORY_PAGES,
  promise: api.updateStoryPage(
    destPage.id,
    formatPageBody({
      ...destPage,
      media: [...destPage.media, ...fromPage.media],
    })
  ),
  meta: {
    destPage,
    fromPage,
  },
});

export const changePageOrientation = ({ pageId, pageBody, horizontalOrientation }) => ({
  type: types.CHANGE_PAGE_ORIENTATION,
  promise: api.updateStoryPage(
    pageId,
    formatPageBody({ ...pageBody, 'horizontal-orientation': horizontalOrientation })
  ),
});

export const createStoryPageMedium = (pageId, body) => ({
  type: REQUEST_ENTITIES,
  promise: api.createStoryPageMedium(pageId, body),
});

export const createStoryPageAudioCaption = (pageId, file) => async dispatch => {
  const { name: filename } = file;
  const payload = await api.createStoryPageMedium(pageId, {
    data: { attributes: { type: 'Audio', filename } },
  });
  dispatch(
    receiveEntity({
      payload,
    })
  );
  const [medium] = Object.values(payload.data.entities.audios);
  return dispatch(uploadMediumFile('', medium, file));
};
