import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { flexbox, layout, color, border, overflow } from 'styled-system';

const CircleIconOuter = styled.div`
  display: flex;
  ${flexbox};
  ${layout};
  ${color};
  ${border};
  ${overflow};
`;

const CircleIcon = ({ children, ...props }) => (
  <CircleIconOuter
    borderRadius="50%"
    size={36}
    alignItems="center"
    justifyContent="center"
    bg="grey90"
    color="defaultText"
    overflow="hidden"
    {...props}
  >
    {children}
  </CircleIconOuter>
);
CircleIcon.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CircleIcon;
