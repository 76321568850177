import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { fonts } from '../styles/constants';
import { getBackground, getTextColour, getBorderColour } from './helper';

const Button = styled.button`
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  border: 1px solid ${props => getBorderColour(props)};
  background: ${props => getBackground(props)};
  color: ${props => getTextColour(props)};
  font-family: ${fonts.quicksand};
  font-weight: bold;
  text-decoration: none;
  font-size: ${props => props.size};
  line-height: 1;
  padding: 0 0.8em;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: none;
  width: ${props => (props.stretch && '100%') || 'auto'};
  transition: 250ms ease;
`;

const ButtonSubmit = ({ children, ...props }) => (
  <Button {...props} type="submit">
    {children}
  </Button>
);

ButtonSubmit.propTypes = {
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  children: PropTypes.node.isRequired,
  muted: PropTypes.bool,
  disabled: PropTypes.bool,
  stretch: PropTypes.bool,
  size: PropTypes.string,
};

ButtonSubmit.defaultProps = {
  primary: false,
  secondary: false,
  muted: false,
  disabled: false,
  size: '18px',
  stretch: false,
};

export default ButtonSubmit;
