import PropTypes from 'prop-types';
import React from 'react';

import { FaUserPlus } from 'react-icons/fa';
import { Text, Box, Flex, Card, Heading, Link } from 'rebass';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { useMedia } from 'the-platform';

// import { Header, Title, TitleSharesWrap } from './styled';
import CollectionPermission, { useHasCollectionPermission } from 'collections/permissions/index';
import { useCollectionCover, useRouteFromEntityParams } from '../../hooks';
import { useEntity } from '../../../entities/hooks';
import AddStoryButton from './add-story-button';
// import CollectionHeaderAvatars from './avatars';
import Options from './options';
import SvgComment from '../story-requests/svg-comment';
import CollectionTitle from '../../shared/title';
import MemoirTooltip from '../../../memoirs/tooltips';
import DetailHeaderButton from '../../../app/detail/button';
import { sizes } from '../../../app/styles/theme';
import { AvatarImageOrPlaceholder } from './avatar-image-or-placeholder';

const CollectionAvatar = ({ id, type, editLink }) => {
  const coverUrl = useCollectionCover({ id, type });
  const canEditAvatar = useHasCollectionPermission({ id, type, feature: 'EDIT_AVATAR' });

  if (canEditAvatar) {
    return (
      <RouterLink to={editLink}>
        <AvatarImageOrPlaceholder coverUrl={coverUrl} showEdit={!coverUrl} />
      </RouterLink>
    );
  }

  return <AvatarImageOrPlaceholder coverUrl={coverUrl} />;
};
CollectionAvatar.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  editLink: PropTypes.string.isRequired,
};

const CollectionOptions = ({ id, type }) => {
  const { isOwner, entity: collection } = useEntity({ id, type });
  const route = useRouteFromEntityParams({ id, type });
  const { replace } = useHistory();

  return (
    <Flex alignSelf="flex-start" justifyContent="flex-end">
      {type === 'memoirs' && (
        <MemoirTooltip
          isEnabled={collection['memoir-shares'] && collection['memoir-shares'].length > 1}
          type="REQUEST_STORY"
          text="You can request a story here."
          complete
        >
          <DetailHeaderButton
            onClick={() => {
              replace(`/${type}/${id}/memoir-request-story`);
            }}
            icon={SvgComment}
            mr={2}
            label="Story Request"
            borderColor="cyan-200"
            bg="cyan-800"
          />
        </MemoirTooltip>
      )}
      <CollectionPermission feature="ADD_STORY" id={id} type={type}>
        <AddStoryButton enabled={!collection['is-a-sample']} />
      </CollectionPermission>
      <CollectionPermission feature="SHARE_COLLECTION" id={id} type={type}>
        <DetailHeaderButton
          onClick={() => {
            replace(`${route}/participants`);
          }}
          icon={FaUserPlus}
          mr={2}
          label="Invite"
          borderColor="cyan-200"
          bg="cyan-800"
        />
      </CollectionPermission>

      <CollectionPermission feature="SHOW_OPTIONS" id={id} type={type}>
        <Options type={type} id={id} isOwner={isOwner} />
      </CollectionPermission>
    </Flex>
  );
};
CollectionOptions.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

const CollectionDetailHeader = ({ id, type }) => {
  const { entity: collection } = useEntity({ id, type });
  const route = useRouteFromEntityParams({ id, type });
  const publicCollectionURL = collection.slug
    ? `${collection['public-url-root']}${collection.slug}`
    : '';
  const isDesktop = useMedia({ minWidth: `${sizes.desktop}px` });

  return (
    <Card bg="cyan-500" px={4} py={3}>
      {!isDesktop && <CollectionOptions id={id} type={type} />}
      <Flex alignItems="center">
        <Box mt={2}>
          <CollectionAvatar id={id} type={type} editLink={`${route}/cover`} />
        </Box>
        <Box mr="auto" mt={2}>
          <Heading
            color="white"
            fontSize={[4, 5]}
            fontWeight="normal"
            mr={3}
            my={1}
            css="word-break: break-word;"
          >
            <CollectionTitle collection={collection} type={type} />
          </Heading>
          {collection.description && <Text color="cyan-050">{collection.description}</Text>}
          {publicCollectionURL && (
            <Link fontSize={0} color="cyan-050" href={publicCollectionURL}>
              {publicCollectionURL}
            </Link>
          )}
          {/* <CollectionPermission id={id} type={type} feature="CAN_VIEW_AVATARS">
            <CollectionHeaderAvatars
              id={id}
              type={type}
              avatarProps={{ borderColor: 'cyan-050' }}
            />
          </CollectionPermission> */}
        </Box>
        {isDesktop && <CollectionOptions id={id} type={type} />}
      </Flex>
    </Card>
  );
};
CollectionDetailHeader.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default CollectionDetailHeader;
