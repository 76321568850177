import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import { State } from 'react-powerplug';
import { color, space, height as sysHeight } from 'styled-system';
import PropTypes from 'prop-types';
import React from 'react';
import Rect from '@reach/rect';
import styled from '@emotion/styled';

import Arrow from './slider-arrow';

const OuterWrap = styled.div`
  background: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07);
  width: 100%;
  display: flex;
  justify-content: center;
  ${color};
  ${space};
`;

const NavWrap = styled.div`
  flex: 1;
  margin: 0 auto;
  max-width: 1200px;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  ${sysHeight};
`;

const Nav = styled.nav`
  display: flex;
  position: absolute;
  transition: all 0.375s ease-in-out;
  min-width: 100%;
`;

const MouseSlider = ({ children, height, width, ...props }) => (
  <OuterWrap {...props}>
    <State
      initial={{
        outerRect: {},
        innerRect: {},
        currentLeftPos: 0,
      }}
    >
      {({ state: { outerRect, innerRect, currentLeftPos }, setState }) => {
        const hasLeftButton = innerRect.left !== outerRect.left;

        const remainderRight = innerRect.right - outerRect.right;
        const hasRightButton = !!Math.max(0, Number(remainderRight));
        // outerRect.width is pane width
        const backOnePane = () =>
          setState({
            currentLeftPos: Math.min(innerRect.left + outerRect.width, 0),
          });
        const forwardOnePane = () =>
          setState({
            currentLeftPos: currentLeftPos - Math.min(remainderRight, outerRect.width),
          });

        return (
          <Rect onChange={rect => rect && setState({ outerRect: rect })}>
            {({ ref }) => (
              <NavWrap height={height} ref={ref}>
                {hasLeftButton && (
                  <Arrow left width={width || height} onClick={backOnePane} bg={props.bg}>
                    <FaChevronLeft style={{ position: 'absolute ' }} />
                  </Arrow>
                )}
                <Rect onChange={inner => inner && setState({ innerRect: inner })}>
                  {({ ref: refOfInner }) => (
                    <Nav ref={refOfInner} style={{ transform: `translateX(${currentLeftPos}px)` }}>
                      {children}
                    </Nav>
                  )}
                </Rect>
                {hasRightButton && (
                  <Arrow right width={width || height} onClick={forwardOnePane} bg={props.bg}>
                    <FaChevronRight style={{ position: 'absolute ' }} />
                  </Arrow>
                )}
              </NavWrap>
            )}
          </Rect>
        );
      }}
    </State>
  </OuterWrap>
);

MouseSlider.propTypes = {
  children: PropTypes.node.isRequired,
  height: PropTypes.arrayOf(PropTypes.number).isRequired,
  width: PropTypes.string,
};

MouseSlider.defaultProps = {
  width: null,
};

export default MouseSlider;
