import {
  get, post, patch, destroy,
} from '../utils/api';

export const getChildJournal = id => get(`/v3/child_journals/${id}`);
export const postChildJournal = body => post('/v3/child_journals', body);
export const patchChildJournal = body => patch(`/v3/child_journals/${body.id}`, { data: { attributes: body } });
export const destroyChildJournal = id => destroy(`/v3/child_journals/${id}`);
export const postChildJournalShare = (id, body) => post(`/v3/child_journals/${id}/child_journal_shares`, { data: { attributes: body } });
export const destroyChildJournalShare = (id, shareId) => destroy(`/v3/child_journals/${id}/child_journal_shares/${shareId}`);
export const postPublicChildJournalShares = ({ childJournalShareId }) => post('/v3/public/child_journal_shares', { data: { 'share-id': childJournalShareId } });
export const getPublicChildJournal = shareId => get(`/v3/public/child_journals/${shareId}`);
export const exportChildJournal = id => patch(`/v3/child_journals/${id}/export`, {});
export const copyStoryToChildJournal = (journalId, storyId) => post(`/v3/child_journals/${journalId}/copy_story/${storyId}`, {});
