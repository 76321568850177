import { Flex } from 'rebass';
import { space, width, fontSize, color } from 'styled-system';
import styled from '@emotion/styled';
import { withProps } from 'recompose';

export const TextFieldWrap = withProps({
  flexDirection: 'column',
  alignItems: 'stretch',
})(styled(Flex)`
  ${space};
  ${width};

  input {
    border: 1px solid
      ${({
        error,
        theme: {
          colours: { border, red },
        },
      }) => (error ? red : border)};
    border-radius: ${props => props.borderRadius}px;
    background: ${props => props.theme.colours.white};
    overflow: hidden;
    outline: none;
    text-align: ${props => (props.center ? 'center' : 'left')};
    width: ${props => (props.stretchWidth ? '100%' : `${props.width}px`)};
    font-size: ${props => props.fontSize}px;
    padding: calc(${props => props.fontSize}px * 0.75) ${props => props.fontSize}px;
    max-width: 100%;
    color: ${props => props.theme.colours.mutedText};
    transition: border 0.3s ease;

    &:focus {
      border-color: ${props => props.theme.colours.brand};
    }

    &::placeholder {
      color: #ccc;
    }
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    display: block;
  }
`);

export const Label = styled.label`
  line-height: 1;
  padding-bottom: 0.5em;
  display: block;
  ${fontSize};
  color: ${props => props.theme.colours.mutedText};
`;

export const ErrorMessageWrap = withProps({
  justifyContent: 'left',
  p: 1,
  pb: 0,
  color: 'red',
})(styled(Flex)`
  ${fontSize};
  ${color};
`);
