import PropTypes from 'prop-types';

import { useQueryEntities } from './hooks';

const QueryEntities = ({
  query, queries, children, getProps,
}) => {
  const state = useQueryEntities({ query, queries, reEntity: getProps.hasEntity });

  if (typeof children === 'function') return children(state);

  return children;
};

QueryEntities.propTypes = {
  query: PropTypes.func,
  queries: PropTypes.arrayOf(PropTypes.func),
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
  ]).isRequired,
  getProps: PropTypes.shape({
  }),
};

QueryEntities.defaultProps = {
  query: null,
  queries: [],
  getProps: {},
};

export default QueryEntities;
