
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import NukaCarousel from 'nuka-carousel';
import { Box } from 'rebass';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import { useMedia } from 'the-platform';

import { Button } from '../button-bar/styled';
import { sizes } from '../../app/styles/theme';
import { StoryPageContext } from './context';

const Carousel = ({ children }) => {
  const { currentMedia, setCurrentMedia } = useContext(StoryPageContext);
  const isTablet = useMedia({ minWidth: `${sizes.phone}px` });

  return (
    <NukaCarousel
      // heightMode="max"
      initialSlideHeight={400}
      renderTopRightControls={({ currentSlide, slideCount }) => (
        <Box css="position: absolute; right: 8px; top: 8px; z-index: 1; color: white; background-color: rgba(0,0,0,0.5); padding: 4px 8px; border-radius: 25px;">
          {currentSlide + 1}/{slideCount}
        </Box>
      )}
      renderCenterLeftControls={({ previousSlide, currentSlide }) =>
        isTablet ? (
          <Button onClick={previousSlide} disabled={currentSlide === 0} css="margin-left: -22px;">
            <FaChevronLeft />
          </Button>
        ) : (
          false
        )
      }
      renderCenterRightControls={({ nextSlide, currentSlide, slideCount }) =>
        isTablet ? (
          <Button
            onClick={nextSlide}
            disabled={currentSlide + 1 === slideCount}
            css="margin-right: -22px;"
          >
            <FaChevronRight />
          </Button>
        ) : (
          false
        )
      }
      beforeSlide={() => (currentMedia ? setCurrentMedia(null) : null)}
    >
      {children}
    </NukaCarousel>
  );
};

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Carousel;
