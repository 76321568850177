import { Route } from 'react-router-dom';
import React from 'react';

import { ProfileWrap } from './styled';
import { useLocation } from '../../app/hooks';
import AboutLifeTales from './about-lifetales';
import AnimatedNavWrap from '../../app/navigation/animated-nav-wrap';
import ContactMethods from './contact-methods';
import NotificationSettings from './notification-settings';
import ProfileEdit from './profile-edit';
import ProfileHeader from './profile-header';

const ProfileDetail = () => {
  const location = useLocation();

  return (
    <ProfileWrap>
      <ProfileHeader />
      <ContactMethods />
      <NotificationSettings />
      <AboutLifeTales />
      <AnimatedNavWrap location={location}>
        <Route path="/profile/edit" component={ProfileEdit} key="profile-edit" />
      </AnimatedNavWrap>
    </ProfileWrap>
  );
};

export default ProfileDetail;
