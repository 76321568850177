import { handle } from 'redux-pack';

import { CREATE_TEXT_MEDIUM, UPLOAD_MEDIUM_SUCCESS } from '../story-media/types';
import * as types from './types';

const initialState = {
  loading: false,
};

export default (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case types.CREATE_STORY_PAGE:
    case types.UPDATE_STORY_PAGE:
    case types.LINK_STORY_PAGES:
    case types.UNLINK_STORY_PAGES:
    case types.CHANGE_PAGE_ORIENTATION:
      return handle(state, action, {
        start: prevState => ({ ...prevState, loading: true }),
        finish: prevState => ({ ...prevState, loading: false }),
        success: prevState => ({
          ...prevState,
        }),
      });
    case CREATE_TEXT_MEDIUM:
    case types.MOVE_STORY_PAGE:
      return handle(state, action, {
        success: prevState => ({
          ...prevState,
          staleStoryId: action.meta.storyId,
        }),
      });
    case UPLOAD_MEDIUM_SUCCESS:
      return {
        ...state,
        staleStoryId: action.meta.storyId,
      };
    default:
      return state;
  }
};
