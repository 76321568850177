import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ fill }) => (
  <svg width="48px" height="35px" viewBox="0 0 48 35" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Friend-List_v2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Add-Friend_Modal2" transform="translate(-149.000000, -31.000000)" fill={fill}>
        <g id="Share-Modal">
          <g id="icons8-checkmark" transform="translate(149.000000, 31.000000)">
            <polygon id="Shape" points="44.9307528 0.198863636 16.0333807 29.4069602 2.79651989 16.2322443 0 19.0909091 16.0333807 35 47.7272727 3.05752841" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

Icon.defaultProps = {
  fill: '#fff',
};

Icon.propTypes = {
  fill: PropTypes.string,
};

export default Icon;
