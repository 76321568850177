import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { singularize, humanize } from 'inflected';
import { FaTrash as DeleteIcon, FaExclamationCircle as ExclamationIcon } from 'react-icons/fa';
import Button from '../../app/buttons';
import { cancelDestroyMediumPrompt, destroyMedium } from '../actions';
import { getStoryPages } from '../../story-pages/actions';
import { Message, ButtonWrap, Title } from './styled';

const Destroy = ({ id, pageId, mediumType, storyId, ...props }) => {
  const mediaName = humanize(singularize(mediumType));
  return (
    <Message>
      <Title>
        <ExclamationIcon size="24" />
        <span>Delete {mediaName}</span>
      </Title>
      <p>Are you sure you want to delete this {mediaName}</p>
      <ButtonWrap>
        <Button
          muted
          onClick={() => {
            props.cancelDestroyMediumPrompt(id);
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            props
              .destroyMedium(id, pageId, mediumType, storyId)
              .then(() => props.getStoryPages(storyId));
          }}
          destructive
        >
          <DeleteIcon color="white" size="24" />
          Delete
        </Button>
      </ButtonWrap>
    </Message>
  );
};

Destroy.propTypes = {
  id: PropTypes.string.isRequired,
  mediumType: PropTypes.string.isRequired,
  pageId: PropTypes.string.isRequired,
  storyId: PropTypes.string.isRequired,
  cancelDestroyMediumPrompt: PropTypes.func.isRequired,
  destroyMedium: PropTypes.func.isRequired,
  getStoryPages: PropTypes.func.isRequired,
};

export default connect(null, { cancelDestroyMediumPrompt, destroyMedium, getStoryPages })(Destroy);
