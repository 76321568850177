import React, { useState } from 'react';
import Modal from '../../../app/modal/v2';
import CollectionOnboardCarousel from '../carousel';
import { SlideOne, SlideTwo, SlideThree, SlideFour } from './slides';
import { useHistory } from 'react-router-dom';
import MemoirsVideoModal from './memoirs-video-modal';

const MemoirsOnboard = () => {
  const { push } = useHistory();
  const [showVideo, setShowVideo] = useState(false);

  const haveCredits = true
  return (
    <>
      {showVideo ? (
        <MemoirsVideoModal onClose={() => setShowVideo(false)} />
      ) : (
        <Modal onClose={() => push('/collections/new')} fullscreenMobile closeButton>
          <CollectionOnboardCarousel
            ctaText={haveCredits ? "Start Your Memoir" : 'Purchase Your Memoir'}
            onClose={() => push('/memoirs/new')}
          >
            <SlideOne setShowVideo={setShowVideo} />
            <SlideTwo />
            <SlideThree />
            <SlideFour />
          </CollectionOnboardCarousel>
        </Modal>
      )}
    </>
  );
};

export default MemoirsOnboard;
