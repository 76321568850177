import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { Flex, Button, Text, Heading } from 'rebass';
import { useDispatch } from 'react-redux';
import { addMemberConnection, acceptConnection } from 'members/connections/api';
import { setCheckmark } from 'ui/actions';
import Avatar from 'members/avatar';
import { useEntity, useEntityActionCreators } from 'entities/hooks/index';

const ConnectionStatus = ({ connection }) => {
  const { id } = useParams();
  const { entity: member } = useEntity({ type: 'members', id });
  const dispatch = useDispatch();
  const { receive } = useEntityActionCreators();

  const addMember = () =>
    addMemberConnection(id)
      .then(payload => receive({ payload }))
      .then(dispatch(setCheckmark({ message: 'Request Sent!' })));

  if (isEmpty(connection))
    return (
      <Flex flexDirection="column" alignItems="center">
        <Avatar id={id} size={80} />
        <Heading>{member['full-name']}</Heading>
        <Button onClick={addMember} variant="outline" my={2}>
          Add as Friend
        </Button>
      </Flex>
    );

  const { status } = connection;
  const showMessage = () => {
    if (status === 'approved') return 'You are connected';
    if (status === 'requested') return 'Request sent';
    if (status === 'pending') return `${member['first-name']} has send you a friend request`;
    if (status === 'ignored') return member['full-name'];
    return false;
  };
  const onClick = () => {
    if (status === 'pending')
      return acceptConnection(connection).then(payload => receive({ payload }));
    if (status === 'ignored') return addMember();
    return false;
  };
  return (
    <Flex alignItems="center" pb={2} flexDirection="column">
      <Avatar id={id} size={80} />
      <Text my={2}>{showMessage()}</Text>
      {(status === 'ignored' || status === 'pending') && (
        <Button onClick={onClick} variant="outline" my={2}>
          {status === 'ignored' && 'Add as Friend'}
          {status === 'pending' && 'Accept'}
        </Button>
      )}
    </Flex>
  );
};

ConnectionStatus.propTypes = {
  connection: PropTypes.shape({
    status: PropTypes.string,
  }),
};

ConnectionStatus.defaultProps = {
  connection: {},
};

export default ConnectionStatus;
