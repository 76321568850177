import PropTypes from 'prop-types';
import React, { useCallback, createContext, useContext } from 'react';
import { useDispatch } from 'react-redux';
import store from 'store';
import { useHistory } from 'react-router-dom';
import { trigger } from 'swr';
import { logout } from './actions';

const LogoutContext = createContext(null);

const Logout = ({ children }) => {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const value = useCallback(() => {
    store.remove('auth_token');
    dispatch(logout());
    push('/');
    trigger(`/v2/members/me`);
  }, [dispatch, push]);

  return <LogoutContext.Provider value={value}>{children}</LogoutContext.Provider>;
};
Logout.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useLogout = () => {
  return useContext(LogoutContext);
};

export default Logout;
