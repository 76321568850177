import styled from '@emotion/styled';
import React from 'react';
import { Button, Box, Text } from 'rebass';
import { layout } from 'styled-system';
import PropTypes from 'prop-types';

const DisplayBox = styled.div`
  ${layout};
`;

const DetailHeaderButton = React.forwardRef(
  ({ icon, iconSize, iconColour, label, size, labelProps, ...props }, ref) => {
    return (
      <Button
        variant="transparent"
        type="button"
        color="white"
        style={{ minHeight: size, minWidth: size }}
        css="display: flex; justify-content: center; align-items: center;"
        sx={{
          borderRadius: 6,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          border: '1px solid',
          borderColor: 'white',
          fontWeight: 'normal',
        }}
        p={2}
        px={2}
        ref={ref}
        {...props}
      >
        <Box as={icon} color={iconColour} size={iconSize} />
        {label && (
          <DisplayBox display={['none', 'none', 'inline-block']}>
            <Text color="white" px={1} fontSize={1} {...labelProps}>
              {label}
            </Text>
          </DisplayBox>
        )}
      </Button>
    );
  }
);

DetailHeaderButton.propTypes = {
  icon: PropTypes.func.isRequired,
  iconSize: PropTypes.number,
  iconColour: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.number,
};
DetailHeaderButton.defaultProps = {
  label: null,
  size: 40,
  iconSize: 20,
  iconColour: 'white',
};

export default DetailHeaderButton;
