import { formatShareBody } from './helper';
import { get, post, patch, destroy } from '../utils/api';

export const getStoryShares = storyId => get(`/v3/stories/${storyId}/story_shares`);
export const addStoryShare = (storyId, shareAttrs) =>
  post(`/v2/stories/${storyId}/story_shares`, formatShareBody(shareAttrs));
export const patchStoryShare = (storyShareId, role, attributes = {}) => {
  const nextAttributes = attributes;
  if (role) {
    nextAttributes['contact-role'] = role;
  }
  return patch(`/v2/story_shares/${storyShareId}`, {
    data: {
      attributes: nextAttributes,
    },
  });
};
export const removeStoryShare = shareId => destroy(`/v2/story_shares/${shareId}`);
export const postPublicStoryShare = shareId =>
  post('/v2/public/story_shares', { data: { 'share-id': shareId } });
export const archiveStory = id => patch(`/v3/story_shares/${id}/archive`);
export const unarchiveStory = id => patch(`/v3/story_shares/${id}/unarchive`);
export const muteStoryShare = id => patch(`/v3/story_shares/${id}/mute`);
export const unmuteStoryShare = id => patch(`/v3/story_shares/${id}/unmute`);
