
import PropTypes from 'prop-types';
import React, { useMemo, useRef } from 'react';
import { useRect } from '@reach/rect';
import ReactPlayer from 'react-player';
import { useEntity } from 'entities/hooks';
import AspectRatio from 'js-aspect-ratio';
import { Box } from 'rebass';

const GalleryExternal = ({ id }) => {
  const { entity } = useEntity({ id, type: 'external-media' });
  const ref = useRef();
  const rect = useRect(ref);

  const aspectRatio = new AspectRatio(16, 9);

  const [aspectWidth, aspectHeight] = useMemo(() => {
    if (!rect) return [0, 0];
    const { width, height } = rect;
    if (!width && !height) return [0, 0];
    if (width > height) return [width, aspectRatio.getHeight(width)];
    if (width <= height) return [aspectRatio.getWidth(height), height];
    return [0, 0];
  }, [aspectRatio, rect]);

  return (
    <Box ref={ref} flex="1">
      <ReactPlayer
        url={entity['external-url']}
        width={aspectWidth}
        height={aspectHeight}
        controls
      />
    </Box>
  );
};
GalleryExternal.propTypes = {
  id: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};

export default GalleryExternal;
