import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { fonts } from '../styles/constants';
import { getBackground, getTextColour, getBorderColour } from './helper';

const StyledLink = styled.span`
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.05);
  border-radius: ${props => (props.pill ? '30px' : '6px')};
  border: ${props => props.borderWidth} solid ${props => getBorderColour(props)};
  background: ${props => getBackground(props)};
  color: ${props => getTextColour(props)};
  font-family: ${fonts.quicksand};
  font-weight: bold;
  text-decoration: none;
  font-size: ${props => props.size};
  line-height: 1;
  padding: 0 ${props => (props.pill ? '1.2em' : '0.8em')};
  height: ${props => props.height};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonLink = ({ href, to, replace, children, linkStyle, ...props }) => {
  const style = { textDecoration: 'none', color: 'inherit', ...linkStyle };
  if (to) {
    return (
      <Link {...{ to, replace, style }}>
        <StyledLink {...props}>{children}</StyledLink>
      </Link>
    );
  }
  return (
    <a {...{ href, style }}>
      <StyledLink {...props}>{children}</StyledLink>
    </a>
  );
};

ButtonLink.propTypes = {
  to: PropTypes.string,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  children: PropTypes.node.isRequired,
  muted: PropTypes.bool,
  disabled: PropTypes.bool,
  replace: PropTypes.bool,
  subtle: PropTypes.bool,
  href: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  borderWidth: PropTypes.string,
  pill: PropTypes.bool,
  linkStyle: PropTypes.shape({}),
};

ButtonLink.defaultProps = {
  replace: false,
  primary: false,
  secondary: false,
  muted: false,
  disabled: false,
  size: '18px',
  height: '44px',
  to: '',
  href: '',
  subtle: false,
  borderWidth: '1px',
  pill: false,
  linkStyle: {},
};

export default ButtonLink;
