import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

import { getStoryPages } from '../story-pages/actions';
import { getStoryShares } from './actions';
import { patchStoryShare, addStoryShare, removeStoryShare } from './api';
import { receiveEntityDeletion } from '../entities/actions';
import { shareStory } from '../stories/actions';
import { useCurrentMemberId } from '../members/hooks';
import { useEntity, useEntityActionCreators } from '../entities/hooks';
import StorySharesForm from './form';

const OwnerShares = ({ storyId, onBack, ...props }) => {
  const { update } = useEntityActionCreators();
  const { story } = useEntity({ id: storyId, type: 'stories' });
  const currentMemberId = useCurrentMemberId();

  return (
    <StorySharesForm
      storyId={storyId}
      updateStoryShares={async changes => {
        const responses = {};

        responses.additions = await Promise.all(
          Object.keys(changes.additions).map(memberId =>
            addStoryShare(storyId, {
              withContactId: memberId,
              role: changes.additions[memberId],
              currentMemberId,
            })
          )
        );

        responses.deletions = await Promise.all(
          changes.deletions.map(shareId => removeStoryShare(shareId))
        );

        responses.updates = await Promise.all(
          Object.keys(changes.updates).map(shareId =>
            patchStoryShare(shareId, changes.updates[shareId])
          )
        );

        await props.getStoryShares(storyId);

        update({
          entityType: 'stories',
          id: storyId,
          attrs: {
            'story-shares': story['story-shares'].filter(
              share => !changes.deletions.includes(share.id)
            ),
          },
        });

        onBack();
      }}
    />
  );
};

OwnerShares.propTypes = {
  shareStory: PropTypes.func.isRequired,
  getStoryShares: PropTypes.func.isRequired,
  receiveEntityDeletion: PropTypes.func.isRequired,
  getStoryPages: PropTypes.func.isRequired,
  storyId: PropTypes.string.isRequired,
  onBack: PropTypes.func.isRequired,
};

export default withRouter(
  connect(
    null,
    { shareStory, getStoryShares, getStoryPages, receiveEntityDeletion }
  )(OwnerShares)
);
