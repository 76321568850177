import { Flex, Image, Text, Button } from 'rebass';
import PropTypes from 'prop-types';
import React from 'react';
import pluralize from 'pluralize';

import { Wrap } from './styled';
import checkmark from '../cover/thumbnails/assets/circle-checkmark.svg';
import { useCollectionCover } from '../../../collections/hooks';
import { AvatarImageOrPlaceholder } from '../../../collections/detail/header/avatar-image-or-placeholder';
import { useCollectionTitle } from '../../../collections/shared/title';

// import tagSvg from './assets/tag.svg';

const SelectCollectionItem = ({
  selected,
  setCurrentMemberList,
  collection,
  setSelected,
  disabled,
  // tagClicked,
}) => {
  const { id, type } = collection;
  const coverUrl = useCollectionCover({ id, type, size: 4 });
  const title = useCollectionTitle({ id, type });

  return (
    <Wrap selected={selected} disabled={disabled}>
      <AvatarImageOrPlaceholder size={40} coverUrl={coverUrl} borderColor="white" />
      <Flex flexDirection="column">
        <Text pb={1}>{title}</Text>
        <Text fontSize={1} color="mutedText">
          <Button type="button" variant="unset" onClick={setCurrentMemberList}>
            {pluralize('members', collection['member-count'], true)}
          </Button>
        </Text>
      </Flex>
      <div className="buttons-wrap">
        <button
          onClick={() => {
            setSelected({ ...collection, type });
          }}
          className={selected ? '' : 'unselected'}
          type="button"
          disabled={disabled}
        >
          {selected && <Image src={checkmark} alt="checkmark" height="40px" width="40px" />}
        </button>
      </div>
    </Wrap>
  );
};

SelectCollectionItem.propTypes = {
  setSelected: PropTypes.func.isRequired,
  setCurrentMemberList: PropTypes.func.isRequired,
  collection: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    'member-count': PropTypes.number.isRequired,
  }).isRequired,
  selected: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

SelectCollectionItem.defaultProps = {
  disabled: false,
};

export default SelectCollectionItem;
