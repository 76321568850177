import { Route, Switch } from 'react-router-dom';
import React from 'react';

import ChildJournalDate from './date';
import ChildJournalGender from './gender';
import ChildJournalName from './name';

const NewChildJournal = () => (
  <Switch>
    <Route path="/:type/new/:id/date-of-birth" component={ChildJournalDate} />
    <Route path="/:type/new/:id/gender" component={ChildJournalGender} />
    <Route path="/:type/new/:id?" component={ChildJournalName} />
  </Switch>
);

export default NewChildJournal;
