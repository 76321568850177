import styled from '@emotion/styled';
import { IoIosClose } from 'react-icons/io';
import { Link as RouterLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Flex, Box, Button, Text } from 'rebass';
import { height, top, left, right, bottom } from 'styled-system';

import React from 'react';

const shadeVariants = {
  exit: { backgroundColor: 'rgba(0, 0, 0, 0)', transition: { duration: 0.7 } },
  enter: { backgroundColor: 'rgba(0, 0 ,0, 0.7)', transition: { duration: 0.7 } },
};
const modalVariants = {
  exit: { y: 36, opacity: 0, transition: { duration: 0.4 } },
  enter: { y: 0, opacity: 1, transition: { duration: 0.4 } },
};

export const GalleryOuter = props => (
  <Flex
    alignItems="stretch"
    flexDirection="column"
    as={motion.div}
    variants={shadeVariants}
    initial="exit"
    animate="enter"
    exit="exit"
    width="100vw"
    css="height: 100vh; position: fixed; top: 0; z-index: 10000;"
    {...props}
  />
);

const GridBox = styled(Box)`
  display: grid;
  grid-template-rows: 1fr max-content;
  ${height};
`;
export const GalleryInner = React.forwardRef((props, ref) => (
  <GridBox
    ref={ref}
    as={motion.div}
    variants={modalVariants}
    m={[0, 4]}
    height={['100vh', 'calc(100vh - 64px)']}
    bg="black"
    css="z-index: 10001; border-radius: 3px;box-shadow: 0 3px 15px rgba(0,0,0,.8);overflow:hidden;"
    {...props}
  />
));

export const GalleryCloseButton = props => (
  <GalleryButton
    icon={IoIosClose}
    as={RouterLink}
    replace
    type="button"
    top={32}
    right={32}
    {...props}
  />
);

const SystemGalleryButton = styled(Button)`
  z-index: 10003;
  position: absolute;
  border: none;
  ${top};
  ${left};
  ${right};
  ${bottom};
`;
export const GalleryButton = ({ icon, iconSize, ...props }) => (
  <SystemGalleryButton
    type="button"
    bg="rgba(0,0,0,.25)"
    fontSize={iconSize}
    borderRadius={0}
    p={2}
    {...props}
  >
    <Text as={icon} color="border" />
  </SystemGalleryButton>
);
