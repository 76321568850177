
import { MdEdit as PencilIcon } from 'react-icons/md';
import { Text, Button } from 'rebass';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { HeaderWrap, TitleWrap } from './styled';
import { getCoverUrl } from '../../helper';
import { selectStory, selectStoryCover } from '../../selectors';
import { useStoryDate } from '../../hooks';
import EditButtons from './edit';

const EditHeader = ({ story, cover }) => {
  const { push } = useHistory();
  const storyDate = useStoryDate(story);

  return (
    <>
      <EditButtons storyId={story.id} />
      <HeaderWrap imageUrl={getCoverUrl(story, cover)}>
        <TitleWrap>
          <h1>{story.title}</h1>
          <Text textAlign="center" fontSize={[0, 2]}>
            {storyDate}
          </Text>
          <Button
            onClick={() => push(`/story/${story.id}/edit/settings`)}
            variant="muted"
            mt={2}
            css="height: 60px; width: 60px; display: inline-flex; border-radius: 50%;"
          >
            <PencilIcon size="2em" />
          </Button>
        </TitleWrap>
      </HeaderWrap>
    </>
  );
};

EditHeader.propTypes = {
  story: PropTypes.shape({
    title: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  cover: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  story: selectStory(state, ownProps.storyId),
  cover: selectStoryCover(state, ownProps.storyId),
});

export default connect(mapStateToProps)(EditHeader);
