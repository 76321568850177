import PropTypes from 'prop-types';
import React, { useState, useMemo } from 'react';

import { Button, Box, Text } from 'rebass';
import Modal from 'app/modal/v3';
import ModalBottom from 'app/modal/v3/bottom';
import QuestionTooltip from 'app/modal/v3/question-tooltip';
import ConnectionRole from 'story-shares/form/connection-role';
import { useRoleFormReducer } from 'story-shares/form/roleFormReducer';
import ShareRole from 'story-shares/form/share-role';
import { useCurrentMemberId } from 'members/hooks';
import { useConnections } from '../../../members/connections/entities';

const useInitialConnections = initialShares => {
  const connections = useConnections();
  const currentMemberId = useCurrentMemberId();

  return useMemo(() => {
    return connections
      .filter(
        connection =>
          !initialShares.map(share => share['with-contact'].id).includes(connection.contact.id)
      )
      .filter(connection => connection.contact.id !== currentMemberId);
  }, [connections, currentMemberId, initialShares]);
};

const ParticipantsForm = ({ updateCollectionShares, initialShares, onBack }) => {
  const connections = useInitialConnections(initialShares);
  const [loading, setLoading] = useState(false);

  const [changes, dispatch] = useRoleFormReducer();

  const onSubmit = () => {
    setLoading(true);
    updateCollectionShares(changes);
  };

  return (
    <Modal
      title="Memoir Participants"
      back={{ onBack }}
      footerOffset={55}
      header={{
        right: (
          <QuestionTooltip>
            <Text color="black">
              You have the option of making a friend a <b>Co-Owner</b> of the Memoir.{' '}
              <b>Co-Owners</b> have all the same privileges as an <b>Owner</b> except for deleting
              the Memoir.
            </Text>
          </QuestionTooltip>
        ),
      }}
    >
      <Box bg="white">
        {initialShares.map(share => (
          <ShareRole
            key={`${share.id}-${share.type}-role`}
            id={share.id}
            type={share.type}
            onClick={({ role }) => {
              if (!role) {
                return dispatch({ type: 'ADD_DELETION', id: share.id });
              }
              return dispatch({ type: 'ADD_UPDATE', id: share.id, role });
            }}
          />
        ))}
        {connections.map(connection => (
          <ConnectionRole
            key={`${connection.id}-role`}
            id={connection.id}
            onClick={next => {
              return dispatch({ type: 'ADD_ADDITION', id: next.id, role: next.role });
            }}
          />
        ))}
      </Box>
      <ModalBottom>
        <Button variant="primary" disabled={loading} onClick={onSubmit} flex="1">
          Confirm
        </Button>
      </ModalBottom>
    </Modal>
  );
};

ParticipantsForm.propTypes = {
  onBack: PropTypes.func.isRequired,
  initialShares: PropTypes.arrayOf(
    PropTypes.shape({
      memberId: PropTypes.string,
      id: PropTypes.string,
    })
  ),
  updateCollectionShares: PropTypes.func.isRequired,
};

ParticipantsForm.defaultProps = {
  initialShares: [],
};

export default ParticipantsForm;
