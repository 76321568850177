import React, { useEffect, useState } from 'react';
import { Flex, Button, Text, Box } from 'rebass';
import { IoIosArrowBack } from 'react-icons/io';
import Progress from './Progress';
// import { useFormikContext } from 'formik';
import { FaArrowRight } from 'react-icons/fa';

function Footer({
    onNextPress,
    onBack,
    type = '',
    nextButtonText,
    disabled = false,
    sx = {},
    middleButton = undefined,
    onSkipPress,
    onBackDisabled = false,
    percentComplete,
    borderRadius,
    maxWidth = 500,
    noOfTransitions
}) {
    const [animationStyle, setAnimationStyle] = useState({
        transform: 'translate(0%, 0%)',
        transition: 'opacity 1s ease',
        opacity: 0,
    });

    const animateElement = () => {
        setAnimationStyle((prevStyle) => ({
            ...prevStyle,
            opacity: 0,
        }));

        setTimeout(() => {
            setAnimationStyle((prevStyle) => ({
                ...prevStyle,
                opacity: 1,
            }));
        }, 1000);
    };

    useEffect(() => {
        animateElement()
    }, [noOfTransitions])

    return (<Box
        maxWidth={maxWidth}
        sx={{
            position: ['fixed', middleButton ? 'fixed' : 'relative'],
            bottom: 0,
            left: maxWidth === '100vw' && 0,
            pb: 2,
            width: '100%',
            background: ['white', middleButton ? 'white' : "unset"],
            zIndex: 3,
            m: 'auto',
            mt: 5,
            ...animationStyle
        }}
    >
        <Progress percentComplete={percentComplete} />
        <Flex
            justifyContent="space-around"
            alignItems="flex-end"
            sx={{

                pt: [2, 2],
                ...sx,
            }}
        >
            {onBack && (
                <Flex
                    height={60}
                    justifyContent="space-around"
                    alignItems="center"
                    bg="#fff"
                    sx={{ borderRadius: 'medium', cursor: onBackDisabled ? 'not-allowed' : 'pointer' }}
                    p="0px 16px"
                    onClick={onBackDisabled ? undefined : onBack}
                    disabled={onBackDisabled}
                >
                    <Text as={IoIosArrowBack} color="black" fontSize={20} />
                    <Text color="black" fontWeight={500} fontSize="20px">
                        {' '}
                        Back
                    </Text>
                </Flex>
            )}
            {middleButton}
            <Flex>
                {onSkipPress && (
                    <Text
                        mr={3}
                        mt={2}
                        onClick={onSkipPress}
                        color="#000"
                        fontSize="18px"
                        fontWeight={700}
                        lineHeight="120%"
                        sx={{
                            cursor: 'pointer',
                            fontStyle: 'italic',
                        }}
                    >
                        Skip
                    </Text>
                )}
                <Box>
                    <Button
                        height={60}
                        bg="#53F"
                        type={type}
                        onClick={onNextPress}
                        disabled={disabled}
                        sx={{ borderRadius, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                        {nextButtonText}
                        {nextButtonText === 'Nope, that’s all!' && <Box ml={2} as={FaArrowRight} color="#fff" />}
                    </Button>
                </Box>

            </Flex>
        </Flex>
    </Box>
    );
}

export default Footer;
