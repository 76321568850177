import { Box, Flex, Text } from 'rebass';
import { color, space } from 'styled-system';
import PropTypes from 'prop-types';
import React from 'react';
import posed, { PoseGroup } from 'react-pose';
import styled from '@emotion/styled';

const PosedToast = posed.div({
  enter: { y: 0, opacity: 1 },
  exit: { y: 50, opacity: 0 },
});

const Wrap = styled(PosedToast)`
  position: fixed;
  z-index: 10001;
  bottom: 0;
  left: 0;
  right: 0;
  ${color};
  ${space};
  transition: background 1s ease;
`;

const UploadManagerToast = ({ onClick, linkColor, isOpen, children, ...props }) => (
  <PoseGroup>
    {isOpen && (
      <Wrap key="toast" {...props}>
        <Flex alignItems="center" justifyContent="center" css="position: relative;" width="100%">
          <Box>{children}</Box>
          <Text
            as="button"
            onClick={onClick}
            css="position: absolute; right: 0;"
            color={linkColor}
            fontSize={1}
          >
            Show Details
          </Text>
        </Flex>
      </Wrap>
    )}
  </PoseGroup>
);

UploadManagerToast.propTypes = {
  onClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  linkColor: PropTypes.string.isRequired,
};

export default UploadManagerToast;
