import { get, post, patch, destroy } from '../utils/api';

export const getCustom = id => get(`/v3/other_journals/${id}`);
export const postCustom = body => post(`/v3/other_journals`, body);
export const patchCustom = body =>
  patch(`/v3/other_journals/${body.id}`, { data: { attributes: body } });
export const destroyCustom = id => destroy(`/v3/other_journals/${id}`);

export const copyStoryToCustom = (customId, storyId) =>
  post(`/v3/other_journals/${customId}/copy_story/${storyId}`);
