import styled from '@emotion/styled';
import { RemainderLabel, RemainderWrap } from '../../stories/shared-with-avatars/styled';
import ButtonLink from '../../app/buttons/link';
import MemberAvatar from '../../members/avatar';

export const Wrap = styled.div`
  display: grid;
  grid-gap: ${props => props.theme.gutter};
  justify-items: center;
  background: ${props => props.theme.colours.brand};
  text-align: center;
  border-radius: ${props => props.theme.borderRadii.normal};
  padding: calc(${props => props.theme.gutter} * 1.5);
`;

export const Title = styled.h2`
  color: ${props => props.theme.colours.white};
  margin: 0;
  font-size: 24px;
`;

export const Body = styled.p`
  font-size: 15px;
  line-height: 25px;
  max-width: 350px;
  color: ${props => props.theme.colours.white};
  margin: 1em 0 0;
`;

export const SharedAvatars = styled.div`
  display: flex;
`;

export const Label = styled(RemainderLabel)`
  margin: 0;
  width: 46px;
  height: 46px;
`;

export const Button = styled(ButtonLink)`
  width: 250px;

  ${props => props.theme.media.tablet`
    width: 270px;
  `}
`;

export const StyledMemberAvatar = styled(MemberAvatar)`
  border: 2px solid ${props => props.theme.colours.brandSubtle};
  margin: 4px;
`;

export const StyledMemberOverflow = styled(RemainderWrap)`
  border: 2px solid ${props => props.theme.colours.brandSubtle};
  border-radius: 50%;
  margin: 4px;
`;
