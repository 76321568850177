import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RecentActivityAvatar from '../recent-activity-avatar';
import { selectPageLikes } from '../../story-pages/selectors';
import { LikesAvatarsWrapper } from './styled';

const ActivityLikeAvatars = ({ activities }) => (
  <LikesAvatarsWrapper>
    {activities
      .map(activity => <RecentActivityAvatar activity={activity} key={`raa-${activity.id}`} />)
      .reverse()
      .slice(0, 7)}
  </LikesAvatarsWrapper>
);

ActivityLikeAvatars.propTypes = {
  activities: PropTypes.arrayOf(PropTypes.shape({})),
};

ActivityLikeAvatars.defaultProps = {
  activities: [],
};

const mapStateToProps = (state, { id }) => ({
  activities: selectPageLikes(state, id),
});

export default connect(mapStateToProps)(ActivityLikeAvatars);
