import posed from 'react-pose';
import styled from '@emotion/styled';
import { top } from 'styled-system';

const PosedWrap = posed.div({
  enter: {
    // y: 0,
    scale: 1,
    opacity: 1,
  },
  exit: {
    // y: 20,
    scale: 0.8,
    opacity: 0,
  },
});

export const Wrap = styled(PosedWrap)`
  background-color: ${props => props.theme.colours.defaultText};
  border-radius: ${props => props.theme.borderRadii.normal};
  display: flex;
  flex-direction: column;
  color: ${props => props.theme.colours.white};
  justify-content: center;
  align-items: center;
  text-align: center;
  position: fixed;
  height: ${props => props.height || '150px'};
  width: ${props => props.width || '150px'};
  top: calc(50vh - 75px);
  left: calc(50vw - 75px);
  z-index: 10001;
  ${top};

  svg {
    margin-bottom: calc(${props => props.theme.gutter} * 0.5);
  }
`;

export const Message = styled.span`
  font-size: 18px;
  padding: 20px;
`;
