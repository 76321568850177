import PropTypes from 'prop-types';
import React from 'react';

import { ModalWrap, LocalWrap, Spinner } from './styled';

const Loading = ({ local }) => {
  if (local) {
    return (
      <LocalWrap>
        <Spinner />
      </LocalWrap>
    );
  }

  return (
    <ModalWrap>
      <Spinner />
    </ModalWrap>
  );
};

Loading.propTypes = {
  local: PropTypes.bool,
};

Loading.defaultProps = {
  local: false,
};

export default Loading;
