import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Flex, Text } from 'rebass';
import Modal from '../../../app/modal/v3';
import FileRow from './file-row';

const NewMultipleMedia = ({ onClose, maxWidth, onSubmitMedia, initialFiles }) => {
  const [files, setFiles] = useState(Array.from(initialFiles));
  return (
    <Modal
      title="Add Multiple Media"
      close={{ onClose }}
      maxWidth={maxWidth}
      aboveTooltip
      closeOnOutsideClick={false}
      footer={
        <Flex mt={3} ml="auto" justifyContent="flex-end">
          <Button variant="outline">
            <label htmlFor="add-multiple-media" style={{ cursor: 'pointer' }}>
              <input
                id="add-multiple-media"
                type="file"
                accept="image/*,video/*,audio/*"
                onChange={e => setFiles([...files, e.target.files[0]])}
                style={{ visibility: 'hidden', cursor: 'pointer', width: '1px', height: '1px' }}
              />
              Add More
            </label>
          </Button>
          <Button
            onClick={() => onSubmitMedia(files)}
            variant="primary"
            ml={2}
            disabled={!files.length}
          >
            Upload Files
          </Button>
        </Flex>
      }
    >
      <Flex flexDirection="column" p={2} mb="68px">
        {files.length ? (
          files.map((file, index) => (
            <FileRow
              key={`${file.lastModified}+${file.name}`}
              file={file}
              index={index}
              handleRemoveMedia={mediaIndex =>
                setFiles(files.filter((_, arrIndex) => mediaIndex !== arrIndex))
              }
            />
          ))
        ) : (
          <Text fontSize={3} mx="auto" mb={2}>
            No media selected
          </Text>
        )}
      </Flex>
    </Modal>
  );
};

NewMultipleMedia.propTypes = {
  maxWidth: PropTypes.number.isRequired,
  onSubmitMedia: PropTypes.func.isRequired,
  initialFiles: PropTypes.shape({}).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NewMultipleMedia;
