import React from 'react';
import PropTypes from 'prop-types';

const MyStoriesIcon = ({ width }) => (
  <svg width={width} height="21" viewBox="0 0 26 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.9062 2.90625H12.7812L10.25 0.375H2.65625C1.26406 0.375 0.125 1.51406 0.125 2.90625V18.0938C0.125 19.4859 1.26406 20.625 2.65625 20.625H22.9062C24.2984 20.625 25.4375 19.4859 25.4375 18.0938V5.4375C25.4375 4.04531 24.2984 2.90625 22.9062 2.90625Z"
      fill="currentColor"
    />
  </svg>
);

MyStoriesIcon.propTypes = {
  width: PropTypes.number,
};

MyStoriesIcon.defaultProps = {
  width: 26,
};

export default MyStoriesIcon;
