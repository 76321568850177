import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { connect } from 'react-redux';
import { likeStoryMedia, unlikeStoryMedia } from '../actions';
import { selectUserLikeForPage } from '../../story-pages/selectors';
import likeFilled from '../story-page-activity/svg/like-filled.svg';
import likeOutine from '../story-page-activity/svg/like-outline.svg';

const Button = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  margin: auto;
  padding: 9.5px 0;
  outline: 0;
`;

const LikeToggle = ({ storyId, userLikeId, medium, children, ...props }) => (
  <Button
    type="button"
    onClick={() =>
      (userLikeId && props.unlikeStoryMedia(userLikeId, storyId)) ||
      props.likeStoryMedia(medium.id, medium.type, storyId)
    }
  >
    <img src={userLikeId ? likeFilled : likeOutine} alt="" />
    {children}
  </Button>
);

LikeToggle.propTypes = {
  unlikeStoryMedia: PropTypes.func.isRequired,
  likeStoryMedia: PropTypes.func.isRequired,
  medium: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  userLikeId: PropTypes.string,
  storyId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

LikeToggle.defaultProps = {
  userLikeId: '',
};

const mapStateToProps = (state, { pageId }) => {
  const { [pageId]: page } = state.entities.pages;
  const userLikeId = selectUserLikeForPage(state, pageId);
  return {
    medium: page.media[0],
    userLikeId,
  };
};

export default connect(mapStateToProps, { likeStoryMedia, unlikeStoryMedia })(LikeToggle);
