import { useContext, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { parse } from 'query-string';
import { ScrollContext } from 'contexts/scroll';

const useHandleScrollSubject = () => {
  const { pathname, search } = useLocation();
  const { replace } = useHistory();
  const { setScrollItemId } = useContext(ScrollContext);

  useEffect(() => {
    if (search) {
      const params = parse(search);
      if (params.subject_id) {
        setScrollItemId(params.subject_id);
      }
      replace(pathname);
    }
  }, [pathname, replace, search, setScrollItemId]);
};

export default useHandleScrollSubject;
