export const formatCommentBody = (mediumId, memberId, attributes) => ({
  data: {
    attributes: {
      type: 'Comment',
      ...attributes,
    },
    relationships: {
      member: {
        data: {
          id: memberId,
          type: 'members',
        },
      },
    },
  },
});

export default formatCommentBody;
