import styled from '@emotion/styled';
import { colours, borderRadii, fonts, gutter, breakpoints } from '../../../app/styles/constants';

export const CoverWrapper = styled.div`
  background-image: url(${props => props.imageUrl});
  background-position: center center;
  background-size: cover;
  background-color: ${props => props.theme.colours.blueBackground};
  width: 100%;
  height: 250px;
  border-top-left-radius: ${borderRadii.normal};
  border-top-right-radius: ${borderRadii.normal};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &::before {
    content: '';
    position: relative;
    top: 130px;
    left: 0;
    height: 250px;
    width: 100%;
    background-image: ${props =>
    !!props.imageUrl &&
    `linear-gradient(${props.theme.colours.photoGradientStart}, ${props.theme.colours.photoGradientEnd})`};
    border-top-left-radius: ${borderRadii.normal};
    border-top-right-radius: ${borderRadii.normal};
  }

  @media screen and (max-height: 576px) {
    height: 200px;

    &::before {
      height: 200px;
    }
  }

  label {
    position: relative;
    font-size: 18px;
    font-family: ${fonts.quicksand};
    font-weight: bold;
    color: ${colours.white};
    cursor: pointer;
    z-index: 2;
    height: calc(100% - ${gutter} * 2);
    width: calc(100% - ${gutter} * 2);
    border: 4px dashed rgba(255, 255, 255, 0.3);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    span {
      margin-top: calc(${gutter} / 2);
      text-align: center;
    }
  }

  img {
    position: relative;
    z-index: 2;
  }
`;

const { xs, sm } = breakpoints;

export const Form = styled.form`
  padding: ${props => props.theme.gutter};
`;

export const ButtonsFooter = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    margin-left: ${props => props.theme.gutter};
  }
`;

export const DatesWrap = styled.div`
  display: flex;
  align-items: flex-end;

  .form__control-group {
    margin-bottom: auto;
  }

  .form__control-group + .form__control-group {
    margin-left: 1em;
  }

  @media (min-width: ${xs}) and (max-width: ${sm}) {
    flex-direction: column;

    hr {
      display: none;
    }
  }
`;

export const Label = styled.div`
  display: block;
  color: #666;
  font-size: 16px;
  padding-bottom: calc(16px / 2);
`;

export default CoverWrapper;
