import React, { forwardRef } from 'react';
import { useTheme } from 'emotion-theming';
import PropTypes from 'prop-types';
import { FiPlus } from 'react-icons/fi';
import { useDropzone } from 'react-dropzone';
import { Button, Text, Flex, Box } from 'rebass';
import { useMobileCheckHook } from 'story-media/hooks';

export const DropArea = forwardRef(
  ({ children, uploadText = 'Upload photo', ...props }, ref) => {
    const theme = useTheme();
    const isForVideo = uploadText.toLowerCase().includes('video');

    return (
      <Box
        sx={{
          border: '3px dashed #7B33FB',
          background: '#F5F4FB',
          borderRadius: theme.borderRadii.normal,
          p: [`${theme.space[6]}px 20px`, theme.space[6]],
          textAlign: 'center',
        }}
        ref={ref}
        {...props}
      >
        <Button
          bg="transparent"
          sx={{
            border: '3px solid transparent',
            backgroundImage: 'linear-gradient(90deg, #3866F4 0%, #7B33FB 48.96%, #BD00FF 100%)',
            borderImageSlice: '1',
            borderRadius: 60,
            p: 0,
          }}
          mb={children ? 2 : 0}
        >
          <Flex
            alignItems="center"
            bg="#F4F6F7"
            py={1}
            px={2}
            sx={{
              borderRadius: 60,
            }}
          >
            <Text as={FiPlus} size={isForVideo ? 20 : 40} color="#7B33FB" />
            <Text color="#7B33FB" fontSize="20px" fontWeight={400} lineHeight="120%">
              {' '}
              {uploadText}
            </Text>
          </Flex>
        </Button>
        {/* {!isFromMobile && (
          <Text mt={1} color="black" fontSize="16px" fontWeight={400} lineHeight="120%">
            {`Drag and Drop ${totalWords[totalWords.length - 1]} here`}
          </Text>
        )} */}
        {children}
      </Box>
    );
  }
);
function getUploadText(accept, multiple, label = '', buttonText) {
  if (buttonText) return buttonText;

  if (accept.includes('image') && accept.includes('video')) return 'Upload Media';

  if (accept.includes('image') || label.includes('image')) {
    if (multiple) return 'Upload Photos';
    return 'Upload Photo';
  }

  if (accept.includes('video') || label.includes('video'))
    if (multiple) return 'Upload Videos';
    else return 'Upload Video';
  return 'Upload Song';
}

function FileDropper({
  onDrop,
  onClick,
  children,
  label,
  multiple,
  disabled,
  accept,
  buttonText
}) {
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  return (
    <DropArea
      uploadText={getUploadText(accept, multiple, label, buttonText)}
      {...getRootProps({ onClick })}
    >
      <input {...getInputProps()} disabled={disabled} multiple={multiple} accept={accept} />
      {children}
    </DropArea>
  );
}

FileDropper.propTypes = {
  onDrop: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  accept: PropTypes.string,
};
FileDropper.defaultProps = { onClick: () => { }, disabled: false, accept: null };

export default FileDropper;
