import { get, post, patch, destroy } from '../utils/api';

export const getMemoir = id => get(`/v3/memoirs/${id}`);
export const postMemoir = body => post(`/v3/memoirs`, body);
export const patchMemoir = body => patch(`/v3/memoirs/${body.id}`, { data: { attributes: body } });
export const getMemoirPaymentUrl = body => patch(`/v3/memoirs/${body.id}/setup_payment`);
export const destroyMemoir = id => destroy(`/v3/memoirs/${id}`);

export const copyStoryToMemoir = (memoirId, storyId) => post(`/v3/memoirs/${memoirId}/copy_story/${storyId}`);

export async function postPrivateEventDetails(eventName, attributes) {
    await post(`/v2/me/tracking_events?event_topic_name=${eventName}`, {
        data: { attributes },
    });
}
