import React, { useContext } from 'react';
import PropTypes from 'prop-types';


import { Button, Flex, Text } from 'rebass';
import Tooltip from '@tippy.js/react';
import { CollectionTooltipContext } from '../../collections/collection-tooltip-context';

const AlertTooltip = ({ children, isVisible, text, onClick }) => (
  <Tooltip
    theme="alert"
    trigger="manual"
    arrow="small"
    interactive
    isVisible={isVisible}
    content={
      <Flex flexDirection="column" p={2}>
        <Text color="defaultText" textAlign="left">
          {text}
        </Text>
        <Button
          onClick={onClick}
          ml="auto"
          mt={2}
          fontWeight="bold"
          css="background-color: transparent; color: #333; border: 1px solid #333;"
        >
          Got It
        </Button>
      </Flex>
    }
  >
    {children}
  </Tooltip>
);

AlertTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  isVisible: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

// const MemoirTooltip = ({ type, text, complete, children, isEnabled }) => {
//   const [isVisible, setIsVisible] = useState(false);
//   const { currentTooltip, updateTooltip } = useContext(CollectionTooltipContext);

//   const isCurrentTooltip = isEnabled && type === currentTooltip.type && !currentTooltip.dismiss;

//   const timeout = useRef();
//   useEffect(() => {
//     if (isCurrentTooltip) {
//       timeout.current = setTimeout(() => {
//         setIsVisible(true);
//       }, 1000);
//     }
//     return () => timeout.current && clearTimeout(timeout.current);
//   }, [isCurrentTooltip]);

//   return (
//     <AlertTooltip
//       isVisible={isCurrentTooltip && isVisible}
//       text={text}
//       onClick={() => updateTooltip(type, { dismiss: true, complete })}
//     >
//       {children}
//     </AlertTooltip>
//   );
// };

const MemoirTooltip = ({ children }) => children;

MemoirTooltip.propTypes = {
  //   type: PropTypes.string.isRequired,
  //   text: PropTypes.string.isRequired,
  //   complete: PropTypes.bool,
  children: PropTypes.node.isRequired,
  //   isEnabled: PropTypes.bool,
};

// MemoirTooltip.defaultProps = {
//   complete: undefined,
//   isEnabled: true,
// };

export default MemoirTooltip;

export const useUpdateTooltip = () => {
  const { updateTooltip } = useContext(CollectionTooltipContext);
  return updateTooltip;
};
