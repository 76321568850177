import React from 'react';
import PropTypes from 'prop-types';
import { Button, Text } from 'rebass';
import { capitalizeFirstLetter } from 'stories/helper';

function ButtonWithIcon({ icon, iconProps, children, sx, ...props }) {
  return (
    <Button
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...sx,
      }}
      {...props}
    >
      <Text as={icon} mr={2} size={16} minWidth={16} {...iconProps} />
      <span>{capitalizeFirstLetter(children)}</span>
    </Button>
  );
}

ButtonWithIcon.propTypes = {
  icon: PropTypes.func.isRequired,
  iconProps: PropTypes.shape({}),
  sx: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
};

ButtonWithIcon.defaultProps = {
  iconProps: {},
  sx: {},
};

export default ButtonWithIcon;
