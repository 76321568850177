// 
import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'formik';
import { Flex, Button, Box, Card } from 'rebass';
import TextField from '../../app/form/formik/text';

const Fieldset = ({ children }) => (
  <Card as={Flex} flexDirection="column">
    {children}
  </Card>
);
Fieldset.propTypes = { children: PropTypes.node.isRequired };

const PersonalJournalForm = ({ handleSubmit, isSubmitting }) => {
  return (
    <Flex flexDirection="column" alignItems="center" as={Form}>
      <Box py={2}>
        <Field label="Name" component={TextField} name="name" showError />
      </Box>
      <Box py={2}>
        <Field label="Slug" component={TextField} name="slug" showError />
      </Box>
      <Box pt={2} pb={4}>
        <Field label="Description" component={TextField} name="description" showError />
      </Box>
      <Button
        type="submit"
        flex={1}
        variant="primary"
        onClick={handleSubmit}
        disabled={isSubmitting}
      >
        Save
      </Button>
    </Flex>
  );
};

PersonalJournalForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default PersonalJournalForm;
