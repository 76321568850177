import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import camelCase from 'lodash/camelCase';
// import isEqual from 'lodash/isEqual';

class EntityAttr extends Component {
  // shouldComponentUpdate(nextProps) {
  //   return !isEqual(nextProps.entity, this.props.entity);
  // }
  //
  render() {
    const {
      entity, attr, fallback, children,
    } = this.props;

    const entityAttr = get(entity, attr, fallback);
    const camelKey = camelCase(attr);
    return children ? children({ attr: entityAttr, [camelKey]: entityAttr }) : entityAttr;
  }
}

EntityAttr.propTypes = {
  entity: PropTypes.shape({}),
  attr: PropTypes.string,
  children: PropTypes.func,
  fallback: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
};

EntityAttr.defaultProps = {
  entity: {},
  attr: '',
  fallback: null,
  children: null,
};

const selectEntity = (state, type, id) => state.entities[type][id];

const mapStateToProps = (state, { id, type }) => ({
  entity: selectEntity(state, type, id) || {},
});

export default connect(mapStateToProps)(EntityAttr);
