import PropTypes from 'prop-types';

import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { useEntity } from 'entities/hooks/index';
import { paramCase } from 'param-case';
import { selectEntity } from 'entities/selectors';
import { useDeepCompareEffect, useSpreadState } from '../../app/hooks';
import { useRelatedEntities } from '../../entities/hooks';

const shouldIncrementTagCount = (tag = {}) => tag['taggable-type'] === 'Story';
const getPrevCount = (tag = {}) => tag.count || 0;

export const useCollectionTagFromParam = ({ id, type, paramTag }) => {
  const { entity } = useEntity({ id, type });
  return useSelector(state => {
    if (!paramTag) return undefined;
    const tagMatch = (entity.tags || []).find(tag => {
      const name = get(state, `entities.tags[${tag.id}].name`, '');
      return paramCase(name) === paramTag;
    });
    if (!tagMatch) return undefined;
    return selectEntity({ state, ...tagMatch });
  });
};

export const getCountedTags = (tags = []) =>
  Object.values(
    tags.reduce((acc, tag) => {
      const prevCount = getPrevCount(acc[tag.name]);
      return {
        ...acc,
        [tag.name]: {
          ...(acc[tag.name] || {}),
          name: tag.name,
          count: shouldIncrementTagCount(tag) ? prevCount + 1 : prevCount,
          id: tag.id,
        },
      };
    }, {})
  );

export const useCollectionTags = ({ id, type }) => {
  const tags = useRelatedEntities({ id, type, relatedType: 'tags' });
  // const tags = useSelector(state => {
  //   Object.values(state.entities.tags).filter(tag => tag.id === id);
  // })

  const getTagsState = () => {
    const sortedTags = Object.values(tags).sort((a, b) =>
      a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0
    );
    const countedTags = getCountedTags(sortedTags);

    return { tags: sortedTags, countedTags };
  };

  const [tagState, setState] = useSpreadState(getTagsState());

  useDeepCompareEffect(() => {
    setState(getTagsState());
  }, [tags]);

  return tagState;
};

const CollectionTags = ({ id, type, children }) => children(useCollectionTags({ id, type }));

CollectionTags.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
};

export default CollectionTags;
