import { isEmpty, flatten } from 'lodash';
import { useSelector } from 'react-redux';
import { useEntities, useEntityActionCreators } from '../../entities/hooks';
import { useActivities } from '../hooks';
import { useCurrentMemberId } from '../../members/hooks/index';
import { markSingleNotificationRead, getNotifications } from '../api';

export const getNotificationLink = notification => {
  // const topics = [ 'new_media', 'story_share', 'story_update', 'new_comment', 'new_like' ];
  if (notification.subject && notification.subject.type === 'connections') return '/friends';
  if (notification.subject && notification.subject.type === 'members') return '/profile';
  if (notification.topic === 'connection_accepted') return '/friends';
  if (notification.topic === 'story_request')
    return `/memoirs/${notification['chronicle-id']}/story-requests`;
  if (notification.topic === 'memoir_share') return `/memoirs/${notification['chronicle-id']}`;
  if ((notification.topic || '').includes('milestone'))
    return `/memoirs/${notification['chronicle-id']}/life-events/${notification.subject.id}`;
  const { 'story-id': storyId } = notification;
  if (storyId) return `/story/${storyId}`;
  return '';
};

export const getNotificationButtonLabel = notification => {
  // const topics = [ 'new_media', 'story_share', 'story_update', 'new_comment', 'new_like' ];
  if (notification.subject && notification.subject.type === 'connections') return 'Go to Friends';
  if (notification.subject && notification.subject.type === 'members') return 'Go to Profile';
  const topic = (() => {
    switch (notification.topic) {
      case 'connection_accepted':
        return 'Go to Friends';
      case 'story_request':
        return 'Go to Story Request';
      case 'memoir_share':
      case 'milestone':
        return 'Go to Memoir';
      default:
        return null;
    }
  })();
  if (topic) return topic;

  const { 'story-id': storyId } = notification;
  if (storyId) return 'Go to Story';
  return '';
};

const useUnreadStoryCreateActivities = () => {
  const allActivities = useEntities('activities');
  const allStoryShares = useEntities('story-shares');

  return Object.values(allActivities)
    .filter(activity => activity.read === false)
    .filter(activity => activity.topic === 'story_share')
    .filter(activity => !activity['chronicle-id'])
    .filter(
      activity =>
        activity.subject &&
        allStoryShares[activity.subject.id] &&
        allStoryShares[activity.subject.id].archived === false
    );
};

export const useUnreadStoryIds = () => {
  // const unreadActivities = useUnreadStoryCreateActivities();
  const allActivities = useActivities();
  const unreadActivities = Object.values(allActivities).filter(activity => activity.read === false);
  const unreadStoryIds = unreadActivities.map(share => share['story-id']);

  return Array.from(new Set(unreadStoryIds));
};

export const useUnreadCollectionIds = () => {
  const allStories = useEntities('stories');
  const unreadStoryIds = useUnreadStoryIds();

  const unreadStories = Object.values(allStories).filter(story =>
    unreadStoryIds.includes(story.id)
  );
  const unreadJournalIds = unreadStories.map(story => story['journal-ids']);

  return Array.from(new Set(...unreadJournalIds));
};

export const useCollectionUnreadStoryCount = id => {
  const allStories = useEntities('stories');
  const unreadStoryIds = useUnreadStoryIds();

  const unreadStories = Object.values(allStories).filter(story =>
    unreadStoryIds.includes(story.id)
  );
  const unreadJournalIds = flatten(unreadStories.map(story => story['journal-ids']));
  const storyCount = unreadJournalIds.filter(journalId => journalId === id).length;

  return storyCount;
};

export const useUnreadActivityForStory = storyId => {
  const unreadActivities = useUnreadStoryCreateActivities();
  return unreadActivities.find(activity => activity['story-id'] === storyId);
};

export const useIsStoryUnread = id =>
  useSelector(
    state =>
      !!Object.values(state.entities.activities).find(
        activity => activity['story-id'] === id && !activity.read
      )
  );

export const useAllStoriesUnreadCount = filter => {
  const allStories = useEntities('stories');
  const unreadStoryIds = useUnreadStoryIds();
  const currentMemberId = useCurrentMemberId();

  const unreadStories = Object.values(allStories).filter(story =>
    unreadStoryIds.includes(story.id)
  );

  if (filter === 'shared')
    return unreadStories.filter(story => story['member-id'] !== currentMemberId).length;

  return unreadStories.filter(story => story['member-id'] === currentMemberId).length;
};

export const useMarkStoryNotificationsRead = storyId => {
  const currentMemberId = useCurrentMemberId();
  const allActivities = useActivities();
  const unreadStoryIds = useUnreadStoryIds();
  const { receive } = useEntityActionCreators();

  if (isEmpty(unreadStoryIds)) return false;
  if (!unreadStoryIds.includes(storyId)) return false;

  const unreadStoryActivitiesIds = Object.values(allActivities)
    .filter(activity => activity.read === false && activity['story-id'] === storyId)
    .map(activity => activity.id);

  return Promise.all(
    unreadStoryActivitiesIds.map(id => markSingleNotificationRead(currentMemberId, id))
  ).then(() => getNotifications(currentMemberId).then(payload => receive({ payload })));
};

export const useCollectionFromStoryId = id => {
  const allStories = useEntities('stories');

  if (id === null) return null;

  const [story] = Object.values(allStories).filter(el => el.id === id);

  if (!story) return null;

  return story.journals[0];
};

export default getNotificationLink;
