import React, { useState } from 'react';
import { Text, Box } from 'rebass';
import { useDispatch } from 'react-redux';
import RecordVideo from './RecordVideo';
import { createMediaForFiles, createTextMedium } from 'story-media/actions';
import RecordAudio from './RecordAudio';
import AddText from './AddText';
import Footer from './Footer';
import UploadMedia from './UploadMedia';
import QuestionBar from './QuestionBar';
import Wrapper from './Wrapper';

const Step3 = ({ attributes, storyId, onStepUp, percentComplete }) => {
    const [selectActionItem, setSelectedActionItem] = useState(null);
    const [noOfTransitions, setNoOfTransitions] = useState(0);

    const dispatch = useDispatch();

    const submitText = (values) => {
        dispatch(createTextMedium(storyId, values), [])
        setNoOfTransitions((prev) => (prev + 1))
    };

    const submitMedia = (files, position) => {
        dispatch(createMediaForFiles(storyId, files, position, true));
        setNoOfTransitions((prev) => (prev + 1))
        // onStepUp()
    }

    const onSubmitMedia = files => {
        return submitMedia(files, 0);
    }

    return (
        <Wrapper justifyContent="center" alignItems="left" noOfTransitions={noOfTransitions}>
            <QuestionBar question={attributes?.question} />
            <Text
                px={2}
                textAlign="left"
                fontFamily='Zilla Slab'
                fontSize={30}
                lineHeight="120%"
                letterSpacing='-0.66px'
                my={3}
            >
                Would you like to add anything else?
            </Text>
            <Box p={2} m="auto">
                <RecordVideo question={attributes?.question} isSelected={selectActionItem === 'record'} onClick={() => setSelectedActionItem('record')} onSubmitMedia={onSubmitMedia} />
                <UploadMedia question={attributes?.question} isSelected={selectActionItem === 'media'} onClick={() => setSelectedActionItem('media')} onSubmitMedia={onSubmitMedia} />
                <AddText
                    question={attributes?.question}
                    isSelected={selectActionItem === 'text'}
                    onClick={() => setSelectedActionItem('text')}
                    onSubmitText={({ text }) => submitText({ text, 'position': 0 }).then(() => setSelectedActionItem(null))}
                    label="Text"
                />

                <RecordAudio
                    question={attributes?.question}
                    isSelected={selectActionItem === 'audio'}
                    onClick={() => setSelectedActionItem('audio')}
                    onSubmitMedia={onSubmitMedia}
                    label="Audio"
                />
            </Box>

            <Footer borderRadius="200px" onNextPress={onStepUp} percentComplete={percentComplete} nextButtonText="Nope, that’s all!" noOfTransitions={noOfTransitions} />
        </Wrapper>
    );
};

export default Step3;
