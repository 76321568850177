import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { downloadStory } from '../actions';
import { selectCurrentMemberEmailIdentifier } from '../../members/selectors';
import { setCheckmark } from '../../ui/actions';
import { useSpreadState } from '../../app/hooks';
import Confirm from './confirm';
import Exporting from './exporting';
import Finished from './finished';
import InvalidEmail from './invalid-email';
import Modal from '../../app/modal/v2';

const selectStoryExportForStory = (state, storyId) => {
  const storyExports = state.entities['story-exports'];
  if (!Object.keys(storyExports).length) {
    return undefined;
  }
  const exportId = Object.keys(storyExports).find(id => storyExports[id].story.id === storyId);
  return storyExports[exportId];
};

const DownloadStory = ({
  onClose,
  storyId: id,
  storyExport: { state: downloadState },
  userHasDownloaded,
  identifier: { email, validated },
  ...props
}) => {
  const [showCheckmark, setState] = useSpreadState({ showCheckmark: true });

  useEffect(() => {
    if (!downloadState) {
      props.downloadStory(id);
    }
    if (!downloadState && userHasDownloaded) onClose();

    return () => {
      if (showCheckmark) {
        props.setCheckmark({ message: 'Download Queued' });
      }
    };
  }, [downloadState, id, onClose, props, showCheckmark, userHasDownloaded]);

  return (
    <Modal onClose={onClose}>
      {!validated && (
        <InvalidEmail
          email={email}
          onDismiss={() => {
            setState({ showCheckmark: false });
            onClose();
          }}
        />
      )}
      {validated && downloadState === 'finished' && (
        <Finished
          onConfirm={() => {
            props.downloadStory(id);
            onClose();
          }}
          onDismiss={() => {
            setState({ showCheckmark: false });
            onClose();
          }}
          email={email}
        />
      )}
      {validated && downloadState === 'exporting' && (
        <Exporting
          onDismiss={() => {
            setState({ showCheckmark: false });
            onClose();
          }}
          email={email}
        />
      )}
      {validated && !downloadState && <Confirm onDismiss={onClose} email={email} />}
    </Modal>
  );
};

DownloadStory.defaultProps = {
  storyExport: {},
  identifier: {},
};

DownloadStory.propTypes = {
  setCheckmark: PropTypes.func.isRequired,
  userHasDownloaded: PropTypes.bool.isRequired,
  identifier: PropTypes.shape({
    email: PropTypes.string,
    validated: PropTypes.bool,
  }),
  storyExport: PropTypes.shape({}),
  downloadStory: PropTypes.func.isRequired,
  storyId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { storyId: id }) => ({
  identifier: selectCurrentMemberEmailIdentifier(state),
  storyExport: selectStoryExportForStory(state, id),
  userHasDownloaded: state.story.hasDownloadedStory,
  id,
});

export default connect(mapStateToProps, { setCheckmark, downloadStory })(DownloadStory);
