import { Text, Box } from 'rebass';
import PropTypes from 'prop-types';
import React from 'react';

import { FaUserCog } from 'react-icons/fa';
import { IoMdCreate } from 'react-icons/io';
import { RoleButton, RoleWrap } from './styled';
import Avatar from '../../members/avatar';
import Name from '../../members/name';
import EyeIcon from '../../stories/assets/eye-icon';
import { colours } from '../../app/styles/theme';

const Role = ({ memberId, role, setRole, fromCollection, disabled, subLabel, coOwnerIcon }) => {
  const isViewerActive = role === 'viewer' || role === 'collaborator';
  const isCollaboratorActive = role === 'collaborator';

  return (
    <RoleWrap>
      <Avatar id={memberId} />
      <Box>
        <Name id={memberId} />
        {fromCollection && (
          <Text fontSize={1} color="grey80">
            shared in collection
          </Text>
        )}
        {subLabel && (
          <Text fontSize={1} color="grey80">
            {subLabel}
          </Text>
        )}
      </Box>
      {!disabled && (
        <>
          <RoleButton
            selected={isViewerActive}
            onClick={() => {
              if (isViewerActive && fromCollection) {
                return false;
              }
              return isViewerActive ? setRole('') : setRole('viewer');
            }}
          >
            <EyeIcon
              colour={isViewerActive ? colours.brand : colours.border}
              style={{ position: 'absolute' }}
            />
          </RoleButton>
          <RoleButton
            selected={isCollaboratorActive}
            onClick={() => {
              if (role === 'collaborator') {
                return setRole('viewer');
              }
              return setRole('collaborator');
            }}
          >
            {coOwnerIcon === 'USER_COG' && <FaUserCog
              color={isCollaboratorActive ? colours.brand : colours.border}
              style={{ position: 'absolute' }}
            />}
            {coOwnerIcon === 'PENCIL' && <IoMdCreate
              color={isCollaboratorActive ? colours.brand : colours.border}
              style={{ position: 'absolute' }}
            />}
          </RoleButton>
        </>
      )}
    </RoleWrap>
  );
};

Role.propTypes = {
  memberId: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  setRole: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  fromCollection: PropTypes.bool,
  subLabel: PropTypes.string,
  coOwnerIcon: PropTypes.oneOf(['PENCIL', 'USER_COG']).isRequired
};
Role.defaultProps = {
  fromCollection: false,
  disabled: false,
  subLabel: '',
};

export default Role;
