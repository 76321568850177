import FontAwesome from 'react-fontawesome';
import React from 'react';

import { AddFriendButton } from '../styled';

const AddFriend = ({ ...props }) => (
  <AddFriendButton to="/friends/add">
    <FontAwesome name="user-plus" />
    <p>Add Friend</p>
  </AddFriendButton>
);

export default AddFriend;
