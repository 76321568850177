import { useDispatch } from 'react-redux';
import { setCurrentCollectionTag } from '../actions';

const useResetCollectionTag = () => {
  const dispatch = useDispatch();
  const resetCollectionTag = () => dispatch(setCurrentCollectionTag(''));

  return resetCollectionTag;
};

export default useResetCollectionTag;
