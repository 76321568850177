import React from 'react';
import { Flex, Heading } from 'rebass';
import Loading from 'app/loading/index';
import useSWR from 'swr';
import { getSWR } from 'utils/api';
import { useCurrentMemberId } from 'members/hooks/index';

const MemberOnboardLoading = () => {
  const currentMemberId = useCurrentMemberId();
  // polls member every second until
  // request updates the member cache
  // once the member cache contains onboarded true this component unmounts
  // AuthMember makes the decision to render or not
  useSWR(`/v2/members/${currentMemberId}`, getSWR, { refreshInterval: 1000 });

  return (
    <Flex p={2} my="auto" flexDirection="column" alignItems="center">
      <Heading mb={3}>Preparing your account...</Heading>
      <Loading local />
    </Flex>
  );
};

export default MemberOnboardLoading;
