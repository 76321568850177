import FontAwesome from 'react-fontawesome';
import { MdEdit } from 'react-icons/md';
import React, { useState } from 'react';
import { Text, Button, Flex } from 'rebass';
import useSWR from 'swr';
import { Formik, Form, Field } from 'formik';
import {
  Activity,
  LikesCommentsAvatars,
  LikesButton,
  ActivityCommentsButton,
} from './styled';
import { useToggle } from '../../app/hooks';
import TextField from 'app/form/formik/text/index';
import { updateTextMedium } from 'story-media/api';
import Modal from 'app/modal/v3/index';

function RenderForm({
  id,
  type,
  onDismiss,
  transcription,
}) {
  const { revalidate } = useSWR(`/v2/media/${id}`)
  return (
    <Modal title="Edit Transcription" close={{ onClose: onDismiss }}>
      <Formik
        initialValues={{ transcription }}
        onSubmit={async (values, { setSubmitting }) => {
          console.log(values)
          setSubmitting(true)
          await updateTextMedium(id, {
            data: {
              attributes: {
                ...values,
                id,
                type,
                'lock-version': 1,
              }
            }
          })
          revalidate()
          setSubmitting(false)
          onDismiss()
        }}
        enableReinitialize
      >
        {({ isSubmitting }) => (
          <Form style={{ margin: '0px 0px 20px 0px' }}>
            <Flex
              justifyContent="space-between"
              flexDirection="column"
              sx={{
                borderRadius: '8px',
                p: 2,
              }}
            >
              <Field
                component={TextField}
                name="transcription"
                showError
                width="100%"
                showTextArea
              />

              <Flex
                justifyContent='flex-end'
                mt={2}
              >
                <Button
                  variant="primary"
                  disabled={isSubmitting}
                  height={52}
                  p={1}
                  px={3}
                >
                  Save
                </Button>
              </Flex>
            </Flex>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

const ShowTranscription = ({ id, transcription, type }) => {
  const [open, toggle] = useToggle(false);
  const [isEditing, setIsEdit] = useState(false);

  if (!transcription) return false;

  return (
    <Activity>
      <LikesCommentsAvatars>
        <ActivityCommentsButton onClick={toggle}>
          <LikesButton style={{ fontWeight: 'bold', marginRight: 'auto' }}>
            Show Transcription
          </LikesButton>
          {open ? <FontAwesome name="chevron-up" /> : <FontAwesome name="chevron-down" />}
        </ActivityCommentsButton>
      </LikesCommentsAvatars>
      {open && (
        isEditing ?
          <RenderForm type={type} id={id} transcription={transcription} onDismiss={() => setIsEdit(false)} />
          : <Flex p="20px" justifyContent="space-between" alignItems="center">
            <Text color="#666"  >{transcription}</Text>
            <Text as={MdEdit} color="#7a69bf" minWidth={24} size={24} sx={{ cursor: 'pointer' }} onClick={() => setIsEdit(true)} />
          </Flex>
      )
      }
    </Activity>
  );
};

ShowTranscription.propTypes = {
};

export default ShowTranscription;
