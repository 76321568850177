import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Box } from 'rebass';
import { space, height } from 'styled-system';
import commentIcon from './svg/comment.svg';
import likeIcon from './svg/like.svg';
import { StoryActivityCount } from '../story-list-summary/styled';

export const StoryActivityWrap = styled.div`
  display: flex;
  justify-content: center;
  color: ${props => props.theme.colours.white};
  height: 92px;
  margin-left: auto;
  ${space};
  ${height};
`;

const StoryActivity = ({ 'like-count': likeCount, 'comment-count': commentCount }) => (
  <StoryActivityWrap height="auto">
    <StoryActivityCount>
      <img src={likeIcon} alt="likes" />
      <span>{likeCount}</span>
    </StoryActivityCount>
    <Separator />
    <StoryActivityCount>
      <img className="story-detail-header__activity-icon" src={commentIcon} alt="comments" />
      <span>{commentCount}</span>
    </StoryActivityCount>
  </StoryActivityWrap>
);

const Separator = () => (
  <Box mx={2} color="lightText">
    |
  </Box>
);

StoryActivity.propTypes = {
  'like-count': PropTypes.number,
  'comment-count': PropTypes.number,
};

StoryActivity.defaultProps = {
  'like-count': 0,
  'comment-count': 0,
};

export default StoryActivity;
