export const breakpoints = {
  xs: 0,
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
};

export const colours = {
  brand: '#7a69bf',
  brandSubtle: '#bfb3ff',
  brandSecondary: '#fdd243',
  accent: '#ffd326',
  accentSubtle: '#fff9e5',
  defaultText: '#333',
  mutedText: '#666',
  lightText: '#999',
  border: '#e6e6e6',
  background: '#f8f8f8',
  white: '#fff',
  black: '#000',
  photoGradientStart: 'rgba(0, 0, 0, 0.3)',
  photoGradientEnd: 'rgba(0, 0, 0, 0.7)',
  red: '#e74c3c',
};

export const fonts = {
  sanFrancisco: '"SF UI Text", "Roboto", sans-serif',
  quicksand: '"Quicksand", sans-serif',
};

export const shadows = {
  small: '0 2px 4px rgba(0, 0, 0, 0.15)',
};

export const borderRadii = {
  normal: '6px',
};

export const gutter = '20px';

export const fontSizes = {
  normal: '16px',
  xs: '12px',
};
