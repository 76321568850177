import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { withProps } from 'recompose';

export const Wrap = styled.div`
  padding: 12px;
  background-color: ${props => props.theme.colours.accentSubtle};
`;

export const InfoWrap = styled.div`
  display: flex;
  align-items: center;

  img {
    height: 40px;
    width: 40px;
  }
  p {
    text-align: left;
    margin: 0 auto 0 8px;
  }
`;

export const CtaWrap = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Button = withProps({ type: 'button' })(styled.button`
  font-family: ${props => props.theme.fonts.sanFrancisco};

  ${props =>
    props.primary &&
    css`
      font-weight: bold;
      color: ${props.theme.colours.brand};
    `};
`);
