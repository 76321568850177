import { FaSmile } from 'react-icons/fa';
import { Flex, Text, Heading } from 'rebass';
import React from 'react';

const EmptyNotifications = () => (
  <Flex alignItems="center" p={4} flexDirection="column" justifyContent="center">
    <Heading color="mutedText" mb={3}>
      All Clear!
    </Heading>
    <Text mb={3}>
      You don&apos;t have any notifications. We&apos;ll notify you here when there&apos;s any new
      activity :)
    </Text>
    <Text mb={3} as={FaSmile} fontSize={144} color="border" />
  </Flex>
);

export default EmptyNotifications;
