
import { useHasDrawer } from 'app/mobile-drawer/hooks';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Box, Heading, Text, Button } from 'rebass';
import { setSidebar } from 'ui/actions';
import { useIsSideBarVisible } from 'ui/hooks';
import storyListEmptyImg from '../assets/blank-page.png';

const StoryListEmptyMessage = ({ isMyStories }) => {
  const isSideBarVisible = useIsSideBarVisible();
  const hasDrawer = useHasDrawer();

  const dispatch = useDispatch();

  return (
    <Box pt={4} mx="auto" textAlign="center">
      <Heading color="mutedText" textAlign="center" mb={4}>
        {isMyStories
          ? 'This is the folder for all the Memoir you create.'
          : 'Select or create Memoir'}
      </Heading>

      {isMyStories && (
        <Box fontSize={3} css="max-width: 35em; text-align: center;" mb={4}>
          <Text mb={1}>
            Try it now — it’s easy! Tap the + button to start. (new paragraph after this)
          </Text>
          <Text mb={1}>
            Use some pictures already on your phone or computer. Maybe even add some captions
            <span role="img" aria-label="smiling face">
              😀
            </span>
            (new paragraph after this)
          </Text>
          <Text>I bet they&rsquo;d make a great memoir! </Text>
        </Box>
      )}
      <Box mx="auto" css="text-align: center;">
        <img src={storyListEmptyImg} alt="" />
      </Box>
      {hasDrawer && !isSideBarVisible && <Button
        mt={4}
        variant="primary"
        onClick={() => dispatch(setSidebar(true))}
      >
        Open Sidebar
      </Button>}
    </Box>
  );
};

StoryListEmptyMessage.propTypes = {
  isMyStories: PropTypes.bool.isRequired,
};

export default StoryListEmptyMessage;
