import { isEmpty } from 'lodash';
import React, { useMemo, createContext, useCallback, useState } from 'react';
import store from 'store';

export const CurrentCollectionContext = createContext({});

export const CurrentCollectionProvider = props => {
  const [currentCollection, setCurrentCollectionState] = useState(store.get('current_collection'));

  const [collectionsFilter, setCollectionFilterState] = useState(
    store.get('collections_filter') || ''
  );

  const setCurrentCollection = useCallback(
    params => {
      setCurrentCollectionState(params);
      store.set('current_collection', params);
    },
    [setCurrentCollectionState]
  );

  const resetCurrentCollection = useCallback(() => {
    store.set('current_collection', null);
    setCurrentCollection({});
  }, [setCurrentCollection]);

  const setCollectionsFilter = useCallback(
    filter => {
      setCollectionFilterState(filter);
      store.set('collections_filter', filter);
    },
    [setCollectionFilterState]
  );

  const value = useMemo(
    () => ({
      hasCurrentCollection: !isEmpty(currentCollection),
      currentCollection: currentCollection || {},
      setCurrentCollection,
      resetCurrentCollection,
      collectionsFilter,
      setCollectionsFilter,
    }),
    [
      collectionsFilter,
      currentCollection,
      resetCurrentCollection,
      setCollectionsFilter,
      setCurrentCollection,
    ]
  );

  return <CurrentCollectionContext.Provider {...props} value={value} />;
};
