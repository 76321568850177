import React, { useMemo, useState } from 'react';
import { get } from 'lodash';
import Modal from 'app/modal/v3';
import { useParams, useHistory } from 'react-router-dom';
import { Button, Text, Flex } from 'rebass';
import { usePostNewMeeting } from 'chats/hooks';
import { useStoryPagesSWR } from 'story-pages/api';

const useInProgressMeetingId = id => {
  const { data } = useStoryPagesSWR(id, {});

  return useMemo(() => {
    if (!data) return null;
    return get(data, 'data.data.relationships.meetings.data[0].id', null);
  }, [data]);
};

const NewMeetingPopup = () => {
  const { id } = useParams();
  const { push, replace } = useHistory();
  const [loading, setLoading] = useState(false);
  const postNewMeeting = usePostNewMeeting(id, 0);

  const inProgressMeetingId = useInProgressMeetingId(id);

  const startChat = async () => {
    setLoading(true);
    if (inProgressMeetingId) {
      return push(`/story/${id}/chats/${inProgressMeetingId}`);
    }
    const [payload] = await postNewMeeting(id);
    if (payload.data) {
      const meetingId = payload.data.result.meetings[0];
      return push(`/story/${id}/chats/${meetingId}`);
    }
    return false;
  };

  return (
    <Modal title="Start new Chat" close={{ onClose: () => replace(`/story/${id}`) }}>
      <Flex flexDirection="column" alignItems="center" p={3} bg="white">
        <Text mb={3}>Record a conversation and save it in your story!</Text>
        <Button type="button" variant="primary" onClick={startChat} disabled={loading}>
          Start Chat
        </Button>
      </Flex>
    </Modal>
  );
};

export default NewMeetingPopup;
