import { connect } from 'react-redux';

import { selectStoryImageEntities } from '../story-pages/selectors';

const StoryImages = ({ images, children }) => children({ images });

StoryImages.defaultProps = {
  images: [],
};

const mapStateToProps = (state, { id }) => ({
  images: selectStoryImageEntities(state, id),
});

export default connect(mapStateToProps)(StoryImages);
