import { Switch, Route } from 'react-router-dom';
import React from 'react';

import ChildOnboard from './child';
import CustomOnboard from './custom';
import FamilyOnboard from './family';
import MemoirsOnboard from './memoirs';
import MyStoriesOnboard from './my-stories';

const CollectionsOnboard = () => {
  return (
    <Switch>
      <Route component={CustomOnboard} path="/collections/onboard/custom" />
      <Route component={ChildOnboard} path="/collections/onboard/child-journals" />
      <Route component={FamilyOnboard} path="/collections/onboard/family-journals" />
      <Route component={MyStoriesOnboard} path="/collections/onboard/personal-journals" />
      <Route component={MemoirsOnboard} path="/collections/onboard/memoirs" />
    </Switch>
  );
};

export default CollectionsOnboard;
