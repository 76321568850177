import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Player from 'react-player';
import Lazyload from 'react-lazyload';
import { useEntity } from 'entities/hooks/index';
import { StoryPageContext } from 'story-pages/item/context';

const ExternalMedium = ({ id, height, isCarousel }) => {
  const { entity } = useEntity({ id, type: 'external-media' });
  const { currentMedia, setCurrentMedia } = useContext(StoryPageContext);

  return (
    <Lazyload height={height || (isCarousel && '400px')} offset={600} once>
      <Player
        url={entity['external-url']}
        width="100%"
        controls
        playing={currentMedia === entity.id}
        onPlay={() => setCurrentMedia(entity.id)}
      />
    </Lazyload>
  );
};

ExternalMedium.propTypes = {
  id: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  isCarousel: PropTypes.bool.isRequired,
};

export default ExternalMedium;
