import { FaCamera as CameraIcon } from 'react-icons/fa';
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import get from 'lodash/get';

import { StyledAvatar, Initials, EditOverlay } from './styled';
import { selectEntity } from '../../entities/selectors';

const getAvatarUrl = image => {
  const versionedAvatar = get(image, '["image-versions"][5].url', '');
  if (versionedAvatar) {
    return versionedAvatar;
  }

  return image.tmpAvatar || image['asset-url'] || '';
};

const getInitials = name => {
  if (!name) return '';
  const splitName = name.split(' ');
  return splitName
    .map((word, index) => {
      if (index === 0 || index === splitName.length - 1) {
        return word[0];
      }
      return '';
    })
    .join('');
};

const Avatar = ({ image, name, fallbackUrl, overflowCount, ...props }) => {
  const avatarUrl = useMemo(() => getAvatarUrl(image), [image]);
  const initials = useMemo(() => getInitials(name), [name]);

  if (avatarUrl || fallbackUrl) {
    const backgroundImage = `url(${avatarUrl || fallbackUrl})`;
    return (
      <StyledAvatar {...props} style={{ backgroundImage }}>
        {props.canEdit && (
          <EditOverlay>
            <CameraIcon size="30%" color="white" />
          </EditOverlay>
        )}
      </StyledAvatar>
    );
  }

  if (overflowCount) {
    return (
      <StyledAvatar {...props}>
        <Initials>{overflowCount}</Initials>
      </StyledAvatar>
    );
  }

  if (initials) {
    return (
      <StyledAvatar {...props}>
        {props.canEdit ? (
          <EditOverlay>
            <CameraIcon size="30%" color="white" />
          </EditOverlay>
        ) : (
          <Initials>{initials}</Initials>
        )}
      </StyledAvatar>
    );
  }

  return (
    <StyledAvatar {...props}>
      <Initials>
        <FontAwesome name="user" />
      </Initials>
    </StyledAvatar>
  );
};

const mapStateToProps = (state, { imageId }) => ({
  image: selectEntity({ state, type: 'images', id: imageId }),
});

Avatar.propTypes = {
  name: PropTypes.string,
  image: PropTypes.shape({}),
  border: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fallbackUrl: PropTypes.string,
  canEdit: PropTypes.bool,
  overflowCount: PropTypes.string,
};

Avatar.defaultProps = {
  name: '',
  image: {},
  size: 36,
  border: false,
  fallbackUrl: '',
  canEdit: false,
  overflowCount: '',
};

export default connect(mapStateToProps)(Avatar);
