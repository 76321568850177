import React from 'react';
import PropTypes from 'prop-types';
import Player from './player';

const AudioMedium = ({ id, editing }) => <Player id={id} editing={editing} />;

AudioMedium.propTypes = {
  id: PropTypes.string.isRequired,
  editing: PropTypes.bool,
};

AudioMedium.defaultProps = { editing: false };
export default AudioMedium;
