

import { Button, Card, Flex } from 'rebass';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const SegmentedNav = ({
  items,
  bg,
  color,
  border,
  itemProps,
  initialSelected,
  selectedTab,
  ...props
}) => {
  const [currentTab, setCurrentTab] = useState(initialSelected || items[0].label);

  useEffect(() => {
    if (selectedTab) {
      setCurrentTab(selectedTab);
    }
  }, [selectedTab]);

  return (
    <Card
      as={Flex}
      border="1px solid"
      borderColor={color}
      bg={bg}
      borderRadius={8}
      m={2}
      justifyContent="stretch"
      css="overflow: hidden;"
      {...props}
    >
      {items.map(({ label, onClick }, i) => (
        <Button
          onClick={e => {
            setCurrentTab(label);
            onClick(e);
          }}
          color={currentTab === label ? bg : color}
          bg={currentTab === label ? color : bg}
          type="button"
          flex="1"
          fontWeight="normal"
          borderRadius={0}
          borderColor={color}
          borderRight={i === items.length - 1 ? 'none' : border}
          key={label}
          {...itemProps}
        >
          {label}
        </Button>
      ))}
    </Card>
  );
};

SegmentedNav.defaultProps = {
  bg: 'white',
  color: 'brand',
  border: '1px solid',
  itemProps: {},
  initialSelected: '',
  selectedTab: '',
};

SegmentedNav.propTypes = {
  bg: PropTypes.string,
  color: PropTypes.string,
  border: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string.isRequired, onClick: PropTypes.func.isRequired })
  ).isRequired,
  itemProps: PropTypes.shape({}),
  initialSelected: PropTypes.string,
  selectedTab: PropTypes.string,
};

export default SegmentedNav;
