import PropTypes from 'prop-types';
import React from 'react';

import { Label, CountIndicator, LinkInner, Button } from './styled';
import { useLocation } from '../../hooks';
import { useHistory } from 'react-router-dom';

const NavigationItem = ({ path, active, handleClick, label, indicatorCount, children }) => {
  const { push } = useHistory();
  const { pathname } = useLocation();

  return (
    <Button
      onClick={() => {
        handleClick();
        if (path) {
          push(path);
        }
      }}
    >
      {!!indicatorCount && <CountIndicator>{indicatorCount}</CountIndicator>}
      <LinkInner active={pathname === path || active}>
        {children}
        {label && <Label>{label}</Label>}
      </LinkInner>
    </Button>
  );
};

NavigationItem.defaultProps = {
  active: false,
  path: null,
  label: '',
  indicatorCount: null,
  children: null,
  handleClick: () => { },
};

NavigationItem.propTypes = {
  indicatorCount: PropTypes.number,
  path: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.node,
  handleClick: PropTypes.func,
  active: PropTypes.bool,
};

export default NavigationItem;
