import styled from '@emotion/styled';
import { withProps } from 'recompose';

export const ManageTags = withProps({ type: 'button' })(styled.button`
  display: flex;
  align-items: center;
  border-radius: ${props => props.theme.borderRadii.large};
  background-color: rgba(0, 0, 0, 0.15);
  padding: 8px;
`);

export const BackToTagsButton = withProps({ type: 'button' })(styled.button`
  display: flex;
  align-items: center;
  margin-right: auto;
  padding: 0 0 8px 0;
`);

export const EditTagInput = styled.input`
  font-size: 16px;
  border: none;
  margin: 1em 0;
`;
