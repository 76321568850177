import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { Text } from 'rebass';
import { FaQuestionCircle } from 'react-icons/fa';
import { useHowDoICollectionStoriesActivitiesCount } from './hooks';
import SidebarIconLink from './sidebar-icon-link';
import CircleIcon from '../../icons/circle-icon';

const HowDoILink = ({ onClick, label }) => {
  const { pathname } = useLocation();
  const badgeCount = useHowDoICollectionStoriesActivitiesCount();
  return (
    <SidebarIconLink
      onClick={onClick}
      isSelected={pathname.includes('how-do-i')}
      label={label}
      unreadStoryCount={badgeCount}
    >
      <CircleIcon size={40} bg="drawerSubtle" color="white">
        <Text size={24} as={FaQuestionCircle} />
      </CircleIcon>
    </SidebarIconLink>
  );
};

HowDoILink.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default HowDoILink;
