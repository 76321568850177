import { useParams } from 'react-router-dom';
import React, { useLayoutEffect, useCallback, useEffect, useState } from 'react';
import store from 'store';
import { get } from 'lodash';
import { Flex, Text, Box, Image } from 'rebass';
import useSWR from 'swr';
import { DateTime } from 'luxon';
import { createStory } from 'stories/actions';
import { useDispatch } from 'react-redux';
import successBg from './assets/Success.png';

import { getResourcePath } from 'collections/api';
import Step1 from './Step1';
import useEntity from 'entities/hooks/use-entity';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import { getStory } from 'stories/api';
import Wrapper from './Wrapper';
// import Loading from 'app/loading/index';

const TellAStory = () => {
    const { memoirId } = useParams();

    const path = getResourcePath({
        ...{ id: memoirId, type: 'memoir_story_requests' },
    });
    const dispatch = useDispatch();

    const { data } = useSWR(path);

    const attributes = data?.data?.data?.attributes
    const { memoirStoryRequest } = useEntity({ type: 'memoir-story-requests', id: attributes?.id })
    const [currentStoryId, setCurrentStoryId] = useState(store.get(`memoir_${memoirId}_storyId`) || null)
    const { member } = useEntity(memoirStoryRequest?.['creator'])

    const memoirDetailId = memoirStoryRequest?.memoir?.id
    const [step, setStep] = useState(1)
    const [storyDetails, setStoryDetails] = useState(null)

    const getStoryIdFromPayload = payload => get(payload, 'data.result.stories[0]', '');

    const getSotryDetails = async (storyId) => {
        const payload = await getStory(storyId)
        const tempStoryDetails = payload?.data?.entities?.stories[storyId];
        setStoryDetails(tempStoryDetails)
    }

    const onStepUp = () => {
        setStep(step + 1)
        getSotryDetails(currentStoryId)
    }
    const onStepDown = () => setStep(step - 1)

    useEffect(() => {
        if (currentStoryId) getSotryDetails(currentStoryId)
    }, [currentStoryId, store.get(`memoir_${memoirId}_storyId`)])

    const createNewStory = useCallback(() => {
        const chronicleAttrs = {};

        if (memoirDetailId) {
            chronicleAttrs['journal-id'] = memoirDetailId;
        }
        if (memoirId) {
            chronicleAttrs['memoir-story-request-id'] = memoirId
        }
        chronicleAttrs['began-on'] = DateTime.local();

        dispatch(createStory(chronicleAttrs, false)).then(async ({ payload }) => {
            let storyId = getStoryIdFromPayload(payload);
            store.set(`memoir_${memoirId}_storyId`, storyId)
            setCurrentStoryId(storyId)
        })
    }, [memoirId, memoirDetailId, dispatch]);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (memoirDetailId && !store.get(`memoir_${memoirId}_storyId`)) {
            createNewStory()
        }
    }, [memoirDetailId, memoirId])

    // if (isValidating) return <Loading />;
    const subjectName = member?.['first-name']
    if (step === 'success')
        return <Wrapper p={2} justifyContent="center" alignItems="left">
            <Image src={successBg} />
            <Box mt={["-170px", "-200px"]} bg="#f8f8f8" height="200px" pt={4}>
                <Text sx={{
                    textAlign: "center",
                    fontFamily: "Zilla Slab",
                    fontSize: "30px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "32px",
                }}
                >
                    Awesome. We’ve let {subjectName} know you told this great story.
                </Text>
                <Text
                    mt={5}
                    sx={{
                        color: 'rgba(0, 0, 0, 0.70)',
                        textAlign: "center",
                        fontFamily: "DM Sans",
                        fontSize: "20px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "32px",
                    }}>
                    See you again soon!
                </Text>
            </Box>
        </Wrapper>

    if (step === 5)
        return <Step5
            story={storyDetails}
            percentComplete={95}
            onStepUp={() => setStep('success')}
            onStepDown={onStepDown}
            attributes={memoirStoryRequest}
            storyId={currentStoryId}
        />

    if (step === 4)
        return <Step4
            story={storyDetails}
            percentComplete={75}
            onStepUp={onStepUp}
            onStepDown={onStepDown}
            attributes={memoirStoryRequest}
            storyId={currentStoryId}
        />

    if (step === 3)
        return <Step3 percentComplete={50} onStepUp={onStepUp} onStepDown={onStepDown} attributes={memoirStoryRequest} storyId={currentStoryId} />

    if (step === 2)
        return <Step2 percentComplete={25} onStepUp={onStepUp} onStepDown={onStepDown} attributes={memoirStoryRequest} storyId={currentStoryId} />

    if (step === 1)
        return <Step1 subjectName={subjectName} onStepUp={onStepUp} attributes={memoirStoryRequest} storyId={currentStoryId} />

    return null
};

export default TellAStory;
