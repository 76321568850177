import React from 'react';
import { useMatch } from 'app/hooks/index';
import StoryOptions from 'stories/options/index';
import { useHistory } from 'react-router-dom';

const CollectionStoryOptions = () => {
  const { replace } = useHistory();
  const {
    params: { id, type, storyId },
  } = useMatch();
  return <StoryOptions storyId={storyId} onClose={() => replace(`/${type}/${id}`)} />;
};

CollectionStoryOptions.propTypes = {};

export default CollectionStoryOptions;
