import { titleize } from 'inflected';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from '@emotion/styled';

import { EditTagInput } from './styled';
import { addStoryTag, getStory } from '../../../stories/api';
import { postCollectionTag, getCollection } from '../../api';
import { useEntity, useEntityActionCreators } from '../../../entities/hooks';
import { useValue } from '../../../app/hooks';
import addTagSvg from '../../../stories/tags/assets/add-tag.svg';
import jsonApiReferenceShape from '../../../app/shapes/json-api-reference-shape';

const NewTag = ({ setNewTagDirty, collection, storyId, onCreate }) => {
  const [value, set, reset] = useValue('');
  const [submitting, setSubmitting] = useState(false);
  const { receive } = useEntityActionCreators();
  const { entity } = useEntity(collection);
  const tagNames = entity['tag-names'] || [];

  if (value === '') {
    setNewTagDirty(false);
  } else {
    setNewTagDirty(true);
  }

  const handleSubmit = async () => {
    if (storyId) {
      await addStoryTag({ journalId: collection.id, storyId, name: value });
      await getStory(storyId).then(payload => receive({ payload }));
      await getCollection(collection).then(payload => receive({ payload }));
      onCreate(titleize(value));
      reset();
      setSubmitting(false);
      return;
    }

    postCollectionTag({ ...collection, attributes: { name: value } })
      .then(() => getCollection(collection))
      .then(async payload => {
        receive({ payload });
        if (storyId) {
          const storyRes = await getStory(storyId);
          receive({ payload: storyRes });
        }
        reset();
        setSubmitting(false);
      });
  };

  return (
    <AddTagWrap
      onSubmit={e => {
        e.preventDefault();
        setSubmitting(true);
        handleSubmit();
      }}
    >
      <EditTagInput
        type="text"
        value={value}
        onChange={e => set(e.target.value)}
        placeholder="Add a new chapter"
      />
      {tagNames.includes(value) && <p>Tag already exists</p>}
      {!tagNames.includes(value) && (
        <button type="submit" disabled={submitting || tagNames.includes(value) || !value}>
          <img src={addTagSvg} alt="add tag" style={{ width: '36px', height: '36px' }} />
          <span style={{ width: 'max-content' }}>Add Chapter</span>
        </button>
      )}
    </AddTagWrap>
  );
};

NewTag.propTypes = {
  setNewTagDirty: PropTypes.string,
  collection: jsonApiReferenceShape.isRequired,
  storyId: PropTypes.string,
  onCreate: PropTypes.func,
};

NewTag.defaultProps = {
  onCreate: () => { },
  storyId: '',
  setNewTagDirty: () => { },
};

const AddTagWrap = styled.form`
  display: flex;
  margin-bottom: 10px;
  padding: 10px 10px 10px 20px;
  background: ${props => props.theme.colours.white};
  input {
    font-size: 16px;
    margin-right: auto;
    border: none;
    width: 100%;
    outline: none;
  }
  button {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    transition: opacity 0.175s ease;
    &:disabled {
      opacity: 0.5;
    }
  }
  p {
    color: ${props => props.theme.colours.red};
    margin-right: 4px;
  }
  span {
    font-size: 14px;
    width: 53px;
    color: ${props => props.theme.colours.brand};
  }
`;

export default NewTag;
