

import { Button, Text } from 'rebass';
import PropTypes from 'prop-types';
import React from 'react';

import { useEntity } from '../../../../entities/hooks';
import MemberAvatar from '../../../../members/avatar';
import MemberName from '../../../../members/name';

const StoryRequestInvitee = ({ share, onClick, selected }) => {
  const { entity, currentMemberId } = useEntity(share);

  if (!entity['with-contact'] || entity['with-contact'].id === currentMemberId) return false;

  return (
    <Button
      variant="transparent"
      onClick={() => onClick(entity['with-contact'].id)}
      type="button"
      css="max-width: 80px;"
      mr={2}
    >
      <MemberAvatar
        id={entity['with-contact'].id}
        size={60}
        mx="auto"
        my={2}
        border="3px solid"
        borderColor={selected ? 'brand' : 'background'}
      />
      <Text textAlign="center" pt={0} fontSize={0} color="mutedText">
        <MemberName id={entity['with-contact'].id} />
      </Text>
    </Button>
  );
};

StoryRequestInvitee.propTypes = {
  share: PropTypes.shape({
    type: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default StoryRequestInvitee;
