import { FaCamera as CameraIcon } from 'react-icons/fa';
import { Flex } from 'rebass';
import { height, width, borderRadius } from 'styled-system';
import FontAwesome from 'react-fontawesome';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import posed from 'react-pose';
import styled from '@emotion/styled';

const PosedBox = posed.div({
  loading: {
    opacity: 0.5,
  },
});

const AvatarPreviewWrap = styled(PosedBox)`
  background: ${props => props.theme.colours.blueBackground};
  overflow: hidden;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  ${borderRadius};
  ${height};
  ${width};

  img {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
    object-position: center;
  }

  svg {
    opacity: 0.75;
  }

  p {
    text-align: center;
    margin: 0 8px;
  }
`;

const EditButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  background: ${props => props.theme.colours.white};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  color: ${props => props.theme.colours.mutedText};
  z-index: 1;
`;

const AvatarPreviewImage = ({ src, circular, iconSize, hasEditButton, ...props }) => {
  const [imgSrc, setImgSrc] = useState(src);
  const mounted = useRef(false);

  useEffect(() => {
    if (src instanceof File || src instanceof Blob) {
      if (!mounted.current) {
        setImgSrc(URL.createObjectURL(src));
      }
    }
    mounted.current = true;
    return () => {
      if (src instanceof File || src instanceof Blob) {
        URL.revokeObjectURL(imgSrc);
      }
    };
  }, [imgSrc, src]);

  return (
    <>
      {hasEditButton && (
        <EditButton>
          <FontAwesome name="pencil" />
        </EditButton>
      )}
      <AvatarPreviewWrap {...props}>
        {imgSrc ? (
          <img src={imgSrc} alt="Preview" />
        ) : (
          <Flex flexDirection="column" alignItems="center" color="white">
            <CameraIcon size={iconSize} />
            <p>{circular ? 'Add an Avatar Photo' : 'Upload Photo'}</p>
          </Flex>
        )}
      </AvatarPreviewWrap>
    </>
  );
};

AvatarPreviewImage.propTypes = {
  src: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(File),
    PropTypes.instanceOf(Blob),
  ]),
  circular: PropTypes.bool.isRequired,
  iconSize: PropTypes.number,
  hasEditButton: PropTypes.bool.isRequired,
};

AvatarPreviewImage.defaultProps = {
  src: '',
  iconSize: 20,
};

export default AvatarPreviewImage;
