import * as types from './types';

const initialState = {
  currentTag: '',
};

export default (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case types.SET_CURRENT_COLLECTION_TAG:
      return {
        ...state,
        currentTag: action.currentTag,
      };
    default:
      return state;
  }
};
