import styled from '@emotion/styled';
import { withProps } from 'recompose';

export const OptionsButton = withProps({ type: 'button' })(styled.button`
  display: grid;
  justify-content: center;
  align-items: center;
  position: absolute;
  margin-left: 8px;
  margin-bottom: 4px;
  height: 40px;
  width: 40px;
  top: 4px;
  right: 4px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  padding: 0;
  svg {
    width: 100%;
  }
`);

export default OptionsButton;
