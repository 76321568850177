
import { Flex } from 'rebass';
import React from 'react';
import { FaPlay } from 'react-icons/fa';

const PlayButton = props => (
  <Flex
    bg="rgba(191, 179, 255, 0.7)"
    color="#fff"
    alignItems="center"
    justifyContent="center"
    p="15px 14px 15px 16px"
    css="border-radius: 50%;"
    {...props}
  >
    <FaPlay />
  </Flex>
);

export default PlayButton;
