import { Route, Switch, useParams } from 'react-router-dom';
import React from 'react';
import { useStoryPagesSWR } from 'story-pages/api';

import NewStoryChat from 'stories/chats/new-story-chat';

import Loading from '../../app/loading';
import NewStoryCover from './cover';
import NewStoryPages from './pages';
import SelectCollection from './select-collection';
import StoryShares from '../../story-shares';
import TextForm from '../../story-media/text-form';

// import TagsModal from './select-collection/tags-modal';

const NewStory = () => {
  const { id } = useParams();
  const { data, revalidate } = useStoryPagesSWR(id, false);

  if (!data) return <Loading />;

  return (
    <>
      <Switch>
        <Route
          path="/stories/new/:id/collection/shares"
          render={routeProps => (
            <StoryShares
              storyId={id}
              onBack={() => routeProps.history.replace(`/stories/new/${id}/collection`)}
              {...routeProps}
            />
          )}
        />
      </Switch>
      <Switch>
        <Route path="/stories/new/:id/chats/:meetingId" component={NewStoryChat} />
        <Route path="/stories/new/:id/collection" component={SelectCollection} />
        <Route path="/stories/new/:id/cover" component={NewStoryCover} />
        <Route path="/stories/new/:id" component={NewStoryPages} />
      </Switch>
      <Route path="/stories/new/:id/edit/text/:mediumId?">
        <TextForm onClose={revalidate} />
      </Route>
    </>
  );
};

export default NewStory;
