import useSWR from 'swr';
import { get, getSWR, post, patch, destroy } from '../utils/api';

export const getMember = id => get(`/v2/members/${id}`);
export const updateMember = (id, body) => patch(`/v2/members/${id}`, body);

export const getMemberSubscription = () => get('/v3/my/subscriptions');
export const getCommerceProducts = product =>
  get(`/v3/commerce/gateways/stripe/products/${product}`);

export const uploadMemberAvatar = async (memberId, filename) =>
  post('/v2/media', {
    type: 'Image',
    data: {
      attributes: {
        type: 'Image',
        'owner-type': 'Member',
        'owner-id': memberId,
        filename,
      },
    },
  });

export const getMemberIdentifiers = memberId => get(`/v2/members/${memberId}/identifiers`);
export const destroyIdentifier = identifierId => destroy(`/v2/identifiers/${identifierId}`);
export const getPublicMember = memberShareId => get(`/v2/public/members/${memberShareId}`);
export const getMemberCollection = () => get('/v3/my/collection');
const interestingMemberEvents = [
  'linked_media_interaction',
  'add_text_interaction',
  'add_img_video_interaction',
  'shared_story_interaction',
  'get_started_interaction',
  'click_add_friend_interaction',
  'child_journal_add_story_prompt',
  'child_journal_add_collaborator_prompt',
  'child_journal_dismissed_getting_started',
  'child_journal_copy_public_link_interaction',
  'member_introduction_story_prompt_interaction',
  'member_viewed_sample_story',
  'member_first_story_prompt_interaction',
  'member_add_friend_prompt_interaction',
  'member_chronicles_prompt_interaction',
  'member_viewed_chronicles_page',
  'member_dismissed_getting_started',
  'member_dismissed_faq_banner_web',
  'member_clicked_faq_link_web',
];

export const getMemberEvents = () =>
  get(`/v2/me/tracking_events?event_topic_names=${interestingMemberEvents.toString()}`);
export const postMemberEvent = (eventType, attributes = {}) => {
  const body =
    (!!Object.keys(attributes).length && {
      data: {
        attributes,
      },
    }) ||
    {};
  return post(`/v2/me/tracking_events?event_topic_name=${eventType}`, body);
};

// SWR
export const useGetMemberCollectionSWR = ({ notMine } = { notMine: true }) => {
  const path = `v3/my/collection?not_mine=${notMine}&page=1&per=10`;
  return useSWR(path);
};

// export const useGetCurrentMemberSWR = () =>
export const useGetMemberSWR = (memberId, opts = {}) =>
  useSWR(`/v2/members/${memberId}`, getSWR, opts);
export const useMemberEventsSWR = () =>
  useSWR(`/v2/me/tracking_events?event_topic_names=${interestingMemberEvents.toString()}`, getSWR, {
    revalidateOnFocus: false,
  });

export const getMemberConnections = memberId => get(`/v2/members/${memberId}/connections`);
export const useMemberConnectionsSWR = ({ memberId }) =>
  useSWR(`/v2/members/${memberId}/connections`, getSWR, {
    revalidateOnFocus: false,
  });

export const updateMemberEmailNotificationSettings = (id, notify = false) =>
  patch(`/v2/members/${id}/settings`, { data: { attributes: { 'email-notifications': notify } } });
