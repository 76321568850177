import { Formik } from 'formik';
import { object, string } from 'yup';
import React, { useEffect } from 'react';
import { trigger } from 'swr';

import { postCustom, destroyCustom } from '../api';
import { prepareAttrs } from '../../utils/api';
import { useEntity, useEntityActionCreators } from '../../entities/hooks';
import { useMatch } from '../../app/hooks';
import { useHistory } from 'react-router-dom';
import FullPageForm from '../../app/full-page-form';
import NewCollectionName from '../../collections/new/name';

const CustomName = () => {
  const { params } = useMatch();
  const { push } = useHistory();
  const { entity: custom } = useEntity({ ...params });
  const { receive, receiveDeletion } = useEntityActionCreators();
  const initialValues = {
    'name-noac': (custom && custom.name) || '',
  };
  const validationSchema = object().shape({
    'name-noac': string().required(),
  });
  const isInitialValid = validationSchema.isValidSync(initialValues);

  useEffect(() => {
    return () => trigger('/v3/my/chronicle_collection');
  }, []);

  return (
    <FullPageForm
      onExitClick={() => {
        if (custom && custom.id) {
          destroyCustom(custom.id)
            .then(() => receiveDeletion({ entityType: 'other-journals', id: custom.id }))
            .then(() => push('/my-stories'));
        } else {
          push('/my-stories');
        }
      }}
      title="Create a Memoir"
    >
      <Formik
        {...{ isInitialValid, initialValues, validationSchema }}
        onSubmit={async values => {
          const attributes = prepareAttrs(values);
          const body = {
            data: {
              attributes,
            },
          };

          // POST custom
          const customRes = await postCustom(body);
          const [id] = customRes.data.result['other-journals'];
          receive({ payload: customRes });
          push(`/custom/${id}`);
        }}
        render={props => (
          <NewCollectionName label="Name of the Memoir" attr="name-noac" {...props} />
        )}
      />
    </FullPageForm>
  );
};

export default CustomName;
