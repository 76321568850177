import React, { createContext, useMemo, useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';

export const TagContext = createContext({});

export const TagContextProvider = props => {
  const [current, setCurrent] = useState('');
  const match = useRouteMatch('/:type/:id');
  const params = match && match.params;
  const type = params && params.type;
  const shouldShowMilestones = type === 'memoirs';
  const [showMilestones, setShowMilestones] = useState(shouldShowMilestones);

  useEffect(() => {
    setShowMilestones(shouldShowMilestones);
  }, [shouldShowMilestones]);

  const value = useMemo(() => {
    return {
      current,
      setCurrent,
      showMilestones,
      setShowMilestones,
    };
  }, [current, showMilestones]);

  return <TagContext.Provider value={value} {...props} />;
};
