import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text } from 'rebass';
import Modal from 'app/modal/v3/index';

const StoryShareInfo = ({ onBack }) => (
  <Modal title="Story Shares" back={{ onBack }}>
    <Box p={4}>
      <Text pb={2}>
        All Memoir participants will be able to see this Story automatically.
      </Text>

      <Text pb={2}>
        You can also share a view-only version of this Story with non-participants. They will be able to view the Story but not be able to comment or like.
      </Text>

      <Text pb={2}>
        If you would like to add someone as a participant, click on the Memoir and invite them via that link
      </Text>
    </Box>
  </Modal>
);
StoryShareInfo.propTypes = {
  onBack: PropTypes.func.isRequired,
};

export default StoryShareInfo;
