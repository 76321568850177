import React from 'react';
import PropTypes from 'prop-types';
import { colours } from '../../app/styles/theme';

const EllipsisIcon = ({ dark }) => (
  <svg width="25" height="6" viewBox="0 0 25 6">
    <g fill="none">
      <rect y="-10" width="25" height="25" />
      <path
        d="M2.9 0C1.3 0 0 1.3 0 2.9 0 4.5 1.3 5.8 2.9 5.8 4.5 5.8 5.8 4.5 5.8 2.9 5.8 1.3 4.5 0 2.9 0ZM12.5 0C10.9 0 9.6 1.3 9.6 2.9 9.6 4.5 10.9 5.8 12.5 5.8 14.1 5.8 15.4 4.5 15.4 2.9 15.4 1.3 14.1 0 12.5 0ZM22.1 0C20.5 0 19.2 1.3 19.2 2.9 19.2 4.5 20.5 5.8 22.1 5.8 23.7 5.8 25 4.5 25 2.9 25 1.3 23.7 0 22.1 0Z"
        fill={dark ? colours.defaultText : colours.white}
      />
    </g>
  </svg>
);

EllipsisIcon.propTypes = {
  dark: PropTypes.bool,
};

EllipsisIcon.defaultProps = {
  dark: false,
};

export default EllipsisIcon;
