// export context
// export popup component

import { get } from 'lodash';
import React, { useContext, createContext, useCallback, useMemo, useState } from 'react';
import { Flex, Heading, Text, Button } from 'rebass';
import Modal from '../modal/v2';
import Paywall from '../paywall';

export const ServerError = createContext({});

export const ServerErrorProvider = props => {
  const [error, setErrorState] = useState(null);

  const setError = useCallback(err => {
    setErrorState(err);
  }, []);

  const value = useMemo(() => ({ error, setError }), [error, setError]);
  return <ServerError.Provider value={value} {...props} />;
};

export const ServerErrorPopup = () => {
  const { error, setError } = useContext(ServerError);

  const status = get(error, 'response.status');

  const { title, body, buttonLabel, buttonAction } = useMemo(() => {
    if (error && status !== 402) {
      const errors = get(error, 'response.data.errors', []);
      const firstError = errors[0] || {};
      return {
        title: firstError.title,
        body: firstError.detail,
      };
    }
    return {};
  }, [error, status]);

  if (error && status === 500) return false;

  if (error && status === 402)
    return (
      <Paywall
        onClose={() => {
          setError(null);
        }}
        errorData={error.response.data}
      />
    );

  if (error)
    return (
      <Modal closeButton onClose={() => setError(null)} aboveTooltip>
        <Flex flexDirection="column" p={3} alignItems="center">
          <Heading>{title}</Heading>
          <Text>{body}</Text>
          {buttonLabel && (
            <Button type="button" variant="primary" onClick={buttonAction}>
              {buttonLabel}
            </Button>
          )}
          <Button type="button" variant="muted" onClick={() => setError(null)}>
            Dismiss
          </Button>
        </Flex>
      </Modal>
    );

  return false;
};
