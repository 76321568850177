import { Box, Flex } from 'rebass';
import { DateTime } from 'luxon';
import { Field, Formik } from 'formik';
import { connect } from 'react-redux';
import { isEmpty, isString, isEqual, isObject } from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import fileExtension from 'file-extension';
import store from 'store';

import { CurrentCollectionContext } from '../../../collections/current-collection-context';
import { bodyWithLockVersion } from '../../../app/form/helper';
import { createStoryCoverMedium } from '../../../story-media/actions';
import { updateStory } from '../../actions';
import { useEntity } from '../../../entities/hooks';
import { useHistory, useMatch } from '../../../app/hooks';
import { useStoryImages, useStoryWithJournalId } from '../../hooks';
import CoverPicker from './picker';
import Main from '../main';
import NewStoryHeader from '../header';
import TextField from '../../../app/form/formik/text';

const getTitle = (title = '', dateTitle, beganOnDate) => {
  if (title !== '') return title;
  const beganOnTitle = DateTime.fromISO(beganOnDate).toLocaleString(DateTime.DATE_FULL);
  if (beganOnTitle !== dateTitle) return beganOnTitle;
  return dateTitle;
};

const NewStoryCover = props => {
  const {
    params: { id },
  } = useMatch();
  const { replace } = useHistory();
  const images = useStoryImages({ id });
  const { entity } = useEntity({ type: 'stories', id });
  const story = useStoryWithJournalId(entity);
  const { currentCollection } = useContext(CurrentCollectionContext);

  const getNextRoute = () => {
    if (isEmpty(currentCollection)) {
      replace(`/stories/new/${id}/collection`);
    } else {
      replace(`/story/${id}`);
    }
  };

  const initialTitle =
    DateTime.local().toLocaleString(DateTime.DATE_FULL) === story.title ? '' : story.title;
  const persistedCover = !isEmpty(story['cover-image']) && story['cover-image'];
  const initialCover = persistedCover || (images[0] && { ...images[0], type: 'images' }) || null;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        title: initialTitle,
        cover: initialCover,
        'journal-id': story['journal-id'],
      }}
      onSubmit={async ({ title, cover, ...values }) => {
        const body = bodyWithLockVersion(story, {
          title: getTitle(title, story.title, story['began-on']),
          ...values,
        });

        await props.updateStory(id, body);
        let fileBlob = null;
        if (isString(cover)) {
          fileBlob = await fetch(cover).then(res => res.blob());
          fileBlob.name = 'story-cover.png';
        }
        if (isObject(cover) && !isEqual(cover, persistedCover)) {
          fileBlob = await fetch(cover['asset-url']).then(res => res.blob());
          const ext = fileExtension(cover['asset-url']);
          fileBlob.name = `story-cover.${ext}`;
        }
        if (fileBlob) {
          await props.createStoryCoverMedium(id, fileBlob);
        }
        if (store.get('requestResponse') === 'true') {
          store.remove('requestResponse');
        }
        getNextRoute();
      }}
    >
      {({ submitForm }) => (
        <>
          <NewStoryHeader
            onBack={() => replace(`/stories/new/${id}`)}
            title="Add a Cover"
            onNext={submitForm}
          />
          <Main mx={[2, 0]}>
            <Flex justifyContent="center">
              <Box flex="0 1 600px" mx={[3, 0]}>
                <Field
                  name="cover"
                  component={CoverPicker}
                  storyId={id}
                  label="Preview"
                  showPreview
                />
                <Field
                  name="title"
                  component={TextField}
                  label="Title"
                  mb={3}
                  width="100%"
                  placeholder="Tap to enter title"
                />
                <Field
                  name="cover"
                  component={CoverPicker}
                  storyId={id}
                  label="Select a cover photo"
                  showThumbnails
                />
              </Box>
            </Flex>
          </Main>
        </>
      )}
    </Formik>
  );
};

NewStoryCover.propTypes = {
  createStoryCoverMedium: PropTypes.func.isRequired,
  updateStory: PropTypes.func.isRequired,
};

export default connect(null, { createStoryCoverMedium, updateStory })(NewStoryCover);
