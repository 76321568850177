import styled from '@emotion/styled';
import { space, layout } from 'styled-system';

import { css } from '@emotion/core';

const headerFontFamily = props =>
  css`
    color: ${props.theme.fonts.sanFrancisco};
  `;

export const TextPageHTML = styled.div``;

export const gTextPageHTML = styled.div`
  background: white;
  font-size: 17px;
  white-space: pre-wrap;
  padding: 60px 1em 1em;
  overflow-y: 'scroll';
  ${space};
  ${layout};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${headerFontFamily};
  }
  h1 {
    font-size: 34px;
  }
  h2 {
    font-size: 26px;
  }
  h3 {
    font-size: 24px;
  }
  h4 {
    font-size: 22px;
  }
  h5 {
    font-size: 20px;
  }
  h6 {
    font-size: 18px;
  }
`;

export const Text = styled.div`
  background: white;
  font-size: 17px;
  white-space: pre-wrap;
  padding: 60px 1em 1em;
  ${space};
  ${layout};

  ${props => props.theme.media.phone`padding: 60px 1.25em 1.25em;`};
  ${props => props.theme.media.tablet`padding: 60px 1.5em 1.5em;`};
`;

export const H1 = styled.h1`
  font-family: ${props => props.theme.fonts.sanFrancisco};
  font-size: 34px;
`;

export const H2 = styled.h2`
  font-family: ${props => props.theme.fonts.sanFrancisco};
  font-size: 26px;
`;

export const H3 = styled.h3`
  font-family: ${props => props.theme.fonts.sanFrancisco};
  font-size: 24px;
`;

export const H4 = styled.h4`
  font-family: ${props => props.theme.fonts.sanFrancisco};
  font-size: 22px;
`;

export const H5 = styled.h5`
  font-family: ${props => props.theme.fonts.sanFrancisco};
  font-size: 20px;
`;

export const H6 = styled.h6`
  font-family: ${props => props.theme.fonts.sanFrancisco};
  font-size: 18px;
  font-weight: normal;
  margin: 1em 0;
`;

export const Picture = styled.picture`
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
  overflow: hidden;
  filter: ${({ uploading }) => (uploading && 'grayscale(100%)') || 'none'};

  img {
    max-width: 600px;
    width: ${({ isCarousel }) => (isCarousel && 'unset') || '100%'};
    margin: 0 auto;
    height: ${({ isCarousel }) => (isCarousel && '100%') || 'unset'};
    display: block;
  }
`;

export const Wrap = styled.div`
  background: rgba(255, 255, 255, 0.1);
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
  overflow: hidden;
`;
