import { keyframes } from '@emotion/core';
import styled from '@emotion/styled';

const load8 = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

const LoadingIcon = styled.div`
  margin: auto;
  border-radius: 50%;
  width: 88px;
  height: 88px;
  font-size: 4px;
  font-weight: lighter;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(122, 105, 191, 0.2);
  border-right: 1.1em solid rgba(122, 105, 191, 0.2);
  border-bottom: 1.1em solid rgba(122, 105, 191, 0.2);
  border-left: 1.1em solid #7a69bf;
  transform: translateZ(0);
  animation: ${load8} 1.1s infinite linear;
`;

export default LoadingIcon;
