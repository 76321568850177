import PropTypes from 'prop-types';
import React from 'react';
import { uniqBy } from 'lodash';

import Loading from './loading';
import StoryPage from '../item';
import StoryPageCollection from '../collection';
import { StoryPageContextProvider } from '../item/context';

const StoryPageList = ({ storyId, editing }) => (
  <StoryPageCollection storyId={storyId}>
    {({ storyPages, loading }) => {
      if (!storyPages.length && loading) return <Loading />;

      if (!storyPages.length) return false;

      return uniqBy(storyPages, 'id').map(({ id }, index, arr) => {
        const { id: nextPageId } = arr[index + 1] || {};
        const { id: prevPageId } = arr[index - 1] || {};
        return (
          <StoryPageContextProvider key={id}>
            <StoryPage
              id={id}
              storyId={storyId}
              isLastPage={index === storyPages.length - 1}
              editing={editing}
              nextPageId={nextPageId}
              prevPageId={prevPageId}
              pageIndex={index}
            />
          </StoryPageContextProvider>
        );
      });
    }}
  </StoryPageCollection>
);

StoryPageList.propTypes = {
  storyId: PropTypes.string.isRequired,
  editing: PropTypes.bool,
};
StoryPageList.defaultProps = {
  editing: false,
};

export default StoryPageList;
