import { Formik } from 'formik';
import { object, string } from 'yup';
import React, { useCallback } from 'react';

import { patchMemoir, postPrivateEventDetails } from '../api';
import { prepareAttrs } from '../../utils/api';
import { useEntity, useEntityActionCreators } from '../../entities/hooks';
import { useHistory, useMatch } from '../../app/hooks';
import FullPageForm from '../../app/full-page-form';
import NewCollectionName from '../../collections/new/name';
import { useLocation } from 'react-router-dom';

const MemoirName = () => {
  const { push } = useHistory();
  const { params } = useMatch();
  const { entity } = useEntity(params);
  const { receive } = useEntityActionCreators();
  const { pathname } = useLocation();
  const isPublicRoute = pathname.includes('/get-started')
  const onBackClick = useCallback(() => {
    if (entity['subject-relation'] === 'me') {
      return push(`/memoirs/${isPublicRoute ? 'get-started' : 'new'}/${entity.id}/subject-type`);
    }
    return push(`/memoirs/${isPublicRoute ? 'get-started' : 'new'}/${entity.id}/subject-name`);
  }, [entity, push]);
  const initialValues = {
    name: entity['first-name'] ? `${entity['first-name']}'s story` : 'My story',
  };
  const validationSchema = object().shape({
    name: string(),
  });
  const isInitialValid = validationSchema.isValidSync(initialValues);

  return (
    <FullPageForm onBackClick={onBackClick} title="Create a Memoir">
      <Formik
        {...{ initialValues, validationSchema, isInitialValid }}
        onSubmit={async values => {
          const body = prepareAttrs({ ...values, 'lock-version': entity['lock-version'] });

          // PATCH child journal
          const payload = await patchMemoir({ id: entity.id, ...body });
          receive({ payload });
          push(`/memoirs/${isPublicRoute ? 'get-started' : 'new'}/${entity.id}/date-of-birth`);
          if (isPublicRoute) {
            postPrivateEventDetails('memoir_name_of_memoir_clicked_next', {
              'subject-type': 'Memoir',
              'subject-id': entity.id,
            });
          }
        }}
        render={formikProps => (
          <NewCollectionName
            attr="name"
            label="Name of the memoir"
            skipPath={`/memoirs/${isPublicRoute ? 'get-started' : 'new'}/${entity.id}/date-of-birth`}
            {...formikProps}
          />
        )}
      />
    </FullPageForm>
  );
};

export default MemoirName;
