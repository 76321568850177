import { colours } from '../styles/constants';

export const getBackground = ({
  primary, secondary, disabled, destructive, subtle,
}) => {
  if (disabled && primary) return colours.border;
  if (destructive) return colours.red;
  if (primary) return colours.brand;
  if (secondary) return colours.brandSecondary;
  if (subtle) return 'transparent';
  return colours.white;
};

export const getTextColour = ({
  primary, secondary, muted, disabled, destructive,
}) => {
  if (disabled && primary) return colours.lightText;
  if (primary || secondary || destructive) return colours.white;
  if (disabled) return colours.lightText;
  if (muted) return colours.mutedText;
  return colours.brand;
};

export const getBorderColour = ({
  primary, secondary, disabled, destructive, subtle,
}) => {
  if (disabled && primary) return colours.border;
  if (primary) return colours.brand;
  if (secondary) return colours.brandSecondary;
  if (destructive) return colours.red;
  if (subtle) return colours.brand;
  return colours.white;
};
