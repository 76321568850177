import { Text } from 'rebass';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import styled from '@emotion/styled';

import { useCollectionStoryRequests } from '../hooks';
import { useMatch } from '../../../../app/hooks';
import Loading from '../../../../app/loading';
import SegmentedNav from '../../../../app/segmented-nav';
import StoryRequestListItem from './item';

const RequestsItemsWrap = styled.div`
  height: 25vh;
  overflow: scroll;
`;

const StoryRequestsList = ({ loading }) => {
  const { params } = useMatch();
  // const relatedEntities = useRelatedEntities({ ...params, relatedType: 'story-request-shares' });
  const [currentTab, setCurrentTab] = useState('PENDING');
  const sentRequests = useCollectionStoryRequests(params);
  const pendingRequests = useCollectionStoryRequests(params, 'sharedWithMe');

  const currentRequestShares = useMemo(() => {
    switch (currentTab) {
      case 'PENDING':
        return pendingRequests;
      case 'SENT':
        return sentRequests;
      default:
        return [];
    }
  }, [currentTab, pendingRequests, sentRequests]);

  return (
    <>
      <SegmentedNav
        items={[
          {
            label: 'Received',
            onClick: () => setCurrentTab('PENDING'),
          },
          {
            label: 'Sent',
            onClick: () => setCurrentTab('SENT'),
          },
        ]}
      />
      <RequestsItemsWrap>
        {loading && <Loading local />}
        {!loading && isEmpty(currentRequestShares) && <Text p="10px 20px">No requests</Text>}
        {!isEmpty(currentRequestShares) &&
          currentRequestShares.map(shareParams => (
            <StoryRequestListItem
              storyRequestShareParams={shareParams}
              key={`request-list-${shareParams.id}`}
            />
          ))}
      </RequestsItemsWrap>
    </>
  );
};

StoryRequestsList.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default StoryRequestsList;
