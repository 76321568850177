import styled from '@emotion/styled';

export const Wrapper = styled.div`
  position: relative;
  margin-left: 0.25em;
`;

export const Icon = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  height: 1em;
  width: 1em;
`;
