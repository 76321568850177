import {
  Box, Button, Flex, Heading,
} from 'rebass';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import { Form, Submit, SkipLink } from '../form-styled';
import SmallText from '../small-text';
import OptionButton from './option-button';

const genderOptions = [
  { label: 'Boy', value: 'male' },
  { label: 'Girl', value: 'female' },
  { label: 'Prefer not to say', value: 'prefer_not_to_say' },
  { label: 'Don\'t know yet', value: 'other' },
];

const CollectionNewGender = ({
  label, skipPath, isValid, isSubmitting,
}) => (
  <Form>
    <Box
      width="100%"
      my="auto"
      pb={50}
    >
      <Heading
        textAlign="center"
        fontSize={24}
        fontWeight="normal"
        py={14}
      >
        {label}
      </Heading>
      <Flex
        flexDirection="column"
        alignItems="stretch"
      >
        <Field
          name="gender"
          render={({ field, form }) => (
            genderOptions.map(option => (
              <OptionButton
                key={`new-cj-${option.value}`}
                selected={field.value === option.value}
                onClick={() => form.setFieldValue(field.name, option.value)}
              >
                {option.label}
              </OptionButton>
            ))
          )}
        />
      </Flex>
      <SmallText
        textAlign="center"
        color="mutedText"
        fontSize={16}
        px={20}
        lineHeight="23px"
      >
        (We do not share this information with anyone.
        It is only used to make your journal experience better.)
      </SmallText>
    </Box>
    <Submit
      as={Button}
      variant="primary"
      fontSize={20}
      disabled={!isValid && !isSubmitting}
    >
      Next
    </Submit>
    <SkipLink to={skipPath}>Skip</SkipLink>
  </Form>
);

CollectionNewGender.propTypes = {
  skipPath: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isValid: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default CollectionNewGender;
