import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Heading, Text } from 'rebass';

const NewMilestoneHeader = ({ title, ctaText, isValid }) => {
  return (
    <Box py={2} pl={3} pr={2} mb={2}>
      <Button
        style={{ display: 'flex' }}
        ml="auto"
        type="submit"
        variant={isValid ? 'primary' : 'outline'}
        disabled={!isValid}
      >
        {ctaText}
      </Button>
      <Heading mb={2} color="defaultText" pr={1}>
        {title}
      </Heading>
      <Text pr={1}>
        Don&apos;t worry if you don&apos;t know all the details, you can always edit it later.
      </Text>
    </Box>
  );
};

NewMilestoneHeader.propTypes = {
  title: PropTypes.string.isRequired,
  ctaText: PropTypes.string.isRequired,
  isValid: PropTypes.bool.isRequired,
};

export default NewMilestoneHeader;
