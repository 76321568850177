export const getCreateCollectionTitle = collection => {
  const templateMessage = journal => `Create a ${journal} Memoir`;
  switch (collection) {
    case 'family-journals':
      return templateMessage('Family');
    case 'personal-journals':
      return templateMessage('Personal');
    case 'child-journals':
      return 'Create a Child Journal';
    default:
      return 'Create a Memoir';
  }
};

export default getCreateCollectionTitle;
