import { isEmpty, flatMap } from 'lodash';
import { useMemo } from 'react';

import { useEntities, useEntity } from '../../entities/hooks';

const useStoryImages = ({ id }) => {
  const { story } = useEntity({ id, type: 'stories' });
  const pages = useEntities('pages');
  const imageEnts = useEntities('images');

  return useMemo(() => {
    if (isEmpty(story)) return [];
    return flatMap(
      story.pages || [],
      pageParams => (pages[pageParams.id] && pages[pageParams.id].media) || []
    )
      .filter(mediumParams => mediumParams.type === 'images')
      .map(imageParams => imageEnts[imageParams.id]);
  }, [imageEnts, pages, story]);
};

export default useStoryImages;
