import { DateTime } from 'luxon';
import { useMemo } from 'react';

const useJsDate = (iso = '') => {
  const jsDate = useMemo(() => iso && DateTime.fromISO(iso).toJSDate(), [iso]);

  return jsDate;
};

export default useJsDate;
