import React from 'react';
import PropTypes from 'prop-types';
import store from 'store';
import Logout from './logout';
import AuthRestore from './restore';
import AuthMember from './member';
import AuthRedirect from './redirect';

const Auth = ({ children }) => {
  // allows devs to set token in env file
  if (process.env.REACT_APP_USER_AUTH_TOKEN) {
    store.set('auth_token', process.env.REACT_APP_USER_AUTH_TOKEN);
  }

  return (
    <AuthRestore>
      <AuthMember>
        <AuthRedirect>
          <Logout>{children}</Logout>
        </AuthRedirect>
      </AuthMember>
    </AuthRestore>
  );
};
Auth.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Auth;
