import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useUploadBatch = ({ status } = {}) => {
  const selectUploadBatch = useCallback(state => state.storyMedia.uploadBatch, []);
  const uploadBatch = useSelector(selectUploadBatch);

  return useMemo(() => {
    if (status) return uploadBatch.filter(item => item.status === status);
    return uploadBatch;
  }, [status, uploadBatch]);
};

export const useUploadBatchCounts = () => {
  const batch = useUploadBatch();

  return useMemo(
    () => ({
      total: batch.length,
      success: batch.filter(item => item.status === 'success').length,
      error: batch.filter(item => item.status === 'error').length,
      uploading: batch.filter(item => item.status === 'uploading').length,
    }),
    [batch]
  );
};

export const useUploadStatus = id => {
  const batch = useUploadBatch();
  return useMemo(() => batch.find(item => item.id === id), [batch, id]);
};
