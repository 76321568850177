import createCachedSelector from 're-reselect';

const selectNotificationEntities = createCachedSelector(
  [({ state }) => state.entities.activities],
  notifications => notifications
)(() => 'notification-entities');

export const selectAllNotifications = createCachedSelector(
  [selectNotificationEntities, ({ memoirsFilter }) => memoirsFilter],
  activities =>
    Object.keys(activities).sort(
      (a, b) => new Date(activities[b]['created-at']) - new Date(activities[a]['created-at'])
    )
)(() => 'all-notifications-by-date');

export const selectMostRecentNotification = createCachedSelector(
  [selectAllNotifications],
  (notifications = []) => notifications[0]
)(() => 'most-recent-notification');

export const selectUnreadNotifications = createCachedSelector(
  [selectNotificationEntities],
  notifications => Object.keys(notifications).filter(id => !notifications[id].read)
)(() => 'unread-notifications');
