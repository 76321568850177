import React, { useContext } from 'react';
import { UAContext } from '@quentin-sommer/react-useragent';
import PropTypes from 'prop-types';
import { singularize } from 'inflected';
import { acceptedFor } from 'story-media/helper';
import { Label } from './styled';
import ButtonLabelWrap from './button-label-wrap';

const NewStoryWithMediumButton = ({ type, children, disabled, handleChange, label, ...props }) => {
  const {
    uaResults: { android },
  } = useContext(UAContext);
  const accept = android ? `${singularize(type)}/*` : acceptedFor[type];
  const acceptAny = android
    ? 'image/*,video/*,audio/*'
    : `${acceptedFor.images},${acceptedFor.audios},${acceptedFor.videos}`;

  return (
    <ButtonLabelWrap label={label}>
      <Label htmlFor={`new_story_with_${type}`} disabled={disabled}>
        <input
          id={`new_story_with_${type}`}
          type="file"
          accept={type === 'any' ? acceptAny : accept}
          disabled={disabled}
          multiple={!android}
          onChange={handleChange}
          {...props}
        />
        {children}
      </Label>
    </ButtonLabelWrap>
  );
};

NewStoryWithMediumButton.propTypes = {
  type: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
};

export default NewStoryWithMediumButton;
