import { Route } from 'react-router-dom';
import React from 'react';

import StoryDetailError from 'stories/error';
import { useStoryPagesSWR } from '../../story-pages/api';
import { useEntity } from '../../entities/hooks';
import { useMatch } from '../../app/hooks';
import ButtonBar from '../../story-pages/button-bar';
import EditHeader from './header';
import Loading from '../../app/loading';
import MoveStoryPageProvider from './move';
import RejectedFilesModal from '../../story-media/rejected-files';
import StoryPages from '../../story-pages/list';
import TextForm from '../../story-media/text-form';
import EditStoryForm from './form';

const EditStory = () => {
  const {
    params: { id },
  } = useMatch();

  const { error, revalidate } = useStoryPagesSWR(id, false);
  const { isPresent } = useEntity({ type: 'stories', id });

  if (error) return <StoryDetailError />;
  if (!isPresent) return <Loading />;
  return (
    <MoveStoryPageProvider>
      <EditHeader storyId={id} />
      <Route
        path="/story/:id/edit/settings"
        render={routeProps => (
          <EditStoryForm
            storyId={id}
            onClose={() => {
              routeProps.history.replace(`/story/${id}/edit`);
            }}
            {...routeProps}
          />
        )}
      />
      <Route path="/story/:id/edit/text/:mediumId?">
        <TextForm onClose={revalidate} />
      </Route>
      <ButtonBar storyId={id} position={0} onSuccess={revalidate} />
      <StoryPages storyId={id} editing />
      <RejectedFilesModal />
    </MoveStoryPageProvider>
  );
};

export default EditStory;
