import React, { useState } from 'react';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { Flex, Box, Text, Heading } from 'rebass';
import { object, string } from 'yup';
import Form from '../form';
import { prepareAttrs, prepareErrors } from '../../../utils/api';
import { postCollection } from '../../api';
import { useEntityActionCreators } from '../../../entities/hooks/index';

const NewPersonalJournal = () => {
  const { push } = useHistory();

  const { receive } = useEntityActionCreators();
  const [initialErrors, setInitialErrors] = useState({});
  const onSubmit = async (values, { setSubmitting }) => {
    setInitialErrors({});
    const attributes = prepareAttrs(values);
    const type = 'personal-journals';
    try {
      const payload = await postCollection({
        type,
        attributes,
      });
      const { id } = payload.data.initial.data;
      receive({ payload });

      push(`/${type}/${id}`);
    } catch (error) {
      setInitialErrors(prepareErrors(error));
    }
    setSubmitting(false);
  };

  const initialValues = {
    name: '',
    slug: '',
    description: '',
  };

  const validationSchema = object().shape({
    name: string().required(),
    slug: string().required(),
    description: string().required(),
  });

  return (
    <Flex justifyContent="center" p={4}>
      <Box flex="0 1 400px">
        <Heading textAlign="center" pb={2}>
          Create a Public Memoir
        </Heading>
        <Text pb={4} px={1}>
          All stories within this collection will be viewable on the LifeTales public site
        </Text>
        <Formik
          enableReinitialize
          initialErrors={initialErrors}
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={onSubmit}
          component={Form}
        />
      </Box>
    </Flex>
  );
};

export default NewPersonalJournal;
