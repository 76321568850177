import React from 'react';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { trigger } from 'swr';
import { useCurrentMemberId } from 'members/hooks/index';
import { addMemberConnection } from '../../members/connections/api';
import { setCheckmark } from '../../ui/actions';
import Avatar from '../../members/avatar';
import MemberName from '../../members/name/index';

const AddMemberAvatar = ({ id, ...props }) => {
  const currentMemberId = useCurrentMemberId();
  return (
    <div className="connection-avatar">
      <Avatar id={id} size="100" />
      <p className="connection-avatar__name">
        <MemberName id={id} />
      </p>
      <button
        type="button"
        className="connection-avatar__button"
        onClick={() =>
          addMemberConnection(id).then(() => {
            trigger(`/v2/members/${currentMemberId}/connections`);
            props.setCheckmark({ message: 'request sent!' });
          })
        }
      >
        <FontAwesome name="user-plus" />
        Add Friend
      </button>
    </div>
  );
};

AddMemberAvatar.propTypes = {
  id: PropTypes.string.isRequired,
  setCheckmark: PropTypes.func.isRequired,
};

export default withRouter(connect(null, { setCheckmark })(AddMemberAvatar));
