import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { ErrorMessage } from 'formik';
import { TextFieldWrap, Label, ErrorMessageWrap } from '../text/styled';

const customStyles = {
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    padding: 20,
    backgroundColor: isSelected ? '#7a69bf' :
      isFocused ? '#bfb3ff' : 'white',
  }),
  control: (base, { isFocused }) => ({
    display: 'flex',
    border: isFocused ? '1px solid #7a69bf' : '1px solid #e6e6e6',
    borderRadius: '8px',
    background: 'white',
    boxShadow: 'none',
    height: '44px',
  }),
  valueContainer: base => ({
    ...base,
    padding: '12px 16px',
  }),
  singleValue: base => ({
    ...base,
    color: '#666',
  }),
};

const SelectField = ({
  options,
  field,
  form,
  label,
  showError,
  placeholder,
}) => (
  <TextFieldWrap>
    {label && <Label {...field}>{label}</Label>}
    <Select
      className="react-select-container"
      options={options}
      name={field.name}
      value={options ? options.find(option => option.value === field.value) : ''}
      onChange={(option: Option) => form.setFieldValue(field.name, option.value)}
      onBlur={field.onBlur}
      styles={customStyles}
      placeholder={placeholder}
    />
    {showError && <ErrorMessage name={field.name} component={ErrorMessageWrap} />}
  </TextFieldWrap>
);

SelectField.propTypes = {
  field: PropTypes.shape({
  }).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape).isRequired,
  form: PropTypes.shape({
  }).isRequired,
  placeholder: PropTypes.string,
};

SelectField.defaultProps = {
  placeholder: 'Select...',
};

export default SelectField;
