import styled from '@emotion/styled';
import { withProps } from 'recompose';

export const Wrap = styled.div`
  display: flex;
  height: ${props => (props.large && '45px') || '30px'};
  overflow: hidden;
}
`;

export const Url = withProps({ type: 'button' })(styled.button`
  flex: 1;
  font-size: ${props => (!props.large && '14') || '16'}px;
  background: ${props => props.theme.colours.white};
  color: ${props => props.theme.colours.brand};
  height: 100%;
  padding: 0 calc(${props => props.theme.gutter} / 2);
  border: 1px solid ${props => props.theme.colours.border};
  border-top-left-radius: ${props => props.theme.borderRadii.normal};
  border-bottom-left-radius: ${props => props.theme.borderRadii.normal};
  border-right: none;
  min-width: 0;

  span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`);

export const Button = withProps({ type: 'button' })(styled.button`
  background: ${props => props.theme.colours.brand};
  color: ${props => props.theme.colours.white};
  padding: 0 17px;
  font-size: 14px;
  font-family: ${props => props.theme.fonts.sanFrancisco};
  height: 100%;
  border: 1px solid ${props => props.theme.colours.brand};
  border-top-right-radius: ${props => props.theme.borderRadii.normal};
  border-bottom-right-radius: ${props => props.theme.borderRadii.normal};
  border-left: none;
`);

export const IconButton = styled.span`
  background: ${props => props.theme.colours.brand};
  height: 21px;
  width: 21px;

  svg {
    fill: white;
  }
`;
