export const formatPageBody = (values) => {
  const { id, media, ...attrs } = values;
  const data = { attributes: { 'lock-version': 0 }, relationships: {} };

  if (id) {
    data.id = id;
    data.attributes.id = id;
  }
  if (attrs) {
    data.attributes = { ...data.attributes, ...attrs };
  }
  if (media) {
    data.relationships.media = { data: media };
  }

  return { data };
};

export default formatPageBody;
