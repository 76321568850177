import { useState } from 'react';

export const useValue = (initial = false) => {
  const [value, setValue] = useState(initial);
  const set = v => setValue(v);
  const reset = () => setValue(initial);

  return [value, set, reset];
};

export default useValue;
