import { isEqual } from 'lodash';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useEntity } from 'entities/hooks';
import { singularize } from 'inflected';
import useCollectionCollection from './collection';

export { default as useCurrentMemberShares } from './current-member-shares';
export { default as useCollectionCover } from './cover';
export { default as useCollectionShares } from './shares';
export { default as useCollectionEntities } from './entities';
export { default as useCollectionTypeName } from './type-name';
export { default as useCollectionCollection } from './collection';
export { default as useCollections } from './collections';
export { default as useAllCollections } from './all-collections';
export { default as useRelatedCollections } from './related-collections';
export { default as useCreateCover } from './create-cover';
export { default as useRouteFromEntityParams } from './route-from-entity-params';
export { default as useEntityFromRouteParams } from './entity-from-route-params';
export { default as useParamsFromRoute } from './params-from-route';
export { default as useCollectionTypeFromCollection } from './type-from-collection';
export { default as useResetCollectionTag } from './reset-collection-tag';
export { default as useCurrentCollectionRoute } from './useCurrentCollectionRoute';

const useStoryCollection = () => {
  const selectStoryCollection = useCallback(state => {
    return Object.values(state.entities.collections).find(elem => !!elem['story-shares']);
  }, []);
  return useSelector(selectStoryCollection, isEqual);
};

export const useStoryCollectionShares = () => {
  const storyCollection = useStoryCollection();
  return storyCollection ? storyCollection['story-shares'] : [];
};

export const useCurrentMemberCollectionShare = ({ id, type }) => {
  const { entity } = useEntity({ id, type });
  return useEntity({ id: entity['current-user-share-id'], type: `${singularize(type)}-shares` });
};

export const useCurrentCollectionTag = () => useSelector(state => state.collection.currentTag);

export const useHowDoICollection = () => {
  const collection = useCollectionCollection();
  const howDoIShare = (collection['journal-shares'] || []).find(share =>
    share.type.includes('how-do-i')
  );
  const { entity: share } = useEntity(howDoIShare);
  return useEntity(share['how-do-i-collection']);
};
