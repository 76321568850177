import React from 'react';

const LockIcon = () => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle opacity="0.3" cx="40" cy="40" r="40" fill="white" />
    <path
      d="M40 16C34.3488 16 29.8 20.5488 29.8 26.2V33C26.0613 33 23 36.0613 23 39.8V53.4C23 57.1387 26.0613 60.2 29.8 60.2H50.2C53.9387 60.2 57 57.1387 57 53.4V39.8C57 36.0613 53.9387 33 50.2 33H33.2V26.2C33.2 22.3285 36.1285 19.4 40 19.4C43.8715 19.4 46.8 22.3285 46.8 26.2V27.9H50.2V26.2C50.2 20.5488 45.6512 16 40 16ZM40 41.5C41.8727 41.5 43.4 43.0273 43.4 44.9C43.4 46.0887 42.7227 47.2574 41.7 47.7688V51.7C41.7 52.7227 41.0227 53.4 40 53.4C38.9773 53.4 38.3 52.7227 38.3 51.7V47.7688C37.2773 47.2574 36.6 46.0887 36.6 44.9C36.6 43.0273 38.1273 41.5 40 41.5Z"
      fill="white"
    />
  </svg>
);

export default LockIcon;
