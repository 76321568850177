import React from 'react';
import PropTypes from 'prop-types';

const PlusIcon = ({ colour, size }) => (
  <svg width={size} height={size} viewBox="0 0 30 30" version="1.1">
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Add-Btn" transform="translate(-20.000000, -20.000000)" fill={colour}>
        <g id="Combined-Shape">
          <path d="M32.9545455,32.9545455 L22.0454545,32.9545455 L22.0454545,32.9545455 C20.9157812,32.9545455 20,33.8703266 20,35 L20,35 L20,35 C20,36.1296734 20.9157812,37.0454545 22.0454545,37.0454545 L32.9545455,37.0454545 L32.9545455,47.9545455 C32.9545455,49.0842188 33.8703266,50 35,50 L35,50 C36.1296734,50 37.0454545,49.0842188 37.0454545,47.9545455 L37.0454545,47.9545455 L37.0454545,37.0454545 L47.9545455,37.0454545 C49.0842188,37.0454545 50,36.1296734 50,35 L50,35 C50,33.8703266 49.0842188,32.9545455 47.9545455,32.9545455 L37.0454545,32.9545455 L37.0454545,22.0454545 L37.0454545,22.0454545 C37.0454545,20.9157812 36.1296734,20 35,20 L35,20 L35,20 C33.8703266,20 32.9545455,20.9157812 32.9545455,22.0454545 L32.9545455,32.9545455 Z" />
        </g>
      </g>
    </g>
  </svg>
);

PlusIcon.propTypes = {
  colour: PropTypes.string,
  size: PropTypes.string,
};

PlusIcon.defaultProps = {
  colour: 'currentColor',
  size: '30px',
};

export default PlusIcon;
