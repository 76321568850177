import React from 'react';
import { Switch, Route } from 'react-router-dom';
import SelectMilestone from './form/select-milestone';
import CreateMilestone from './create';
import EditMilestone from './edit';
import MilestoneDetail from './detail';

const NewMilestone = () => {
  return (
    <Switch>
      <Route component={CreateMilestone} path="/memoirs/:id/life-events/new/:milestoneType" />
      <Route component={SelectMilestone} path="/memoirs/:id/life-events/new" />
      <Route component={EditMilestone} path="/memoirs/:id/life-events/:milestoneId/edit" />
      <Route component={MilestoneDetail} path="/memoirs/:id/life-events/:milestoneId" />
    </Switch>
  );
};

export default NewMilestone;
