// import React from 'react';
import { DateTime } from 'luxon';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getStoryPages } from '../actions';
import { selectEntity } from '../../entities/selectors';
import { useStoryPagesSWR } from '../api';

const StoryPageCollection = ({ children, storyId, storyPages }) => {
  const { data, error } = useStoryPagesSWR(storyId, false);

  return children({ loading: !data && !error, storyPages, storyId });
};

StoryPageCollection.propTypes = {
  storyPages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
    })
  ),
  children: PropTypes.func.isRequired,
  getStoryPages: PropTypes.func.isRequired,
};

StoryPageCollection.defaultProps = {
  storyPages: [],
};

export const selectStoryPagesWithMedia = ({ state, storyId }) => {
  const story = selectEntity({ state, type: 'stories', id: storyId });
  if (!story) return undefined;
  return (story.pages || [])
    .map(page => selectEntity({ state, ...page }))
    .filter(page => {
      if (!page) return false;
      if (page.optimisticDeleted) return false;
      return !!(page.media && page.media.length);
    })
    .sort((p1, p2) => {
      if (p1.position === p2.position) {
        return DateTime.fromISO(p2['updated-at']) - DateTime.fromISO(p1['updated-at']);
      }
      return p1.position - p2.position;
    });
};

const mapStateToProps = (state, { storyId }) => ({
  storyPages: selectStoryPagesWithMedia({ state, storyId }),
});

export default connect(mapStateToProps, { getStoryPages })(StoryPageCollection);
