import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import FormPageHint from './hint';
import FormPageTitle from './title';
import FormPageRule from './rule';

const Outer = styled.div`
  background: ${props => props.theme.colours.background};
  min-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: ${props => props.theme.gutter} 0;

  p {
    color: ${props => props.theme.colours.defaultText};
    font-size: 15px;
    text-align: center;
  }
`;

const Inner = styled.div`
  background: ${props => props.theme.colours.white};
  border: 1px solid ${props => props.theme.colours.border};
  box-shadow: ${props => props.theme.shadows.small};
  padding: ${props => props.theme.gutter};
  width: 100%;
  max-width: 414px;
  margin: ${props => props.theme.gutter};
`;

const FormPage = ({ children, header, footer }) => (
  <Outer>
    {header}
    <Inner>{children}</Inner>
    {footer}
  </Outer>
);

FormPage.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.element,
  footer: PropTypes.element,
};

FormPage.defaultProps = {
  header: null,
  footer: null,
};

export default FormPage;
export const Hint = FormPageHint;
export const Title = FormPageTitle;
export const Rule = FormPageRule;
