import React, { useEffect, useState } from 'react';
import { Box } from 'rebass';
// import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
// import { VoiceRecorder } from "react-voice-recorder-player";
import AudioReactRecorder, { RecordState } from 'audio-react-recorder'


function ReactAudioPlayer({ isRecording, onStop }) {
    const [recordState, setRecordState] = useState(RecordState.NONE);

    useEffect(() => {
        if (isRecording)
            setRecordState(RecordState.START)
        else
            setRecordState(RecordState.STOP)
    }, [isRecording])

    return <Box bg="#000" sx={{ borderRadius: '4px' }}>
        <AudioReactRecorder
            canvasWidth={300}
            canvasHeight={180}
            foregroundColor="#fff"
            backgroundColor="#000"
            state={recordState}
            onStop={onStop}
        />
    </Box>

}

export default ReactAudioPlayer;