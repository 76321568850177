import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { connect } from 'react-redux';
import { setRejectedMedia } from '../actions';
import { selectRejectedMedia } from '../selectors';
import Modal from '../../app/modal/v2';
import { ButtonFooter, ConfirmText } from '../../app/modal/v2/styled';
import Button from '../../app/buttons';

const Text = styled.span`
  display: block;
  font-size: 18px;
  margin: 0 0 10px;

  b {
    display: block;
  }
`;

const RejectedMediaModal = ({ rejectedMedia, ...props }) =>
  !!rejectedMedia.length && (
    <Modal aboveTooltip>
      <ConfirmText>
        <Text>
          Sorry, we cannot upload the following:
          <b>{rejectedMedia.toString()}</b>
        </Text>
        <Text style={{ opacity: '.6' }}>
          (Either the browser you are using does not support the file type or it exceeds the upload
          limit of 2GB)
        </Text>
      </ConfirmText>
      <ButtonFooter>
        <Button onClick={() => props.setRejectedMedia([])}>Got it</Button>
      </ButtonFooter>
    </Modal>
  );

RejectedMediaModal.propTypes = {
  setRejectedMedia: PropTypes.func.isRequired,
  rejectedMedia: PropTypes.arrayOf(PropTypes.string),
};

RejectedMediaModal.defaultProps = {
  rejectedMedia: [],
};

const mapStateToProps = state => ({ rejectedMedia: selectRejectedMedia(state) });

export default connect(mapStateToProps, { setRejectedMedia })(RejectedMediaModal);
