import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const Wrap = styled.div`
  width: 100%;
  max-width: ${props => props.theme.keyedBreakpoints.xl};
  margin: 0 auto;
  padding: ${props => props.theme.gutter};
  color: ${props => props.theme.colours.mutedText};
  padding-bottom: 52px; /* space for bottom nav */
  ${props => props.theme.media.desktop`
    padding-bottom: 0;
    margin-top: 60px;
  `};
`;

export const NoFriendsWrap = styled.div`
  display: grid;
  grid-gap: calc(${props => props.theme.gutter} * 2);
  text-align: center;
  justify-items: center;
  padding: calc(${props => props.theme.gutter} * 3) 0;

  h1,
  h2 {
    margin: 0;
  }

  h1 {
    font-size: 48px;
  }
`;

export const ConnectionsWrap = styled.div`
  display: grid;
  grid-gap: ${props => props.theme.gutter};
  grid-template-columns: 1fr 1fr;
  ${props => props.theme.media.smallPhone`
    grid-template-columns: repeat(auto-fill, 175px);
  `};
`;

export const AddFriendButton = styled(Link)`
  font-family: 'SF UI Text', sans-serif;
  border: 4px solid #c0b4f9;
  color: #7c69c0;
  text-decoration: none;
  width: 100%;
  max-width: 180px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 20px;
  text-align: center;

  .fa {
    color: #7c69c0;
    line-height: 69px;
    font-size: 60px;
    margin: 10px 0 29px;
  }

  p {
    margin: 0;
  }
`;

export default Wrap;
