import PropTypes from 'prop-types';
import React from 'react';
import { Text, Flex } from 'rebass';
import { FaMapMarkedAlt } from 'react-icons/fa';

const MilestoneLocation = ({ location, ...props }) => {
  if (!location) return false;
  return (
    <Flex {...props}>
      <Text as={FaMapMarkedAlt} mr={[1, 2]} color="grey60" fontSize={12} />
      <Text fontSize={12} color="grey60" fontWeight="normal">
        {location}
      </Text>
    </Flex>
  );
};

MilestoneLocation.propTypes = {
  location: PropTypes.string,
};
MilestoneLocation.defaultProps = {
  location: null,
};

export default MilestoneLocation;
