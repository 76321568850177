
import React from 'react';
import styled from '@emotion/styled';
import { Flex } from 'rebass';

export const GradientHeader = props => (
  <Flex
    flexDirection="column"
    alignItems="center"
    p={4}
    css="background: linear-gradient(180deg, #9A35EC 0%, #6023E6 100%); border-radius: 6px 6px 50% 50% / 6px 6px 20% 20%; color: #fff; width: 100%;"
    {...props}
  />
);

export const List = styled.ul`
  margin-top: 0;
  list-style: none;
  line-height: 2;
  text-align: left;
  padding: 0;

  li {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
  }

  .badge {
    padding: 0px 6px;
    font-size: 12px;
    border-radius: 4px;
    font-weight: bold;
    margin-left: 4px;
  }

  .premium {
    background-color: ${props => props.theme.colours.brandSubtle};
    color: ${props => props.theme.colours.brand};
  }

  .free {
    background-color: ${props => props.theme.colours.grey80};
    color: ${props => props.theme.colours.mutedText};
  }
`;
