import { v4 } from 'uuid';
import * as loadImage from 'blueimp-load-image';

export const acceptedFor = {
  images: 'image/png,.png,image/jpg,.jpg,image/jpeg,.jpeg,image/heic,.heic,image/gif,.gif',
  audios: 'audio/*,.mp3,.m4a,.mp4',
  videos: 'video/*,.mkv,.mp4,.m4v,.mov,.webm,.3gp',
};

const getMediumTypeFromBody = values => !!values.text && 'Text';

export const formatMediumBody = values => ({
  data: {
    attributes: {
      type: getMediumTypeFromBody(values),
      ...values,
    },
  },
});

const getInvalidFileType = file => {
  if (file.type.includes('image')) return false;
  if (file.type.includes('audio')) return false;
  if (file.type.includes('video')) return false;
  return true;
};

const getInvalidFileSize = file => file.size > 2147483648; // upload limit: 2GB

export const validateMedia = filesArray =>
  filesArray.reduce(
    (acc, file) => {
      if (getInvalidFileType(file) || getInvalidFileSize(file)) {
        return {
          validMedia: [...acc.validMedia],
          invalidMedia: [...acc.invalidMedia, file],
        };
      }

      return {
        validMedia: [...acc.validMedia, file],
        invalidMedia: [...acc.invalidMedia],
      };
    },
    {
      validMedia: [],
      invalidMedia: [],
    }
  );

const getVideoName = filename => {
  const parts = filename.split('.');
  const [ext] = parts.slice(-1);
  const uuid = v4();

  return `${uuid}.${ext.toLowerCase()}`;
};

const getImageFilename = file => {
  if (file.type.includes('image') && file.name.includes('.jpeg')) {
    return file.name.replace('.jpeg', '.jpg');
  }
  return file.name;
};

export const processVideo = file => {
  const filename = getVideoName(file.name);
  return new Promise(resolve => {
    resolve([
      {
        type: 'Video',
        preview_filename: 'placeholder',
        filename,
      },
      file,
      // new File([file], filename, { type: file.type }),
    ]);
  });
};

export const processAudio = file =>
  new Promise(resolve => resolve([{ type: 'Audio', filename: file.name }, file]));

export const processImage = file => {
  if (file.type.includes('gif'))
    return Promise.resolve([{ type: 'Image', filename: file.name }, file]);
  return new Promise(resolve => {
    loadImage(
      file,
      canvas => {
        canvas.toBlob(
          blob => {
            const newImage = blob;
            newImage.name = file.name;
            resolve([
              {
                type: 'Image',
                filename: getImageFilename(file),
              },
              newImage,
            ]);
          },
          file.type,
          100
        );
      },
      {
        maxWidth: 1542,
        maxHeight: 1542,
        orientation: true,
        canvas: true
      }
    );
  });
};

export const calculateAspectRatio = ({ srcWidth, srcHeight, maxWidth = NaN, maxHeight = NaN }) => {
  const ratios = [maxWidth / srcWidth, maxHeight / srcHeight].filter(n => !Number.isNaN(n));

  const ratio = Math.min(...ratios);

  return { width: srcWidth * ratio, height: srcHeight * ratio };
};

export const getHeightAndWidthForMedium = ({ height: srcHeight, width: srcWidth } = {}) => {
  const maxWidth = Math.min(600, window.innerWidth);
  const { width, height } = calculateAspectRatio({
    srcWidth,
    srcHeight,
    maxWidth,
    maxHeight: window.innerHeight - 150,
  });
  return { width: `${width}px`, height: `${height}px` };
};

export const getMediumFromPayload = ({ payload }) => {
  const mediumType = Object.keys(payload.data.entities)[0];
  const mediumId = payload.data.result[mediumType][0];
  return payload.data.entities[mediumType][mediumId];
};

export const processFile = file => {
  if (file.type.includes('image')) return processImage(file);
  if (file.type.includes('audio')) return processAudio(file);
  if (file.type.includes('video')) return processVideo(file);
  return file;
};
