import useSWR from 'swr';
import { getSWR } from '../../../utils/api';

const MemberSubscription = ({ children }) => {
  useSWR(`/v3/my/subscriptions`, getSWR, { revalidateOnFocus: false });
  useSWR(`/v3/commerce/gateways/stripe/products/MEMOIRS`, getSWR, { revalidateOnFocus: false });

  return children;
};

export default MemberSubscription;
