import { useSelector } from 'react-redux';
import store from 'store';
import uniqBy from 'lodash/uniqBy';
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { selectEntity } from 'entities/selectors';
import { useCurrentMemberId } from 'members/hooks';
import { useHowDoICollection } from 'collections/hooks/index';
import { useUnreadActivities } from 'notifications/hooks';

export const useUnreadMyStoriesActivityCount = () => {
  const currentMemberId = useCurrentMemberId();
  const unreadActivities = useUnreadActivities();
  const unreadMyStoryActivities = useSelector(state =>
    unreadActivities.filter(activity => {
      if (!activity['story-id']) return false;
      // we have to use the story id here because the push notification doesn't include the story relationship
      const story = selectEntity({ state, type: 'stories', id: activity['story-id'] });
      return story && story.member && story.member.id === currentMemberId;
    })
  );
  return uniqBy(unreadMyStoryActivities, 'story-id').length;
};

export const useUnreadSharedStoriesActivitiesCount = () => {
  const { entity } = useHowDoICollection();
  const currentMemberId = useCurrentMemberId();
  const unreadActivities = useUnreadActivities();
  const unreadSharedStoryActivities = useSelector(state =>
    unreadActivities.filter(activity => {
      if (!activity['story-id']) return false;
      // don't include how do i collection activities
      if ((activity.collections || []).map(collection => collection.id).includes(entity.id))
        return false;
      const story = selectEntity({ state, type: 'stories', id: activity['story-id'] });

      return story && story.member && story.member.id !== currentMemberId;
    })
  );
  return uniqBy(unreadSharedStoryActivities, 'story-id').length;
};

export const useHowDoICollectionStoriesActivitiesCount = () => {
  const { entity } = useHowDoICollection();
  const unreadActivities = useUnreadActivities();
  const unreadHowDoIActivities = unreadActivities.filter(activity => {
    if (!activity['story-id']) return false;
    // don't include how do i collection activities
    return (activity.collections || []).map(collection => collection.id).includes(entity.id);
  });
  return uniqBy(unreadHowDoIActivities, 'story-id').length;
};

export const useHowDoICollectionPath = () => {
  const [hasVisitedHowDoI, setHasVisitedHowDoI] = useState(!!store.get('hasVisitedHowDoI'));
  const { entity } = useHowDoICollection();
  const { pathname } = useLocation();

  const basePath = `/how-do-i/${entity.id}`;
  const gettingStartedPath = `${basePath}/getting-started`;

  useEffect(() => {
    if (!hasVisitedHowDoI && pathname === gettingStartedPath) {
      store.set('hasVisitedHowDoI', true);
      setHasVisitedHowDoI(true);
    }
  }, [gettingStartedPath, hasVisitedHowDoI, pathname]);

  return hasVisitedHowDoI ? basePath : gettingStartedPath;
};
