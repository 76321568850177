import styled from '@emotion/styled';
import { getBackground, getTextColour, getBorderColour } from './helper';

export default styled.button`
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.05);
  border-radius: ${props => (props.pill ? '30px' : '6px')};
  border: 1px solid ${props => getBorderColour(props)};
  background: ${props => getBackground(props)};
  color: ${props => getTextColour(props) || '#666'};
  font-family: ${props => props.theme.fonts.sanFrancisco};
  text-decoration: none;
  font-size: ${props => props.size || '18px'};
  line-height: 1;
  padding: 0 ${props => (props.pill ? '1.2em' : '0.8em')};
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: none;
  width: ${props => (props.stretch && '100%') || 'auto'};
  opacity: ${props => (props.loading && '.7') || '1'};
  transition: 250ms ease;

  svg {
    padding-right: 5px;
  }
`;
