import styled from '@emotion/styled';
import { withProps } from 'recompose';

export const RoleWrap = styled.div`
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
  display: grid;
  grid-gap: 5px;
  grid-template-columns: max-content auto max-content max-content;
  align-items: center;
  padding: 10px;
`;

export const RoleButton = withProps({ type: 'button' })(styled.button`
  background: ${props => props.theme.colours.white};
  border: 1px solid
    ${props => (props.selected ? props.theme.colours.brand : props.theme.colours.border)};
  border-radius: ${props => props.theme.borderRadii.small};
  color: ${props => (props.selected ? props.theme.colours.brand : props.theme.colours.border)};
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`);

export const ButtonWrap = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  padding: 10px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.07) 100%);
  position: absolute;
  bottom: 0;
  width: 100%;
`;

export const Button = withProps({ type: 'button' })(styled.button`
  background: ${({
    primary,
    theme: {
      colours: { brand, white },
    },
  }) => (primary ? brand : white)};
  color: ${({
    primary,
    theme: {
      colours: { brand, white },
    },
  }) => (primary ? white : brand)};
  border-radius: 6px;
  height: 44px;
  font-family: ${props => props.theme.fonts.quicksand};
  font-size: 18px;
  font-weight: bold;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.05);
`);
