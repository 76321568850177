import React from 'react';
import TextField from 'app/form/formik/text/index';
import { Button, Flex, Box, Heading, Text } from 'rebass';
import { Formik, Form, Field } from 'formik';
import TextForm from '../text-form/form';

const NewStoryMediumForm = ({
  onSubmitText,
  onSubmitExternalMedium,
  currentForm,
  setCurrentForm,
}) => {
  if (currentForm === 'textForm')
    return (
      <Formik initialValues={{ text: '' }} onSubmit={onSubmitText}>
        {formikProps => <TextForm {...formikProps} onCancel={() => setCurrentForm('')} />}
      </Formik>
    );

  if (currentForm.includes('externalURLForm')) {
    const [, source] = currentForm.split('-');
    return (
      <Formik
        initialValues={{ externalURL: '' }}
        onSubmit={values => onSubmitExternalMedium(values)}
      >
        {({ values }) => (
          <Form>
            <Box py={3} px={4}>
              <Heading fontSize={4} pb={1}>
                Add a {source} Link
              </Heading>
              <Text pb={2}>Submit a valid {source} link and it will be embedded in your story</Text>
              <Flex pt={3} pb={3} flexDirection="column">
                <Box pr={3} width="100%">
                  <Field component={TextField} name="externalURL" width="100%" label="Link" />
                </Box>
                <Button
                  type="submit"
                  variant="primary"
                  mt={3}
                  width="100px"
                  disabled={!values.externalURL}
                >
                  Add
                </Button>
              </Flex>
            </Box>
          </Form>
        )}
      </Formik>
    );
  }

  return false;
};

export default NewStoryMediumForm;
