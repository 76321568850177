import PropTypes from 'prop-types';
import React from 'react';

import { NoFriendsWrap } from './styled';
import AddFriend from './my-friends/add';

const NoFriends = ({ count }) => {
  if (count) return false;
  return (
    <NoFriendsWrap>
      <h1>More friends, more fun!</h1>
      <h2>Invite your friends and family to LifeTales!</h2>
      <AddFriend />
    </NoFriendsWrap>
  );
};

NoFriends.propTypes = {
  count: PropTypes.number.isRequired,
};

export default NoFriends;
