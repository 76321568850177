
import { get } from 'lodash';
import { Text, Flex, Button } from 'rebass';
import CopyToClipboard from 'react-copy-to-clipboard';
import React, { useState } from 'react';
import { singularize } from 'inflected';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import useSWR from 'swr';
import { ReactComponent as SmallLinkIcon } from './assets/link.svg';
import { ShareRow, ShareRowContainer } from '../../app/share-row';
import { setCheckmark } from '../../ui/actions';
import { useGetCollectionSWR } from '../../collections/api';

const CopyPublicLink = props => (
  <CopyToClipboard {...props}>
    <Button variant="outline">Copy</Button>
  </CopyToClipboard>
);

const ShareRowPublicLink = ({ link, ...props }) => {
  const dispatch = useDispatch();
  const [showLink, setShowLink] = useState(false);

  if (!showLink)
    return (
      <ShareRow icon={SmallLinkIcon} label="Share with non-participant" sublabel="send this link" {...props}>
        <CopyPublicLink
          text={link}
          onCopy={() => {
            dispatch(setCheckmark({ message: 'Link Copied', top: 100 }));
            setShowLink(true);
          }}
        />
      </ShareRow>
    );

  return (
    <ShareRowContainer {...props}>
      <Flex alignItems="center">
        <Text color="mutedText" fontSize={1} mx={2}>
          {link}
        </Text>
        <CopyPublicLink
          text={link}
          onCopy={() => {
            dispatch(setCheckmark({ message: 'Link Copied' }));
            setShowLink(true);
          }}
        />
      </Flex>
    </ShareRowContainer>
  );
};
ShareRowPublicLink.propTypes = { link: PropTypes.string.isRequired };

export const ShareRowPublicStoryLink = ({ storyId }) => {
  const { data } = useSWR(`/v2/stories/${storyId}`);
  const shareURL = get(data, "data.data.links['story-share-url']");

  return <ShareRowPublicLink link={shareURL || ''} />;
};
ShareRowPublicStoryLink.propTypes = {
  storyId: PropTypes.string.isRequired,
};

export const ShareRowPublicCollectionLink = ({ id, type }) => {
  const { data } = useGetCollectionSWR({ type, id });
  const shareURL = get(data, `data.data.links[${singularize(type)}-share-url]`, '');

  return <ShareRowPublicLink link={shareURL} label="Invite new participants" sublabel="send this link" />;
};
ShareRowPublicCollectionLink.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default ShareRowPublicStoryLink;
