import styled from '@emotion/styled';
import React from 'react';
import { color, space, flexbox } from 'styled-system';

const Card = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  ${color};
  ${space};
  ${flexbox};
`;

const ModalBottom = props => (
  <Card
    bg="background"
    p={2}
    css="display: flex;position: absolute; bottom: 0; left: 0; right: 0;"
    {...props}
  />
);

export default ModalBottom;
