import PropTypes from 'prop-types';
import React from 'react';
import { toggleNewStoryVisible } from 'stories/actions';

import { FaPlus } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import MemoirTooltip, { useUpdateTooltip } from '../../../memoirs/tooltips';
import DetailHeaderButton from '../../../app/detail/button';

const AddStoryButton = ({ enabled, disabled }) => {
  const updateTooltip = useUpdateTooltip();
  const dispatch = useDispatch();

  if (!enabled) return false;

  const handleClick = () => {
    updateTooltip('ADD_STORY', { complete: true });
    dispatch(toggleNewStoryVisible());
  };

  return (
    <MemoirTooltip type="ADD_STORY" text="This is how you can add a story!">
      <DetailHeaderButton
        onClick={handleClick}
        disabled={disabled}
        icon={FaPlus}
        mr={2}
        label="Add Story"
        borderColor="cyan-200"
        bg="cyan-800"
      />
    </MemoirTooltip>
  );
};

AddStoryButton.propTypes = {
  enabled: PropTypes.bool,
  disabled: PropTypes.bool,
};

AddStoryButton.defaultProps = {
  enabled: true,
  disabled: false,
};

export default AddStoryButton;
