import React from 'react';
import { useConnectionsByStatus } from 'connections/hooks';
import { Box } from 'rebass';
import ConnectionAvatar from 'friends/connection-avatar/index';
import AddFriend from './add';
import { ConnectionsWrap } from '../styled';

const MyFriends = () => {
  const requestedFriends = useConnectionsByStatus('requested');
  const approvedFriends = useConnectionsByStatus('approved');

  if (
    (requestedFriends && requestedFriends.length) ||
    (approvedFriends && approvedFriends.length)
  ) {
    return (
      <Box>
        <h2>My Friends</h2>
        <ConnectionsWrap>
          <AddFriend />
          {requestedFriends.map(({ id, type }) => (
            <ConnectionAvatar connectionId={id} key={`${id}-${type}`} />
          ))}
          {approvedFriends.map(({ id, type }) => (
            <ConnectionAvatar connectionId={id} key={`${id}-${type}`} />
          ))}
        </ConnectionsWrap>
      </Box>
    );
  }
  return false;
};

export default MyFriends;
