import { Field, Form } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';

import CoverField from '../../app/form/formik/cover';
import Button from '../../app/buttons';
import TextField from '../../app/form/formik/text';

const StyledForm = styled(Form)`
  display: grid;
  grid-gap: 8px;
`;

const ProfileForm = ({ isValid, isSubmitting, avatarId }) => (
  <StyledForm>
    <Field name="avatar" component={CoverField} currentCover={avatarId} circular width={150} />
    <Field name="first-name" component={TextField} label="First Name" width="100%" showError />
    <Field name="last-name" component={TextField} label="Last Name" width="100%" showError />
    <Button type="submit" primary disabled={!isValid || isSubmitting} style={{ marginTop: '32px' }}>
      Save
    </Button>
  </StyledForm>
);

ProfileForm.propTypes = {
  avatarId: PropTypes.string,
  isValid: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

ProfileForm.defaultProps = {
  avatarId: '',
};

export default ProfileForm;
