import { Flex, Heading, Box } from 'rebass';
import React from 'react';
import PropTypes from 'prop-types';

import StoryListEmptyMessage from '../list/empty';
import { useHasDrawer } from '../../app/mobile-drawer/hooks';

const StoriesListPage = ({ notMine }) => {

  const hasDrawer = useHasDrawer();

  return (
    <Box mb={hasDrawer ? 70 : 0} p={3}>
      <Heading
        my={hasDrawer ? 1 : 2}
        mb={hasDrawer ? 1 : 2}
        ml={2}
        color="lightText"
        fontWeight="normal"
        fontFamily="sans"
        fontSize={[3, 3, 3, 4]}
      >
      </Heading>
      <Flex flexWrap="wrap" alignItems="stretch">
        <StoryListEmptyMessage isMyStories={!notMine} />
      </Flex>
    </Box>
  );
};

StoriesListPage.propTypes = {
  notMine: PropTypes.bool.isRequired,
};

export default StoriesListPage;
