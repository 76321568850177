import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { postExternalMedium } from 'story-media/api';
import { useEntityActionCreators } from 'entities/hooks/index';
import { usePostNewMeeting } from 'chats/hooks';
import { ButtonBar, Button } from './styled';
import { createMediaForFiles, createTextMedium } from '../../story-media/actions';
import { useHistory, useToggle } from '../../app/hooks';
import LinkButton from '../link/link-button';
import NewMediaPopup from '../../story-media/new';
import PlusIcon from '../../assets/svg/plus-icon';
import useLocation from 'app/hooks/location';

const ButtonBarWrap = ({ storyId, onSuccess, ...props }) => {
  const { pathname } = useLocation();
  const [on, toggle] = useToggle();
  const { replace } = useHistory();
  const dispatch = useDispatch();
  const { position } = props;
  const { receive } = useEntityActionCreators();
  const postNewMeeting = usePostNewMeeting(storyId, position);

  const submitText = useCallback(values => dispatch(createTextMedium(storyId, values)), [
    dispatch,
    storyId,
  ]);
  const submitMedia = useCallback(
    (files, transcribe) => dispatch(createMediaForFiles(storyId, files, position, false, transcribe)),
    [dispatch, position, storyId]
  );
  return (
    <ButtonBar>
      <Button onClick={toggle}>
        <PlusIcon size="24px" />
      </Button>
      {on && (
        <NewMediaPopup
          title="Add to Story"
          position={position}
          onRecordChat={async () => {
            const [payload] = await postNewMeeting();
            replace(`/story/${storyId}/chats/${payload.data.result.meetings[0]}`);
          }}
          onSubmitExternalMedium={({ externalURL }) => {
            postExternalMedium({ externalURL, storyId, position })
              .then(payload => receive({ payload }))
              .then(() => {
                if (!pathname.includes('new')) {
                  replace(`/story/${storyId}/edit`);
                }
                if (onSuccess)
                  onSuccess();
                toggle();
              });
          }}
          onSubmitText={({ text }) => {
            submitText({ text, 'page-position': position }).then(() => {
              if (!pathname.includes('new')) {
                replace(`/story/${storyId}/edit`);
              }
              if (onSuccess)
                onSuccess();
              toggle();
            });
          }}
          onSubmitMedia={(files, transcribe = false) => {
            submitMedia(files, transcribe).then(() => {
              if (!pathname.includes('new')) {
                replace(`/story/${storyId}/edit`);
              }
              if (onSuccess)
                onSuccess();
              toggle();
            });
          }}
          onClose={toggle}
        />
      )}
      <LinkButton {...props} />
    </ButtonBar>
  );
};

ButtonBarWrap.propTypes = {
  storyId: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  pageIdBefore: PropTypes.string,
  pageIdAfter: PropTypes.string,
  isLastPage: PropTypes.bool,
  onSuccess: PropTypes.func.isRequired,
};

ButtonBarWrap.defaultProps = {
  pageIdBefore: '',
  pageIdAfter: '',
  isLastPage: false,
};

export default ButtonBarWrap;
