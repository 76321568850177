import React, { useState } from 'react';
import Modal from 'app/modal/v3';
import ActionItems from './ActionItems';
import { FaAlignLeft } from 'react-icons/fa';
import TextForm from 'story-media/text-form/form';
import { Formik } from 'formik';

function AddText({ isSelected, question, onClick, onSubmitText, label = "Respond with text" }) {
    const [startAddingText, setStartAddingText] = useState(false);

    const handleClick = () => {
        setStartAddingText(true)
        onClick()
    };

    const onClose = () => setStartAddingText(false)

    return <>
        {startAddingText && <Modal
            close={{ onClose }}
            maxWidth={800}
            title={question}
            closeOnOutsideClick={false}
            aboveTooltip
        >
            <Formik initialValues={{ text: '' }} onSubmit={(values) => {
                onClose()
                onSubmitText(values)
            }}>
                {formikProps => <TextForm  {...formikProps} onCancel={onClose} />}
            </Formik>
        </Modal>}

        <ActionItems
            onClick={handleClick}
            isSelected={isSelected}
            nextIcon={isSelected}
            icon={FaAlignLeft}
            mt={2}
            label={label}
        />
    </>
}

export default AddText;