import React from 'react';
import PropTypes from 'prop-types';

import { Box, Button, Flex } from 'rebass';
import Modal from 'app/modal/v3';
import ModalBottom from 'app/modal/v3/bottom';

const ConfirmModal = ({ onCancel, onConfirm, confirmText, title, confirmVariant, children }) => {
  return (
    <Modal title={title} close={{ onClose: onCancel }} footerOffset={60}>
      <Box bg="white">{children}</Box>
      <ModalBottom>
        <Flex justifyContent="center" width="100%">
          <Button type="button" variant="muted" onClick={onCancel} mr={2}>
            Cancel
          </Button>
          <Button type="button" variant={confirmVariant} onClick={onConfirm} mr={2}>
            {confirmText}
          </Button>
        </Flex>
      </ModalBottom>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  confirmText: PropTypes.string,
  confirmVariant: PropTypes.string,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};
ConfirmModal.defaultProps = {
  confirmText: 'Confirm',
  confirmVariant: 'primary',
  title: '',
};

export default ConfirmModal;
