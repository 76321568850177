import { Box, Button, Flex } from 'rebass';
import { Formik } from 'formik';
import { UserAgent } from '@quentin-sommer/react-useragent';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';

import { createStoryPageAudioCaption, createStoryPageMedium } from '../../story-pages/actions';
import { useToggle } from '../../app/hooks';
import HiddenFileInput from '../../app/form/hidden-file-input';
import Modal from '../../app/modal/v2';
import TextForm from '../text-form/form';
import audioSvg from './assets/audio.svg';
import textSvg from './assets/text.svg';

const Label = styled.label`
  border: 1px solid ${props => props.theme.colours.border};
  border-radius: 8px;
  background: white;
  height: 34px;
  width: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Icon = styled.img`
  height: 32px;
  width: 32px;
`;

const StoryMediumCaption = ({ pageId, enabled, hasAudio, ...props }) => {
  const [on, toggle] = useToggle(false);
  const [disabled, toggleDisabled] = useToggle(false);
  if (!enabled) return false;

  return (
    <Flex p={3}>
      <Button type="button" variant="transparent" onClick={toggle} mr="auto">
        <Flex alignItems="center" color="mutedText">
          <Icon src={textSvg} alt="add a text caption" />
          <Box ml="4px">Add a caption</Box>
        </Flex>
      </Button>
      <UserAgent mobile tablet>
        {hasAudio && (
          <Label htmlFor={`${pageId}-audio-caption`}>
            <HiddenFileInput
              id={`${pageId}-audio-caption`}
              accept="audio/*"
              capture="microphone"
              disabled={disabled}
              onChange={e => {
                toggleDisabled();
                const { files } = e.target;
                const audio = Array.from(files)[0];
                props.createStoryPageAudioCaption(pageId, audio).then(toggleDisabled);
              }}
            />
            <Icon src={audioSvg} alt="add an audio caption" />
          </Label>
        )}
      </UserAgent>
      {on && (
        <Modal onClose={toggle} closeButton bg="white" maxWidth="414px">
          <Box p={3}>
            <Modal.Title>Add a Caption</Modal.Title>
            <Formik
              initialValues={{
                text: '',
                type: 'Text',
              }}
              onSubmit={attributes => {
                props.createStoryPageMedium(pageId, { data: { attributes } }).then(toggle);
              }}
              render={fProps => <TextForm {...fProps} onCancel={toggle} />}
            />
          </Box>
        </Modal>
      )}
    </Flex>
  );
};

StoryMediumCaption.propTypes = {
  enabled: PropTypes.bool.isRequired,
  hasAudio: PropTypes.bool.isRequired,
  pageId: PropTypes.string.isRequired,
  createStoryPageMedium: PropTypes.func.isRequired,
  createStoryPageAudioCaption: PropTypes.func.isRequired,
};

export default connect(null, {
  createStoryPageMedium,
  createStoryPageAudioCaption,
})(StoryMediumCaption);
