import { useMemo } from 'react';

import { useEntity } from '../../entities/hooks';

const useEntityFromRouteParams = ({ type: routeType, ...rest }) => {
  const type = useMemo(() => (routeType === 'custom' ? 'other-journals' : routeType), [routeType]);

  return useEntity({ type, ...rest });
};

export default useEntityFromRouteParams;
