import { Route, useParams } from 'react-router-dom';
import React, { useLayoutEffect } from 'react';

import StoryMutePopup from 'stories/mute/popup';
import useHandleScrollSubject from 'stories/hooks/handle-scroll-subject';
import NewMeetingPopup from 'stories/chats/popup';
import { TagContextProvider } from '../collections/detail/tag-context';
import { useStoryPagesSWR } from '../story-pages/api';
import { useEntity } from '../entities/hooks';
import StoryTagsModal from './tags/modal';
import Loading from '../app/loading';
import StoryDelete from './delete/modal';
import StoryDetailError from './error';
import StoryDetailHeader from './story-detail-header';
import StoryDetailHeaderNav from './story-detail-header/story-detail-header-nav';
import StoryJournalModal from './journal';
import StoryOptions from './options';
import StorySharedModal from '../story-shares/shared-story-modal';
import StoryShareOptions from './options/share-options';
import { useMarkStoryNotificationsRead } from '../notifications/notification/helper';
import StoryDetailPageList from './list';
import StoryDetailNotify from './notify';

const StoryDetail = () => {
  const { id } = useParams();
  useHandleScrollSubject();

  const { error } = useStoryPagesSWR(id, { page: 1, per: 0 });

  const { entity, isPresent } = useEntity({ id, type: 'stories' });

  useMarkStoryNotificationsRead(id);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!isPresent && error) return <StoryDetailError />;
  if (!isPresent) return <Loading />;

  return (
    <TagContextProvider>
      <StoryDetailHeaderNav id={id} />
      <StoryDetailHeader story={entity} />
      <Route
        exact
        path="/story/:id/delete"
        render={routeProps => <StoryDelete storyId={routeProps.match.params.id} />}
      />
      <Route exact path="/story/:id/shared" component={StorySharedModal} />
      <Route exact path="/story/:id/collection" component={StoryJournalModal} />
      <Route
        exact
        path="/story/:id/tags"
        render={routeProps => {
          const {
            match: { params },
            history,
          } = routeProps;
          return (
            <StoryTagsModal
              storyId={params.id}
              onBack={() => history.replace(`/story/${params.id}`)}
              {...routeProps}
            />
          );
        }}
      />
      <Route
        exact
        path="/story/:id/shares"
        render={routeProps => {
          const {
            match: { params },
            history,
          } = routeProps;
          return (
            <StoryShareOptions
              storyId={params.id}
              onClose={() => history.replace(`/story/${id}`)}
              {...routeProps}
            />
          );
        }}
      />
      <Route
        exact
        path="/story/:id/options"
        render={routeProps => {
          const {
            match: { params },
            history,
          } = routeProps;
          return (
            <StoryOptions
              storyId={params.id}
              onClose={() => history.replace(`/story/${id}`)}
              {...routeProps}
            />
          );
        }}
      />
      <Route
        exact
        path="/story/:id/mute"
        render={routeProps => {
          const {
            match: { params },
            history,
          } = routeProps;
          return (
            <StoryMutePopup
              storyId={params.id}
              onClose={() => history.replace(`/story/${id}`)}
              {...routeProps}
            />
          );
        }}
      />
      <Route path="/story/:id/notify" component={StoryDetailNotify} />
      <Route path="/story/:id/new-chat" component={NewMeetingPopup} />
      <StoryDetailPageList />
    </TagContextProvider>
  );
};

export default StoryDetail;
