import { Switch, Route } from 'react-router-dom';
import React from 'react';

import BasicNewCollection from './new/basic';
import Detail from './detail';
import NewChildJournal from '../child-journals/new';
import NewCustom from '../custom/new';
import NewMemoir from '../memoirs/new';
import TagPicker from './new/tags/picker';
import NewPersonalJournal from './personal-journals/new';

const Memoirs = () => {
  return (
    <Switch>
      <Route path="/:type/new/:id/tags" component={TagPicker} />
      <Route component={NewChildJournal} path="/child-journals/new" />
      <Route component={NewMemoir} path="/memoirs/new" />
      <Route component={NewCustom} path="/custom/new" />
      <Route component={NewPersonalJournal} path="/personal-journals/new" />
      <Route
        path="/family-journals/new"
        render={() => <BasicNewCollection titleAttr="name" placeholder="E.g. The Smith Family" />}
      />
      <Route
        path="/personal-journals/new"
        render={() => <BasicNewCollection titleAttr="name" placeholder="E.g. My Memoir" />}
      />
      <Route component={BasicNewCollection} path="/:type/new" />
      <Route component={Detail} path="/:type/:id" />
    </Switch>
  );
};

export default Memoirs;
