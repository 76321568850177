import './styles.css';


import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'rebass';
import { useHistory } from 'react-router-dom';

import { useCurrentMemberId } from '../../members/hooks';
import { useEntity } from '../../entities/hooks';
import Avatar from '../../members/avatar';
import DeleteConnection from './delete-connection';
import Status from './connection-status';

const ConnectionAvatar = ({ connectionId }) => {
  const { connection } = useEntity({ type: 'connections', id: connectionId });
  const { member } = useEntity({ type: 'members', id: connection.contact.id });
  const currentMemberId = useCurrentMemberId();
  const { push } = useHistory();

  if (member.id === currentMemberId) return false;

  return (
    <div className={`connection-avatar connection-avatar--${connection.status}`}>
      <DeleteConnection connection={connection} />
      <Button
        onClick={() => push(`/friends/${member.id}`)}
        variant="unset"
        css="cursor: pointer; display: flex; flex-direction: column; align-items: center;"
      >
        <Avatar id={member.id} size={100} />
        <p className="connection-avatar__name">{member['full-name']}</p>
      </Button>
      <Status connectionId={connection.id} />
    </div>
  );
};

ConnectionAvatar.propTypes = {
  connectionId: PropTypes.string.isRequired,
};

export default ConnectionAvatar;
