import { Box, Card, Flex, Text } from 'rebass';
import { FaSyncAlt, FaCheck, FaExclamationTriangle } from 'react-icons/fa';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { keyframes } from '@emotion/core';
import styled from '@emotion/styled';

import { formatBytes } from '../../utils/format-bytes';
import { useEntity } from '../../entities/hooks';

// import formatBytes from '../../app/utils/format-bytes';

const rotate = keyframes`
  from { transform: rotate(0deg) }
  to { transform: rotate(360deg) }
`;

const FileName = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Preview = styled(Box)`
  background-size: cover;
  background-repeat: no-repeat;
  height: 45px;
  width: 45px;
`;

const Item = styled(Card)`
  overflow: hidden;
  position: relative;
  box-shadow: 0px -1px rgba(0, 0, 0, 0.08);
`;

const ProgressBackground = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  height: 70px;
  z-index: 0;
  transition: width 0.35s ease-out;
`;

const UploadMediumStatus = ({ percentComplete, status, id, fileName, fileSize, type }) => {
  // const uploadStatus = useUploadStatus(type);
  const { entity: medium } = useEntity({ id, type });
  const bg = useMemo(() => {
    switch (status) {
      case 'success':
        return '#E9F2E6';
      case 'error':
        return 'noticeSubtle';
      default:
        return 'transparent';
    }
  }, [status]);

  return (
    <Item bg="background">
      <ProgressBackground
        bg={bg}
        style={{
          width: `${percentComplete}%`,
        }}
      />
      <Flex p={2} alignItems="center" css="position: relative; z-index: 1;">
        <Preview
          style={{ backgroundImage: medium.type === 'images' ? `url(${medium.tmpUrl})` : '' }}
          mr={2}
        />
        <FileName mr={4}>{fileName}</FileName>
        <Text ml="auto" flex="0 1" css="white-space: nowrap;">
          {formatBytes(fileSize)}
        </Text>
        <Box mx={3}>
          {status === 'success' && <Box as={FaCheck} color="green" />}
          {status === 'error' && <Box as={FaExclamationTriangle} color="noticeIntense" />}
          {status === 'uploading' && (
            <Box
              as={FaSyncAlt}
              color="brand"
              css={`
                animation: ${rotate} 2s linear infinite;
              `}
            />
          )}
        </Box>
      </Flex>
    </Item>
  );
};

UploadMediumStatus.propTypes = {
  percentComplete: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  fileSize: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
};

export default UploadMediumStatus;
