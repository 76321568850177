/* eslint-disable prettier/prettier */
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useState, useCallback, useMemo } from 'react';

import { Box, Button, Flex, Card } from 'rebass';
import { aliasStoryToCollection, removeStoryFromCollection } from '../../collections/api';
import { getStory } from '../actions';
import { useCollections } from '../../collections/hooks';
import { useEntity } from '../../entities/hooks';
import SelectCollectionItem from '../new/select-collection/item';
import Modal from '../../app/modal/v3';
import { getStoryShares } from '../../story-shares/actions';
import ModalCollectionMemberList from './modal-collection-member-list'

const StoryAlias = ({ storyId, onSuccess, onClose, onBack, ...props }) => {
  const collections = useCollections({ noSamples: true, filterNoReadOnly: true });
  const { story } = useEntity({ type: 'stories', id: storyId });

  const [error, setError] = useState(false);
  const [addedCollections, setAddedCollections] = useState([]);
  const [removedCollections, setRemovedCollections] = useState([]);

  const hasChanges = !!addedCollections.length || !!removedCollections.length;

  const handleClick = async () => {
    if (hasChanges) {
      await Promise.all(
        addedCollections.map(collection =>
          aliasStoryToCollection({
            id: collection.id,
            type: collection.type,
            storyId,
          })
        )
      );
      await Promise.all(
        removedCollections.map(collection =>
          removeStoryFromCollection({
            id: collection.id,
            type: collection.type,
            storyId,
          })
        )
      ).then(() => {
        props.getStory(storyId);
        props.getStoryShares(storyId);
      });
      onSuccess();
    } else {
      setError(true);
    }
  };

  const journals = story.journals || [];
  const isInitialSelected = useCallback(collection =>
    journals.map(journal => journal.id).includes(collection.id), [journals]);

  const sortedCollections = useMemo(() => collections.sort(a => (isInitialSelected(a) ? -1 : 1)), [collections, isInitialSelected]);

  const isSelected = collection => {
    if (removedCollections.map(journal => journal.id).includes(collection.id)) {
      return false;
    }
    return [
      ...journals.map(journal => journal.id),
      ...addedCollections.map(journal => journal.id),
    ].includes(collection.id);
  };

  const handleItemClick = collection => {
    if (isSelected(collection)) {
      setAddedCollections(prev => prev.filter(el => el.id !== collection.id));
      if (isInitialSelected(collection)) {
        setRemovedCollections(prev => [...prev, collection]);
      }
    }
    if (!isSelected(collection)) {
      setAddedCollections(prev => [...prev, collection]);
      setRemovedCollections(prev => prev.filter(el => el.id !== collection.id));
    }
  };

  const [collectionForMemberList, setCollectionForMemberList] = useState(null)

  if (collectionForMemberList) {
    return (
      <ModalCollectionMemberList
        id={collectionForMemberList.id}
        type={collectionForMemberList.type}
        back={{ onBack: () => setCollectionForMemberList(null) }}
      />)
  }


  return (
    <Modal
      title="Add to Memoirs"
      close={{ onClose }}
      back={{ onBack }}
      footerOffset={55}
      footer={
        <Button
          type="button"
          onClick={handleClick}
          variant="primary"
          flex={1}
          disabled={!hasChanges}
        >
          Done
        </Button>
      }
    >
      <Box bg="white">
        {error && (
          <Card variant="error" mb={2}>
            There was an error
          </Card>
        )}
        <Flex flexDirection="column" alignItems="stretch">
          {sortedCollections.map(collection => (
            <SelectCollectionItem
              key={`select-collection-${collection.id}`}
              selected={isSelected(collection)}
              collection={collection}
              setSelected={coll => handleItemClick(coll)}
              setCurrentMemberList={() => setCollectionForMemberList({ id: collection.id, type: collection.type })}
              disabled={
                isSelected(collection) &&
                addedCollections.length + journals.length - removedCollections.length <= 1
              }
            />
          ))}
        </Flex>
      </Box>
    </Modal>
  );
};

StoryAlias.propTypes = {
  getStory: PropTypes.func.isRequired,
  storyId: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  onBack: PropTypes.func,
};

export default connect(
  null,
  { getStory, getStoryShares }
)(StoryAlias);
