import React from 'react';
import { useHistory } from 'react-router-dom';
import { Flex, Heading, Box, Text } from 'rebass';
import headerImg from './assets/add-friend-header.svg';
import Modal from '../../app/modal/v3';
import PublicFriendUrl from '../public-friend-url';

const AddFriend = () => {
  const { replace } = useHistory();
  const onClose = () => replace('/friends');
  return (
    <Modal
      close={{ onClose }}
      header={{
        body: (
          <Flex flexDirection="column" alignItems="center">
            <img src={headerImg} alt="Add Friends" />
            <Heading py={2}>More friends, more fun!</Heading>
          </Flex>
        ),
      }}
    >
      <Box bg="white" p={3}>
        <Text pb={3} textAlign="center">
          Send your <strong>personalized link</strong> to those that you want to{' '}
          <strong>add</strong> or <strong>invite</strong>.
        </Text>
        <Text pb={3} textAlign="center">
          When they join through your link, you will be connected automatically :)
        </Text>
        <PublicFriendUrl />
      </Box>
    </Modal>
  );
};

export default AddFriend;
