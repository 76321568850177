import React from 'react';
import PropTypes from 'prop-types';
import NavigationItem from '../item';
import StoriesIcon from '../icons/stories';
import { useUnreadStoryIds } from '../../../notifications/notification/helper';

const StoriesLink = ({ showLabel }) => {
  const unreadStoryCount = useUnreadStoryIds().length;

  return (
    <NavigationItem
      label="Feed"
      showLabel={showLabel}
      path="/stories"
      indicatorCount={unreadStoryCount}
    >
      <StoriesIcon />
    </NavigationItem>
  );
};

StoriesLink.defaultProps = {
  showLabel: true,
};

StoriesLink.propTypes = {
  showLabel: PropTypes.bool,
};

export default StoriesLink;
