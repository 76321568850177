import React from 'react';
import PropTypes from 'prop-types';
import Component from '@reach/component-component';
import store from 'store';

const SubscriptionCodeStorage = ({
  match: {
    params: { subscriptionCode },
  },
  history: { push },
}) => (
  <Component
    didMount={() => {
      store.set('lt_subscription_code', subscriptionCode);
      push('/stories');
    }}
  />
);

SubscriptionCodeStorage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      subscriptionCode: PropTypes.string,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default SubscriptionCodeStorage;
