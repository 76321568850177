import { format } from 'timeago.js';
import { useMemo } from 'react';

import { useJsDate } from '.';

const useTimeFromNow = (iso = '') => {
  const jsDate = useJsDate(iso);
  const timeFromNow = useMemo(() => format(jsDate), [jsDate]);

  return timeFromNow;
};

export default useTimeFromNow;
