import React from 'react';
import ChatPage from 'chats/chat-page';
import { useParams, useHistory } from 'react-router-dom';

const StoryChatPage = () => {
  const { id, meetingId } = useParams();
  const { replace } = useHistory();
  return <ChatPage meetingId={meetingId} onExit={() => replace(`/story/${id}`)} />;
};

export default StoryChatPage;
