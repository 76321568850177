import useReceiveEntity from './use-receive-entity';
import useReceiveEntityDeletion from './use-receive-entity-deletion';
import useUpdateEntity from './use-update-entity';

const useEntityActionCreators = () => {
  const receiveDeletion = useReceiveEntityDeletion();
  const receive = useReceiveEntity();
  const update = useUpdateEntity();

  return { receiveDeletion, receive, update };
};

export default useEntityActionCreators;
