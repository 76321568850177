import { isEqual } from 'lodash';
import { useRef, useEffect } from 'react';

// https://gist.github.com/kentcdodds/fb8540a05c43faf636dd68647747b074

function useDeepCompareMemoize(value) {
  const ref = useRef();

  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

export default function useDeepCompareEffect(callback, dependencies) {
  useEffect(callback, useDeepCompareMemoize(dependencies));
}
