import { useMemo } from 'react';
import { useEntities, useEntity } from '../../../entities/hooks';
import { useJSONAPIRequest } from '../../../app/json-api-client';

const useMemberSubscription = (product = 'MEMOIRS') => {
  useJSONAPIRequest({
    path: '/v3/my/subscriptions',
  });

  const allProducts = useEntities('products');
  const allPlans = useEntities('pricing-plans');
  const allSubscriptions = useEntities('subscriptions');

  const productEntity = Object.values(allProducts).filter(p => p.identifier === product)[0];
  const isTrialEligible = productEntity && productEntity['free-trial-eligible'];
  const trialPlan = Object.values(allPlans).filter(plan => !!plan['activation-url'])[0];

  const subscription =
    allSubscriptions &&
    Object.values(allSubscriptions).sort(
      (a, b) => new Date(b['created-at']) - new Date(a['created-at'])
    )[0];
  const pricingPlanId = subscription && subscription['pricing-plan'].id;
  const { entity: pricingPlan } = useEntity({ type: 'pricing-plans', id: pricingPlanId });

  const trialUrl = useMemo(
    () =>
      trialPlan && trialPlan['activation-url']
        ? trialPlan['activation-url'].replace(/^(.*?)\/api\//, '/')
        : '',
    [trialPlan]
  );

  const value = useMemo(() => {
    return {
      isTrialEligible,
      allPlans,
      trialUrl,
      pricingPlan,
      subscription,
    };
  }, [allPlans, isTrialEligible, pricingPlan, subscription, trialUrl]);

  return value;
};

export default useMemberSubscription;
