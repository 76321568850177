import { useSelector } from 'react-redux';
import { selectEntity } from 'entities/selectors';
import { useCurrentMemberId } from 'members/hooks';
import { useHowDoICollection } from 'collections/hooks/index';

export const useActivities = () => {
  const selectActivities = state => state.entities.activities;
  return useSelector(selectActivities);
};

export const useUnreadActivities = () => {
  const activities = useSelector(state =>
    Object.values(state.entities.activities).filter(activity => !activity.read)
  );
  return activities;
};

export const useUnreadMyStoriesActivityCount = () => {
  const currentMemberId = useCurrentMemberId();
  const unreadActivities = useUnreadActivities();
  const unreadMyStoryActivities = useSelector(state =>
    unreadActivities.filter(activity => {
      if (!activity['story-id']) return false;
      // we have to use the story id here because the push notification doesn't include the story relationship
      const story = selectEntity({ state, type: 'stories', id: activity['story-id'] });
      return story.member.id === currentMemberId;
    })
  );
  return unreadMyStoryActivities.length;
};

export const useUnreadSharedStoriesActivitiesCount = () => {
  const { entity } = useHowDoICollection();
  const currentMemberId = useCurrentMemberId();
  const unreadActivities = useUnreadActivities();
  const unreadSharedStoryActivities = useSelector(state =>
    unreadActivities.filter(activity => {
      if (!activity['story-id']) return false;
      // don't include how do i collection activities
      if ((activity.collections || []).map(collection => collection.id).includes(entity.id))
        return false;
      const story = selectEntity({ state, type: 'stories', id: activity['story-id'] });
      return story.member && story.member.id !== currentMemberId;
    })
  );
  return unreadSharedStoryActivities.length;
};

export const useHowDoICollectionStoriesActivitiesCount = () => {
  const { entity } = useHowDoICollection();
  const unreadActivities = useUnreadActivities();
  const unreadHowDoIActivities = unreadActivities.filter(activity => {
    if (!activity['story-id']) return false;
    // don't include how do i collection activities
    return (activity.collections || []).map(collection => collection.id).includes(entity.id);
  });
  return unreadHowDoIActivities.length;
};

export default useActivities;
