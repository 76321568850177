import React from 'react';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import { Link } from 'react-router-dom';

const getIconName = status => (status === 'pending' && 'times') || 'trash';

const DeleteConnection = ({ connection: { id, status } }) => (
  <Link to={`/friends/${id}/delete`} className="connection-avatar__delete">
    <FontAwesome name={getIconName(status)} />
  </Link>
);

DeleteConnection.propTypes = {
  connection: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
};

export default DeleteConnection;
