import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { color, space, flexbox, layout, border, boxShadow } from 'styled-system';
import { Heading, Box, Text } from 'rebass';
import ScrollLock from 'react-scrolllock';
import ModalHeader, { CloseButton, BackButton } from './top';
import ModalBottom from './bottom';

const Shade = styled.div`
  position: fixed;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  z-index: 10000;
  ${space};
  ${color};
`;

const Inner = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  ${space};
  ${color};
  ${layout};
  ${flexbox};
  ${border};
  ${boxShadow};
`;

const ModalInner = React.forwardRef((props, ref) => (
  <Inner
    ref={ref}
    bg="background"
    borderRadius={8}
    width="100%"
    maxWidth={600}
    maxHeight="100%"
    flexDirection="column"
    alignItems="stretch"
    boxShadow="high"
    css="position: relative;"
    {...props}
  />
));

const Modal = ({
  children,
  back,
  close,
  header,
  footer,
  footerOffset,
  title,
  subtitle,
  closeOnOutsideClick,
  shadeProps,
  isScrollActive,
  ...props
}) => {
  const innerRef = useRef(null);
  useEffect(() => {
    const handleClick = e => {
      if (innerRef.current.contains(e.target)) {
        return;
      }
      if (closeOnOutsideClick && close.onClose) {
        close.onClose();
      }
    };

    document.addEventListener('mousedown', handleClick, false);
    return () => document.removeEventListener('mousedown', handleClick, false);
  }, [close, close.onClose, closeOnOutsideClick]);

  const getRenderLeft = () => {
    if (header.left) return header.left;
    if (back.onBack) return <BackButton {...back} />;
    return null;
  };

  const getRenderCenter = () => {
    if (header.center) return header.center;
    if (title)
      return (
        <Heading fontWeight="normal" fontSize={3}>
          {title}
        </Heading>
      );
    return null;
  };

  const getRenderBody = () => {
    if (header.body) return header.body;
    if (subtitle)
      return (
        <Text fontWeight="normal" textAlign="center" pt={2} pb={1} fontSize={1}>
          {subtitle}
        </Text>
      );
    return null;
  };

  const getRenderRight = () => {
    if (header.right) return header.right;
    if (close.onClose) return <CloseButton {...close} />;
    return null;
  };

  const left = getRenderLeft();
  const center = getRenderCenter();
  const right = getRenderRight();
  const body = getRenderBody();

  const hasModalTop = !!(left || center || right || header.body);

  return (
    <Shade p={[0, 4]} pt={4} bg="photoGradientEnd" {...shadeProps}>
      <ModalInner {...props} ref={innerRef}>
        {hasModalTop && (
          <ModalHeader
            flex="0 1"
            // ref={modalTopRef}
            left={left}
            right={right}
            center={center}
            body={body}
          />
        )}
        <ScrollLock isActive={isScrollActive}>
          <Box flex="0 1" pb={footerOffset} css="overflow-y: auto;">
            {children}
          </Box>
        </ScrollLock>
        {footer && <ModalBottom>{footer}</ModalBottom>}
      </ModalInner>
    </Shade>
  );
};
Modal.propTypes = {
  children: PropTypes.node.isRequired,
  back: PropTypes.shape({
    onBack: PropTypes.func,
    label: PropTypes.string,
  }),
  close: PropTypes.shape({
    onClose: PropTypes.func,
    label: PropTypes.string,
  }),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  header: PropTypes.shape({
    left: PropTypes.node,
    center: PropTypes.node,
    right: PropTypes.node,
    body: PropTypes.node,
  }),
  footer: PropTypes.node,
  footerOffset: PropTypes.number,
  closeOnOutsideClick: PropTypes.bool,
};
Modal.defaultProps = {
  back: {},
  close: {},
  title: '',
  subtitle: '',
  header: {},
  footer: null,
  footerOffset: 0,
  closeOnOutsideClick: true,
  isScrollActive: true
};

export default Modal;
