import React from 'react';
import CollectionOnboardSlide from '../slide';
import bg1 from '../assets/bg-1.png';
import bg2 from '../assets/bg-2.png';
import bg3 from '../assets/bg-3.png';
import bg4 from '../assets/bg-4.png';
import img1 from './assets/img-1.png';
import img2 from './assets/img-2.png';
import img3 from './assets/img-3.png';
import img4 from './assets/img-4.png';

export const SlideOne = () => (
  <CollectionOnboardSlide
    bg={bg1}
    header="My Family"
    body="Invite just your family and close friends to share pictures and stories in this collection. Everyone you add will be notified when there’s activity."
    image={img1}
    imgAltText="family tree"
  />
);

export const SlideTwo = () => (
  <CollectionOnboardSlide
    bg={bg2}
    header="Fun and Engaging"
    body="Everyone can view, comment on, and like all stories as well as add their own."
    image={img2}
    imgAltText="story with comments"
  />
);

export const SlideThree = () => (
  <CollectionOnboardSlide
    bg={bg3}
    header="Flexible Storytelling"
    body="Tell each story your way with photos, videos, text and audio!"
    image={img3}
    imgAltText="story about egypt"
  />
);

export const SlideFour = () => (
  <CollectionOnboardSlide
    bg={bg4}
    header="Easy Archiving"
    body="Add chapters to each story for easy finding. Use common tags (like trips) or create your own."
    image={img4}
    imgAltText="tags"
  />
);
