import { Box } from 'rebass';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Modal from 'app/modal/v3/index';
import useRouteFromEntityParams from 'collections/hooks/route-from-entity-params';
import SelectContributorExperience from './SelectContributorExperience';
import { createMemoirStoryRequest, updateMemoirStoryRequest } from 'story-requests/api';
import SelectQuestion from './SelectQuestion';
import AddVideo from './AddVideo';
import CopyLink from './CopyLink';
import useEntity from 'entities/hooks/use-entity';
import { getMemoirStoryRequestQuestionSuggestions } from 'story-requests/api';
import { useMobileCheckHook } from 'story-media/hooks';
import { ButtonFooter, ConfirmText } from 'app/modal/v2/styled';
import Button from 'app/buttons/index';

const ConfirmationModal = React.memo(({ title, message, loading, onCancel, onConfirm, deleteLabel }) => (
  <Modal title={title} close={{ onClose: onCancel }}>
    <ConfirmText>{message}</ConfirmText>
    <ButtonFooter >
      <Button onClick={onCancel} muted>
        Cancel
      </Button>
      <Button onClick={onConfirm} destructive loading={loading}>
        {deleteLabel}
      </Button>
    </ButtonFooter>
  </Modal>
));

const groupedByCategory = (data) => {
  return data.reduce((result, item) => {
    const category = item.attributes.category;

    if (!result[category])
      result[category] = [];

    result[category].push({
      id: item.attributes.id,
      question: item.attributes.question
    });

    return result;
  }, {});
};

function SectionRender({ step, isStillAlive, subjectRelation, created_memoir_story_request_id, type, replace, ...props }) {
  const [memoirStoryRequestId, setMemoirStoryRequestId] = useState(created_memoir_story_request_id)
  const [isSubmitting, setIsSubmitting] = useState(null)
  const [storyRequestData, setStoryRequestData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [suggestions, setSuggestions] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)
  const [selectedSuggestion, setSelectedSuggestion] = useState(null)

  const { memoirId, onStepUp } = props

  async function loadIdeas() {
    setIsLoading(true)

    getMemoirStoryRequestQuestionSuggestions(memoirId, storyRequestData?.id).then(({ data }) => {
      const { data: storyRequestQuestions } = data?.initial
      setSuggestions(groupedByCategory(storyRequestQuestions))

      setIsLoading(false)
    }).catch((error) => {

      if (!error) return undefined;
      if (!error.response) return undefined;

      const { data } = error.response;
      const { errors } = JSON.parse(data);
      setErrorMessage(errors[0].detail)
      setIsLoading(false)
    });
  }

  useEffect(() => {
    if ((isStillAlive === false || subjectRelation === 'me') && !memoirStoryRequestId) {
      const attributes = {
        "contributor-experience": 'other_contributors',
      }
      onSubmit(attributes)
    }
    if (memoirStoryRequestId) {
      setSuggestions(null)
      loadIdeas()
    }
  }, [isStillAlive, memoirStoryRequestId, subjectRelation, storyRequestData?.['contributor-experience']])

  async function onSubmit(attributes) {
    setIsSubmitting(true)
    if (!memoirStoryRequestId) {
      const { data: { initial: { data } } } = await createMemoirStoryRequest({ memoirId, attributes })

      setMemoirStoryRequestId(data?.id)
      // replace(`/${type}/${memoirId}/memoir-request-story/${data?.id}`)
      setStoryRequestData(data?.attributes)
    }
    else {
      const { data: { initial: { data } } } = await updateMemoirStoryRequest({ memoirId, memoirStoryRequestId, attributes })
      setStoryRequestData(data?.attributes)
    }
    setIsSubmitting(false)
    onStepUp()
  }

  if (step === 4)
    return <CopyLink
      storyRequestData={storyRequestData}
      onSubmit={onSubmit}
      percentComplete={90}
      {...props}
    />


  if (step === 3)
    return <AddVideo
      storyRequestData={storyRequestData}
      onSubmit={onSubmit}
      percentComplete={90}
      {...props}
    />

  if (step === 2)
    return <SelectQuestion
      isSubmitting={isSubmitting}
      storyRequestData={storyRequestData}
      onSubmit={onSubmit}
      percentComplete={66}
      loadIdeas={loadIdeas}
      suggestions={suggestions}
      isLoading={isLoading}
      errorMessage={errorMessage}
      selectedSuggestion={selectedSuggestion}
      setSelectedSuggestion={setSelectedSuggestion}
      isStillAlive={isStillAlive}
      {...props}
    />

  if (step === 1)
    return <SelectContributorExperience
      isSubmitting={isSubmitting}
      storyRequestData={storyRequestData}
      onSubmit={onSubmit}
      percentComplete={33}
      {...props}
    />

  return null

}

const NewStoryRequest = () => {
  const { type, id, created_memoir_story_request_id } = useParams()
  const route = useRouteFromEntityParams({ type, id });
  const { replace } = useHistory();
  const { memoir } = useEntity({ type, id })

  const [step, setStep] = useState(1)
  const [closeModal, setCloseModal] = useState(false)

  const isFromMobile = useMobileCheckHook()

  const onStepUp = () => {
    setStep(step + 1)
  }
  const onStepDown = () => setStep(step - 1)

  useEffect(() => {
    if (memoir?.['subject-alive'] === false || memoir?.['subject-relation'] === 'me')
      setStep(2)
    else
      setStep(1)
  }, [memoir?.['subject-alive']])

  return <Modal
    title="Story Request"
    shadeProps={{ style: { padding: 0 } }}
    style={{ height: '100vh', background: '#fff' }}
    maxWidth={500}
    closeButton
    close={{
      onClose: () => {
        if (step === 4)
          replace(`${route}`)
        else
          setCloseModal(true)
      }
    }}
    aboveTooltip
    closeOnOutsideClick={false}
    isScrollActive={!isFromMobile}
  >
    {closeModal && <ConfirmationModal
      message="Are you sure you want to cancel this story request?"
      deleteLabel="Cancel story Request"
      onCancel={() => setCloseModal(false)}
      onConfirm={() => {
        replace(`${route}`)
      }}
    />}
    <Box sx={{
      position: 'absolute',
      height: step === 4 ? `100vh` : [`calc(100vh - 150px)`, `calc(100vh - 50px)`],
      width: '100%',
      overflowY: 'scroll',
      maxWidth: 500,
      '-webkit-overflow-scrolling': 'touch',
      touchAction: 'auto',
      mt: 2
    }}>
      <SectionRender isStillAlive={memoir?.['subject-alive']} subjectRelation={memoir?.['subject-relation']} created_memoir_story_request_id={created_memoir_story_request_id} replace={replace} memoirId={id} type={type} step={step} onStepUp={onStepUp} onStepDown={onStepDown} />
    </Box>
  </Modal>
};

NewStoryRequest.propTypes = {

};

export default NewStoryRequest;
