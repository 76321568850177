import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Route } from 'react-router-dom';
import { withProps } from 'recompose';

const ButtonStyles = css`
  all: unset;
  position: relative;
  font-size: 18px;
  font-family: ${props => props.theme.fonts.quicksand};
  font-weight: ${props => (props.active ? 'bold' : 'regular')};
  color: ${props => (props.active ? props.theme.colours.brand : props.theme.colours.mutedText)};
  padding: 1em;
  cursor: pointer;
`;

export const ExternalLink = withProps({
  target: '_blank',
  rel: 'noopener noreferrer',
})(styled.a`
  ${ButtonStyles};
`);

const StyledButton = styled.button`
  ${ButtonStyles};
`;

const StoryFilterLink = ({ handleClick, path, children }) => (
  <Route path={path}>
    {({ match, history: { push } }) => (
      <StyledButton
        type="button"
        active={match && match.isExact}
        onClick={() => {
          handleClick();
          push(path);
        }}
      >
        {children}
      </StyledButton>
    )}
  </Route>
);

StoryFilterLink.propTypes = {
  handleClick: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default StoryFilterLink;
