import { Link } from 'react-router-dom';
import { space } from 'styled-system';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

export const LinksWrap = styled.div`
  display: grid;
  grid-gap: ${props => props.theme.gutter};
  grid-template-columns: repeat(2, max-content);
  margin: 0 auto 0 ${props => props.theme.gutter};
  ${space};
`;

export const LinksWrapRight = styled.div`
  display: grid;
  grid-gap: ${props => props.theme.gutter};
  grid-template-columns: repeat(4, max-content);
  height: 100%;
  align-items: center;
  margin: 0 0 0 auto;
`;

export const MenuButtonStyles = css`
  text-decoration: none;
  text-align: left;
  color: ${props => props.theme.colours.mutedText};
  padding: 1em 3em 1em 1em;
  font-size: 18px;
`;

export const UserDropdownButton = styled(Link)`
  ${MenuButtonStyles};
`;
