import { Box, Button, Flex, Heading } from 'rebass';
import { get } from 'lodash';
import React from 'react';
import store from 'store';
import useSWR from 'swr';

import { getStoryRequestSWR } from '../../../../story-requests/api';
import { useEntity } from '../../../../entities/hooks';
import { useMatch } from '../../../../app/hooks';
import { useHistory } from 'react-router-dom';
import { useMemberById } from '../../../../members/hooks';
import Modal from '../../../../app/modal/v3';
import ErrorModal from '../../../../app/modal/v2/error';
import VideoMedium from '../../../../story-media/medium-types/video';
import StoryRequestDetailOptions from './options';

const StoryRequestDetail = () => {
  const {
    params: { type, id, storyRequestId },
    url,
  } = useMatch();
  const { replace, push } = useHistory();

  const { error } = useSWR(...getStoryRequestSWR(storyRequestId));

  const { storyRequest, isPresent, isOwner } = useEntity({
    type: 'story-requests',
    id: storyRequestId,
  });
  const member = useMemberById(storyRequest.member && storyRequest.member.id);
  const pageId = get(storyRequest, `pages[0].id`, '');
  const { page } = useEntity({ type: 'pages', id: pageId });
  const medium = get(page, 'media[0]', {});

  const handleRespondClick = () => {
    replace(`${url}/response`);
    store.set('requestResponse', 'true');
  };

  if (error) {
    return (
      <ErrorModal
        onClose={() => replace(`/${type}/${id}`)}
        text="There doesn't seem to be a video attached to this request."
        closeText="Go Back"
      />
    );
  }

  if (isPresent)
    return (
      <Modal
        close={{ onClose: () => push(`/${type}/${id}`) }}
        header={{ left: <StoryRequestDetailOptions /> }}
      >
        <Flex flexDirection="column" p={20}>
          {isOwner && (
            <Flex alignItems="center">
              <Heading>{storyRequest.title}</Heading>
            </Flex>
          )}
          <Box mt={20}>{medium.type === 'videos' && <VideoMedium id={medium.id} />}</Box>
          {!isOwner && (
            <Button onClick={handleRespondClick} variant="primary">
              Respond to {member['first-name']}
            </Button>
          )}
        </Flex>
      </Modal>
    );

  return false;
};

export default StoryRequestDetail;
