import { space, justifyContent } from 'styled-system';
import { transparentize } from 'polished';
import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';

import { useEntity } from '../../entities/hooks';

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  padding-right: 0;
  ${space};
  ${justifyContent};
`;

const TagBadge = styled.span`
  background: ${props => transparentize(0.5, props.theme.colours.brand)};
  height: 20px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  color: ${props => props.theme.colours.white};
  font-size: 12px;
  margin-left: 5px;
  padding: 0 8px;
`;

const TagBadgeList = ({ storyId, tagsToShow, onClick, ...props }) => {
  const { entity: story } = useEntity({ id: storyId, type: 'stories' });
  const storyTags = story['tag-names'] || [];

  const namesList = storyTags.slice(0, tagsToShow);
  const moreTagsCount = storyTags.slice(tagsToShow).length;

  return (
    <Button type="button" onClick={onClick} {...props}>
      {namesList.map(tagName => (
        <TagBadge key={`${tagName}${storyId}`}>#{tagName}</TagBadge>
      ))}
      {!!moreTagsCount && <TagBadge>{`${moreTagsCount} more`}</TagBadge>}
    </Button>
  );
};

TagBadgeList.propTypes = {
  storyId: PropTypes.string.isRequired,
  tagsToShow: PropTypes.number,
  onClick: PropTypes.func,
};

TagBadgeList.defaultProps = {
  tagsToShow: 2,
  onClick: () => {},
};

export default TagBadgeList;
