import { DateTime } from 'luxon';
import { Flex, Text, Box } from 'rebass';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FaRegCalendar } from 'react-icons/fa';

const useFormattedDate = date =>
  useMemo(() => (date ? DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED) : null), [date]);

const MilestoneDate = React.memo(({ startDate, endDate, ...props }) => {
  if (!(startDate || endDate)) return false;

  const formattedStartDate = useFormattedDate(startDate);
  const formattedEndDate = useFormattedDate(endDate);

  return (
    <Flex {...props}>
      <Text as={FaRegCalendar} mr={[1, 2]} fontSize={12} color="grey60" />
      <Text color="grey60" fontSize={12} fontWeight="normal">
        {formattedStartDate}
      </Text>
      {formattedEndDate && (
        <Text color="grey60" fontSize={12} fontWeight="normal">
          <Box as="span" px={1}>
            &mdash;
          </Box>
          {formattedEndDate}
        </Text>
      )}
    </Flex>
  );
});

MilestoneDate.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
};

MilestoneDate.defaultProps = {
  startDate: null,
  endDate: null,
};

export default MilestoneDate;
