import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'rebass';
import { useHistory } from 'react-router-dom';
import { useCurrentMemberId } from 'members/hooks/index';

const FriendProfileLink = ({ children, id }) => {
  const { push } = useHistory();
  const currentMemberId = useCurrentMemberId();

  if (!id) return false;

  const path = id === currentMemberId ? `/my-stories` : `/friends/${id}`;

  return (
    <Button onClick={() => push(path)} variant="unset">
      {children}
    </Button>
  );
};

FriendProfileLink.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
};

export default FriendProfileLink;
