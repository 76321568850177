import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { ThemeProvider } from 'emotion-theming';
import { UserAgentProvider } from '@quentin-sommer/react-useragent';
import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/browser';
import { LastLocationProvider } from 'react-router-last-location';
import * as firebase from 'firebase/app';
import 'firebase/analytics';

import App from './app';
import configureStore from './store/configure-store';
import register from './registerServiceWorker';
import theme from './app/styles/theme';

const store = configureStore();
const rootEl = document.getElementById('root');

// Create a root element using React 18 API
const root = ReactDOM.createRoot(rootEl); // Updated for React 18

const app = () => (
  <ReduxProvider store={store}>
    <ThemeProvider theme={theme}>
      <UserAgentProvider ua={window.navigator.userAgent}>
        <Router>
          <LastLocationProvider>
            <Route path="/" component={App} />
          </LastLocationProvider>
        </Router>
      </UserAgentProvider>
    </ThemeProvider>
  </ReduxProvider>
);

if (process.env.REACT_APP_BUILD_TARGET === 'production') {
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.firebaseapp.com`,
    databaseURL: `https://${process.env.REACT_APP_FIREBASE_PROJECT_ID}.firebaseio.com`,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.appspot.com`,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  };
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();

  Sentry.init({ dsn: `https://${process.env.REACT_APP_SENTRY_ID}@sentry.io/226699` });
  /* eslint-disable */
  (function (w, d, s, l, i) {
    w[l] = w[l] || []; w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    const f = d.getElementsByTagName(s)[0]; const j = d.createElement(s); const dl = l != 'dataLayer' ? `&l=${l}` : '';
    j.async = true; j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
    f.parentNode.insertBefore(j, f);
  }(window, document, 'script', 'dataLayer', process.env.REACT_APP_GTM_CODE));
  /* eslint-enable */
}

root.render(app()); // Updated to use 'render' on the root
register();
