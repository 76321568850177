import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import NukaCarousel from 'nuka-carousel';

const CollectionOnboardCarousel = ({ children, ctaText, onClose }) => {
  const [showCta, setShowCta] = useState(false);
  const [hideControls, setHideControls] = useState(false);

  return (
    <Wrap>
      <NukaCarousel
        slidesToShow={1}
        heightMode="current"
        renderAnnounceSlideMessage={({ currentSlide, slideCount }) => {
          if (currentSlide === slideCount - 1) {
            setShowCta(true);
          }
          if (slideCount === 1) {
            setHideControls(true);
          }
        }}
        withoutControls={hideControls}
      >
        {children}
      </NukaCarousel>
      {showCta ? (
        <CtaButton type="button " onClick={() => onClose()}>
          {ctaText}
        </CtaButton>
      ) : (
        <SkipButton type="button" onClick={() => onClose()}>
          Skip
        </SkipButton>
      )}
    </Wrap>
  );
};

CollectionOnboardCarousel.propTypes = {
  onClose: PropTypes.func.isRequired,
  ctaText: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;

  .slider-frame {
    border-radius: 6px 6px 0 0;
  }
`;

const CtaButton = styled.button`
  background: linear-gradient(180deg, #b2a2e1 0%, ${props => props.theme.colours.brand} 100%);
  color: ${props => props.theme.colours.white};
  border-radius: ${props => props.theme.borderRadii.large};
  padding: 20px 60px;
  font-family: ${props => props.theme.fonts.sanFrancisco};
  font-weight: bold;
  margin: 10px auto;
`;

const SkipButton = styled.button`
  padding: 20px 60px;
  margin: 10px 0 10px auto;
  color: ${props => props.theme.colours.brand};
`;

export default CollectionOnboardCarousel;
