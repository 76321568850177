import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'rebass';
import { useImage } from 'entities/hooks/useImage';

const GalleryImage = ({ id, height, width }) => {
  const imageSrc = useImage({ id, size: 3 });

  return (
    <Image
      maxHeight="100%"
      width="100%"
      maxWidth="100%"
      src={imageSrc}
      sx={{ display: 'block', objectFit: 'contain' }}
    />
  );
};

GalleryImage.propTypes = {
  id: PropTypes.string.isRequired,
};

export default GalleryImage;
