import { connect } from 'react-redux';
import Component from '@reach/component-component';
import PropTypes from 'prop-types';
import React from 'react';
import isEmpty from 'lodash/isEmpty';

import { receiveEntity, updateEntity } from './actions';
import { selectEntity, selectCurrentMemberId } from './selectors';

const Entity = ({
  id, type, entity, getEntity, currentMemberId, children, logEntity, ...props
}) => (
  <Component
    initialState={{ loading: getEntity && isEmpty(entity), error: '' }}
    didMount={({ setState }) => (
      getEntity && getEntity()
        .then(res => props.receiveEntity({ payload: res }))
        .catch(error => setState({ error, loading: false }))
        .then(() => setState({ loading: false }))
    )}
  >
    {({ state: { loading, error } }) => (logEntity && console.log('entity: ', entity)) || children({
      isPresent: !isEmpty(entity),
      isOwner: !!entity.member && entity.member.id === currentMemberId,
      currentMemberId,
      entity,
      loading,
      error,
      updateEntity: attrs => props.updateEntity({ id, entityType: type, attrs }),
    })}
  </Component>
);

Entity.propTypes = {
  entity: PropTypes.shape({}),
  currentMemberId: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
  receiveEntity: PropTypes.func.isRequired,
  getEntity: PropTypes.func,
  updateEntity: PropTypes.func.isRequired,
  id: PropTypes.string,
  type: PropTypes.string.isRequired,
  logEntity: PropTypes.bool,
};

Entity.defaultProps = {
  entity: {},
  getEntity: null,
  id: null,
  logEntity: false,
};

const mapStateToProps = (state, { id, type }) => ({
  entity: selectEntity({ state, type, id }),
  currentMemberId: selectCurrentMemberId({ state }),
});

export default connect(mapStateToProps, { receiveEntity, updateEntity })(Entity);
