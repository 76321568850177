import { useState } from 'react';

export const useToggle = (initial = false) => {
  const [on, setOn] = useState(initial);
  const toggle = (nextOn = null) => setOn((typeof nextOn === 'boolean' && nextOn) || !on);

  return [on, toggle];
};

export default useToggle;
