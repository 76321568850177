import { useState } from 'react';

import { useAllCollections } from '.';
import { useDeepCompareEffect } from '../../app/hooks';

const useRelatedCollections = collectionParams => {
  const allCollections = useAllCollections();
  const [relatedCollections, setRelatedCollections] = useState([]);

  const relatedCollectionKeys =
    collectionParams && collectionParams.map(collection => collection.id);

  useDeepCompareEffect(() => {
    setRelatedCollections(
      Object.values(allCollections).filter(
        collection => relatedCollectionKeys && relatedCollectionKeys.includes(collection.id)
      )
    );
  }, [allCollections, JSON.stringify(collectionParams)]);

  return relatedCollections;
};

export default useRelatedCollections;
