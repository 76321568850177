import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useCurrentMemberCollectionShare } from 'collections/hooks';

export const useHasCollectionPermission = ({ feature, id, type }) => {
  const { entity: share } = useCurrentMemberCollectionShare({ id, type });
  const { 'contact-role': role } = share;

  return useMemo(() => {
    switch (feature) {
      case 'SHOW_OPTIONS':
      case 'ADD_STORY':
        return role !== 'read_only';
      case 'MANAGE_TAGS':
      case 'REMOVE_STORY':
      case 'EDIT_COLLECTION':
      case 'EXPORT_COLLECTION':
      case 'EDIT_AVATAR':
        return role === 'owner' || role === 'collaborator';
      case 'DELETE_COLLECTION':
        return role === 'owner';
      case 'LEAVE_COLLECTION':
        return role !== 'owner';
      case 'SHARE_COLLECTION':
        if (type === 'personal-journals') return false;
        // we allowed sharing for the memoir subject, but memoir subject is no longer implemented
        // if (collection['subject-id'] === currentMemberId) return true;
        return role === 'owner' || role === 'collaborator';
      case 'CAN_VIEW_AVATARS':
        if (type === 'personal-journals') return false;
        if (type === 'how-do-i-collections') return false;
        return true;
      default:
        return true;
    }
  }, [feature, role, type]);
};

const CollectionPermission = ({ feature, id, type, children }) => {
  const hasPermission = useHasCollectionPermission({ feature, id, type });

  if (typeof children === 'function') return children({ hasPermission });

  return hasPermission ? children : false;
};

CollectionPermission.propTypes = {
  feature: PropTypes.oneOf([
    'SHARE_COLLECTION',
    'EDIT_COLLECTION',
    'EXPORT_COLLECTION',
    'DELETE_COLLECTION',
    'EXPORT_COLLECTION',
    'LEAVE_COLLECTION',
    'REMOVE_STORY',
    'ADD_STORY',
    'SHOW_OPTIONS',
    'EDIT_AVATAR',
    'CAN_VIEW_AVATARS',
    'MANAGE_TAGS',
  ]).isRequired,
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default CollectionPermission;
