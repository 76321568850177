import React, { useReducer, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useCurrentMember } from 'members/hooks/index';
import { getApiBase } from 'utils/api-base';
import VideoRoom from './VideoRoom';

function reducer(state, action) {
  switch (action.type) {
    case 'setRoomName':
      return { ...state, roomName: action.roomName };
    case 'setToken':
      return { ...state, token: action.token };
    case 'logout':
      return { ...state, token: null };
    default:
      return state;
  }
}

function VideoChat() {
  const { meetingId } = useParams();
  const initialState = {};
  const { 'first-name': firstName } = useCurrentMember();

  const [{ roomName, token }, dispatch] = useReducer(reducer, initialState);
  const apiBase = getApiBase('v3');

  useEffect(() => {
    const doToken = async () => {
      const tokenResponse = await axios({
        method: 'post',
        url: `${apiBase}/public/meetings/${meetingId}/meeting_access_tokens?identity=${firstName}`,
        headers: {
          'Content-Type': 'application/json',
        },
      });
      dispatch({
        type: 'setToken',
        token: tokenResponse.data.data.attributes['access-token'],
      });
    };
    doToken();
  }, [apiBase, firstName, meetingId]);

  if (token) return <VideoRoom roomName={roomName} token={token} dispatch={dispatch} />;
  return false;
}
export default VideoChat;
