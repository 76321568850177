import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'rebass';
import TextMedium from 'story-media/medium-types/text';
import GalleryExternal from './gallery-external';
import GalleryImage from './gallery-image';
import GalleryVideo from './gallery-video';
import GalleryAudio from './gallery-audio';

const Wrap = props => <Flex alignItems="center" justifyContent="center" {...props} />;

const GalleryMedium = ({ id, type, direction }) => {
  return (
    <Wrap>
      {type === 'texts' && <TextMedium id={id} />}
      {type === 'images' && <GalleryImage id={id} />}
      {type === 'videos' && <GalleryVideo id={id} />}
      {type === 'audios' && <GalleryAudio id={id} />}
      {type === 'external-media' && <GalleryExternal id={id} />}
    </Wrap>
  );
};

GalleryMedium.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default GalleryMedium;
