import styled from '@emotion/styled';

import { fonts, colours } from '../../../app/styles/constants';

export default styled.h1`
  font-size: 20px;
  font-family: ${fonts.quicksand};
  color: ${colours.mutedText};
  text-align: center;
`;
