import { Formik } from 'formik';
import { object, date } from 'yup';
import React from 'react';

import { patchChildJournal } from '../api';
import { prepareAttrs } from '../../utils/api';
import { useEntity, useEntityActionCreators } from '../../entities/hooks';
import { useJsDate, useMatch } from '../../app/hooks';
import FullPageForm from '../../app/full-page-form';
import NewCollectionDate from '../../collections/new/date';
import { useHistory } from 'react-router-dom';

const ChildJournalDate = () => {
  const { push } = useHistory();
  const { params } = useMatch();
  const { entity } = useEntity(params);
  const { receive } = useEntityActionCreators();
  const initialDate = useJsDate(entity['date-of-birth']);
  const initialValues = {
    'date-of-birth': initialDate || null,
  };
  const validationSchema = object().shape({
    'date-of-birth': date(),
  });
  const isInitialValid = validationSchema.isValidSync(initialValues);

  return (
    <FullPageForm
      onBackClick={() => push(`/child-journals/new/${entity.id}`)}
      title="Create a Child Journal"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        isInitialValid={isInitialValid}
        onSubmit={async values => {
          const body = prepareAttrs({ ...values, 'lock-version': entity['lock-version'] });

          // PATCH child journal
          const cjRes = await patchChildJournal({ id: entity.id, ...body });
          const [id] = cjRes.data.result['child-journals'];
          receive({ payload: cjRes });
          push(`/child-journals/new/${id}/gender`);
        }}
        render={formikProps => (
          <NewCollectionDate
            label={`${entity['first-name']}'s Birthday`}
            skipPath={`/child-journals/new/${entity.id}/gender`}
            {...formikProps}
          />
        )}
      />
    </FullPageForm>
  );
};

export default ChildJournalDate;
