import React from 'react';
import PropTypes from 'prop-types';
import { Text, Flex, Box, Button } from 'rebass';
import SampleBadge from 'collections/list/item/sample-badge';

const Badge = ({ count }) => (
  <Flex
    alignItems="center"
    justifyContent="center"
    ml="auto"
    bg="accent"
    width="20px"
    css="border-radius: 50%; height: 20px; font-size: 12px; font-weight: bold; min-width: 20px;"
    color="defaultText"
  >
    {count}
  </Flex>
);

const DrawerIconLink = ({
  children,
  onClick,
  label,
  sublabel,
  isSelected,
  unreadStoryCount,
  isSample,
  ...props
}) => (
  <Button
    variant="transparent"
    onClick={onClick}
    type="button"
    bg={isSelected ? 'cyan-700' : 'transparent'}
    border="none"
    fontWeight="normal"
    px={3}
    py={2}
    style={{ textAlign: 'left', minHeight: '56px', position: 'relative' }}
    width="100%"
    {...props}
  >
    <Flex alignItems="center">
      {children}
      <Box ml={2}>
        <Text fontSize={sublabel ? 1 : 2} color="drawerBasicallyWhite">
          {label}
        </Text>
        {sublabel && (
          <Text mt={1} fontSize={0} color="drawerSubtle">
            {sublabel}
          </Text>
        )}
      </Box>
      {unreadStoryCount > 0 && <Badge count={unreadStoryCount} />}
      {isSample && <SampleBadge />}
    </Flex>
  </Button>
);
DrawerIconLink.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  sublabel: PropTypes.string,
  isSelected: PropTypes.bool,
  unreadStoryCount: PropTypes.number,
  isSample: PropTypes.bool,
};
DrawerIconLink.defaultProps = {
  sublabel: '',
  isSelected: false,
  unreadStoryCount: 0,
  isSample: false,
};
export default DrawerIconLink;
