import { height, background, width, fontSize, top } from 'styled-system';
import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { withProps } from 'recompose';

export const CircleButton = withProps({ type: 'button' })(styled.button`
  ${height};
  ${width};
  ${background};
  ${fontSize};
  ${top};
  border-radius: 50%;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.07);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
`);

const ArrowWrap = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${width};

  ${props =>
    props.left &&
    css`
      left: 0;
      background: linear-gradient(
        to right,
        ${(props.bg && props.theme.colours[props.bg]) || '#FFFFFF'} 74.23%,
        rgba(255, 255, 255, 0) 100%
      );
    `};

  ${props =>
    props.right &&
    css`
      right: 0;
      background: linear-gradient(
        to left,
        ${(props.bg && props.theme.colours[props.bg]) || '#FFFFFF'} 74.23%,
        rgba(255, 255, 255, 0) 100%
      );
    `};

  svg {
    font-size: inherit;
    color: #333;
  }
`;

const Arrow = ({ children, ...props }) => (
  <ArrowWrap {...props}>
    <CircleButton
      height={[24, 32, 44]}
      width={[24, 32, 44]}
      fontSize={[12, 14, 16]}
      background="white"
    >
      {children}
    </CircleButton>
  </ArrowWrap>
);

Arrow.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Arrow;
