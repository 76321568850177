import { Switch, Route } from 'react-router-dom';
import React, { useContext } from 'react';

import StoryDetailPageGallery from 'stories/page-gallery/index';
import { CurrentModalContext } from './modal/context';
import CollectionCover from '../collections/detail/cover';
import CollectionsOnboard from '../collections/onboard';
import NewCollection from '../collections/new';

const ModalRoutes = () => {
  const { currentModal } = useContext(CurrentModalContext);

  return (
    <>
      {currentModal === 'new-collection' && <NewCollection />}
      <Switch>
        <Route path="/:type/:id/cover" component={CollectionCover} />
        <Route path="/collections/onboard/:type" component={CollectionsOnboard} />
        <Route path="/story/:id/page/:pageId" component={StoryDetailPageGallery} />
      </Switch>
    </>
  );
};

export default ModalRoutes;
