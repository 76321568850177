import { lighten } from 'polished';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { withProps } from 'recompose';

export const buttonStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 7.5px;
  color: ${props => props.theme.colours.defaultText};
  height: 50px;
  width: 50px;
  padding: 10px;
  border: 1px solid #dadada;
  border-radius: 50%;
  font-size: 20px;
  transition: all 0.175s ease;

  &:hover {
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.05);
    color: ${props => props.theme.colours.brand};
    border-color: ${props => props.theme.colours.brand};
    background-image: linear-gradient(to top, #f6f6f6, #fff);
    & + span {
      color: ${props => props.theme.colours.brand};
    }
  }

  &:active {
    box-shadow: 1px 1px 5px rgba(122, 105, 191, 0.25);
    color: ${props => props.theme.colours.brand};
    border-color: ${props => props.theme.colours.brand};
    & + span {
      color: ${props => props.theme.colours.brand};
    }
  }

  &:disabled {
    color: ${props => lighten(0.25, props.theme.colours.brand)};
    background: ${props => props.theme.colours.white};
    border-color: ${props => lighten(0.25, props.theme.colours.brand)};
    box-shadow: none;
    cursor: default;
  }
`;

export const Button = withProps({ type: 'button' })(styled.button`
  ${buttonStyles};
`);

export const Label = styled.label`
  ${buttonStyles};
  position: relative;
  cursor: pointer;
  line-height: 1;

  ${props =>
    props.disabled &&
    css`
      color: ${lighten(0.25, props.theme.colours.brand)};
      background: ${props.theme.colours.white};
      border-color: ${lighten(0.25, props.theme.colours.brand)};
      box-shadow: none;
      cursor: default;
    `};

  input {
    position: absolute;
    left: -9999em;
  }
`;
