import { get, post, patch, destroy } from '../../utils/api';

export const getMemberConnections = memberId => get(`/v2/members/${memberId}/connections`);
export const addMemberConnection = memberId =>
  post(`/v2/members/${memberId}/connections`, {
    data: {
      attributes: { status: 'requested' },
      relationships: { contact: { data: { type: 'members', id: memberId } } },
    },
  });
export const acceptConnection = connection =>
  patch(`/v2/connections/${connection.id}`, {
    data: { attributes: { 'lock-version': connection['lock-version'], status: 'approved' } },
  });
export const deleteConnection = connectionId => destroy(`/v2/connections/${connectionId}`);
export const postMemberShare = shareId =>
  post('/v2/public/connections', { data: { 'share-id': shareId } });
export const getPublicMember = shareId => get(`/v2/public/members/${shareId}`);
