import React from 'react';
import Participant from './Participant';
import { Box } from 'rebass';

const Participants = ({ participants, localParticipant }) => {
  if (!localParticipant) return false;
  if (!(participants && participants.length)) {
    return (
      <Box height="85vh" sx={{ display: 'grid', justifyContent: 'center' }}>
        <Participant
          participant={localParticipant}
          key={localParticipant.sid}
          height="100%"
          sx={{ gridArea: '1 / 1 / 2 / 2' }}
        />
      </Box>
    );
  }

  if (participants.length === 1) {
    return (
      <Box height="85vh" sx={{ display: 'grid', justifyContent: 'center' }}>
        <Participant
          participant={localParticipant}
          key={localParticipant.sid}
          height="100%"
          bg="none"
          sx={{
            gridArea: '1 / 1 / 2 / 2',
            transform: 'scale(.2) translateY(-10%) translateX(-10%)',
            transformOrigin: 'bottom right',
            zIndex: 1,
          }}
        />
        {participants.slice(0, 1).map((participant) => (
          <Participant
            participant={participant}
            key={participant.sid}
            height="100%"
            sx={{ gridArea: '1 / 1 / 2 / 2' }}
          />
        ))}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'grid',
        gridGap: 3,
        alignItems: 'center',
        justifyContent: 'center',
        gridTemplateColumns: 'repeat(auto-fill, minmax(360px, 480px))',
        gridAutoFlow: 'dense',
      }}
      p={3}
    >
      {participants.map((participant) => (
        <Participant participant={participant} key={participant.sid} />
      ))}
      <Participant participant={localParticipant} key={localParticipant.sid} />
    </Box>
  );
};

export default Participants;
