import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSWRPages } from 'swr';
import { useStoryPagesSWR } from 'story-pages/api';
import Loading from '../../app/loading/index';
import { StoryPageContextProvider } from '../../story-pages/item/context';
import StoryPage from '../../story-pages/item';
import getNextPaginatedPage from '../../app/pagination/getNextPaginatedPage';
import LoadMoreButton from '../../app/pagination/load-more-button';

const StoryPagesBody = ({ data, storyId }) => {
  if (!data) return <Loading local />;

  const { data: pages } = data.data.data.relationships.pages || { data: [] };

  if (!pages.length) {
    return false;
  }

  return pages.map(({ id }, index, arr) => {
    const { id: nextPageId } = arr[index + 1] || {};
    const { id: prevPageId } = arr[index - 1] || {};
    return (
      <StoryPageContextProvider key={id}>
        <StoryPage
          id={id}
          storyId={storyId}
          isLastPage={index === pages.length - 1}
          editing={false}
          nextPageId={nextPageId}
          prevPageId={prevPageId}
          pageIndex={index}
        />
      </StoryPageContextProvider>
    );
  });
};

const PaginatedStoryPagesBody = () => {
  const { id } = useParams();
  const { pages, loadMore, isReachingEnd, isLoadingMore } = useSWRPages(
    `/v3/stories/${id}/pages`,
    ({ offset, withSWR }) => {
      const queryParams = { page: offset || 1, per: 10 };
      /* eslint-disable */
      const { data } = withSWR(useStoryPagesSWR(id, queryParams));
      /* eslint-enable */
      return <StoryPagesBody storyId={id} data={data} />;
    },
    getNextPaginatedPage
  );
  return (
    <>
      {pages}
      {!isLoadingMore && !isReachingEnd && (
        <LoadMoreButton disabled={isLoadingMore || isReachingEnd} loadMore={loadMore} />
      )}
    </>
  );
};

const StoryDetailPageList = () => {
  const { id } = useParams();

  const { data } = useStoryPagesSWR(id);

  const itemCount = useMemo(() => {
    if (!data) return undefined;
    return data.data.data.meta['item-count'];
  }, [data]);

  if (itemCount > 10) return <PaginatedStoryPagesBody />;
  // if greater than 10 pages, render paginated list
  // otherwise return initial list

  return <StoryPagesBody storyId={id} data={data} />;
};

export default StoryDetailPageList;
