import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

const Chip = ({ text, variant }) => <Wrap variant={variant}>{text}</Wrap>;

Chip.propTypes = {
  text: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['primary', 'secondary']),
};

Chip.defaultProps = {
  variant: 'primary',
};

const Wrap = styled.div`
  border-radius: 16px;
  padding: 4px 12px;
  margin: 4px 8px 4px 0;
  ${props =>
    props.variant === 'primary' &&
    css`
      background-color: ${props.theme.colours.darkBlue};
      color: ${props.theme.colours.white};
    `};
  ${props =>
    props.variant === 'secondary' &&
    css`
      background-color: ${props.theme.colours.brandSubtle};
      color: ${props.theme.colours.darkBlue};
    `};
`;

export default Chip;
