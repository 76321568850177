import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { createCollectionCoverMedium } from '../actions';

const useCreateCover = () => {
  const dispatch = useDispatch();
  return useCallback(opts => dispatch(createCollectionCoverMedium(opts)), [dispatch]);
};

export default useCreateCover;
