import React from 'react';
import PropTypes from 'prop-types';
import Tippy from '@tippy.js/react';
import { Box } from 'rebass';
import { IoIosHelpCircleOutline } from 'react-icons/io';

const QuestionTooltip = ({ children }) => (
  <Tippy
    arrow="small"
    theme="alert"
    trigger="click"
    placement="bottom"
    zIndex={10001}
    content={children}
  >
    <Box css="display: inline-block;">
      <IoIosHelpCircleOutline size={24} />
    </Box>
  </Tippy>
);

QuestionTooltip.propTypes = {
  children: PropTypes.node.isRequired,
};

export default QuestionTooltip;
