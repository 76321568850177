import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Field, Form } from 'formik';
import TextField from '../../app/form/formik/text';
import Button from '../../app/buttons/submit';

const StyledForm = styled(Form)`
  display: grid;
  grid-gap: 8px;
`;

const CompleteProfileForm = ({ isValid, isSubmitting }) => (
  <StyledForm>
    <Field name="first-name" component={TextField} label="First Name" showError width="100%" />
    <Field name="last-name" component={TextField} label="Last Name" showError width="100%" />
    <Button
      type="submit"
      primary
      stretch
      disabled={!isValid || isSubmitting}
      style={{ marginTop: '32px' }}
    >
      Save Profile
    </Button>
  </StyledForm>
);

CompleteProfileForm.propTypes = {
  isValid: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default CompleteProfileForm;
