import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Link, Text, Flex } from 'rebass';
import { FaVideo } from 'react-icons/fa';

const EditVideoDropdownLink = ({ videoId }) => {
  const { id } = useParams();
  return (
    <Flex
      sx={{ borderBottom: '1px solid', borderColor: 'defaultText' }}
      mx="-.6rem"
      px=".6rem"
      height={30}
      alignItems="center"
    >
      <Link
        as={RouterLink}
        replace
        to={`/story/${id}/video/${videoId}`}
        sx={{ textDecoration: 'none', color: 'defaultText', display: 'flex', alignItems: 'center' }}
      >
        <Text as={FaVideo} mr={2} />
        <Text as="span">Edit Video</Text>
      </Link>
    </Flex>
  );
};

EditVideoDropdownLink.propTypes = {
  videoId: PropTypes.string.isRequired,
};

export default EditVideoDropdownLink;
