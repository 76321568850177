export const UPDATE_MEMBER_AVATAR = 'UPDATE_MEMBER_AVATAR';
export const GET_MEMBER_IDENTIFIERS = 'GET_MEMBER_IDENTIFIERS';
export const DESTROY_IDENTIFIER = 'DESTROY_IDENTIFIER';
export const GET_PUBLIC_MEMBER = 'GET_PUBLIC_MEMBER';
export const DELETE_MEMBER_AVATAR = 'DELETE_MEMBER_AVATAR';
export const SET_AVATAR_FOR_EDIT = 'SET_AVATAR_FOR_EDIT';
export const GET_MEMBER_EVENTS = 'GET_MEMBER_EVENTS';
export const POST_MEMBER_EVENT = 'POST_MEMBER_EVENT';
export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const GET_MEMBER_COLLECTION = 'GET_MEMBER_COLLECTION';
