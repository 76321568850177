import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { Body } from '../../../app/modal/v2/styled-info';
import Button from '../../../app/buttons';

const ConfirmDelete = ({
  tag: { name, count }, onCancel, onDelete,
}) => (
  <Body style={{ borderRadius: '8px' }}>
    <p>
      {`Your tag "${name}" will be removed from ${count} ${pluralize('story', count)}`}
    </p>
    <div style={{ display: 'flex', marginTop: '32px' }}>
      <Button onClick={onCancel} style={{ margin: '0 16px 0 auto' }}>
        Cancel
      </Button>
      <Button onClick={onDelete} destructive>
        {`Delete ${name} tag`}
      </Button>
    </div>
  </Body>
);

ConfirmDelete.propTypes = {
  tag: PropTypes.shape({}).isRequired,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default ConfirmDelete;
