import { Image, Box, Button, Flex } from 'rebass';
import { isObject, isString } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';
import * as loadImage from 'blueimp-load-image';
import styled from '@emotion/styled';

import { useRect } from '@reach/rect';
import { useDropzone } from 'react-dropzone';
import { ReactComponent as CropIcon } from '../assets/icon-circle-crop.svg';
import { ImageEntity } from '../../../../entities';
import { Label } from '../../../../app/form/formik/text/styled';
import { useToggle } from '../../../../app/hooks';
import Absolute from '../../../../app/layout/absolute';
import CoverEditor from '../editor';
import CoverPickerThumbnails from '../thumbnails';
import CoverPreview from '../preview';
import addImageSvg from '../thumbnails/assets/add-cover-photo.svg';

/* eslint-disable react/jsx-props-no-spreading */
const StoryCoverPicker = ({ field, form, storyId, showPreview, showThumbnails, label }) => {
  const [isCropping, toggleCropping] = useToggle(false);
  const defaultSelected = isObject(field.value) ? field.value.id : '';
  const [selected, setSelected] = useState(defaultSelected);
  const [droppedFile, setDroppedFile] = useState(null);

  const ref = useRef();
  const rect = useRect(ref);

  const handleDrop = async dropped => {
    setSelected('');
    if (!dropped[0]) return false;
    const image = await new Promise(resolve => {
      loadImage(
        dropped[0],
        canvas => {
          canvas.toBlob(
            blob => {
              resolve(blob);
            },
            'image/png',
            100
          );
        },
        {
          canvas: true,
          aspectRatio: 2 / 1,
        }
      );
    });
    if (typeof field.value === 'string') {
      URL.revokeObjectURL(field.value);
    }

    setDroppedFile(dropped[0]);
    return form.setFieldValue(field.name, URL.createObjectURL(image));
  };
  const { getRootProps, getInputProps } = useDropzone({ onDrop: handleDrop });

  const handleCropButtonClick = async assetSrc => {
    if (assetSrc) {
      await fetch(assetSrc)
        .then(res => res.blob())
        .then(blob => form.setFieldValue(field.name, URL.createObjectURL(blob)));
    }
    toggleCropping();
  };

  return (
    <Box ref={ref}>
      {label && <Label {...field}>{label}</Label>}
      {showPreview && (
        <>
          <Box css="position: relative;">
            {isCropping && (
              <CoverEditor
                src={droppedFile || field.value}
                acceptEdit={cover => {
                  URL.revokeObjectURL(field.value);
                  setDroppedFile(null);
                  const coverUrl = URL.createObjectURL(cover);
                  form.setFieldValue(field.name, coverUrl);
                }}
                exitEditor={toggleCropping}
                editorWidth={rect && rect.width}
              />
            )}
            {!isCropping && (
              <>
                <ImageEntity id={(field.value || {}).id}>
                  {({ tmpUrlSrc, coverSrc, assetSrc }) => {
                    const previewSrc = isString(field.value) ? field.value : tmpUrlSrc || coverSrc;
                    return (
                      <>
                        <CoverPreview previewSrc={previewSrc} handleDrop={handleDrop} />
                        {previewSrc && (
                          <>
                            <Shade display="inline-flex" left="0" top="0" />
                            <Absolute
                              display="inline-flex"
                              left="50%"
                              top="50%"
                              style={{ transform: 'translate(-50%)' }}
                            >
                              <Title>{form.values.title}</Title>
                            </Absolute>
                            <Absolute display="inline-flex" right={10} bottom={10}>
                              <Button
                                variant="unset"
                                type="button"
                                borderRadius="50%"
                                onClick={() => handleCropButtonClick(assetSrc)}
                              >
                                <CropIcon height={36} />
                              </Button>
                            </Absolute>
                          </>
                        )}
                      </>
                    );
                  }}
                </ImageEntity>
              </>
            )}
          </Box>
        </>
      )}
      {showThumbnails && (
        <>
          <Flex alignItems="center" flexWrap="wrap">
            <CoverPickerThumbnails
              handleClick={async image => {
                // setDroppedFile(null);
                const file = await fetch(image['asset-url']).then(res => res.blob());
                handleDrop([file]);
                setSelected(image.id);
                form.setFieldValue(field.name, { type: 'images', id: image.id });
              }}
              storyId={storyId}
              selected={selected}
            />
          </Flex>
          <Flex alignItems="center" my={1} sx={{ cursor: 'pointer' }} {...getRootProps()}>
            <input {...getInputProps({ multiple: false, accept: 'image/*' })} />
            <Image width={40} mr={2} src={addImageSvg} alt="select from gallery" />
            Select from photo library
          </Flex>
        </>
      )}
    </Box>
  );
};

StoryCoverPicker.propTypes = {
  field: PropTypes.shape({}).isRequired,
  form: PropTypes.shape({}).isRequired,
  storyId: PropTypes.string.isRequired,
  showPreview: PropTypes.bool,
  showThumbnails: PropTypes.bool,
  label: PropTypes.string,
};

StoryCoverPicker.defaultProps = {
  showPreview: false,
  showThumbnails: false,
  label: '',
};

const Title = styled.h2`
  margin: 0;
  color: ${props => props.theme.colours.white};
  font-family: ${props => props.theme.fonts.sans};
`;

const Shade = styled(Absolute)`
  background-color: rgba(0, 0, 0, 0.2);
  height: 100%;
  width: 100%;
  border-radius: ${props => props.theme.borderRadii.large};
`;

export default StoryCoverPicker;
