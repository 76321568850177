import PropTypes from 'prop-types';
import React from 'react';

import { ButtonFooter, ConfirmText } from '../modal/v2/styled';
import Button from '../buttons';
import Modal from '../modal/v3';

const DeleteModal = React.memo(({ title, message, loading, onCancel, onConfirm, deleteLabel }) => (
  <Modal title={title} close={{ onClose: onCancel }}>
    <ConfirmText>{message}</ConfirmText>
    <ButtonFooter>
      <Button onClick={onCancel} muted>
        Cancel
      </Button>
      <Button onClick={onConfirm} destructive loading={loading}>
        {deleteLabel}
      </Button>
    </ButtonFooter>
  </Modal>
));

DeleteModal.propTypes = {
  message: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  deleteLabel: PropTypes.string,
  title: PropTypes.string,
};

DeleteModal.defaultProps = {
  message: 'Are you sure you want to delete this resource?',
  loading: false,
  deleteLabel: 'Delete',
  title: '',
};

export default DeleteModal;
