import { produce } from 'immer';
import { useReducer } from 'react';

/* eslint-disable default-case */
/* eslint-disable no-param-reassign */
/* eslint-disable no-useless-return */
const reducer = (prev, action) =>
  produce(prev, draft => {
    switch (action.type) {
      case 'ADD_DELETION': {
        draft.deletions.push(action.id);
        delete draft.updates[action.id];
        return;
      }
      case 'ADD_UPDATE': {
        draft.deletions = draft.deletions.filter(i => i !== action.id);
        draft.updates[action.id] = action.role;
        return;
      }
      case 'ADD_ADDITION': {
        if (action.role === '') {
          delete draft.additions[action.id];
        } else {
          draft.additions[action.id] = action.role;
        }
        return;
      }
    }
  });
/* eslint-enable default-case */
/* eslint-enable no-param-reassign */
/* eslint-enable unexpected-return */

export const initialState = {
  additions: {},
  updates: {},
  deletions: [],
};

export const useRoleFormReducer = () => {
  return useReducer(reducer, initialState);
};

export default reducer;
