import React from 'react';
import PropTypes from 'prop-types';
import { FaRegBell, FaRegBellSlash } from 'react-icons/fa';
import { Text } from 'rebass';
import StoryPermission from 'stories/permission';
import { TooltipButton } from 'stories/story-detail-header/styled';
import { useIsStoryMuted } from 'stories/hooks/index';

const StoryMuteDropdownLink = ({ id, onClick }) => {
  const isMuted = useIsStoryMuted(id);
  return (
    <StoryPermission feature="TOGGLE_NOTIFICATIONS" id={id}>
      <TooltipButton onClick={onClick}>
        {isMuted && <Text color="black" fontSize={2} mr={1} as={FaRegBellSlash} />}
        {!isMuted && <Text color="black" fontSize={2} mr={1} as={FaRegBell} />}
        &nbsp;
        {isMuted ? 'Unmute' : 'Mute'}
      </TooltipButton>
    </StoryPermission>
  );
};
StoryMuteDropdownLink.propTypes = {
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default StoryMuteDropdownLink;
