import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FriendProfileLink from 'friends/profile/link';
import MemberAvatar from '../../members/avatar';
import likeIcon from '../assets/like-filled.svg';
import commentIcon from '../assets/comment.svg';
import { Wrapper, Icon } from './styled';

const getAvatarSrc = activity => (activity.type === 'Like' && likeIcon) || commentIcon;

const RecentActivityAvatar = ({ activity }) => (
  <FriendProfileLink id={activity.member.id}>
    <Wrapper>
      <Icon src={getAvatarSrc(activity)} alt="" />
      <MemberAvatar id={activity.member.id} />
    </Wrapper>
  </FriendProfileLink>
);

RecentActivityAvatar.propTypes = {
  activity: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  activity: state.entities[ownProps.activity.type][ownProps.activity.id],
});

export default connect(mapStateToProps)(RecentActivityAvatar);
