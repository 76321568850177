import React from 'react';

const SayYeahFiUndo = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 3.99995H3.9L6.7 1.79995C7.1 1.49995 7.2 0.799946 6.9 0.399946C6.6 -5.3972e-05 5.9 -0.100054 5.5 0.199946L0.5 4.19995C0.1 4.39995 0 4.69995 0 4.99995C0 5.29995 0.1 5.59995 0.4 5.79995L5.4 9.79995C5.6 9.89995 5.8 9.99995 6 9.99995C6.3 9.99995 6.6 9.89995 6.8 9.59995C7.1 9.19995 7.1 8.49995 6.6 8.19995L3.9 5.99995H11C14.3 5.99995 17 8.69995 17 11.9999C17 15.2999 14.3 17.9999 11 17.9999H7C6.4 17.9999 6 18.3999 6 18.9999C6 19.5999 6.4 19.9999 7 19.9999H11C15.4 19.9999 19 16.3999 19 11.9999C19 7.59995 15.4 3.99995 11 3.99995Z"
        fill="white"
      />
    </svg>
  );
};

export default SayYeahFiUndo;
