import { fontSize, height, space, color } from 'styled-system';
import { withRouter, useLocation } from 'react-router-dom';
import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { useDispatch } from 'react-redux';
import { paramCase } from 'param-case';
import { withProps } from 'recompose';

import { useCurrentCollectionTag } from 'collections/hooks/index';
import { setCurrentCollectionTag } from 'collections/actions';
// import { TagContext } from '../tag-context';
import CollectionPermission from 'collections/permissions/index';
import { jsonApiReferenceShape } from '../../../app/shapes';
import { useHistory } from 'react-router-dom';
import { useRouteFromEntityParams } from '../../hooks';
import { useEntity } from '../../../entities/hooks';
import CollectionTags from '../../tags';
import SliderWrap from './slider-wrap';
import hamburgerSvg from './assets/hamburger.svg';
import MemoirTooltip, { useUpdateTooltip } from '../../../memoirs/tooltips';

const CollectionTagFilterNav = ({ collection }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const current = useCurrentCollectionTag();
  const setCurrent = tag => dispatch(setCurrentCollectionTag(tag));

  const { replace } = useHistory();
  const route = useRouteFromEntityParams(collection);
  const { entity } = useEntity({ type: collection.type, id: collection.id });
  const updateTooltip = useUpdateTooltip();

  const milestonesCurrent = pathname.includes('milestones');

  return (
    <SliderWrap pl={4} height={48} bg="cyan-500">
      <MemoirTooltip
        type="TIMELINE"
        text={`The timeline tab displays ${entity['first-name']}'s major life events.`}
        complete
      >
        <Button
          current={current === '' && milestonesCurrent}
          key="tag-filter-milestones"
          onClick={() => {
            setCurrent('');
            replace(`${route}/milestones`);
          }}
          data-reserve-space="Timeline"
          height={48}
          fontSize={[14, 16]}
        >
          Timeline
        </Button>
      </MemoirTooltip>
      <MemoirTooltip
        type="RECENT_TAB"
        text="These tags are where the stories live. You can find all stories in 'Recent'. Once the stories are tagged, they are organized into chapters!"
      >
        <Button
          current={current === '' && !milestonesCurrent}
          key="tag-filter-recent"
          onClick={() => {
            setCurrent('');
            replace(route);
            updateTooltip('RECENT_TAB', { complete: true });
          }}
          data-reserve-space="Recent"
          height={48}
          fontSize={[14, 16]}
        >
          Recent
        </Button>
      </MemoirTooltip>
      <CollectionTags id={collection.id} type={collection.type}>
        {({ countedTags, tags }) =>
          countedTags
            .filter(tag => (tags.find(t => t.name === tag.name) || {}).favourite).sort((a, b) => a.name.localeCompare(b.name))
            .map(tag => {
              // const content = `${tag.name} (${tag.count})`;
              const content = tag.name;
              return (
                <Button
                  current={tag.name === current}
                  key={`tag-filter-${tag.name}`}
                  onClick={() => {
                    replace(`${route}/${paramCase(tag.name)}`);
                    setCurrent(tag.name);
                  }}
                  data-reserve-space={content}
                  height={48}
                  fontSize={[14, 16]}
                >
                  {content}
                </Button>
              );
            })
        }
      </CollectionTags>
      <CollectionPermission feature="MANAGE_TAGS" id={collection.id} type={collection.type}>
        <Button onClick={() => replace(`${route}/manage/tags`)} height={48} ml="auto">
          <img src={hamburgerSvg} alt="tags menu" />
        </Button>
      </CollectionPermission>
    </SliderWrap>
  );
};

CollectionTagFilterNav.propTypes = {
  collection: jsonApiReferenceShape.isRequired,
};

export const Button = withProps({ type: 'button' })(styled.button`
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  padding: 0 10px;
  white-space: break-spaces;
  max-width: 180px;
  width: max-content;
  color: ${props => props.theme.colours['cyan-050']};
  letter-spacing: 0.2px;
  ${color};
  ${height};
  ${fontSize};
  ${space};

  &::after {
    display: block;
    content: attr(data-reserve-space);
    color: transparent;
    font-weight: bold;
    height: 1px;
    margin-top: -1px;
  }

  &:hover {
    color: ${props => props.theme.colours.white};
  }

  ${props =>
    props.current &&
    css`
      color: ${props => props.theme.colours.white};
      border-bottom: 3px solid ${props.theme.colours.white};
      font-weight: bold;
    `};
`);

export default withRouter(CollectionTagFilterNav);
