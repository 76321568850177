import { useContext } from 'react';
import { CurrentCollectionContext } from '../../collections/current-collection-context';

export default entity => {
  const { currentCollection } = useContext(CurrentCollectionContext);
  const nextEntity = entity;
  if (currentCollection.id) {
    nextEntity['journal-id'] = currentCollection.id;
  }
  return nextEntity;
};
