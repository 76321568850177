import React from 'react';
import { Box } from 'rebass';
import ConnectionAvatar from 'friends/connection-avatar/index';
import { useConnectionsByStatus } from 'connections/hooks';
import { ConnectionsWrap } from '../styled';

const FriendRequests = () => {
  const pendingFriends = useConnectionsByStatus('pending');
  if (pendingFriends && pendingFriends.length) {
    return (
      <Box>
        <h2>Friend Requests</h2>
        <ConnectionsWrap>
          {pendingFriends.map(({ id, type }) => (
            <ConnectionAvatar connectionId={id} key={`${id}-${type}`} />
          ))}
        </ConnectionsWrap>
      </Box>
    );
  }
  return false;
};

export default FriendRequests;
