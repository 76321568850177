import * as types from './types';

export const receiveEntityDeletion = ({ entityType, id }) => ({
  type: types.RECEIVE_ENTITY_DELETION,
  entityType,
  id,
});

export const receiveEntity = ({ payload }) => ({
  type: types.RECEIVE_ENTITY,
  payload,
});

export const receiveEntityRequest = ({ payload, meta }) => ({
  type: types.RECEIVE_ENTITY,
  payload,
  meta,
});

export const updateEntity = ({ entityType, id, attrs }) => ({
  type: types.UPDATE_ENTITY,
  entityType,
  id,
  attrs,
});

export const requestEntities = promise => ({
  type: types.REQUEST_ENTITIES,
  promise,
});
