import { DateTime } from 'luxon';
import { useMemo } from 'react';

const useStoryDate = ({ 'created-at': createdAt, 'began-on': began, 'ended-on': ended } = {}) => {
  return useMemo(() => {
    if (!began && !ended && !createdAt) return '';

    const parsedCreated = DateTime.fromISO(createdAt);

    if (!began && !ended) return parsedCreated.toFormat('LLL d, yyyy');

    const parsedbegan = DateTime.fromISO(began);
    const parsedended = DateTime.fromISO(ended);

    const formattedbegan = parsedbegan.toFormat('LLL d, yyyy');

    if (!ended || parsedbegan.toISODate() === parsedended.toISODate()) return `${formattedbegan}`;

    const formattedended = parsedended.toFormat('LLL d, yyyy');

    if (!began) return `${formattedended}`;

    const shortbegan = parsedbegan.toFormat('LLL d');

    if (parsedbegan.c.month === parsedended.c.month && parsedbegan.c.year === parsedended.c.year) {
      return `${shortbegan} - ${parsedended.toFormat('d, yyyy')}`;
    }

    if (parsedbegan.c.year === parsedended.c.year) {
      return `${shortbegan} - ${formattedended}`;
    }

    return '';
  }, [began, createdAt, ended]);
};

export default useStoryDate;
