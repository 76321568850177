import styled from '@emotion/styled';
import { withProps } from 'recompose';

export const Notice = withProps({ type: 'button' })(styled.button`
  background: #fff9e5;
  border-radius: 8px;
  padding: 10px 20px;
  display: flex;
  align-items: center;

  span {
    font-size: 14px;
    margin-left: 15px;
  }
`);

export const EmojiList = styled.ul`
  list-style: none;
  padding-left: 20px;
  li:before {
    content: '👉 ';
  }
  li {
    margin-bottom: 8px;
  }
`;

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const ButtonWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin: 10px;
`;

export const ConfirmMessage = styled.div`
  text-align: center;
  margin: 20px 40px;
`;

export const SuccessMessage = styled(ConfirmMessage)`
  svg {
    background: #c8e6c9;
    border-radius: 50%;
    color: #4caf50;
  }
`;
