

import { Heading, Flex, Button } from 'rebass';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import { destroyMilestone } from '../api';
import { getCollection } from '../../../collections/actions';

const DeleteMilestone = ({ milestoneId, memoirId, onCancel, onSuccess, isVisible }) => {
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const confirmDestroy = useCallback(() => {
    setSubmitting(true);
    dispatch(() => destroyMilestone({ milestoneId, memoirId }))
      .then(() => dispatch(getCollection({ type: 'memoirs', id: memoirId })))
      .then(() => {
        onSuccess();
        setSubmitting(false);
      });
  }, [dispatch, onSuccess, milestoneId, memoirId]);
  if (!isVisible) return false;
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      css="background-image: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,255,.85) 30%, rgba(255,255,255,.5) 100%); z-index: 1; position: absolute; top: 0; bottom: 0; left: 0; right: 0;"
    >
      <Heading mb={3} textAlign="center">
        Are you sure you want to delete this life event?
      </Heading>
      <Flex>
        <Button
          m={1}
          disabled={submitting}
          type="button"
          onClick={confirmDestroy}
          variant="destructive"
        >
          Delete
        </Button>
        <Button m={1} disabled={submitting} type="button" variant="muted" onClick={onCancel}>
          Cancel{' '}
        </Button>
      </Flex>
    </Flex>
  );
};

DeleteMilestone.propTypes = {
  milestoneId: PropTypes.string.isRequired,
  memoirId: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
};

export default DeleteMilestone;
