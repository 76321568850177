import styled from '@emotion/styled';

export const Wrap = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.05);
  position: fixed;
  width: 100%;
  padding: 0.5em;
  color: #fff;
  z-index: 10001;
  text-align: center;
`;

export default Wrap;
