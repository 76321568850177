// import React from 'react';
// import { Switch, Route } from 'react-router-dom';

const DrawerHeaderRightLinks = () => {
  return false;
  // saving the Switch in case we need links here
  //
  // return (
  //   <Switch>

  //   </Switch>
  // );
};

export default DrawerHeaderRightLinks;
