import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Entities } from '../../entities';
import { useCurrentMemberId } from '../hooks';
import { useDeepCompareEffect } from '../../app/hooks';
import { useEntities } from '../../entities/hooks';

const getMemberName = (connection = { contact: {} }, members = {}) => {
  const { id: memberId } = connection.contact;
  const member = members[memberId];
  const { 'full-name': memberName } = member;
  if (!memberName) return '';
  return memberName
    .toLowerCase()
    .split(' ')
    .join('');
};

export const useConnections = ({ hasCurrentUser } = {}) => {
  const currentMemberId = useCurrentMemberId();
  const members = useEntities('members');
  const connections = useEntities('connections');
  const [sortedConnections, setState] = useState([]);

  useDeepCompareEffect(() => {
    const nextConnections = Object.values(connections)
      .filter(connection => {
        const memberName = getMemberName(connection, members);
        if (!memberName) return false;
        if (hasCurrentUser) return true;
        return !(connection.contact.id === currentMemberId);
      })
      .sort((a, b) => {
        const connectionNameA = getMemberName(a, members);
        const connectionNameB = getMemberName(b, members);
        if (connectionNameB === connectionNameA) return 0;
        return connectionNameA < connectionNameB ? -1 : 1;
      });
    setState(nextConnections);
  }, [members, connections]);

  return sortedConnections;
};

const ConnectionEntities = ({ children }) => {
  const sortedConnections = useConnections();

  return (
    <Entities type="connections">
      {({ entities, ...restConnections }) =>
        children({
          ...restConnections,
          entities: sortedConnections,
        })
      }
    </Entities>
  );
};

ConnectionEntities.propTypes = {
  children: PropTypes.func.isRequired,
};

export default ConnectionEntities;
