

import PropTypes from 'prop-types';
import React from 'react';
import Tippy from '@tippy.js/react';

import { useConnectionsByStatus } from 'connections/hooks';
import { useCurrentMemberId } from 'members/hooks/index';
import MemberAvatar from 'members/avatar/index';
import { useToggle } from '../../hooks';
import MeDropdown from './me-dropdown';
import NavigationItem from '../item';

const MeLink = ({ showLabel }) => {
  const [on, toggle] = useToggle(false);
  const currentMemberId = useCurrentMemberId();
  const pendingConnections = useConnectionsByStatus('pending');
  const pendingCount = (pendingConnections && pendingConnections.length) || 0;

  const handleClick = () => {
    toggle(false);
  };

  return (
    <Tippy
      content={<MeDropdown handleClick={handleClick} indicatorCount={pendingCount} />}
      isVisible={on}
      trigger="manual"
      interactive
      theme="light"
      zIndex={104}
      arrow
      onHidden={() => on && toggle(false)}
    >
      <NavigationItem
        label="Me"
        indicatorCount={pendingCount}
        showLabel={showLabel}
        handleClick={handleClick}
        active={on}
      >
        <MemberAvatar size={23} id={currentMemberId} />
      </NavigationItem>
    </Tippy>
  );
};

MeLink.defaultProps = {
  showLabel: true,
};

MeLink.propTypes = {
  showLabel: PropTypes.bool,
};

export default MeLink;
