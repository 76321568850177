import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { selectEntity } from 'entities/selectors';

const useStoryPageGalleryMedia = pageId => {
  return useSelector(state => {
    const page = selectEntity({ state, id: pageId, type: 'pages' });
    if (!page) return [];
    // filter to only visible media
    const visibleMedia = page.media.filter(mediumParams => {
      const medium = selectEntity({ state, ...mediumParams });
      return medium.state === 'finished' || medium.state === 'pre_uploaded' || !!medium.tmpUrl;
    });

    // collect captions
    const getIsCaption = (currentMedium, nextMedium) => {
      if (!nextMedium) return false;
      if (currentMedium.type === 'images' && ['texts', 'audios'].includes(nextMedium.type))
        return true;
      if (currentMedium.type === 'videos' && nextMedium.type === 'texts') return true;
      return false;
    };

    return visibleMedia.reduce((acc, mediumParams, index, allMedia) => {
      // if previous medium has caption, and that captoin is this medium, return last value
      const [prevMedium] = acc.slice(-1);
      if (get(prevMedium, 'caption.id') === mediumParams.id) return acc;
      // if (get(prevMedium, 'caption.id', '') === mediumParams.id) return acc;

      // if video or image, check if the next medium is a caption
      if (['videos', 'images'].includes(mediumParams.type)) {
        const nextMedium = allMedia[index + 1];
        const isCaption = getIsCaption(mediumParams, nextMedium);
        if (isCaption) return [...acc, { ...mediumParams, caption: nextMedium }];
      }

      return [...acc, mediumParams];
    }, []);
  });
};

export default useStoryPageGalleryMedia;
