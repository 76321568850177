import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Heading, Button } from 'rebass';
import emptySvg from './assets/empty.svg';

const MilestoneEmpty = ({ onClick }) => (
  <Flex flexDirection="column" alignItems="center" mb={3}>
    <img src={emptySvg} alt="quill and ink" />
    <Heading mt={2} mb={4}>
      Know the details? Share it!
    </Heading>
    <Button onClick={onClick} variant="primary">
      Add Details
    </Button>
  </Flex>
);

MilestoneEmpty.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default MilestoneEmpty;
