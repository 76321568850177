import PropTypes from 'prop-types';
import React from 'react';
import Tippy from '@tippy.js/react';
import { FaTags } from 'react-icons/fa';
import { Text } from 'rebass';

import { IoIosMore } from 'react-icons/io';
import StoryMuteDropdownLink from 'stories/mute/dropdown-link';
import { TooltipButton, TooltipWrap } from './styled';
import { useHistory, useToggle } from '../../app/hooks';
// import { useUserStoryShareFromStory } from '../../story-shares/hooks';
import StoryPermission, { useHasStoryPermission } from '../permission';
import DetailHeaderButton from '../../app/detail/button';

const StoryDetailHeaderOptions = ({ storyId }) => {
  const { replace } = useHistory();
  const [on, toggle] = useToggle();
  // const storyShare = useUserStoryShareFromStory(storyId) || {};
  const canViewOptions = useHasStoryPermission({
    feature: 'VIEW_STORY_OPTIONS',
    id: storyId,
  });
  const canManageTags = useHasStoryPermission({ feature: 'MANAGE_STORY_TAGS', id: storyId });
  const canMute = useHasStoryPermission({ feature: 'TOGGLE_NOTIFICATIONS', id: storyId });

  if (canViewOptions)
    return (
      <DetailHeaderButton icon={IoIosMore} onClick={() => replace(`/story/${storyId}/options`)} />
    );

  if (canManageTags || canMute)
    return (
      <Tippy
        content={
          <TooltipWrap>
            <StoryPermission feature="MANAGE_STORY_TAGS" id={storyId}>
              <TooltipButton
                onClick={() => {
                  replace(`/story/${storyId}/tags`);
                  toggle();
                }}
              >
                <Text fontSize={2} as={FaTags} pr={1} />
                Tag Story
              </TooltipButton>
            </StoryPermission>
            <StoryMuteDropdownLink
              id={storyId}
              onClick={() => {
                replace(`/story/${storyId}/mute`);
                toggle();
              }}
            />
          </TooltipWrap>
        }
        interactive
        theme="light"
        arrow
        trigger="manual"
        isVisible={on}
      >
        <DetailHeaderButton icon={IoIosMore} onClick={toggle} />
      </Tippy>
    );

  return false;
};

StoryDetailHeaderOptions.propTypes = {
  storyId: PropTypes.string.isRequired,
};

export default StoryDetailHeaderOptions;
