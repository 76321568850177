import { Flex, Heading, Image } from 'rebass';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import React from 'react';

import RatioBox from '../../../app/aspect-ratio-box';
import addImageSvg from './assets/add-cover-photo.svg';

const CoverPreview = ({ previewSrc, handleDrop }) => {
  const { getRootProps, getInputProps } = useDropzone({ onDrop: handleDrop });
  return (
    <RatioBox
      ratio="1 / 2"
      borderRadius={8}
      mb={3}
      innerProps={{ sx: { display: 'flex', alignItems: 'stretch', width: '100%' } }}
    >
      {!previewSrc && (
        <Flex
          flex="1"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          bg="blueBackground"
          borderRadius="large"
          css={`
            max-height: 100%;
          `}
          {...getRootProps()}
        >
          <input {...getInputProps({ accept: 'image/*', multiple: false })} />
          <Heading mt="auto" mb={3} fontFamily="sans" color="white">
            Every book needs a cover
          </Heading>
          <Flex
            mb="auto"
            alignItems="center"
            pr={4}
            pl={2}
            css="border: 1px solid white; border-radius: 30px; color: white;"
          >
            <Image height={60} src={addImageSvg} alt="" />
            Add a Cover Photo
          </Flex>
        </Flex>
      )}
      {previewSrc && <Image borderRadius={8} src={previewSrc} alt="staged cover" width="100%" />}
    </RatioBox>
  );
};

CoverPreview.propTypes = {
  previewSrc: PropTypes.string,
  handleDrop: PropTypes.func.isRequired,
};

CoverPreview.defaultProps = {
  previewSrc: '',
};

export default CoverPreview;
