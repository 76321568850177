import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { OuterWrap, InnerWrap, Blur } from './styled';
import { selectMediumError } from '../selectors';
import DestroyPrompt from './destroy';
import Processing from './processing';
import UploadError from './upload';

const Prompt = ({ promptDelete, mediumError, id, pageId, mediumType, storyId, children }) => {
  if (!promptDelete && !mediumError) return children;

  return (
    <OuterWrap>
      <InnerWrap>
        {promptDelete && (
          <DestroyPrompt
            {...{
              id,
              pageId,
              mediumType,
              storyId,
            }}
          />
        )}
        {mediumError === 'processing' && (
          <Processing
            {...{
              id,
              pageId,
              mediumType,
              storyId,
            }}
          />
        )}
        {mediumError === 'upload failed' && (
          <UploadError
            {...{
              id,
              pageId,
              mediumType,
              storyId,
            }}
          />
        )}
      </InnerWrap>
      <Blur>{children}</Blur>
    </OuterWrap>
  );
};

Prompt.propTypes = {
  children: PropTypes.node.isRequired,
  promptDelete: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  mediumType: PropTypes.string.isRequired,
  pageId: PropTypes.string.isRequired,
  storyId: PropTypes.string.isRequired,
  mediumError: PropTypes.string,
};

Prompt.defaultProps = {
  mediumError: '',
};

const mapStateToProps = (state, { id, mediumType }) => {
  const mediumError = selectMediumError(state, id, mediumType);

  return {
    mediumError,
  };
};

export default connect(mapStateToProps)(Prompt);
