import React, { useState } from 'react';
import VideoPreview from "chats/VideoPreview";
import WebcamRecordVideo from 'chats/RecordVideo';
import { Box } from 'rebass';
import { UAContext } from '@quentin-sommer/react-useragent';
import Modal from 'app/modal/v3';
import { UserAgent } from '@quentin-sommer/react-useragent';
import styled from '@emotion/styled';
import ActionItems from './ActionItems';
import { FaVideo } from 'react-icons/fa';
import NewMultipleMedia from 'story-media/new/multiple-media/index';
import { useContext } from 'react';

const Label = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-family: ${props => props.theme.fonts.sanFrancisco};
  position: relative;
  color: ${props => props.theme.colours.brand};
  margin-top: 5px;
  cursor: pointer;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 100%;
    width: 100%;
  }
`;

function RecordVideo({ onSubmitMedia, label = "Record a Video reply", question = '', isSelected = false, onClick = () => { } }) {
    const [file, setFile] = useState(null);
    const [startRecording, setStartRecording] = useState(false);
    const [initialFiles, setInitialFiles] = useState(false);
    const [currentForm, setCurrentForm] = useState('');
    const maxWidth = currentForm ? 620 : 414;
    const {
        uaResults: { android },
    } = useContext(UAContext);

    const onClose = () => setStartRecording(false)

    const handleChange = (e) => {
        onClick()
        if (android) return setInitialFiles(e.target.files);
        return onSubmitMedia(e.target.files, true);
    }

    if (initialFiles)
        return (
            <NewMultipleMedia
                onClose={onClose}
                initialFiles={initialFiles}
                maxWidth={maxWidth}
                onSubmitMedia={files => onSubmitMedia(files)}
            />
        );

    return <>
        {startRecording && <Modal
            close={{ onClose: () => setStartRecording(false) }}
            maxWidth={800}
            title={question}
            closeOnOutsideClick={false}
            aboveTooltip
        >
            {file ?
                <VideoPreview
                    blob={file}
                    onReject={() => setFile(undefined)}
                    onAccept={() => {
                        setStartRecording(false)
                        const newFile = new File([file], file.name, { type: file.type, lastModified: Date.now() });
                        handleChange({ target: { files: [newFile] } })
                        setFile(null)
                    }}
                    minHeight={360}
                    height="auto"
                    width={['100vw', '640px', '800px']}
                    mt={-80}
                />
                : <WebcamRecordVideo onEnded={(blob) => setFile(blob)} />}
        </Modal>}

        <UserAgent mobile>
            {isMobile => (
                <>
                    <Box sx={{ position: 'relative', width: '100%' }}>
                        {isMobile ?
                            <Label htmlFor="selfie_video">
                                <input
                                    type="file"
                                    id="selfie_video"
                                    capture="user"
                                    accept="video/*"
                                    onChange={handleChange}
                                />
                                <ActionItems
                                    onClick={onClick}
                                    icon={FaVideo}
                                    nextIcon={isSelected}
                                    mt={2}
                                    label={label}
                                    isSelected={isSelected}
                                />
                            </Label>
                            :
                            <ActionItems
                                onClick={() => {
                                    setStartRecording(true)
                                    onClick()
                                }}
                                icon={FaVideo}
                                nextIcon={isSelected}
                                my={2}
                                label={label}
                                isSelected={isSelected}
                            />
                        }
                    </Box>
                </>
            )}
        </UserAgent>
    </>
}

export default RecordVideo;