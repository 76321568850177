import React from 'react';
import { Button, Text, Box } from 'rebass';
import { FiScissors, FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import SayYeahFiUndo from './SayYeahFiUndo';

const IconButton = ({ sx, ...props }) => (
  <Button
    type="button"
    variant="videoEdit"
    sx={{
      display: 'flex',
      alignItems: 'center',
      ...(sx || {}),
    }}
    {...props}
  />
);

const Buttons = ({ onClipClick, onUndoClick, onLeftClick, onRightClick }) => {
  return (
    <Box m={3} sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gridGap: 3 }}>
      <IconButton onClick={onLeftClick} sx={{ justifySelf: 'left' }}>
        <Text as={FiArrowLeft} />
        <Text pl={1}>left</Text>
      </IconButton>
      <IconButton onClick={onClipClick} sx={{ justifySelf: 'right' }}>
        <Text as={FiScissors} />
        <Text pl={1}>clip</Text>
      </IconButton>
      <IconButton onClick={onUndoClick} sx={{ justifySelf: 'left' }}>
        <Text as={SayYeahFiUndo} />
        <Text pl={1}>undo</Text>
      </IconButton>
      <IconButton onClick={onRightClick} sx={{ justifySelf: 'right' }}>
        <Text pl={1}>right</Text>
        <Text as={FiArrowRight} />
      </IconButton>
    </Box>
  );
};

export default Buttons;
