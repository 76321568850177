import PropTypes from 'prop-types';
import React from 'react';
import { useEntity } from 'entities/hooks';

const GalleryAudio = ({ id }) => {
  const { audio } = useEntity({ id, type: 'audios' });

  return <audio src={audio.tmpUrl || audio['asset-url']} controls />;
};
GalleryAudio.propTypes = {
  id: PropTypes.string.isRequired,
};

export default GalleryAudio;
