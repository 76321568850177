import { IoMdMove as MoveIcon } from 'react-icons/io';
import { Box } from 'rebass';
import { MdEdit as PencilIcon, MdFileDownload as DownloadIcon } from 'react-icons/md';
import { FaTrash as TrashIcon } from 'react-icons/fa';
import { connect, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import Tippy from '@tippy.js/react';
import EditVideoDropdownLink from 'story-media/video-editor/EditVideoDropdownLink';

import { useEntity } from 'entities/hooks';
import { EditButtonsWrap, MoreLink, MoreLinkAnchor } from './styled';
import { promptDestroyMedium } from './actions';
import { useToggle } from '../app/hooks';
import EllipsisIcon from '../assets/svg/ellipsis-icon';
import MoveStoryPage from '../stories/edit-story/move';

const EditButtons = ({ mediumType, storyId, pagePosition, pageId, id, isGrouped, ...props }) => {
  const promptingDestroy = useSelector(state => state.storyMedia.destroyPrompts.includes(storyId));
  const { entity: medium } = useEntity({ id, type: mediumType });
  const [on, toggle] = useToggle();
  const { pathname } = useLocation();
  const { replace } = useHistory();
  const getEditTextLink = () => {
    if (pathname.includes('new')) {
      return `/stories/new/${storyId}/edit/text/${id}`;
    }
    return `/story/${storyId}/edit/text/${id}`;
  };
  const textEditLink = mediumType === 'texts' ? getEditTextLink() : '';

  return (
    !promptingDestroy && (
      <EditButtonsWrap>
        <Tippy
          trigger="manual"
          placement="bottom right"
          theme="light"
          interactive
          isVisible={on}
          content={
            <Box my="-.3rem">
              {textEditLink && (
                <MoreLink
                  onClick={() => {
                    replace(textEditLink);
                    toggle();
                  }}
                >
                  <PencilIcon />
                  <span>Edit</span>
                </MoreLink>
              )}
              <MoveStoryPage.Consumer>
                {({ moveStoryPageId, setMoveStoryPage }) => (
                  <MoreLink
                    onClick={() => {
                      setMoveStoryPage(pageId, pagePosition);
                      toggle();
                    }}
                    disabled={!!moveStoryPageId}
                  >
                    <MoveIcon />
                    <span>Move</span>
                  </MoreLink>
                )}
              </MoveStoryPage.Consumer>
              {!!medium['unprocessed-url'] && (
                <MoreLinkAnchor
                  target="_blank"
                  href={medium['unprocessed-url']}
                  download={medium['unprocessed-url']}
                  onClick={toggle}
                >
                  <DownloadIcon />
                  <span>Download</span>
                </MoreLinkAnchor>
              )}
              {mediumType === 'videos' && <EditVideoDropdownLink videoId={id} />}
              <MoreLink
                color="red"
                borderBottom="none"
                onClick={() => {
                  props.promptDestroyMedium(id);
                  toggle();
                }}
              >
                <TrashIcon />
                <span>Delete</span>
              </MoreLink>
            </Box>
          }
        >
          <button
            type="button"
            onClick={toggle}
            style={{
              padding: 0,
              border: 'none',
              background: 'none',
            }}
          >
            <EllipsisIcon color="white" />
          </button>
        </Tippy>
      </EditButtonsWrap>
    )
  );
};

EditButtons.propTypes = {
  id: PropTypes.string.isRequired,
  storyId: PropTypes.string.isRequired,
  mediumType: PropTypes.string.isRequired,
  promptDestroyMedium: PropTypes.func.isRequired,
  pageId: PropTypes.string.isRequired,
  pagePosition: PropTypes.number.isRequired,
};

export default connect(null, { promptDestroyMedium })(EditButtons);
