import { Button, Flex, Box } from 'rebass';
import { Form, Field } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';
import UAParser from 'ua-parser-js';

import MarkdownEditor from '../../app/form/formik/markdown-editor';
import TextArea from '../../app/form/formik/auto-grow';

const AndroidFieldWrap = styled.div`
  textarea {
    outline: none;
    font-size: 17px;
    border: none;
    width: 100%;
    resize: none;
    padding: 1em;
    font-family: inherit;
  }
`;

const TextForm = ({ isSubmitting, onCancel }) => {
  const isAndroid = UAParser(navigator.userAgent).os.name === 'Android';

  return (
    <Form as={Box} minHeight={300}>
      {isAndroid ? (
        <AndroidFieldWrap>
          <Field
            name="text"
            component={TextArea}
            type="text"
            placeholder="Add Text"
            style={{
              minHeight: '12vh',
              maxHeight: '48vh',
            }}
          />
        </AndroidFieldWrap>
      ) : (
        <Field component={MarkdownEditor} name="text" />
      )}
      <Flex justifyContent="flex-end" p={3}>
        <Button
          type="button"
          variant="muted"
          onClick={onCancel}
          disabled={isSubmitting}
          mr={3}
          py={2}
          px={3}
        >
          Cancel
        </Button>
        <Button type="submit" variant="primary" disabled={isSubmitting} py={2} px={3}>
          Save
        </Button>
      </Flex>
    </Form>
  );
};

TextForm.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default TextForm;
