import React, { useMemo, useRef, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Box } from 'rebass';
import Modal from 'app/modal/v3';
import { exportCollection } from '../../api';
import { useCollectionTypeName, useParamsFromRoute, useRouteFromEntityParams } from '../../hooks';

const getErrorMessage = error => {
  if (!error) return undefined;
  if (!error.response) return undefined;

  const { data } = error.response;
  const { errors } = JSON.parse(data);

  return errors[0].detail;
};

const ExportCollection = () => {
  const { id, type } = useParams();
  const entityParams = useParamsFromRoute({ type, id });
  const route = useRouteFromEntityParams(entityParams);
  const { replace } = useHistory();
  const typeName = useCollectionTypeName(type);
  const title = useMemo(() => `Export ${typeName}`, [typeName]);

  const shouldRequest = useRef(true);
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (shouldRequest.current) {
      shouldRequest.current = false;
      exportCollection(entityParams)
        .then(() => {
          setSuccess(true);
          setLoading(false);
        })
        .catch(err => {
          setError(true);
          setErrorMessage(getErrorMessage(err));
          setLoading(false);
        });
    }
  });

  return (
    <Modal close={{ onClose: () => replace(`${route}`) }} title={title}>
      <Box p={3}>
        {loading && <div>loading</div>}
        {error && <div>Error: {errorMessage}</div>}
        {success && <p>Success! An email is on its way with a link to download your {typeName}.</p>}
      </Box>
    </Modal>
  );
};

export default ExportCollection;
