import { borderRadius, space } from 'styled-system';
import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';
import { Box } from 'rebass';

const AspectRatioOuter = styled.div`
  position: relative;
  display: block;
  content: '';
  width: 100%;
  padding-top: calc(${({ ratio }) => ratio} * 100%);
  overflow: hidden;
  ${borderRadius};
  ${space};

  & > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

const AspectRatioBox = ({ children, innerProps, ...props }) => (
  <AspectRatioOuter {...props}>
    <Box {...innerProps}>{children}</Box>
  </AspectRatioOuter>
);

AspectRatioBox.propTypes = {
  children: PropTypes.node.isRequired,
  ratio: PropTypes.string.isRequired,
  innerProps: PropTypes.shape({}),
};
AspectRatioBox.defaultProps = {
  innerProps: {},
};

export default AspectRatioBox;
