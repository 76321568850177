import { space } from 'styled-system';
import styled from '@emotion/styled';

export const Wrap = styled.div`
  position: relative;
  background-color: ${props => props.theme.colours.background};
  margin: 1em auto;
  max-width: 600px;
  width: 100%;
  box-shadow: 0 1px 1px 0 #ccc;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15);
  ${space};

  a {
    outline: none;
  }

  /* used for carousel */
  .slider-control-bottomcenter {
    position: relative !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;

    ul {
      top: 0 !important;
    }
    button {
      color: ${props => props.theme.colours.brand} !important;
    }
  }
`;

export default Wrap;
