import React, { useMemo, useState } from 'react';
import Modal from 'app/modal/v3/index';
import { useParams, useHistory } from 'react-router-dom';
import { useEntity } from 'entities/hooks';
import { Box, Heading, Text, Flex, Button } from 'rebass';
import { sendStoryUpdate } from 'stories/api';
import { DateTime } from 'luxon';

const StoryDetailNotify = () => {
  const { id } = useParams();
  const { replace } = useHistory();
  const { story } = useEntity({ id, type: 'stories' });
  const { 'last-update-sent': lastUpdateSent } = story;
  const [loading, setLoading] = useState(false);

  const formattedLastUpdateSent = useMemo(() => {
    if (!lastUpdateSent) return '';
    return DateTime.fromISO(lastUpdateSent).toLocaleString(DateTime.DATE_MED);
  }, [lastUpdateSent]);

  const sendUpdate = () => {
    setLoading(true);
    sendStoryUpdate(id).then(() => {
      setLoading(false);
      replace(`/story/${id}`);
    });
  };

  return (
    <Modal close={{ onClose: () => replace(`/story/${id}`) }} title="Send Update">
      <Box p={3} bg="white">
        <Heading fontSize={3} mb={2}>
          Ready to Send Update?
        </Heading>
        {formattedLastUpdateSent && (
          <Text fontSize={1} mb={1} color="mutedText">
            Last update sent {formattedLastUpdateSent}
          </Text>
        )}
        <Text fontSize={1}>All shared members will receive an update notification.</Text>
      </Box>
      <Flex py={12} px={3} justifyContent="flex-end">
        <Button disabled={loading} variant="primary" onClick={sendUpdate}>
          Send
        </Button>
      </Flex>
    </Modal>
  );
};

export default StoryDetailNotify;
