
import PropTypes from 'prop-types';
import { useRect } from '@reach/rect';
import AspectRatio from 'js-aspect-ratio';
import React, { useMemo, useRef } from 'react';
import ReactPlayer from 'react-player';
import { useEntity } from 'entities/hooks';
import { Box } from 'rebass';

const GalleryVideo = ({ id }) => {
  const { video } = useEntity({ id, type: 'videos' });
  const ref = useRef();
  const rect = useRect(ref);

  const aspectRatio = new AspectRatio(16, 9);

  const [aspectWidth, aspectHeight] = useMemo(() => {
    if (!rect) return [0, 0];
    const { width, height } = rect;
    if (!width && !height) return [0, 0];
    if (width > height) return [width, aspectRatio.getHeight(width)];
    if (width <= height) return [aspectRatio.getWidth(height), height];
    return [0, 0];
  }, [aspectRatio, rect]);

  return (
    <Box ref={ref} flex="1">
      <ReactPlayer
        url={video.tmpUrl || video['asset-url']}
        controls
        css="object-fit: contain;"
        width={aspectWidth}
        height={aspectHeight}
        config={{
          file: {
            attributes: { poster: video['preview-asset-url'] || null },
          },
        }}
      />
    </Box>
  );
};
GalleryVideo.propTypes = {
  id: PropTypes.string.isRequired,
};

export default GalleryVideo;
