import React from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';

const Tablet = ({ hasMax, ...props }) => <Media query={{ minWidth: '768px' }} {...props} />;
Tablet.propTypes = {
  hasMax: PropTypes.bool,
};
Tablet.defaultProps = {
  hasMax: true,
};

export default Tablet;
