import { connect } from 'react-redux';
import Lazyload from 'react-lazyload';
import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';
import isNumber from 'lodash/isNumber';

import { Picture } from './styled';

const getFilterStyle = mediumLoading => ({
  filter: (mediumLoading && 'grayscale') || 'none',
});

const ImageMedium = ({ medium, mediumLoading, height, isCarousel }) => (
  <Lazyload height={height} offset={600} once>
    <Picture isCarousel={isCarousel}>
      <source srcSet={get(medium, '["image-versions"][2].url')} />
      <source srcSet={get(medium, '["image-versions"][5].url')} media="(max-width: 430px)" />
      <img
        src={medium.tmpUrl || medium['asset-url']}
        style={getFilterStyle(mediumLoading)}
        alt=""
      />
    </Picture>
  </Lazyload>
);

ImageMedium.propTypes = {
  medium: PropTypes.shape({}).isRequired,
  mediumLoading: PropTypes.bool,
  height: PropTypes.number,
  isCarousel: PropTypes.bool,
};

ImageMedium.defaultProps = {
  mediumLoading: false,
  height: 100,
  isCarousel: false,
};

const mapStateToProps = (state, ownProps) => ({
  medium: state.entities.images[ownProps.id],
  mediumLoading: isNumber(state.storyMedia.uploadBatch.map(item => item.id).includes(ownProps.id)),
  height: Number((ownProps.height || '').replace('px', '')),
});

export default connect(mapStateToProps)(ImageMedium);
