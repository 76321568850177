import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { CurrentCollectionContext } from '../../../collections/current-collection-context';
import { useLocation } from '../../hooks';
import MyStoriesIcon from '../sidebar/my-stories-icon';
import NavigationItem from '../item';
import { useRouteFromEntityParams } from '../../../collections/hooks';

const ChroniclesLink = ({ showLabel }) => {
  const { pathname } = useLocation();
  const { currentCollection, setCollectionsFilter } = useContext(CurrentCollectionContext);
  const collectionRoute = useRouteFromEntityParams(currentCollection);
  const activePaths = [
    'story',
    'memoirs',
    'custom',
    'family-journals',
    'personal-journals',
    'child-journals',
  ];

  return (
    <NavigationItem
      label="Stories"
      path={currentCollection.id ? collectionRoute : '/my-stories'}
      active={activePaths.some(path => pathname.includes(path))}
      showLabel={showLabel}
      handleClick={() => {
        setCollectionsFilter(null);
      }}
    >
      <MyStoriesIcon />
    </NavigationItem>
  );
};

ChroniclesLink.propTypes = {
  showLabel: PropTypes.bool,
};

ChroniclesLink.defaultProps = {
  showLabel: true,
};

export default ChroniclesLink;
