import styled from '@emotion/styled';
import { withProps } from 'recompose';

export const Wrap = styled.div`
  display: flex;
  align-items: center;
  background-color: ${props => (props.selected ? 'white' : 'transparent')};
  border-radius: ${props => props.theme.borderRadii.normal};
  padding: 4px 0 4px 8px;
  opacity: ${props => (props.disabled ? '0.5' : '1')};

  .buttons-wrap {
    margin-left: auto;
    display: flex;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: auto;
      margin-right: 8px;
      padding: 0;
      border-radius: 50%;
    }
  }

  .tags {
    border: 1px solid #999;
  }

  .unselected {
    height: 40px;
    width: 40px;
    border: 2px solid #999;
    border-radius: 50%;
  }
`;

export const ShareButton = withProps({ type: 'button' })(styled.button`
  display: flex;
  align-items: center;
  margin-top: 8px;
  width: 100%;
  background-color: ${props => props.theme.colours.white};
  padding: 8px 0;
  border-radius: ${props => props.theme.borderRadii.normal};

  img {
    width: 40px;
    height: 40px;
    margin-left: 8px;
    margin-right: 8px;
  }

  svg {
    margin-left: auto;
    margin-right: 16px;
  }
`);
