import { Button } from 'rebass';
import PropTypes from 'prop-types';
import React from 'react';

import { ReactComponent as AlertIcon } from '../../../collections/detail/delete/alert.svg';
import { Header, Body, Title, Copy } from './styled-info';
import Modal from '.';

const ErrorModal = ({ titleText, text, onClose, closeText }) => (
  <Modal onClose={onClose} closeButton>
    <Header>
      <AlertIcon />
    </Header>
    <Body centered>
      <Title>{titleText}</Title>
      <Copy>{text}</Copy>
      <Button onClick={onClose} variant="outline" mt={3}>
        {closeText}
      </Button>
    </Body>
  </Modal>
);

ErrorModal.propTypes = {
  titleText: PropTypes.string,
  text: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  closeText: PropTypes.string.isRequired,
};

ErrorModal.defaultProps = {
  titleText: 'Something went wrong',
};

export default ErrorModal;
