import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  FaExclamationCircle as ExclamationIcon,
} from 'react-icons/fa';
import Button from '../../app/buttons';
import RetryIcon from './retry-icon';
import { destroyMedium, uploadMediumFile } from '../actions';
import { selectMedium } from '../selectors';
import { Message, ButtonWrap, Title } from './styled';

const UploadPrompt = ({
  id, pageId, mediumType, storyId, medium, ...props
}) => (
  <Message>
    <Title>
      <ExclamationIcon size="24" />
      <span>
        Upload Failed
      </span>
    </Title>
    <p>
      Do you want to cancel the upload or retry?
    </p>
    <ButtonWrap>
      <Button
        muted
        onClick={() => (props.destroyMedium(
          id,
          pageId,
          mediumType,
          storyId,
        ))}
      >
        Cancel
      </Button>
      <Button
        primary
        onClick={async () => {
          const file = await fetch(medium.tmpUrl).then(res => res.blob());
          return props.uploadMediumFile(storyId, medium, file);
        }}
      >
        <RetryIcon />
        Retry
      </Button>
    </ButtonWrap>
  </Message>
);

UploadPrompt.propTypes = {
  id: PropTypes.string.isRequired,
  mediumType: PropTypes.string.isRequired,
  pageId: PropTypes.string.isRequired,
  storyId: PropTypes.string.isRequired,
  destroyMedium: PropTypes.func.isRequired,
  uploadMediumFile: PropTypes.func.isRequired,
  medium: PropTypes.shape({
  }).isRequired,
};

const mapStateToProps = (state, { id, mediumType }) => ({
  medium: selectMedium(state, id, mediumType),
});

export default connect(
  mapStateToProps,
  { destroyMedium, uploadMediumFile },
)(UploadPrompt);
