import { Button } from 'rebass';
import { Field, Form } from 'formik';
import { useRect } from '@reach/rect';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import styled from '@emotion/styled';

import CoverField from '../../../app/form/formik/cover';
import DateField from '../../../app/form/formik/date';
import SelectField from '../../../app/form/formik/select';
import TextField from '../../../app/form/formik/text';
import getGenderOptions from './options';

const StyledForm = styled(Form)`
  display: grid;
  grid-gap: 8px;
`;

const CollectionFormBody = ({ isSubmitting, isValid, collection, type }) => {
  const ref = useRef();
  const rect = useRect(ref);

  return (
    <div ref={ref}>
      <StyledForm>
        <Field
          name="cover"
          component={CoverField}
          currentCover={collection['subject-avatar'] && collection['subject-avatar'].id}
          width={rect && rect.width}
        />
        <Field
          component={TextField}
          name="first-name-noac"
          label="First Name"
          showError
          width="100%"
        />
        <Field
          label="Gender"
          component={SelectField}
          options={getGenderOptions(type)}
          name="gender"
          width="100%"
        />
        <Field
          component={DateField}
          name="date-of-birth"
          label="Birthday"
          placeholder="Birthday"
          width="100%"
        />
        <Button variant="primary" type="submit" primary disabled={!isValid || isSubmitting} mt={4}>
          Save
        </Button>
      </StyledForm>
    </div>
  );
};

CollectionFormBody.propTypes = {
  isValid: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  collection: PropTypes.shape({}),
  type: PropTypes.string.isRequired,
};

CollectionFormBody.defaultProps = {
  collection: {},
};

export default CollectionFormBody;
