import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { DateTime } from 'luxon';
import { Text } from 'rebass';

export const useFormattedSeconds = (seconds, format = 'm:ss') =>
  useMemo(
    () => DateTime.fromISO('2012-04-10').plus({ seconds }).toFormat(format),
    [seconds, format]
  );

function FormattedTime({ seconds }) {
  const time = useFormattedSeconds(seconds);
  if (!seconds) return null;
  return <Text as="time">{time}</Text>;
}
FormattedTime.propTypes = {
  seconds: PropTypes.number,
};
FormattedTime.defaultProps = {
  seconds: 0,
};

export default FormattedTime;
