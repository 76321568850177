import React, { useRef, useCallback, useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import useSWR from 'swr';
import { Box, Flex, Button } from 'rebass';
import { useNavSuppressed } from 'ui/hooks';
import { useKeyPress } from 'app/hooks';
import VideoPreview from './VideoPreview';
import Timeline from './Timeline';
import { useSubmitClips, patchGeneratingClipsReducer, useVisibleTrackRange } from './hooks';
import ClipLine from './ClipLine';
import Buttons from './Buttons';

const VideoEditPage = () => {
  const { videoId, id } = useParams();
  const { replace } = useHistory();
  useNavSuppressed();
  useSWR(`/v2/media/${videoId}`);
  const [clipLinePosition, setClipLinePosition] = useState(0);
  const [pixelsPerSecond] = useState(20);
  const [isPlaying, setIsPlaying] = useState(false);
  const outerRef = useRef(null);
  const wideRef = useRef(null);
  const visibleTrackRange = useVisibleTrackRange(wideRef);

  const [loading, setLoading] = useState(false);
  const [seek, setSeek] = useState(null);

  // immer stuff
  const [clips, setClips] = useState([]);
  const undoStack = useRef([]);

  const dispatch = useCallback((action, undoable = true) => {
    setClips(currentState => {
      const [nextState, , inversePatches] = patchGeneratingClipsReducer(currentState, action);
      if (undoable) undoStack.current.push(inversePatches); // store patches if this is undoable
      return nextState;
    });
  }, []);

  const handleUndo = () => {
    if (!undoStack.current.length) return;
    const patches = undoStack.current.pop();
    dispatch({ type: 'applyPatches', patches }, false);
  };

  const handleSelectClip = useCallback(
    clipIndex => {
      dispatch({ type: 'selectClip', clipIndex }, false);
    },
    [dispatch]
  );

  const handleTimelineClick = useCallback(
    position => {
      if (!isPlaying) {
        setClipLinePosition(position);
        setSeek(position);
      }
    },
    [isPlaying]
  );

  const handleCliplineDragEnd = useCallback(
    position => {
      setSeek(position);
      setClipLinePosition(position);
    },
    [setSeek]
  );

  const handleApproachingRightEdge = useCallback(() => {
    const { left, right } = visibleTrackRange;
    const nextCenter = left + (right - left) * 0.5;
    // outerRef.current.scrollLeft = nextCenter;
    outerRef.current.scroll({ left: nextCenter, behaviour: 'smooth' });
  }, [visibleTrackRange]);

  const handlePageLeft = useCallback(() => {
    const { left, right } = visibleTrackRange;
    const nextCenter = left - (right - left) * 0.5;
    // outerRef.current.scrollLeft = nextCenter;
    outerRef.current.scroll({ left: nextCenter, behaviour: 'smooth' });
  }, [visibleTrackRange]);

  const spacePress = useKeyPress(' ');

  useEffect(() => {
    if (spacePress) {
      setIsPlaying(p => !p);
    }
  }, [spacePress]);

  const onFinish = () => replace(`/story/${id}/edit`);
  const submitClips = useSubmitClips(videoId, clips, {
    onStart: () => setLoading(true),
    onFinish,
  });

  const [originalDuration, setOriginalDuration] = useState(null);

  const arrowRightPress = useKeyPress('ArrowRight');

  useEffect(() => {
    if (arrowRightPress) {
      setClipLinePosition(pos =>
        Math.min(pos + 2.5 * pixelsPerSecond, originalDuration * pixelsPerSecond)
      );
      setSeek(pos => Math.min(pos + 2.5 * pixelsPerSecond, originalDuration * pixelsPerSecond));
    }
  }, [arrowRightPress, originalDuration, pixelsPerSecond]);

  const arrowLeftPress = useKeyPress('ArrowLeft');

  useEffect(() => {
    if (arrowLeftPress) {
      setClipLinePosition(pos => Math.max(pos - 2.5 * pixelsPerSecond, 0));
    }
    if (arrowLeftPress) {
      setSeek(pos => Math.max(pos - 2.5 * pixelsPerSecond, 0));
    }
  }, [arrowLeftPress, arrowRightPress, originalDuration, pixelsPerSecond]);

  return (
    <Box
      sx={{
        height: '100vh',
        width: '100%',
        display: 'grid',
        gridTemplateRows: 'max-content 1fr max-content',
        bg: 'black',
        mb: '-64px',
      }}
    >
      <Flex justifyContent="flex-end" p={3}>
        <Button disabled={loading} variant="videoEdit" onClick={onFinish} type="button" mr="auto">
          Cancel
        </Button>
        <Button disabled={loading} variant="videoEdit" onClick={submitClips} type="button">
          Done
        </Button>
      </Flex>
      <VideoPreview
        seek={seek / pixelsPerSecond}
        onPlay={e => {
          setIsPlaying(true);
        }}
        onPause={() => {
          setIsPlaying(false);
        }}
        onEnded={() => {
          setIsPlaying(false);
          setClipLinePosition(0);
        }}
        onLoadedMetadata={e => {
          dispatch({ type: 'makeClip', endAt: e.target.duration }, false);
          setOriginalDuration(e.target.duration);
        }}
        isPlaying={isPlaying}
      />
      <Box sx={{ display: 'grid', gridTemplateRows: 'max-content 1fr' }}>
        <Buttons
          onClipClick={() =>
            dispatch({ type: 'makeClip', endAt: clipLinePosition / pixelsPerSecond })
          }
          onUndoClick={handleUndo}
          onLeftClick={handlePageLeft}
          onRightClick={handleApproachingRightEdge}
        />
        <Box ref={outerRef} maxWidth="100%" sx={{ overflowX: 'scroll' }}>
          <Box
            height="100%"
            ref={wideRef}
            width={pixelsPerSecond * originalDuration}
            sx={{ display: 'grid' }}
          >
            <ClipLine
              isPlaying={isPlaying}
              pixelsPerSecond={pixelsPerSecond}
              originalDuration={originalDuration}
              clipLinePosition={clipLinePosition}
              onDrag={setSeek}
              onDragEnd={handleCliplineDragEnd}
              setClipLinePosition={setClipLinePosition}
              visibleTrackRangeRight={visibleTrackRange.right}
              onApproachingRightEdge={handleApproachingRightEdge}
            />
            <Timeline
              onSelectClip={handleSelectClip}
              onTimelineClick={handleTimelineClick}
              originalDuration={originalDuration}
              clips={clips}
              pixelsPerSecond={pixelsPerSecond}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

// VideoEditPage.propTypes = {
//   videoId: PropTypes.string.isRequired,
// };

export default VideoEditPage;
