import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Image } from 'rebass';
import VideoThumbnail from 'react-video-thumbnail';
import { useParams } from 'react-router-dom';
import { useEntity } from 'entities/hooks';
import { range } from 'lodash';

const ThumbnailImage = ({ url, seconds, height, width, opacity }) => {
  const [src, setSrc] = useState(null);

  if (!src)
    return (
      <Box sx={{ overflow: 'hidden', height, width }}>
        <VideoThumbnail
          videoUrl={url}
          snapshotAtTime={seconds}
          thumbnailHandler={snapshot => setSrc(snapshot)}
        />
      </Box>
    );

  return <Image src={src} flex="0 0 auto" sx={{ objectFit: 'cover', opacity, height, width }} />;
};
ThumbnailImage.propTypes = {
  url: PropTypes.string.isRequired,
  seconds: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  opacity: PropTypes.number.isRequired,
};

const Clip = React.memo(({ clip, height, width, children, ...props }) => {
  const { videoId } = useParams();
  // const { video } = useEntity({ type: 'videos', id: videoId });
  // const containingSnapshots = useMemo(
  //   () => range(Math.floor(clip.startAt), Math.ceil(clip.endAt), 2),
  //   [clip]
  // );
  return (
    <Flex
      bg={clip.isSelected ? 'rgba(255, 43, 0, 0.3)' : 'rgba(255, 255, 255, .12)'}
      key={`${clip.startAt}${clip.endAt}`}
      sx={{
        borderRadius: 5,
        overflow: 'hidden',
        boxShadow: 'inset 0 0 0 1px #e7e7e7',
      }}
      height={height}
      width={width - 1}
      {...props}
    />
  );
});

Clip.propTypes = {
  clip: PropTypes.shape({
    startAt: PropTypes.number,
    endAt: PropTypes.number,
  }).isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};

export default Clip;
