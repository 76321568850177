import PropTypes from 'prop-types';
import React from 'react';

import { ImageEntity } from '../../../../entities';
import StoryImages from '../../../images';
import Thumbnail from './thumbnail';

const CoverPickerThumbnails = ({ storyId, handleClick, selected }) => (
  <StoryImages id={storyId}>
    {({ images }) =>
      images.map(image => (
        <ImageEntity key={`thumbnail-${image.id}`} id={image.id} size={2}>
          {({ thumbnailSrc, assetSrc, tmpUrlSrc }) => (
            <Thumbnail
              url={thumbnailSrc || tmpUrlSrc || assetSrc}
              selected={image.id === selected}
              onClick={() => handleClick(image)}
            />
          )}
        </ImageEntity>
      ))
    }
  </StoryImages>
);

CoverPickerThumbnails.propTypes = {
  storyId: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
};

export default CoverPickerThumbnails;
