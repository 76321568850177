import { connect } from 'react-redux';
import { object, string } from 'yup';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import React, { useEffect } from 'react';
import { Flex, Button, Text } from 'rebass';
import { updateMember } from 'members/actions';
import { getMemberCollection } from 'members/api';
import { useCurrentMember } from 'members/hooks/index';
import TextField from 'app/form/formik/text/index';

const Profile = ({ label, onNextPress, ...props }) => {
  const member = useCurrentMember();

  useEffect(() => {
    if (member?.['first-name'] && member?.['last-name'])
      onNextPress();
  }, [member?.['first-name'], member?.['last-name']])

  return (
    <Flex flexDirection="column" width="100%">
      <Text
        mt={3}
        sx={{
          color: "#16161D",
          textAlign: "center",
          fontFamily: "Zilla Slab",
          fontSize: "20px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: '120%',
          letterSpacing: '-0.44px'
        }}>{label}</Text>
      <Formik
        initialValues={{
          'first-name': member['first-name'] || '',
          'last-name': member['last-name'] || '',
          'lock-version': member['lock-version'],
        }}
        onSubmit={async ({ ...values }, { setSubmitting }) => {
          await props.updateMember(member.id, { ...values });
          setSubmitting(false);
          onNextPress();
          return false;
        }}
        validationSchema={object().shape({
          'first-name': string()
            .required('First Name is required')
            .nullable(),
          'last-name': string()
            .required('Last Name is required')
            .nullable(),
        })}
      >
        {({ isValid, isSubmitting }) => (
          <Form style={{ textAlign: 'center' }}>
            <Field name="first-name" component={TextField} placeholder="First Name" showError width="100%" my={3} />
            <Field name="last-name" component={TextField} placeholder="Last Name" showError width="100%" />
            <Button
              type="submit"
              height={60}
              m="auto"
              mt={3}
              bg="#53F"
              disabled={!isValid || isSubmitting}
              sx={{ marginTop: '32px', borderRadius: '200px' }}
            >
              Tell this story
            </Button>
          </Form>
        )}
      </Formik>
    </Flex>
  );
};

Profile.propTypes = {
  updateMember: PropTypes.func.isRequired,
};

export default connect(null, { updateMember })(Profile);
