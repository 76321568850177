import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { color } from 'styled-system';

export const HeaderWrap = styled.div`
  background-image: url(${props => props.imageUrl});
  background-color: ${props => props.theme.colours.blueBackground};
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  max-width: ${props => props.theme.keyedBreakpoints.xl};
  margin: 60px auto auto auto;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: ${props =>
      !!props.imageUrl &&
      `linear-gradient(${props.theme.colours.photoGradientStart}, ${props.theme.colours.photoGradientEnd})`};
  }

  ${props => props.theme.media.tablet`
    height: 60vh;
  `}
`;

export const TitleWrap = styled.div`
  color: #fff;
  position: relative;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    margin: 10px 0;
  }
`;

export const EditLink = styled(Link)`
  border: 4px dashed rgba(255, 255, 255, 0.3);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: ${props => props.theme.colours.white};
  text-decoration: none;
  position: relative;
  z-index: 1;
  height: 190px;
  width: calc(100% - ${props => props.theme.gutter});
  max-width: 600px;
  margin: auto 0 0.6em;
  text-align: center;
  transition: background 0.2s ease;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  .story-detail-header__title-and-dates {
    margin: auto;
  }
`;

export const IconWrap = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  background: ${props => props.theme.colours.white};
  border-radius: 50%;
  font-size: 20px;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-left: 3px;
  }
`;

export const Wrap = styled.div`
  width: 100%;
`;

export const EditHeader = styled.div`
  display: flex;
  width: 100%;
  position: sticky;
  top: 0;
  transition: 0.3s ease all;
  margin-bottom: -60px;
  z-index: 2;
  padding: 8px;
  ${color};

  ${props => props.theme.media.desktop`
    top: 70px;
  `}
`;

export const MoveHeaderWrap = styled.div``;
