import React, { useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass';
import { useWindowSize } from 'the-platform';
import { useRect } from '@reach/rect';
import RulerSeconds from './RulerSeconds';
import Clip from './Clip';

const Timeline = ({
  clips,
  interval,
  onSelectClip,
  pixelsPerSecond,
  originalDuration,
  onTimelineClick,
}) => {
  const containerRef = useRef(null);
  const innerRef = useRef(null);
  const rect = useRect(innerRef);
  const { width } = useWindowSize();

  const durationSeconds = useMemo(() => {
    if (!originalDuration) return 0;
    return Math.ceil(originalDuration) + 1;
  }, [originalDuration]);

  const viewportSeconds = useMemo(() => {
    if (width) {
      return Math.ceil(width / pixelsPerSecond);
    }
    return 0;
  }, [pixelsPerSecond, width]);

  const displaySeconds = useMemo(() => {
    // if (!(durationSeconds && viewportSeconds)) return [];
    const seconds = Math.max(durationSeconds, viewportSeconds);
    // debugger;

    return new Array(seconds)
      .fill()
      .map((_, i) => i)
      .filter(n => n % interval === 0);
  }, [durationSeconds, interval, viewportSeconds]);

  const trackWidth = pixelsPerSecond * originalDuration;
  const rulerWidth = Math.max(trackWidth, width);

  return (
    <Box ref={containerRef} width="100%" bg="black" sx={{ gridArea: '1 / 1 / 2 / 2' }}>
      <Box
        ref={innerRef}
        width={rulerWidth + 32}
        sx={{
          display: 'grid',
          gridTemplateRows: 'max-content 1fr',
          borderTop: '1px solid',
          borderColor: '#3A3A3A',
          overflow: 'hidden',
        }}
        px={3}
        onClick={e => onTimelineClick(e.clientX - 16 + (rect.left ? rect.left * -1 : 0))}
      >
        {/* <Flex> */}
        {/*   {displaySeconds.map(second => ( */}
        {/*     <RulerSeconds */}
        {/*       seconds={second} */}
        {/*       pixelsPerSecond={pixelsPerSecond} */}
        {/*       key={`ruler=${second}`} */}
        {/*       interval={interval} */}
        {/*     /> */}
        {/*   ))} */}
        {/* </Flex> */}
        <Box sx={{ display: 'grid', overflow: 'hidden' }} width={trackWidth} mr={3}>
          <Flex justifyContent="space-between" py={3} sx={{ gridArea: '1 / 1 / 1 / 1', zIndex: 1 }}>
            {clips.map((clip, clipIndex) => {
              const width = (clip.endAt - clip.startAt) * pixelsPerSecond;
              const outerWidth = clipIndex ? width - 2 : width;
              return (
                <Box
                  key={`${clip.startAt}${clip.endAt}`}
                  width={outerWidth}
                  sx={{ overflow: 'hidden' }}
                  borderLeft="1px solid black"
                  borderRight="1px solid black"
                >
                  <Text
                    color="#e7e7e7"
                    fontSize={1}
                    pb={2}
                    sx={{ whiteSpace: 'nowrap', userSelect: 'none' }}
                    pl={1}
                  >
                    {clip.label}
                  </Text>
                  <Clip
                    height={75}
                    clip={clip}
                    width={outerWidth}
                    onClick={() => onSelectClip(clipIndex, clip)}
                  />
                </Box>
              );
            })}
          </Flex>
          <Box width={trackWidth} py={3} sx={{ gridArea: '1 / 1 / 1 / 1', overflow: 'hidden' }}>
            <Text color="black" sx={{ userSelect: 'false' }} pb={2} fontSize={1} whiteSpace>
              Seconds
            </Text>
            <Flex width={rulerWidth}>
              {displaySeconds.map(second => (
                <RulerSeconds
                  seconds={second}
                  pixelsPerSecond={pixelsPerSecond}
                  key={`ruler=${second}`}
                  interval={interval}
                />
              ))}
            </Flex>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

Timeline.propTypes = {
  pixelsPerSecond: PropTypes.number.isRequired,
  onSelectClip: PropTypes.func.isRequired,
  clips: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  originalDuration: PropTypes.number.isRequired,
  interval: PropTypes.number,
  onTimelineClick: PropTypes.func.isRequired,
};

Timeline.defaultProps = {
  interval: 5,
};

export default Timeline;
