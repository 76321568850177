
import { Button, Flex, Heading } from 'rebass';
import { boxShadow, border } from 'styled-system';
import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';
import store from 'store';

const Outer = styled(Flex)`
  ${border};
  ${boxShadow};
`;

const Inner = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  max-width: 600px;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
  margin: 0 8px;
`;

const NewStoryHeader = ({ onCancel, onBack, onNext, title, disabled }) => {
  const isRespondingToRequest = store.get('requestResponse') === 'true';

  return (
    <Outer
      bg="white"
      justifyContent="center"
      mb={3}
      borderBottom="1px solid"
      borderColor="border"
      boxShadow="small"
    >
      <Inner>
        {!isRespondingToRequest && onCancel && (
          <Button
            disabled={disabled}
            type="button"
            variant="transparent"
            onClick={onCancel}
            color="mutedText"
            css="grid-column: 1 / 2; justify-self: start;"
          >
            Cancel
          </Button>
        )}
        {onBack && (
          <Button
            disabled={disabled}
            type="button"
            variant="outline"
            onClick={onBack}
            color="mutedText"
            css="grid-column: 1 / 2; justify-self: start;"
          >
            Back
          </Button>
        )}
        <Heading
          fontFamily="sans"
          fontSize={3}
          fontWeight="normal"
          css="grid-column: 2 / 3; justify-self: center;"
        >
          {title}
        </Heading>
        {onNext && (
          <Button
            disabled={disabled}
            type="button"
            variant="primary"
            onClick={onNext}
            css="grid-column: 3 / 4; justify-self: end;"
          >
            Next
          </Button>
        )}
      </Inner>
    </Outer>
  );
};

NewStoryHeader.propTypes = {
  onCancel: PropTypes.func,
  onBack: PropTypes.func,
  onNext: PropTypes.func,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  nextLabel: PropTypes.string,
};

NewStoryHeader.defaultProps = {
  onCancel: null,
  onBack: null,
  onNext: null,
  title: '',
  disabled: false,
  nextLabel: 'Next',
};

export default NewStoryHeader;
