import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tippy from '@tippy.js/react';
import { FaTag, FaTrashAlt } from 'react-icons/fa';
import CollectionPermission from 'collections/permissions';

import StoryPermission, { useHasStoryPermission } from 'stories/permission';
import { useHistory } from 'react-router-dom';
import { useStoryFromStoryShare } from 'stories/hooks/index';
import { jsonApiReferenceShape } from 'app/shapes/index';
import StoryMuteDropdownLink from 'stories/mute/dropdown-link';
import { TooltipButton, TooltipWrap } from '../../../stories/story-detail-header/styled';
import { OptionsButton } from './styled';
import EllipsisIcon from '../../../assets/svg/ellipsis-icon';

import { useMatch } from '../../../app/hooks';

const CollectionStoryOptions = ({ type, id, collectionParams }) => {
  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const { replace } = useHistory();
  const { url } = useMatch();
  const { story } = useStoryFromStoryShare({ id, type });
  const { id: storyId } = story;
  const canManageTags = useHasStoryPermission({ feature: 'MANAGE_STORY_TAGS', id: storyId });
  const canViewOptions = useHasStoryPermission({ feature: 'VIEW_STORY_OPTIONS', id: storyId });
  const canMute = useHasStoryPermission({ feature: 'TOGGLE_NOTIFICATIONS', id: storyId });
  const hasDropdown = !canViewOptions && (canManageTags || canMute);

  return (
    <>
      <StoryPermission feature="VIEW_STORY_OPTIONS" id={storyId}>
        <OptionsButton onClick={() => replace(`${url}/story-options/${storyId}`)}>
          <EllipsisIcon />
        </OptionsButton>
      </StoryPermission>
      {hasDropdown && (
        <Tippy
          content={
            <TooltipWrap>
              <CollectionPermission
                feature="REMOVE_STORY"
                id={collectionParams.id}
                type={collectionParams.type}
              >
                <TooltipButton
                  onClick={() => {
                    replace(`${url}/remove-story/${storyId}`);
                    setTooltipOpen(false);
                  }}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <FaTrashAlt />
                  &nbsp;Remove Story
                </TooltipButton>
              </CollectionPermission>
              <StoryPermission feature="MANAGE_STORY_TAGS" id={storyId}>
                <TooltipButton
                  onClick={() => {
                    replace(`${url}/story-tags/${storyId}`);
                    setTooltipOpen(false);
                  }}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <FaTag />
                  &nbsp;Tag Story
                </TooltipButton>
              </StoryPermission>
              <StoryMuteDropdownLink
                id={storyId}
                onClick={() => {
                  replace(`${url}/mute/${storyId}`);
                  setTooltipOpen(false);
                }}
              />
            </TooltipWrap>
          }
          interactive
          theme="light"
          arrow
          trigger="manual"
          placement="bottom-end"
          isVisible={isTooltipOpen}
        >
          <OptionsButton onClick={() => setTooltipOpen(open => !open)}>
            <EllipsisIcon />
          </OptionsButton>
        </Tippy>
      )}
    </>
  );
};

CollectionStoryOptions.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  collectionParams: jsonApiReferenceShape.isRequired,
};

export default CollectionStoryOptions;
