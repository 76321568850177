import { useSelector } from 'react-redux';

import { selectEntity } from 'entities/selectors';
import { useCollectionCollection } from '.';

const useCollectionShares = ({ filterIsOwner, filterNoReadOnly } = {}) => {
  const collection = useCollectionCollection();

  return useSelector(state => {
    return (collection['journal-shares'] || []).filter(({ id, type }) => {
      const journalShare = selectEntity({ state, id, type });
      if (filterNoReadOnly && journalShare['contact-role'] === 'read_only') return false;
      if (filterIsOwner && journalShare['contact-role'] !== 'owner') return true;
      return true;
    });
  });
};

export default useCollectionShares;
