import styled from '@emotion/styled';
import { css } from '@emotion/core';

export const PillButton = styled.button`
  display: flex;
  align-items: center;
  font-family: sans-serif;
  height: 32px;
  line-height: 32px;
  padding: 0 1em;
  background: none;
  font-size: 14px;
  border-radius: 16px;
  border: 1px solid;
  border-color: ${props => (props.primary ? props.theme.colours.brand : props.theme.colours.mutedText)};
  color: ${props => (props.primary ? props.theme.colours.brand : props.theme.colours.mutedText)};
  cursor: pointer;
  ${props => props.destructive && css`
    color: ${props.theme.colours.red};
    border-color: ${props.theme.colours.red};
  `};
`;

export const PillButtonLabel = styled.label`
  display:inline-block;
  font-family: sans-serif;
  height: 32px;
  line-height: 32px;
  padding: 0 1em;
  background: none;
  font-size: 14px;
  border-radius: 16px;
  border: 1px solid;
  border-color: ${props => (props.primary ? props.theme.colours.brand : props.theme.colours.mutedText)};
  color: ${props => (props.primary ? props.theme.colours.brand : props.theme.colours.mutedText)};
  cursor: pointer;
`;

export const ButtonsWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 10px;
  & > button, label {
    margin: 10px;
  }
  ${props => props.theme.media.phone`
    flex-direction: row;
  `};
`;
