import { Box, Button, Flex } from 'rebass';
import { Formik, Field, Form } from 'formik';
import { isEmpty, omit } from 'lodash';
import { object, string } from 'yup';
import { pluralize } from 'inflected';
import { useRect } from '@reach/rect';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';

import { patchCollection } from '../../api';
import { prepareAttrs } from '../../../utils/api';
import { useCreateCover } from '../../hooks';
import { useEntity, useEntityActionCreators } from '../../../entities/hooks';
import CoverField from '../../../app/form/formik/cover';
import TextField from '../../../app/form/formik/text';

const EditBasicCollection = ({ type, id, onSuccess }) => {
  const { entity: collection } = useEntity({ id, type: pluralize(type) });
  const { receive } = useEntityActionCreators();
  const createCover = useCreateCover();
  const ref = useRef();
  const rect = useRect(ref);

  if (isEmpty(collection)) return false;

  return (
    <Formik
      initialValues={{
        'name-noac': collection.name,
        cover: '',
      }}
      onSubmit={async ({ cover, ...values }, { setSubmitting }) => {
        const attrs = prepareAttrs(values);
        if (cover instanceof Blob) {
          // create chronicle subject avatar
          await createCover({ id, type, cover });
        }
        const payload = await patchCollection({ type, id, attributes: attrs });
        // omit cover-image from patch collection response
        const updatedJournal = Object.values(payload.data.entities[type])[0];
        payload.data.entities[type][updatedJournal.id] = omit(updatedJournal, 'cover-image');

        receive({ payload });
        setSubmitting(false);
        onSuccess();
      }}
      validationSchema={object().shape({
        'name-noac': string().required('Name is required'),
      })}
      render={({ isValid, isSubmitting }) => (
        <div ref={ref}>
          <Form>
            <Box mb={3}>
              <Field
                name="cover"
                component={CoverField}
                currentCover={collection['cover-image'] && collection['cover-image'].id}
                width={rect && rect.width}
                hasConfirm
              />
            </Box>
            <Box mb={3}>
              <Field component={TextField} name="name-noac" label="Name" showError width="100%" />
            </Box>
            <Flex mb={3}>
              <Button
                variant="primary"
                type="submit"
                disabled={!isValid || isSubmitting}
                flex="1"
                py={12}
              >
                Save
              </Button>
            </Flex>
          </Form>
        </div>
      )}
    />
  );
};

EditBasicCollection.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default EditBasicCollection;
