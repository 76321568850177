import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import DeleteModal from 'app/delete-modal';
import { removeStoryFromCollection } from 'collections/api';
import { useEntityActionCreators } from 'entities/hooks/index';

const RemoveStoryFromCollection = () => {
  const { storyId, id, type } = useParams();
  const { replace } = useHistory();
  const [loading, setLoading] = useState(false);
  const { receiveDeletion } = useEntityActionCreators();

  const goToCollection = () => replace(`/${type}/${id}`);

  return (
    <DeleteModal
      message="Are you sure you want to remove this story from the collection?"
      loading={loading}
      onCancel={goToCollection}
      onConfirm={async () => {
        setLoading(true);
        await removeStoryFromCollection({ id, type, storyId });
        receiveDeletion({ entityType: 'stories', id: storyId });

        goToCollection();
      }}
      deleteLabel="Remove Story"
    />
  );
};

export default RemoveStoryFromCollection;
