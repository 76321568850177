import React, { useMemo } from 'react';
import { Box } from 'rebass';
import useSWR from 'swr';
import { useCurrentMemberId } from 'members/hooks/index';
import { getSWR } from 'utils/api';
import { ConnectionsWrap } from './styled';
import AddMemberAvatar from './member-avatar';

const FriendSuggestions = () => {
  const memberId = useCurrentMemberId();
  const { data } = useSWR(`/v2/members/${memberId}/suggested`, getSWR, {
    revalidateOnFocus: false,
  });

  const members = useMemo(() => {
    if (!data) return [];
    return data.data.data;
  }, [data]);

  if (!members.length) return false;

  return (
    <Box>
      <h2>People You May Know</h2>
      <ConnectionsWrap>
        {members.map(({ id }) => (
          <AddMemberAvatar id={id} key={id} />
        ))}
      </ConnectionsWrap>
    </Box>
  );
};

export default FriendSuggestions;
