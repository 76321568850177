import { useNetworkStatus } from 'the-platform';
import React from 'react';

import { Wrap } from './styled';

const ConnectionStatus = () => {
  const { isOnline } = useNetworkStatus();

  if (isOnline) return false;

  return <Wrap className="offline">connection lost</Wrap>;
};

export default ConnectionStatus;
