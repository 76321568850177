import styled from '@emotion/styled';

export const ModalWrap = styled.div`
  width: 100vw;
  height: 100vh;
  background: #fff;
  background: rgba(255, 255, 255, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
`;

export const LocalWrap = styled.div`
  z-index: 10;
  display: flex;
`;

export const Spinner = styled.div`
  font-size: 4px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(122, 105, 191, 0.2);
  border-right: 1.1em solid rgba(122, 105, 191, 0.2);
  border-bottom: 1.1em solid rgba(122, 105, 191, 0.2);
  border-left: 1.1em solid #7a69bf;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
  margin: auto;
  border-radius: 50%;
  width: 88px;
  height: 88px;

  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;
