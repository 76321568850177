import { Formik } from 'formik';
import { connect } from 'react-redux';
import { object, string } from 'yup';
import PropTypes from 'prop-types';
import React from 'react';

import { bodyWithLockVersion } from '../../../app/form/helper';
import { createStory, updateStory } from '../../actions';
import { useEntity } from '../../../entities/hooks';
import { useJsDate, useToggle } from '../../../app/hooks';
import CoverPhoto from './async-cover';
import Form from './form';
import Modal from '../../../app/modal/v3';
import { useStoryWithJournalId } from '../../hooks/index';

const StoryForm = ({ storyId, onClose, ...props }) => {
  const { entity } = useEntity({ type: 'stories', id: storyId });
  const story = useStoryWithJournalId(entity);

  const [tagsToggled, toggle] = useToggle();
  const initialBegan = useJsDate(story['began-on']);
  const initialEnded = useJsDate(story['ended-on']);

  return (
    <Modal aboveTooltip closeOnOutsideClick={false} close={{ onClose }} closeButton>
      {!tagsToggled && <CoverPhoto id={storyId} />}
      <Formik
        initialValues={{
          'began-on': initialBegan,
          'ended-on': initialEnded,
          title: story.title === 'Untitled Story' ? '' : story.title,
          id: storyId,
          'journal-id': story['journal-id'],
        }}
        onSubmit={async values => {
          props.updateStory(storyId, bodyWithLockVersion(story, values)).then(() => {
            onClose();
          });
        }}
        validationSchema={object().shape({
          title: string().required(),
        })}
      >
        {formProps => (
          <Form
            hasTags={!!story['journal-id']}
            isShowingTags={tagsToggled}
            toggleTags={toggle}
            handleCancel={onClose}
            {...formProps}
          />
        )}
      </Formik>
    </Modal>
  );
};

StoryForm.propTypes = {
  createStory: PropTypes.func.isRequired,
  updateStory: PropTypes.func.isRequired,
  getMemberCollection: PropTypes.func.isRequired,
  storyId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default connect(null, {
  createStory,
  updateStory,
})(StoryForm);
