
import { Box, Text, Flex, Button } from 'rebass';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';

import FriendProfileLink from 'friends/profile/link';
import StoryPermission from 'stories/permission/index';
import { jsonApiReferenceShape } from 'app/shapes/index';
import { HeaderBottomWrap } from './styled';
import { HeaderWrap, Header, BlurredHeader } from '../../collections/detail/header/styled';
import { DesktopLarge } from '../../app/responsive';
import { getCoverUrl } from '../helper';
import { selectStoryCover } from '../selectors';
import { useStoryDate } from '../hooks';
import Avatar from '../../members/avatar';
import SharedWithAvatars from '../shared-with-avatars';
import StoryActivity from './story-activity';
import { useEntity } from '../../entities/hooks';
import MyStoriesIcon from '../../app/navigation/sidebar/my-stories-icon';
import TagBadgeList from '../tags/badge-list';
import { CurrentCollectionContext } from '../../collections/current-collection-context';
import addTagSvg from '../tags/assets/add-tag.svg';
import { useCollectionTypeFromCollection, useRouteFromEntityParams } from '../../collections/hooks';

const StoryDetailHeader = ({ story, coverUrl }) => {
  const { push } = useHistory();
  const storyDate = useStoryDate(story);

  const { currentCollection, setCurrentCollection } = useContext(CurrentCollectionContext);
  const { entity: storyCreator } = useEntity({
    type: 'members',
    id: story && story.member && story.member.id,
  });
  const storyJournals = (story && story.journals) || [];
  const journalCount = storyJournals.length;
  const { entity: firstJournal } = useEntity(storyJournals ? storyJournals[0] : {});
  const firstJournalName = firstJournal && firstJournal.name;
  const firstJournalType = useCollectionTypeFromCollection(firstJournal);
  const firstJournalRoute = useRouteFromEntityParams({
    type: firstJournalType,
    id: firstJournal.id,
  });

  if (isEmpty(currentCollection) && !isEmpty(firstJournal)) {
    setCurrentCollection({ id: firstJournal.id, type: firstJournalType });
  }

  return (
    <HeaderWrap>
      <DesktopLarge>{giant => giant && <BlurredHeader img={coverUrl} />}</DesktopLarge>
      <Header img={coverUrl}>
        <HeaderBottomWrap mx={[3, 4]}>
          <Box>
            {storyJournals && (
              <Flex alignItems="center" color="white">
                <MyStoriesIcon width={20} />
                <Text textAlign="left" fontSize={[0, 2]} color="white" ml={1}>
                  <Button
                    variant="transparent"
                    onClick={() => {
                      push(firstJournalRoute);
                      setCurrentCollection({ id: firstJournal.id, type: firstJournalType });
                    }}
                  >
                    {firstJournalName}
                  </Button>
                  {journalCount > 1 ? `, ${journalCount - 1} more` : ''}
                </Text>
              </Flex>
            )}
            <h1>{story.title || storyDate}</h1>
            {storyDate && (
              <Text textAlign="left" fontSize={[0, 2]} color="white">
                {storyDate}
              </Text>
            )}
          </Box>
          <Flex flexDirection="column" ml="auto">
            <StoryActivity {...story} />
            <StoryPermission id={story.id} feature="TAG_STORY">
              <>
                {!isEmpty(story.tags) && (
                  <TagBadgeList
                    storyId={story.id}
                    onClick={() => push(`/story/${story.id}/tags`)}
                  />
                )}
                {isEmpty(story.tags) && currentCollection && (
                  <Button
                    variant="transparent"
                    ml="auto"
                    mt={2}
                    css="padding: 0;"
                    onClick={() => push(`/story/${story.id}/tags`)}
                  >
                    <img src={addTagSvg} alt="add tag" />
                  </Button>
                )}
              </>
            </StoryPermission>
          </Flex>
        </HeaderBottomWrap>
      </Header>
      <Box bg="white">
        <Flex px={[3, 4]} py={[2, 3]} css="max-width: 1200px;" mx="auto">
          <Box mr="auto">
            <Text mb={2} color="mutedText" fontSize={1}>
              Created by
            </Text>
            <FriendProfileLink id={story.member.id}>
              <Flex alignItems="center">
                <Avatar id={story.member && story.member.id} size={40} />
                <Text ml={2} fontFamily="quicksand" fontSize={3}>
                  {storyCreator && storyCreator['full-name']}
                </Text>
              </Flex>
            </FriendProfileLink>
          </Box>
          <Box>
            <StoryPermission id={story.id} feature="VIEW_MEMBER_AVATARS">
              <SharedWithAvatars />
            </StoryPermission>
          </Box>
        </Flex>
      </Box>
    </HeaderWrap>
  );
};

StoryDetailHeader.propTypes = {
  story: PropTypes.shape({
    title: PropTypes.string,
    'began-on': PropTypes.string,
    'ended-on': PropTypes.string,
    'share-state': PropTypes.string,
    id: PropTypes.string,
    member: jsonApiReferenceShape,
  }).isRequired,
  coverUrl: PropTypes.string.isRequired,
  owner: PropTypes.shape({
    id: PropTypes.string,
  }),
};

StoryDetailHeader.defaultProps = {
  owner: {},
};

const mapStateToProps = (state, ownProps) => {
  const cover = selectStoryCover(state, ownProps.story.id);
  const coverUrl = getCoverUrl(ownProps.story, cover);

  return { coverUrl };
};

export default connect(mapStateToProps)(StoryDetailHeader);
