import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';
//import UAParser from 'ua-parser-js';
import { Box } from 'rebass';
import { flexbox, color, space, layout } from 'styled-system';
// import Banner from './banner';

// import IosBanner from '../ios-banner';

export const BannerOuter = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  display: flex;
  ${flexbox};
  ${layout};
  ${color};
  ${space};
`;

// const browserUpdateBanner = {
//   name: 'browserUpdateBanner',
//   title: 'Browser Upgrade Recommended',
//   linkURL: 'https://browsehappy.com/',
//   linkLabel: 'Upgrade',
//   subtitle: `Your browser version is outdated.
//   Upgrade for the best experience.`,
// };

// const chromeUpdateBanner = {
//   name: 'chromeUpdateBanner',
//   title: 'Use Google Chrome for best experience.',
//   subtitle: 'Our website is not optimized for this browser',
//   linkLabel: 'Get Chrome',
//   linkURL: 'https://play.google.com/store/apps/details?id=com.android.chrome',
// };

// const chromeWebviewBanner = {
//   name: 'chromeWebviewBanner',
//   title: 'Website is running inside an app',
//   subtitle: 'Open browser for better experience',
//   linkLabel: 'Got It',
//   linkURL: '#',
// };

const BrowserVersionsWrap = ({ children }) => {
  // const browserInfo = UAParser(navigator.userAgent);
  // const [showIosBanner, setShowIosBanner] = useState(browserInfo.os.name === 'iOS');

  // const [currentBanner, setCurrentBanner] = useState({});
  // const bannerLoaded = useRef(false);

  // useEffect(() => {
  //   bannerLoaded.current = true;
  //   const browserName = browserInfo.browser.name.toLowerCase();
  //   const osName = browserInfo.os.name.toLowerCase();
  //   const majorVersion = browserInfo.browser.major;

  //   const recommendUpdateBrowser =
  //     browserName === 'ie' ||
  //     (browserName === 'chrome' && majorVersion < 76) ||
  //     (browserName === 'safari' && majorVersion < 12) ||
  //     (browserName === 'firefox' && majorVersion < 68) ||
  //     (browserName === 'edge' && majorVersion < 18);

  //   if (browserName === 'chrome webview') {
  //     setCurrentBanner(chromeWebviewBanner);
  //   } else if (osName === 'android' && browserName !== 'chrome') {
  //     setCurrentBanner(chromeUpdateBanner);
  //   } else if (recommendUpdateBrowser) {
  //     setCurrentBanner(browserUpdateBanner);
  //   }
  // }, [bannerLoaded, browserInfo.browser.major, browserInfo.browser.name, browserInfo.os.name]);

  // const hasBanner = !!Object.keys(currentBanner).length;
  // const bannerHeight = 80;

  return (
    <Box pt={0}>
      {/* {showIosBanner && <IosBanner hideBanner={() => setShowIosBanner(false)} />} */}
      {/* {hasBanner && (
        <Banner
          height={bannerHeight}
          alignItems="center"
          p={2}
          bg={showIosBanner ? 'white' : 'accentSubtle'}
          {...currentBanner}
          onClose={() => {
            setCurrentBanner({});
          }}
        />
      )} */}
      {children}
    </Box>
  );
};

BrowserVersionsWrap.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BrowserVersionsWrap;
