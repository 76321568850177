import React from 'react';
import ReactPlayer from 'react-player';

function ResponsiveReactPlayer({ playerWrapper = {}, ...props }) {
  return (
    <div className="player-wrapper" style={playerWrapper}>
      <ReactPlayer className="react-player" width="100%" height="100%" controls {...props} />
    </div>
  );
}

export default ResponsiveReactPlayer;
