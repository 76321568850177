// import { selectStory } from '../stories/selectors';
import { createSelector } from 'reselect';
import { flatten } from 'lodash';

import { selectEntity } from '../entities/selectors';
import { selectStoryPagesWithMedia } from './collection';

const selectStoryPage = (state, id) => selectEntity({ state, id, type: 'pages' });

export const selectVisiblePageMedia = (state, pageId) => {
  const storyPage = selectStoryPage(state, pageId);
  if (!storyPage) return [];
  return storyPage.media.filter(medium => {
    const entity = selectEntity({ state, id: medium.id, type: medium.type });
    return (
      !entity['error-message-cache'] &&
      (['finished', 'pre_uploaded'].includes(entity.state) || !!entity.tmpUrl)
    );
  });
};

export const selectEditPageMedia = (state, pageId) => {
  const storyPage = selectStoryPage(state, pageId);
  if (!storyPage) return [];
  return storyPage.media.filter(medium => {
    const entity = selectEntity({ state, id: medium.id, type: medium.type });
    return entity.state !== 'hidden';
  });
};

export const selectNextPageId = (state, storyId, pageId) => {
  const story = selectEntity({ state, type: 'stories', id: storyId });
  const page = selectStoryPage(state, pageId);
  if (!(story && page)) return '';

  const emptyPages = Object.values(state.entities.pages)
    .filter(el => el.media && el.media.length < 1)
    .map(el => el.id);

  const pages = story.pages.filter(el => !emptyPages.includes(el.id));
  const pageIndex = story.pages.findIndex(el => el.id === page.id);
  return pages[pageIndex + 1] && pages[pageIndex + 1].id;
};

export const selectPageActivities = (state, id) => {
  const page = selectEntity({ state, id, type: 'pages' });
  if (!page) return undefined;
  return flatten(
    page.media.map(medium => {
      const entity = selectEntity({ state, ...medium });
      if (!entity) return [];
      return entity['media-activities'] || [];
    })
  );
};

export const selectPageComments = (state, pageId) => {
  const activities = selectPageActivities(state, pageId);
  const comments = activities.filter(el => el.type === 'comments');
  return comments.sort((a, b) => {
    const commentA = selectEntity({ state, ...a });
    const commentB = selectEntity({ state, ...b });
    const dateA = new Date(commentA['created-at']);
    const dateB = new Date(commentB['created-at']);

    return dateA - dateB;
  });
};

export const selectPageLikes = (state, pageId) => {
  const activities = selectPageActivities(state, pageId);
  return activities.filter(el => el.type === 'likes');
};

export const selectUserLikeForPage = createSelector(
  [selectPageLikes, state => state.entities.likes, state => state.auth.currentMemberId],
  (pageLikes, likes, currentMemberId) =>
    (pageLikes || []).map(el => el.id).find(id => likes[id].member.id === currentMemberId)
);

export const selectPreviousPage = (state, pageIdBefore) => {
  const page = state.entities.pages[pageIdBefore];
  if (page) return page.id;
  return '';
};

export const selectFirstPage = (state, storyId) =>
  state.entities.stories[storyId].pages.length && state.entities.stories[storyId].pages[0].id;

export const selectStoryImages = (state, storyId) => {
  const pages = selectStoryPagesWithMedia({ state, storyId }) || {};
  const storyMedia = (Object.values(pages) || [])
    .map(page => page.media)
    .reduce((acc, val) => [...acc, ...val], []);
  const storyImages = storyMedia.filter(medium => medium.type === 'images');
  return storyImages;
};

export const selectStoryImageEntities = (state, storyId) => {
  const storyImages = selectStoryImages(state, storyId);
  return storyImages.map(image => selectEntity({ state, ...image }));
};
