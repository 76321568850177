import { useMedia } from 'the-platform';
import React from 'react';
import styled from '@emotion/styled';

import { useIsNavigationVisible } from 'ui/hooks';
import { breakpoints } from '../../styles/theme';
import Sidebar from '.';

const DesktopSidebarWrap = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  padding-top: 70px;
  z-index: 5;

  & + div {
    position: relative;
    margin-left: 270px;
    margin-top: 70px;
  }
`;

const DesktopSidebar = () => {
  const isDesktop = useMedia({ minWidth: breakpoints.lg });
  const isVisible = useIsNavigationVisible();
  if (!isDesktop || !isVisible) return false;
  return (
    <DesktopSidebarWrap>
      <Sidebar />
    </DesktopSidebarWrap>
  );
};

export default DesktopSidebar;
