import React, { useRef, useMemo, useState, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import PropTypes from 'prop-types';
import InvitationURLModal from 'chats/invitation-url-modal';
import { Flex, Box, Button, Text } from 'rebass';
import { useNavSuppressed } from 'ui/hooks';
import { FaInfo } from 'react-icons/fa';
import VideoChat from 'chats/VideoChat';
import { useClipsSWR, destroyMeeting } from 'chats/api';
import ConfirmModal from 'app/confirm';

const ChatPage = ({ meetingId, onExit }) => {
  const closedInfoModalRef = useRef(false);
  const controls = useAnimation();
  const { data, mutate } = useClipsSWR(meetingId);
  // const { meeting } = useEntity({ type: 'meetings', id: meetingId });
  const [copyInfoModalVisible, setCopyInfoModalVisible] = useState(true);
  const [endChatVisible, setEndChatVisisble] = useState(false);

  const clipsCount = useMemo(() => {
    if (!data) return 0;
    return data.data.data.length || 0;
  }, [data]);

  useEffect(() => {
    if (!closedInfoModalRef.current && !copyInfoModalVisible) {
      controls.start({
        scale: 1.2,
        transition: { yoyo: 5, duration: 0.3 },
      });
      closedInfoModalRef.current = true;
    }
  }, [controls, copyInfoModalVisible]);

  useNavSuppressed();

  return (
    <Box>
      {copyInfoModalVisible && (
        <InvitationURLModal onClose={() => setCopyInfoModalVisible(false)} />
      )}
      {endChatVisible && (
        <ConfirmModal
          onConfirm={() => {
            onExit();
            destroyMeeting(meetingId);
          }}
          title="Leave Chat"
          onCancel={() => setEndChatVisisble(false)}
        >
          <Text textAlign="center" fontSize={2} p={3} pb={3}>
            Are you sure you want to leave this chat?
          </Text>
        </ConfirmModal>
      )}
      <Box sx={{ display: 'grid', gridTemplateRows: 'max-content 1fr', height: '100vh' }}>
        <Flex p={2} alignItems="center" justifyContent="space-between">
          <motion.button
            initial={{ scale: 1 }}
            animate={controls}
            style={{
              padding: 8,
              marginRight: 'auto',
              color: 'white',
              background: 'rgba(0,0,0,.6)',
              borderRadius: 3,
            }}
            type="button"
            onClick={() => setCopyInfoModalVisible(true)}
          >
            <Text as={FaInfo} color="white" size={15} />
          </motion.button>
          <Button
            variant="primary"
            type="button"
            fontSize={2}
            ml="auto"
            onClick={() => setEndChatVisisble(true)}
          >
            End Chat
          </Button>
        </Flex>
        <VideoChat />
      </Box>
    </Box>
  );
};

ChatPage.propTypes = {
  meetingId: PropTypes.string.isRequired,
  onExit: PropTypes.func.isRequired,
};

export default ChatPage;
