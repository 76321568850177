import { REQUEST_ENTITIES } from '../entities/types';
import * as api from './api';
import * as types from './types';

export const getNotifications = memberId => ({
  type: types.GET_NOTIFICATIONS,
  promise: api.getNotifications(memberId),
});

export const markNotificationsRead = (memberId, notificationId) => ({
  type: REQUEST_ENTITIES,
  promise: api.markNotificationsRead(memberId, notificationId),
});

export const markSingleNotificationRead = (memberId, notificationId) => ({
  type: REQUEST_ENTITIES,
  promise: api.markSingleNotificationRead(memberId, notificationId),
});

export const markNotificationsSeen = () => ({ type: types.MARK_SEEN_NOTIFICATIONS });

export const receiveNotification = notification => ({
  type: types.RECEIVE_NOTIFICATION,
  payload: notification,
});
