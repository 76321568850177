import React from 'react';
import { withProps } from 'recompose';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { FaCheck as CheckIcon } from 'react-icons/fa';

const Wrap = styled.div`
  display: flex;
  align-items: center;
`;

export const Button = withProps({ type: 'button' })(styled.button`
  height: 36px;
  width: 36px;
  border-radius: 3px;
  border: 1px solid
    ${props => (props.selected ? props.theme.colours.brand : props.theme.colours.border)};
  padding-top: 4px;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  svg {
    fill: ${props => (props.selected ? props.theme.colours.brand : props.theme.colours.border)};
  }
`);

const TagOption = ({ name, onClick, selected, isOwner }) => (
  <Wrap>
    <p>{name}</p>
    {isOwner && (
      <Button onClick={onClick} selected={selected}>
        <CheckIcon size={16} style={{ position: 'absolute' }} />
      </Button>
    )}
  </Wrap>
);

TagOption.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  isOwner: PropTypes.bool,
};

TagOption.defaultProps = {
  onClick: () => {},
  selected: false,
  isOwner: false,
};

export default TagOption;
