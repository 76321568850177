import React from 'react';
import PropTypes from 'prop-types';
// import Markdown from 'markdown-to-jsx';
import { useEntity } from 'entities/hooks';
import { Box } from 'rebass';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

const TextMedium = ({ id, editing }) => {
  const { entity } = useEntity({ id, type: 'texts' });
  return (
    <Box className="quill ql-lifetales" width="100%" py={2} pt={editing ? 4 : 2}>
      <ReactQuill readOnly theme="bubble" value={entity.text} />
    </Box>
  );
};

TextMedium.propTypes = {
  id: PropTypes.string.isRequired,
  editing: PropTypes.bool,
};

TextMedium.defaultProps = {
  editing: false,
};

export default TextMedium;
