
import React from 'react';
import PropTypes from 'prop-types';
import { useMedia } from 'the-platform';
import { Flex } from 'rebass';
import { Scroll } from 'react-fns';
import { sizes } from '../styles/theme';

const DetailNavOuter = ({ height, ...props }) => {
  const isDesktop = useMedia({ minWidth: `${sizes.desktop}px` });

  return (
    <Scroll>
      {({ y }) => (
        <Flex
          justifyContent="center"
          alignItems="center"
          css="position: sticky;"
          style={{
            width: '100%',
            background: y ? 'white' : 'transparent',
            boxShadow: y ? '0 0 12px rgba(0, 0, 0, 0.25)' : 'none',
            top: isDesktop ? '70px' : '0',
            zIndex: 2,
            height,
            marginBottom: -height,
            transition: '.3s ease all',
          }}
        >
          <Flex
            style={{ maxWidth: 1200, position: 'absolute', width: '100%' }}
            m="0 auto"
            as="nav"
            px={2}
            {...props}
          />
        </Flex>
      )}
    </Scroll>
  );
};
DetailNavOuter.propTypes = {
  height: PropTypes.number,
};

DetailNavOuter.defaultProps = {
  height: 60,
};
export default DetailNavOuter;
