export const formatShareBody = ({ currentMemberId, withContactId, role } = { role: 'viewer' }) => ({
  data: {
    attributes: {
      'member-id': currentMemberId,
      'with-contact-id': withContactId,
      'contact-role': role,
    },
    relationships: {
      member: {
        data: {
          id: currentMemberId,
          type: 'members',
        },
      },
      with_contact: {
        data: {
          id: withContactId,
          type: 'members',
        },
      },
    },
  },
});

export default formatShareBody;
