import React from 'react';

const CircleCheckmark = () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 15C1 7.2804 7.2804 1 15 1C22.7196 1 29 7.2804 29 15C29 22.7196 22.7196 29 15 29C7.2804 29 1 22.7196 1 15Z"
      fill="white"
      stroke="url(#paint0_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.1423 17.4443C13.0642 17.5223 12.9373 17.5223 12.8583 17.4443L9.70725 14.2933C9.31625 13.9023 8.68425 13.9023 8.29325 14.2933C7.90225 14.6842 7.90225 15.3163 8.29325 15.7073L12.2933 19.7072C12.4883 19.9022 12.7442 20.0002 13.0002 20.0002C13.2562 20.0002 13.5122 19.9022 13.7073 19.7072L21.7072 11.7072C22.0982 11.3162 22.0982 10.6842 21.7072 10.2932C21.3162 9.90225 20.6842 9.90225 20.2933 10.2932L13.1423 17.4443Z"
      fill="url(#paint1_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="15"
        y1="1"
        x2="15"
        y2="29"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9A35EC" />
        <stop offset="1" stopColor="#6023E6" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="15.0002"
        y1="10"
        x2="15.0002"
        y2="20.0002"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9A35EC" />
        <stop offset="1" stopColor="#6023E6" />
      </linearGradient>
    </defs>
  </svg>
);

export default CircleCheckmark;
