import { color, maxWidth, maxHeight, space } from 'styled-system';
import posed from 'react-pose';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

import { shadows, borderRadii, colours } from '../../styles/constants';

const shadePoses = {
  enter: { opacity: 1 },
  exit: { opacity: 0 },
};

export const Shade = styled(posed.div(shadePoses))`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${props => props.theme.colours.photoGradientEnd};
  height: 100%;
  width: 100%;
  justify-content: center;
  z-index: ${props => (props.aboveTooltip && 10000) || 9998};
`;

const innerPoses = {
  pre: { y: 50, opacity: 0 },
  enter: { y: 0, opacity: 1 },
  exit: { opacity: 0 },
};

export const ShadeInner = styled(posed.div(innerPoses))`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  padding: ${props => props.theme.gutter};
  padding-bottom: ${props =>
    (props.isIOS && `calc(${props.theme.gutter} + 44pt)`) || props.theme.gutter};

  ${props => props.theme.media.tablet`padding-bottom: ${props.theme.gutter};`};
  z-index: ${props => (props.aboveTooltip && 10001) || 9999};

  ${props =>
    props.fullscreenMobile &&
    css`
      padding: 0;
      padding-bottom: ${(props.isIOS && `calc(${props.theme.gutter} + 44pt)`) ||
        props.theme.gutter};

      ${props.theme.media.tablet`
      padding-bottom: ${props.theme.gutter};
    `};
    `};
`;

export const Wrapper = styled.div`
  position: relative;
  width: calc(100vw - (2 * ${props => props.theme.gutter}));
  margin: auto;
  max-width: 560px;
  background: ${props => props.theme.colours[props.background] || '#f7f7f7'};
  box-shadow: ${shadows.small};
  border-radius: ${borderRadii.normal};
  color: ${props => props.theme.colours.mutedText};
  ${color};
  ${space};
  ${maxWidth};
  ${maxHeight};

  ${props =>
    props.fullscreenMobile &&
    css`
      width: 100vw;

      ${props.theme.media.tablet`
      width: calc(100vw - (2 *${props.theme.gutter}));
      height: auto;
    `};
    `};
`;

export const ButtonFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: ${props => props.theme.gutter};

  button {
    margin-left: 1em;
  }
`;

export const ConfirmText = styled.p`
  font-size: 20px;
  text-align: center;
  padding: 2em;
  margin: 0;
`;

export const Header = styled.div`
  padding: 1em;
  border-bottom: 1px bottom ${colours.border};

  h3 {
    margin: 0;
  }
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  position: absolute;
  z-index: 1;
  top: 5px;
  ${props => (props.left ? 'left: 5px;' : 'right: 5px;')};
  padding: 0;
  margin: 0;
  outline: none;
  cursor: pointer;
  color: inherit;
`;

export const Title = styled.h2`
  font-family: ${props => props.theme.fonts.quicksand};
  font-size: 18px;
  text-align: center;
  color: ${props => props.theme.colours.mutedText};
  margin: 0 ${props => props.theme.gutter};
  ${props => props.theme.media.phone`
    font-size: 24px;
  `};
  justify-self: center;
`;

export const InnerWrap = styled.div`
  display: grid;
  grid-gap: ${props => props.theme.gutter};
  padding: ${props => props.theme.gutter};
`;

const InfoModalHeader = styled.header`
  background: ${props => props.theme.colours.background};
  border-top-left-radius: ${props => props.theme.borderRadii.container};
  border-top-right-radius: ${props => props.theme.borderRadii.container};
  padding: 30px;
  display: grid;
  grid-gap: 15px;
  text-align: center;
  color: ${props => props.theme.colours.lightText};

  svg {
    margin: auto;
  }
`;

const InfoModalTitle = styled.h1`
  margin: 0;
  font-size: 24px;
`;

const InfoModalBody = styled.div`
  background: ${props => props.theme.colours.white};
  border-bottom-left-radius: ${props => props.theme.borderRadii.container};
  border-bottom-right-radius: ${props => props.theme.borderRadii.container};
  padding: 20px 30px 30px;
  font-size: 16px;
`;

const InfoModalContent = styled.div`
  display: grid;
  grid-gap: 20px;

  p {
    margin: 0;
  }
`;

export const PlainTitle = styled.h3`
  font-size: 18px;
  font-family: ${props => props.theme.fonts.sanFrancisco};
  margin: 0;
`;

export const Copy = styled.p`
  font-size: 14px;
  margin: 0;
`;

export const InfoModal = {
  Header: InfoModalHeader,
  Title: InfoModalTitle,
  Body: InfoModalBody,
  Content: InfoModalContent,
};
