import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { EntityAttr } from '../../entities';
import { Label } from '../edit-story/form/styled';
import tagSvg from './assets/tag.svg';

const Button = styled.button`
  display: flex;
  align-items: center;
  img {
    margin-right: 4px;
  }
`;

const Tags = styled.div`
  display: flex;
  margin-top: 5px;
`;

const Tag = styled.div`
  font-size: 14px;
  background: ${props => props.theme.colours.brandSubtle};
  padding: 0 10px;
  height: 28px;
  border-radius: 14px;
  border: 1px solid ${props => props.theme.colours.brand};
  margin-right: 5px;
  display: flex;
  align-items: center;
`;

const TagList = ({ id, onClick }) => (
  <EntityAttr type="stories" id={id} attr="tag-names" fallback={[]}>
    {({ attr: tags }) => (
      <>
        <Label>Chapter</Label>
        <Button type="button" onClick={onClick}>
          <img src={tagSvg} alt="tag" />
          Add to Chapter
        </Button>
        <Tags>
          {tags.map(tag => (
            <Tag key={`${id}-${tag}`}>{tag}</Tag>
          ))}
        </Tags>
      </>
    )}
  </EntityAttr>
);

TagList.propTypes = {
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default TagList;
