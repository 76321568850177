import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { receiveEntityDeletion } from '../actions';

const useReceiveEntityDeletion = () => {
  const dispatch = useDispatch();
  return useCallback(opts => dispatch(receiveEntityDeletion(opts)), [dispatch]);
};

export default useReceiveEntityDeletion;
