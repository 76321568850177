import { get } from 'lodash';
import { parse } from 'query-string';

export default ({ data }) => {
  const nextPageLink = get(data, 'data.data.links["next-page"]', '');

  if (!nextPageLink) return null;

  const [, stringParams] = nextPageLink.split('?');
  const nextPageParams = parse(stringParams);
  return nextPageParams.page;
};
