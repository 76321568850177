import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Tooltip from '@tippy.js/react';
import { getStoryPages, changePageOrientation } from '../actions';
import { Button } from '../button-bar/styled';
import { ReactComponent as HScroll } from './assets/h-scroll.svg';
import { ReactComponent as VScroll } from './assets/v-scroll.svg';

const OrientationButton = ({ page, storyId }) => {
  const dispatch = useDispatch();
  const [showTooltip, setTooltip] = useState(false);
  const [isHorizontal, setIsHorizontal] = useState(page['horizontal-orientation']);

  return (
    <Tooltip
      content={`Linked pages will scroll ${
        isHorizontal ? 'horizontally' : 'vertically'
      } to viewers`}
      trigger="manual"
      isVisible={showTooltip}
      onShown={() => setTimeout(() => setTooltip(false), 3000)}
    >
      <Button
        onClick={() => {
          setIsHorizontal(!isHorizontal);
          if (!showTooltip) {
            setTooltip(true);
          }
          dispatch(
            changePageOrientation({
              pageId: page.id,
              pageBody: page,
              horizontalOrientation: !isHorizontal,
            })
          ).then(() => {
            dispatch(getStoryPages(storyId));
          });
        }}
        mt="8px"
        mb="-52px"
        p={0}
        zIndex={1}
      >
        {isHorizontal ? <HScroll /> : <VScroll />}
      </Button>
    </Tooltip>
  );
};

OrientationButton.propTypes = {
  page: PropTypes.shape({}).isRequired,
  storyId: PropTypes.string.isRequired,
};

export default OrientationButton;
