import { IoIosClose } from 'react-icons/io';
import FontAwesome from 'react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';

import { EditHeader } from './styled';
import ModifyLink from '../../story-detail-header/modify-link';
import MoveStoryPage from '../move';

const EditButtons = ({ storyId }) => (
  <MoveStoryPage.Consumer>
    {({ moveStoryPageId, resetMoveStoryPage }) => (
      <EditHeader
        bg={moveStoryPageId ? 'brand' : 'white'}
        color={moveStoryPageId ? 'white' : 'inherit'}
      >
        {moveStoryPageId ? (
          <>
            <button
              type="button"
              onClick={() => resetMoveStoryPage()}
              style={{
                fontSize: '2em',
                color: 'white',
                lineHeight: 1,
              }}
            >
              <IoIosClose />
            </button>
            <span style={{ margin: 'auto' }}>Moving Media</span>
          </>
        ) : (
          <>
            <span style={{ marginLeft: 'auto' }}>
              <ModifyLink confirm to={`/story/${storyId}`}>
                <FontAwesome name="check" />
                <span>Save</span>
              </ModifyLink>
            </span>
          </>
        )}
      </EditHeader>
    )}
  </MoveStoryPage.Consumer>
);

EditButtons.propTypes = {
  storyId: PropTypes.string.isRequired,
};

export default EditButtons;
