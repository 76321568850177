import React from 'react';
import { Flex, Button } from 'rebass';
import Modal from '../../../app/modal/v2';
import { Header, Title } from '../../../app/modal/v2/styled-info';
import { useMatch } from '../../../app/hooks';
import { useHistory } from 'react-router-dom';
import milestoneTypes from './milestoneTypes';
import useLocation from 'app/hooks/location';

const SelectMilestone = ({ onClose }) => {
  const { params: { id } } = useMatch();
  const { pathname } = useLocation();
  const { push } = useHistory();
  const basePath = `/memoirs/${id}/milestones`;

  return (
    <Modal onClose={() => {
      if (onClose)
        onClose()
      else
        push(basePath)
    }} closeButton fullscreenMobile aboveTooltip>
      <Header>
        <Title style={{ marginBottom: '0' }}>Add To Timeline</Title>
      </Header>
      <Flex p={2} flexDirection="column" bg="background">
        {milestoneTypes.map(type => (
          <StyledButton
            color={type.color}
            onClick={() => push(pathname.replace('/milestones', `/life-events/new`) + '/' + type.param)}
            key={type.label}
          >
            <img src={type.icon} alt={type.label} style={{ marginRight: '16px' }} />
            {type.label}
          </StyledButton>
        ))}
      </Flex>
    </Modal>
  );
};

const StyledButton = props => (
  <Button
    mb={2}
    py={3}
    bg="white"
    color="defaultText"
    style={{ display: 'flex', alignItems: 'center', textAlign: 'left' }}
    {...props}
  />
);

export default SelectMilestone;
