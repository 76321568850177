
import { IoIosCheckmark } from 'react-icons/io';
import { FaInfo, FaAngleRight } from 'react-icons/fa';
import PropTypes from 'prop-types';

import React, { useCallback, useMemo, useContext, useEffect } from 'react';

import { Button, Text, Flex } from 'rebass';
import { EntityAttr } from '../../entities';
import { Notice, Wrap, ButtonWrap, ConfirmMessage, SuccessMessage, EmojiList } from './styled';
import { getResourcePath, triggerChronicleCollection } from '../../collections/api';
import {
  useCollectionShares,
  useRouteFromEntityParams,
  useResetCollectionTag,
} from '../../collections/hooks';
import { useSpreadState } from '../../app/hooks';
import { useHistory } from 'react-router-dom';
import ListItem from './list-item';
import Modal from '../../app/modal/v3';
import { useJSONAPIRequest } from '../../app/json-api-client/index';
import { StoryOptionsContext } from '../options';

const StoryCollectionModal = ({ onBack }) => {
  const { storyId, onClose } = useContext(StoryOptionsContext);
  const { push } = useHistory();
  const collectionShares = useCollectionShares({ filterNoReadOnly: true });
  const [state, setState] = useSpreadState({
    value: null,
    promptingConfirm: false,
    showingMoreInfo: false,
  });
  const { value, promptingConfirm, showingMoreInfo } = state;
  const resetCollectionTag = useResetCollectionTag();

  const { loading, mutate, data } = useJSONAPIRequest({
    method: 'post',
  });

  const getModalState = useCallback(() => {
    if (data) return 'success';
    if (!promptingConfirm && !showingMoreInfo) return 'initial';
    if (promptingConfirm && !showingMoreInfo) return 'prompting confirm';
    if (!promptingConfirm && showingMoreInfo) return 'showing more info';
    return Error('copy story modal state undefined');
  }, [data, promptingConfirm, showingMoreInfo]);

  useEffect(() => {
    if (getModalState() === 'success') {
      triggerChronicleCollection();
    }
  }, [getModalState]);

  const setCollection = useCallback(
    nextValue => {
      setState({ value: nextValue });
    },
    [setState]
  );

  const route = useRouteFromEntityParams({
    type: value ? value.type : null,
    id: value ? value.id : null,
  });

  const copyPath = useMemo(() => (value ? `${getResourcePath(value)}/copy_story/${storyId}` : ''), [
    value,
    storyId,
  ]);

  const handleBackClick = () => {
    if (showingMoreInfo) return setState({ showingMoreInfo: false });
    return onBack();
  };

  const modalProps =
    data || promptingConfirm
      ? {}
      : { title: 'Copy Story', close: { onClose }, back: { onBack: handleBackClick } };

  return (
    <Modal {...modalProps}>
      {getModalState() === 'success' && (
        <Wrap>
          <SuccessMessage>
            <IoIosCheckmark size={48} />
            <p>Story successfully copied.</p>
          </SuccessMessage>
          <ButtonWrap>
            <Button onClick={onClose} type="button" variant="muted">
              Dismiss
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                onClose();
                push(route);
                resetCollectionTag();
              }}
              type="button"
            >
              Go to Memoir
            </Button>
          </ButtonWrap>
        </Wrap>
      )}
      {getModalState() === 'initial' && (
        <>
          <Wrap>
            <Notice onClick={() => setState({ showingMoreInfo: true })}>
              <Flex bg="accent" p={2} mr={2} css="border-radius: 50%;">
                <FaInfo />
              </Flex>
              <Text>Learn more about copying</Text>
              <FaAngleRight style={{ marginLeft: 'auto' }} />
            </Notice>
            {collectionShares.map(share => (
              <ListItem
                {...share}
                key={share.id}
                currentValue={value}
                setCollection={setCollection}
                storyId={storyId}
              />
            ))}
            <Button
              variant="primary"
              flex={1}
              m={2}
              disabled={!value || loading}
              loading={loading}
              onClick={() => {
                setState({ promptingConfirm: true });
              }}
              type="button"
            >
              Save
            </Button>
          </Wrap>
        </>
      )}
      {getModalState() === 'showing more info' && (
        <Wrap style={{ padding: '20px' }}>
          <Text fontWeight="bold" mb={3}>
            Copying a story
          </Text>
          <Text>When you copy a story, you create a completely separate version of the story.</Text>
          <EmojiList>
            <li>Comments and Likes are not copied</li>
            <li>Future changes made to one story does not affect the other one</li>
            <li>
              You can share one story with one group and the other story with a different group and
              they&apos;re not connected in any way
            </li>
            <li>Comments/Likes for one story are not shown in the other</li>
          </EmojiList>
          <Text fontWeight="bold" mb={3}>
            Sharing a story in a different Memoir
          </Text>
          <Text>
            If instead, you would like this <strong>exact story</strong> to be in more than one
            Memoir, use our Sharing feature to add it to that Memoir.
          </Text>
          <EmojiList>
            <li>Exactly the same story will be in both places</li>
            <li>Everyone will see all comment/likes from everyone else, past and future</li>
            <li>
              Future changes to the story in one Memoir will also change it in the other one
            </li>
          </EmojiList>
        </Wrap>
      )}
      {getModalState() === 'prompting confirm' && (
        <Wrap>
          <ConfirmMessage>
            <h3>Are you sure?</h3>
            <p>
              a copy of “
              <EntityAttr id={storyId} type="stories" attr="title" />” will be created and put into{' '}
              <EntityAttr {...value} attr="name" />. Comments, likes and story shares will not be
              copied.
            </p>
          </ConfirmMessage>
          <ButtonWrap>
            <Button
              onClick={() => {
                setState({ promptingConfirm: false, value: null });
              }}
              disabled={loading}
              type="button"
              variant="muted"
            >
              Cancel
            </Button>
            <Button
              disabled={loading}
              loading={loading}
              onClick={() => mutate({}, copyPath)}
              type="button"
              variant="primary"
            >
              Confirm
            </Button>
          </ButtonWrap>
        </Wrap>
      )}
    </Modal>
  );
};
StoryCollectionModal.propTypes = {
  onBack: PropTypes.func,
};
StoryCollectionModal.defaultProps = {
  onBack: null,
};

export default StoryCollectionModal;
