import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { Box, Flex, Button, Text } from 'rebass';
import { IoIosPlay, IoIosPause } from 'react-icons/io';
import { useEntity } from 'entities/hooks';
// import useAspectRatioFit from 'app/hooks/aspectRatioFit';
// import { useRect } from '@reach/rect';

const VideoPreview = ({
  seek,
  onLoadedMetadata,
  onPause,
  onPlay,
  isPlaying,
  setIsPlaying,
  onEnded,
}) => {
  const { videoId, id } = useParams();
  const { video } = useEntity({ type: 'videos', id: videoId });
  const { 'asset-url': assetURL } = video;
  const ref = useRef();

  useEffect(() => {
    if (seek) {
      ref.current.seekTo(seek, 'seconds');
    }
  }, [seek]);

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateRows: '1fr max-content',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ReactPlayer
        onLoadedMetadata={onLoadedMetadata}
        onPause={onPause}
        onPlay={onPlay}
        onEnded={onEnded}
        ref={ref}
        url={assetURL}
        playing={isPlaying}
        style={{ maxHeight: '100%', maxWidth: '100%' }}
      />
      <Flex p={3} justifyContent="center">
        {!isPlaying && (
          <Button
            type="button"
            variant="videoEdit"
            onClick={onPlay}
            size={48}
            p={2}
            sx={{ borderRadius: '50%' }}
          >
            <Text size={24} as={IoIosPlay} />
          </Button>
        )}
        {isPlaying && (
          <Button
            type="button"
            variant="videoEdit"
            onClick={onPause}
            size={48}
            p={2}
            sx={{ borderRadius: '50%' }}
          >
            <Text size={24} as={IoIosPause} />
          </Button>
        )}
      </Flex>
    </Box>
  );
};

VideoPreview.defaultProps = {
  seek: 0,
  onLoadedMetadata: () => {},
  onPlay: () => {},
  onPause: () => {},
};

VideoPreview.propTypes = {
  seek: PropTypes.number,
  onLoadedMetadata: PropTypes.func,
  onPause: PropTypes.func,
  onPlay: PropTypes.func,
};

export default VideoPreview;
