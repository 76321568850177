import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FiMail as MailIcon } from 'react-icons/fi';
import Button from '../../app/buttons';
import { Header, Title, Body } from '../../app/modal/v2/styled-info';

const DownloadConfirm = ({ onDismiss, email }) => (
  <Fragment>
    <Header>
      <MailIcon size={58} color="#999" />
      <Title>
        A download link will be sent to
        {' '}
        {email}
      </Title>
    </Header>
    <Body>
      <p>It can take a few minutes to generate the downloadable story.</p>
      <p>The download link will expire in 24hrs. We will let you know when the email is sent.</p>
      <Button
        onClick={onDismiss}
        style={{ width: '100%', marginTop: '20px' }}
        primary
      >
        Got it
      </Button>
    </Body>
  </Fragment>
);

DownloadConfirm.defaultProps = {
  onDismiss: () => {},
  email: '',
};

DownloadConfirm.propTypes = {
  onDismiss: PropTypes.func,
  email: PropTypes.string,
};

export default DownloadConfirm;
