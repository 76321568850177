import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass';

function QuestionBar({ question, showBorder = true }) {
    return <Flex flexDirection="column">
        <Text
            p={2}
            textAlign="left"
            fontFamily='Zilla Slab'
            fontSize={20}
            lineHeight="20px"
            letterSpacing='-0.66px'
            fontStyle="italic"
        >
            <b>Q.&nbsp;</b>   {question}
        </Text>
        {showBorder && <Box sx={{ bg: '#766AB9', width: '100%', height: '2px' }} />}
    </Flex>
}

QuestionBar.defaultProps = { question: '' };
QuestionBar.propTypes = { question: PropTypes.string };

export default QuestionBar;