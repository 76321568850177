import { useSelector } from 'react-redux';

import { selectRelatedEntities } from '../selectors';

const useRelatedEntities = ({ id, type, relatedType }) => {
  return useSelector(state =>
    selectRelatedEntities({
      state,
      id,
      type,
      relatedType,
    })
  );
};

export default useRelatedEntities;
