

import { Box, Button, Flex } from 'rebass';
import { IoMdVideocam } from 'react-icons/io';
import { UAContext } from '@quentin-sommer/react-useragent';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import posed from 'react-pose';

const PosedRecordButton = posed.div({
  visible: { x: 0, opacity: 1 },
  hidden: { x: 50, opacity: 0 },
});

const RecordButton = ({ visible, setFile }) => {
  const {
    uaResults: { mac, safari, computer },
  } = useContext(UAContext);
  const accept = mac || safari ? 'video/mp4,video/x-m4v,video/*' : 'video/*';

  return (
    <Box css="position: relative;" as={PosedRecordButton} pose={visible ? 'visible' : 'hidden'}>
      <Button
        as="label"
        htmlFor="story_request_record_button"
        variant="primary"
        fontSize={3}
        py={3}
        px={4}
        css={visible && `cursor: pointer;`}
      >
        <input
          id="story_request_record_button"
          capture
          type="file"
          style={{ position: 'absolute', left: -9999, top: 0, opacity: 0 }}
          accept={accept}
          onChange={e => {
            const [file] = e.target.files;
            if (!file.type.includes('video')) {
              // eslint-disable-next-line no-alert
              window.alert('you must add a video file');
              return;
            }
            setFile(e.target.files[0]);
          }}
          disabled={!visible}
        />
        <Flex as="span" alignItems="center" justifyContent="center">
          <Box as={IoMdVideocam} mr={1} />
          {computer ? 'Upload Video' : 'Start Recording'}
        </Flex>
      </Button>
    </Box>
  );
};

RecordButton.propTypes = {
  visible: PropTypes.bool.isRequired,
  setFile: PropTypes.func.isRequired,
};

export default RecordButton;
