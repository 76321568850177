import React, { useState } from 'react';
import { Text, Box, Flex } from 'rebass';
import { useDispatch } from 'react-redux';
import RecordVideo from './RecordVideo';
import { createMediaForFiles, createTextMedium } from 'story-media/actions';
import RecordAudio from './RecordAudio';
import AddText from './AddText';
import VideoMedium from 'story-media/medium-types/video';
import Profile from './Profile';
import QuestionBar from './QuestionBar';
import UploadMedia from './UploadMedia';
import Footer from './Footer';
import Wrapper from './Wrapper';

function AddMediaSection({ attributes, onSubmitMedia, submitText }) {
    const [selectActionItem, setSelectedActionItem] = useState(null);

    return <Flex flexDirection="column">
        <Box m="auto">
            <RecordVideo question={attributes?.question} isSelected={selectActionItem === 'record'} onClick={() => setSelectedActionItem('record')} onSubmitMedia={onSubmitMedia} />
            <UploadMedia question={attributes?.question} type="video/*" label="Upload a video" isSelected={selectActionItem === 'media'} onClick={() => setSelectedActionItem('media')} onSubmitMedia={onSubmitMedia} />
            <AddText
                question={attributes?.question}
                isSelected={selectActionItem === 'text'}
                onClick={() => setSelectedActionItem('text')}
                onSubmitText={({ text }) => submitText({ text, 'position': 0 }).then(() => setSelectedActionItem(null))}
                label="Respond with text"
            />
            <RecordAudio
                question={attributes?.question}
                isSelected={selectActionItem === 'audio'}
                onClick={() => setSelectedActionItem('audio')}
                onSubmitMedia={onSubmitMedia}
                label="Respond with audio"
            />
        </Box>
        <Text
            textAlign="center"
            fontFamily='Zilla Slab'
            fontSize={20}
            mt={3}
            lineHeight="120%"
            letterSpacing='-0.44px'
        >
            <b><i> You can add photos and more videos to accompany your story in the next step</i></b>.
        </Text>
    </Flex>
}

const Step1 = ({ subjectName, attributes, storyId, onStepUp }) => {
    const [uploadMediaSection, setUploadMediaSection] = useState(false)
    const [showFooter, setShowFooter] = useState(false)

    const medium = attributes?.['video-request']
    const contributorExperience = attributes?.['contributor-experience']

    const dispatch = useDispatch();

    const submitText = (values) => {
        dispatch(createTextMedium(storyId, values), [])
        onStepUp()
    };

    const submitMedia = (files, position, transcribe) => {
        dispatch(createMediaForFiles(storyId, files, position, true, transcribe));
        onStepUp()
    }

    const onSubmitMedia = (files, transcribe) => {
        return submitMedia(files, 0, transcribe);
    }

    return (
        <Wrapper>
            {uploadMediaSection ? // for other_contributors
                <Flex flexDirection="column" justifyContent="center" alignItems="left">
                    <QuestionBar question={attributes?.question} />
                    <AddMediaSection attributes={attributes} onSubmitMedia={onSubmitMedia} submitText={submitText} />
                </Flex>
                : <Flex flexDirection="column" justifyContent="center" alignItems="center" p={2}>
                    <Text fontFamily='Zilla Slab' fontSize={20} lineHeight="120%" letterSpacing='-0.352px' textAlign="left" mr="auto">
                        {subjectName} wants to know...
                    </Text>
                    <Text
                        textAlign="left"
                        fontFamily='Zilla Slab'
                        fontSize={30}
                        mt={2}
                        mr="auto"
                        lineHeight="120%"
                        letterSpacing='-0.66px'
                        fontStyle="italic"
                    >
                        {attributes?.question}
                    </Text>
                    <Box mt={20} >{medium?.type === 'videos' && <VideoMedium id={medium?.id} className="tellAStory" />}</Box>
                    {contributorExperience === 'other_contributors' && <Profile label="What's your name?" onNextPress={() => setUploadMediaSection(true)} />}
                    {contributorExperience === 'memoir_subject' && <AddMediaSection attributes={attributes} onSubmitMedia={onSubmitMedia} submitText={submitText} />}
                </Flex>}
            {showFooter && <Footer onNextPress={onStepUp} percentComplete={25} nextButtonText="Next" />}
        </Wrapper>
    );
};

export default Step1;
