import { useEntities } from 'entities/hooks';
import { useMemo } from 'react';

export const useConnectionsByStatus = status => {
  const connections = useEntities('connections');

  return useMemo(() => {
    return Object.keys(connections)
      .filter(key => {
        const connection = connections[key];

        return connection.status === status;
      })
      .map(key => ({ id: key, type: 'connections' }));
  }, [connections, status]);
};

export default useConnectionsByStatus;
