export const filterLists = (storyIds = [], stories = {}, memberId = '') => {
  const allByDate = storyIds.sort((a, b) => {
    const createdB = stories[b]['created-at'];
    const createdA = stories[a]['created-at'];
    const lastCreatedB = stories[b]['last-media-created-at'];
    const lastCreatedA = stories[a]['last-media-created-at'];
    const storyB = lastCreatedB > createdB ? lastCreatedB : createdB;
    const storyA = lastCreatedA > createdA ? lastCreatedA : createdA;
    return (new Date(storyB) - new Date(storyA));
  });

  const [all, archived] = allByDate.reduce((acc, id) => {
    if (stories[id] && stories[id].archived) {
      return [acc[0], [...acc[1], id]];
    }
    return [[...acc[0], id], acc[1]];
  }, [[], []]);

  const memberAndShared = all.reduce((acc, storyId) => {
    const story = stories[storyId];
    let { ownedByMember, sharedWithMember } = acc;

    if (story.member.id === memberId) {
      ownedByMember = [...ownedByMember, storyId];
    } else {
      sharedWithMember = [...sharedWithMember, storyId];
    }

    return {
      ownedByMember,
      sharedWithMember,
    };
  }, {
    ownedByMember: [],
    sharedWithMember: [],
  });

  return {
    ...memberAndShared,
    archived,
    all,
  };
};

export const getStoriesLink = (currentFilter = 'all') => {
  if (currentFilter === 'all') return '/stories';
  if (currentFilter === 'ownedByMember') return '/stories/my-stories';
  if (currentFilter === 'sharedWithMember') return '/stories/shared-with-me';
  if (currentFilter === 'archived') return '/stories/archived';
  return '/stories';
};

export const getCoverUrl = (story, image = {}) => {
  if (image.tmpUrl) return image.tmpUrl;
  if (image['image-versions']) return image['image-versions'][2].url;
  if (image['asset-url']) return image['asset-url'];
  return story['image-url'] || '';
};

export function capitalizeFirstLetter(string = '') {
  if (typeof string === 'string') return string.charAt(0).toUpperCase() + string.slice(1);

  return string;
}

export default filterLists;
