import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';

import {
  Wrap,
  Title,
  Body,
  SharedAvatars,
  StyledMemberAvatar,
  StyledMemberOverflow,
  Label,
  Button,
} from './styled';
import { selectCurrentMember } from '../../members/selectors';
import { selectStoryOwner, selectStory } from '../../stories/selectors';
import { selectStorySharesForStory, selectStoryShareMember } from '../selectors';
import MemberAvatar from '../../members/avatar';
import Modal from '../../app/modal/v2';

const StorySharedModal = ({ members, title, avatarsToDisplay, ...props }) => (
  <Modal aboveTooltip>
    <Wrap>
      <Title>
        <span role="img" aria-label="party">
          🎉
        </span>{' '}
        You Shared {title}
      </Title>
      <SharedAvatars>
        {members.slice(0, avatarsToDisplay).map(member => (
          <StyledMemberAvatar size="50" key={member} id={member} />
        ))}
        {members.length > avatarsToDisplay && (
          <span>
            {members.slice(avatarsToDisplay, avatarsToDisplay + 1).map(member => (
              <StyledMemberOverflow>
                <MemberAvatar
                  size="46"
                  key={member}
                  id={member}
                  style={{ gridArea: '1 / 1', margin: '0' }}
                />
                <Label>+{members.length - avatarsToDisplay}</Label>
              </StyledMemberOverflow>
            ))}
          </span>
        )}
      </SharedAvatars>
      <Body>Meanwhile, you can continue to edit or update this story at any time.</Body>
      <Button to={`/story/${props.match.params.id}`} replace>
        Got It!
      </Button>
    </Wrap>
  </Modal>
);

StorySharedModal.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
  }).isRequired,
  members: PropTypes.arrayOf(PropTypes.string).isRequired,
  avatarsToDisplay: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
};

const mapStateToProps = (
  state,
  {
    match: {
      params: { id: storyId },
    },
  }
) => {
  const owner = selectStoryOwner(state, storyId) || {};
  const { title } = selectStory(state, storyId) || {};
  const currentMember = selectCurrentMember(state);
  const storyShares = selectStorySharesForStory(state, storyId) || [];
  const currentUserIsOwner = currentMember.id === owner.id;
  const members = storyShares
    .map(storyShare => {
      const member = selectStoryShareMember(state, storyShare);
      return member && member.id;
    })
    .filter(memberId => !!memberId)
    .filter(memberId => !(currentUserIsOwner && memberId === currentMember.id));
  const avatarsToDisplay = 3;

  return {
    storyId,
    members,
    currentUserIsOwner,
    avatarsToDisplay,
    title,
  };
};

export default connect(mapStateToProps)(StorySharedModal);
