import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useEntity } from 'entities/hooks/index';
import { Button, Text, Card, Flex } from 'rebass';
import { IoIosArrowUp } from 'react-icons/io';
import { jsonApiReferenceShape } from 'app/shapes/index';
import ReactPlayer from 'react-player';
import { useCaptionText } from 'story-media/hooks';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

const GalleryCaption = ({ caption, ...props }) => {
  const { entity, isPresent } = useEntity({ id: caption.id, type: caption.type });
  const [truncated, setTruncated] = useState(true);
  const truncateLength = 60;

  const captionText = useCaptionText(entity.text, truncated, truncateLength);
  if (!isPresent) return false;

  return (
    <Card p={3} {...props}>
      <Flex justifyContent="center" mx="auto">
        {caption.type === 'texts' && (
          <Flex width="35em" justifyContent="space-between">
            <Text color="white" lineHeight={1.5} className="quill ql-lifetales">
              <ReactQuill theme="bubble" value={captionText} readOnly />
            </Text>
            {captionText.length > truncateLength && (
              <Button onClick={() => setTruncated(false)} type="button" variant="unset" ml="auto">
                <Text color="white" as={IoIosArrowUp} />
              </Button>
            )}
          </Flex>
        )}
        {caption.type === 'audios' && (
          <ReactPlayer height={40} controls url={entity['asset-url']} />
        )}
      </Flex>
    </Card>
  );
};
GalleryCaption.defaultProps = { caption: {} };
GalleryCaption.propTypes = {
  caption: PropTypes.shape(jsonApiReferenceShape),
};

export default GalleryCaption;
