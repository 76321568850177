import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { updateEntity } from '../actions';

const useUpdateEntity = () => {
  const dispatch = useDispatch();
  return useCallback(opts => dispatch(updateEntity(opts)), [dispatch]);
};

export default useUpdateEntity;
