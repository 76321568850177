import createCachedSelector from 're-reselect';

export const selectEntity = createCachedSelector(
  [({ state, type }) => state.entities[type], ({ id }) => id],
  (entities = {}, id = '') => entities[id]
)(({ type, id }) => `${type}:${id}`);

export const selectRelatedEntities = createCachedSelector(
  [
    selectEntity,
    ({ state, relatedType }) => state.entities[relatedType],
    ({ relatedType }) => relatedType,
  ],
  (entity, relatedEntities, relatedType) =>
    ((entity && entity[relatedType]) || []).map(({ id }) => relatedEntities[id]).filter(el => !!el)
)(({ type, id, relatedType }) => `${type}:${id}:${relatedType}`);

export const selectCurrentMemberId = ({ state }) => state.auth.currentMemberId;

export default selectEntity;
