import React, { createContext, useContext, useEffect, useMemo, useState, useCallback } from 'react';
import store from 'store';

import { omit, isUndefined } from 'lodash';
import { CurrentCollectionContext } from './current-collection-context';
import { useEntity } from '../entities/hooks';

const creatorTooltips = [
  'TIMELINE',
  'ADD_MILESTONE',
  'VIEW_MILESTONE',
  'ADD_THREE_MILESTONES',
  'INVITE_PARTICIPANTS',
  'RECENT_TAB',
  'ADD_STORY',
  'REQUEST_STORY',
];
const participantTooltips = [
  'TIMELINE',
  'VIEW_MILESTONE',
  'ADD_MILESTONE',
  'RECENT_TAB',
  'ADD_STORY',
  'REQUEST_STORY',
];

const initialTooltipState = {
  dismiss: false,
  complete: false,
};

const initialState = [
  ...creatorTooltips.map(type => ({ type, role: 'creator', ...initialTooltipState })),
  ...participantTooltips.map(type => ({ type, role: 'participant', ...initialTooltipState })),
];

const getCurrentTooltip = (state = [], role = '') =>
  state
    .filter(tooltip => tooltip.role === role)
    .find(tooltip => !tooltip.complete || !tooltip.dismiss);

export const CollectionTooltipContext = createContext({});

export const CollectionTooltipProvider = props => {
  const { currentCollection } = useContext(CurrentCollectionContext);
  const { id, type } = currentCollection || {};
  const { isOwner } = useEntity({ type, id });
  const role = isOwner ? 'creator' : 'participant';

  const [state, setState] = useState(store.get('memoir_tooltips') || initialState);

  // updateTooltipState
  const updateTooltip = useCallback(
    (tipType = '', nextTip = {}) => {
      setState(prev =>
        prev.map(tooltip =>
          tooltip.type === tipType && tooltip.role === role
            ? { ...tooltip, ...omit(nextTip, isUndefined) }
            : tooltip
        )
      );
    },
    [role]
  );

  useEffect(() => {
    store.set('memoir_tooltips', state);
  }, [state]);

  const value = useMemo(
    () => ({
      currentTooltip: getCurrentTooltip(state, role) || {},
      updateTooltip,
    }),
    [role, state, updateTooltip]
  );
  return <CollectionTooltipContext.Provider value={value} {...props} />;
};
