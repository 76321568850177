import useSWR from 'swr';
import { stringify } from 'query-string';
import { get, post, patch } from '../utils/api';

// export const getStoryPages = storyId => get(`/v2/stories/${storyId}/pages`);
export const getStoryPages = storyId => get(`/v3/stories/${storyId}/story_share`);
export const createStoryPage = (storyId, body) => post(`/v2/stories/${storyId}/pages`, body);
export const createStoryPageMedium = (pageId, body) => post(`/v2/pages/${pageId}/media`, body);
export const updateStoryPage = (pageId, body) =>
  get(`/v2/pages/${pageId}`).then(response => {
    // add lock version to patch body
    const { entities, result } = response.data;
    const lockVersion = entities.pages[result.pages[0]]['lock-version'];
    const patchBody = {
      data: {
        ...body.data,
        attributes: {
          ...body.data.attributes,
          'lock-version': lockVersion,
        },
      },
    };

    return patch(`/v2/pages/${pageId}`, patchBody);
  });

export const moveStoryPage = (storyId, pageId, position) =>
  get(`/v2/pages/${pageId}`)
    .then(response => {
      // add lock version to patch body
      const { entities, result } = response.data;
      const lockVersion = entities.pages[result.pages[0]]['lock-version'];
      const patchBody = {
        data: {
          attributes: {
            'lock-version': lockVersion,
            position,
          },
        },
      };

      return patch(`/v2/pages/${pageId}`, patchBody);
    })
    .then(() => getStoryPages(storyId));

export const useStoryPagesSWR = (storyId, queryParams = { page: 1, per: 10 }) => {
  return useSWR(`/v3/stories/${storyId}/pages${queryParams ? `?${stringify(queryParams)}` : ''}`);
};
