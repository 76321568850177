import React from 'react';

const SvgComment = props => (
  <svg fill="none" height="1em" viewBox="0 0 22 22" width="1em" {...props}>
    <path
      clipRule="evenodd"
      d="M21.169 9.25c0 6.77-7.698 10.808-9.643 11.721a.304.304 0 0 1-.131.029.315.315 0 0 1-.31-.319v-2.636a.56.56 0 0 0-.548-.553C5.223 17.305 1 14.38 1 9.25 1 3.947 5.515 1 11.085 1c5.569 0 10.084 2.947 10.084 8.25z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgComment;
