/* eslint-disable no-alert */
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { CurrentCollectionContext } from '../../collections/current-collection-context';
import StoryTagsManager from './manager';

const StoryTagsModal = props => {
  const { onBack } = props;
  const { currentCollection } = useContext(CurrentCollectionContext);

  return <StoryTagsManager collection={currentCollection} onSuccess={onBack} {...props} />;
};
StoryTagsModal.propTypes = {
  onBack: PropTypes.func.isRequired,
};

export default StoryTagsModal;
