import PropTypes from 'prop-types';
import React from 'react';

import { Entity } from '.';

const getImageSize = (entity, index) =>
  entity['image-versions'] && entity['image-versions'][index].url;

const ImageEntity = ({ children, ...props }) => (
  <Entity type="images" {...props}>
    {entityProps => {
      if (!entityProps.isPresent) return children(entityProps);

      return children({
        ...entityProps,
        tmpUrlSrc: entityProps.entity.tmpUrl,
        thumbnailSrc: getImageSize(entityProps.entity, 7),
        coverSrc: getImageSize(entityProps.entity, 3),
        assetSrc: entityProps.entity['asset-url'],
      });
    }}
  </Entity>
);

ImageEntity.propTypes = {
  children: PropTypes.func.isRequired,
};

export default ImageEntity;
