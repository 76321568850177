import { Heading, Text } from 'rebass';
import PropTypes from 'prop-types';
import React from 'react';

const headingProps = {
  fontWeight: 'normal',
  fontFamily: 'quicksand',
  fontSize: 4,
  textAlign: 'center',
  pb: 2,
};

const textProps = {
  color: 'lightText',
  fontSize: 2,
  textAlign: 'center',
  pb: 3,
};

const SupportCopy = ({ title, body, children }) => {
  if (!title && !body) return children;
  return (
    <>
      <Heading {...headingProps}>{title}</Heading>
      <Text {...textProps}>{body}</Text>
      {children}
    </>
  );
};

SupportCopy.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default SupportCopy;
