import { REQUEST_ENTITIES } from '../entities/types';
import { formatMemberBody } from './helper';
import { uploadMediumFile } from '../story-media/actions';
import * as api from './api';
import * as types from './types';

export const getMember = id => ({
  type: REQUEST_ENTITIES,
  promise: api.getMember(id),
});

export const getMemberCollection = () => ({
  type: REQUEST_ENTITIES,
  promise: api.getMemberCollection('me'),
});

export const updateMember = (memberId, attributes) => {
  const body = formatMemberBody(memberId, attributes);

  return {
    type: REQUEST_ENTITIES,
    promise: api.updateMember(memberId, body),
  };
};

export const uploadMemberAvatar = (memberId, file) => dispatch => {
  const fileUrl = URL.createObjectURL(file);

  dispatch({
    type: types.UPDATE_MEMBER_AVATAR,
    promise: api.uploadMemberAvatar(memberId, file.name),
    meta: {
      memberId,
      fileUrl,
      onSuccess: result => {
        const medium = result.data.entities.images[result.data.result.images[0]];
        dispatch(uploadMediumFile(memberId, medium, file));
      },
    },
  });
};

export const deleteMemberAvatar = member => ({
  type: types.DELETE_MEMBER_AVATAR,
  member,
});

export const getMemberIdentifiers = memberId => ({
  type: types.GET_MEMBER_IDENTIFIERS,
  promise: api.getMemberIdentifiers(memberId),
});

export const destroyIdentifier = identifierId => ({
  type: types.DESTROY_IDENTIFIER,
  promise: api.destroyIdentifier(identifierId),
  meta: {
    identifierId,
  },
});

export const getPublicMember = memberShareId => ({
  type: types.GET_PUBLIC_MEMBER,
  promise: api.getPublicMember(memberShareId),
});

export const postMemberEvent = (eventType, body) => ({
  type: types.POST_MEMBER_EVENT,
  promise: api.postMemberEvent(eventType, body),
});

export const getMemberEvents = () => ({
  type: types.GET_MEMBER_EVENTS,
  promise: api.getMemberEvents(),
});
