import PropTypes from 'prop-types';
import React from 'react';
import Tippy from '@tippy.js/react';

const EventTooltip = ({
  postEventFunc,
  title,
  smallText,
  children,
  ...props
}) => (
  <Tippy
    content={(
      <button
        type="button"
        style={{
          all: 'unset',
          cursor: 'pointer',
          margin: '-.6rem -1.2rem',
          padding: '.6rem 1.2rem',
        }}
        onClick={postEventFunc}
      >
        <div>{title}</div>
        {smallText && <small>{smallText}</small>}
      </button>
    )}
    arrow
    hideOnClick
    interactive
    theme="light"
    size="big"
    {...props}
  >
    {children}
  </Tippy>
);

EventTooltip.defaultProps = {
  postEventFunc: () => {},
  smallText: '',
  title: '',
};

EventTooltip.propTypes = {
  postEventFunc: PropTypes.func,
  title: PropTypes.string,
  smallText: PropTypes.string,
  children: PropTypes.node,
};

EventTooltip.defaultProps = {
  children: null,
};

export default EventTooltip;
