import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import useSWR from 'swr';

import StoryOptionModals from './modals';
import { useStoryPagesSWR } from '../../story-pages/api';

export const StoryOptionsContext = createContext({});

const StoryOptions = ({ storyId, onClose }) => {
  useStoryPagesSWR(storyId);
  const { data: sharesData } = useSWR(`/v3/stories/${storyId}/story_shares`);
  const { data: storyData } = useSWR(`/v2/stories/${storyId}`);

  const [currentModal, setCurrentModal] = useState('');

  if (!sharesData || !storyData) return false;

  return (
    <StoryOptionsContext.Provider value={{ currentModal, setCurrentModal, storyId, onClose }}>
      <StoryOptionModals
        goHome={() => setCurrentModal('')}
        storyId={storyId}
        currentModal={currentModal}
      />
    </StoryOptionsContext.Provider>
  );
};

StoryOptions.propTypes = {
  onClose: PropTypes.func.isRequired,
  storyId: PropTypes.string.isRequired,
};

export default StoryOptions;
