import customSvg from './assets/custom.svg';
import childSvg from './assets/child.svg';
import marriageSvg from './assets/marriage.svg';
import schoolSvg from './assets/school.svg';
import workSvg from './assets/work.svg';
import placesSvg from './assets/places-lived.svg';

const milestoneTypes = [
  {
    label: 'Add custom event',
    param: 'custom',
    color: 'brand',
    icon: customSvg,
    title: 'Add Custom Event',
    placeholder: 'Had an event',
  },
  {
    label: 'Child',
    param: 'birth',
    color: 'defaultText',
    icon: childSvg,
    title: 'Add a Child',
    placeholder: 'Had a child',
  },
  {
    label: 'Marriage',
    param: 'marriage',
    color: 'defaultText',
    icon: marriageSvg,
    title: 'Add Marriage',
    placeholder: 'Got married',
  },
  {
    label: 'School',
    param: 'school',
    color: 'defaultText',
    icon: schoolSvg,
    title: 'Add School',
    placeholder: 'Went to school',
  },
  {
    label: 'Work',
    param: 'work',
    color: 'defaultText',
    icon: workSvg,
    title: 'Add Work',
    placeholder: 'Went to work at...',
  },
  {
    label: 'Places lived',
    param: 'places-lived',
    color: 'defaultText',
    icon: placesSvg,
    title: 'Add Places Lived',
    placeholder: 'Lived in...',
  },
];

export default milestoneTypes;
