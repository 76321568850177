import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { withProps } from 'recompose';
import { colours } from 'app/styles/theme';

import { HeaderButtonStyles, EllipsisButton } from '../../../stories/story-detail-header/styled';

export const getHeaderGradient = colours =>
  `linear-gradient(to bottom, ${colours.photoGradientStart}, ${colours.photoGradientEnd})`;

export const HeaderWrap = styled.div`
  display: grid;
  overflow: hidden;
`;

export const BlurredHeader = withProps(({ img }) => ({
  style: {
    backgroundImage: img ? `${getHeaderGradient(colours)}, url(${img})` : null,
    backgroundColor: img ? 'white' : colours.blueBackground,
  },
}))(styled.div`
  background-size: 100%;
  position: relative;
  display: initial;
  background-size: cover;
  background-position: center;
  height: 50vw;
  max-height: 610px;
  filter: blur(20px);
  z-index: -1;
  margin: -10px;
  grid-area: 1 / 1;
  transform: scale(1.1);
`);

export const Header = withProps(({ img }) => ({
  style: {
    backgroundImage: img ? `${getHeaderGradient(colours)}, url(${img})` : null,
    backgroundColor: img ? 'white' : colours.blueBackground,
  },
}))(styled.header`
  background-color: ${props => props.theme.colours.blueBackground};
  background-position: center;
  background-size: 100%;
  display: grid;
  width: 100%;
  height: 50vw;
  max-width: 1200px;
  max-height: 600px;
  margin: 0 auto;
  grid-area: 1 / 1;
`);

export const StyledMemoirDetail = styled.div`
  background: ${props => props.theme.colours.memoirBlue};
  background-size: 250px 100%;
  margin: 0 auto;
`;

export const NavLink = styled(Link)`
  ${HeaderButtonStyles};
  box-shadow: none;
  border: 1px solid ${props => props.theme.colours.white};

  .fa {
    color: white;
    font-size: 20px;
  }
`;

export const NavLinkButton = withProps({ type: 'button' })(styled.button`
  ${HeaderButtonStyles};
  cursor: pointer;
  border: 1px solid ${props => props.theme.colours.white};
  opacity: ${props => (props.disabled ? 0.3 : 1)};

  .fa {
    color: white;
    font-size: 20px;
  }
`);

export const NavButton = styled(EllipsisButton)`
  ${HeaderButtonStyles};
`;

export const Title = styled.h1`
  font-family: ${props => props.theme.fonts.quicksand};
  font-size: 24px;
  color: ${props => props.theme.colours.white};
  margin: 0;
  font-weight: normal;
  ${props => props.theme.media.desktop`
    font-size: 45px;
  `};
`;

export const NavBarRight = styled.div`
  grid-column: 3;
  justify-self: end;
  display: flex;
  align-items: center;
`;

export const NavLabel = styled.span`
  font-size: 14px;
  font-family: ${props => props.theme.fonts.quicksand};
  color: ${props => props.theme.colours.white};
  margin-left: 8px;
`;

export const TooltipLink = styled(Link)`
  text-decoration: none;
  color: ${props => props.theme.colours.defaultText};
  ${props =>
    props.destructive &&
    css`
      color: ${props.theme.colours.red};
    `};
`;

export const TitleSharesWrap = styled.div`
  display: grid;
  grid-gap: ${props => props.theme.gutter} 0px;
  grid-template-columns: auto fit-content(100%) fit-content(100%);
  align-items: end;
  margin-bottom: 20px;
`;
