import { singularize } from 'inflected';
import React, { useCallback, useState, useMemo } from 'react';
import { trigger } from 'swr';

import { destroyCollectionShare } from '../../api';
import { useCollectionTypeName, useParamsFromRoute, useRouteFromEntityParams } from '../../hooks';
import { useCurrentMemberId } from '../../../members/hooks';
import { useToggle, useMatch } from '../../../app/hooks';
import { useHistory } from 'react-router-dom';
import { useEntityActionCreators, useEntities } from '../../../entities/hooks';
import DeleteModal from '../../../app/delete-modal';

export const useShareToDelete = ({ id, shareParentName }) => {
  const currentMemberId = useCurrentMemberId();
  const shares = useEntities(`${shareParentName}-shares`);
  const [shareToDelete, setShare] = useState('');

  useMemo(() => {
    const s = Object.values(shares)
      .filter(share => share['with-contact'].id === currentMemberId)
      .filter(share => share[shareParentName].id === id);
    setShare(s[0] && s[0].id);
  }, [currentMemberId, id, shareParentName, shares]);

  return shareToDelete;
};

const DeleteCollectionShare = () => {
  const {
    params: { id, type },
  } = useMatch();
  const { replace } = useHistory();
  const route = useRouteFromEntityParams({ type, id });
  const entityParams = useParamsFromRoute({ type, id });
  const [loading, toggle] = useToggle();
  const shareParentName = singularize(entityParams.type);
  const shareId = useShareToDelete({ id, shareParentName });
  const { receiveDeletion } = useEntityActionCreators();

  const onConfirm = useCallback(() => {
    const removeFromJournalShares = () => {
      return receiveDeletion({ entityType: entityParams.type, id: entityParams.id });
    };

    toggle();
    return destroyCollectionShare({ id, type: entityParams.type, shareId })
      .then(removeFromJournalShares)
      .then(() => replace('/my-stories'))
      .then(() => trigger('/v3/my/chronicle_collection'))
      .catch(toggle);
  }, [entityParams.id, entityParams.type, id, receiveDeletion, replace, shareId, toggle]);

  const onCancel = useCallback(() => replace(route), [replace, route]);

  return (
    <DeleteModal
      title="Leave Memoir"
      message="Are you sure? If you do leave, this Memoir of Stories will no longer be shared with you."
      loading={loading}
      onCancel={onCancel}
      onConfirm={onConfirm}
      deleteLabel="Leave"
    />
  );
};

export default DeleteCollectionShare;
