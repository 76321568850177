import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import React, { Fragment, useContext, useMemo } from 'react';
import { isEqual } from 'lodash';

import { useStoryPagesSWR } from 'story-pages/api';
import { MoveContext } from '../../stories/edit-story/move';
import { Wrap } from './styled';
import { selectVisiblePageMedia, selectEditPageMedia } from '../selectors';
import ButtonBar from '../button-bar';
import MoveBar from '../move-bar';
import StoryMedium from '../../story-media';
import StoryMediumCaption from '../../story-media/caption';
import StoryPageActivity from '../../stories/story-page-activity';
import UnlinkButton from '../link/unlink-button';
import Carousel from './carousel';
import OrientationButton from './orientation-button';
import { useEntity } from '../../entities/hooks/index';
import { useHistory } from 'react-router-dom';

const StoryPage = ({ id, storyId, editing, nextPageId, prevPageId, isLastPage }) => {
  const { revalidate } = useStoryPagesSWR(storyId, false);
  const { replace } = useHistory();
  const { page } = useEntity({ type: 'pages', id });
  const pageMediaWithoutCaptions = useSelector(state => {
    return selectEditPageMedia(state, id) || selectVisiblePageMedia(state, id);
  }, isEqual);

  const pageMedia = useMemo(() => {
    const { media } = page;
    const imageWithAudioCaption =
      media.length === 2 && media[0].type === 'images' && media[1].type === 'audios';

    if (editing) return pageMediaWithoutCaptions;

    if (!page['horizontal-orientation'] && !imageWithAudioCaption) return pageMediaWithoutCaptions;

    if (
      !pageMediaWithoutCaptions.filter(medium => ['videos', 'images'].includes(medium.type))
        .length > 1
    ) {
      return pageMediaWithoutCaptions;
    }

    const isCaptionableWithText = medium => ['images', 'videos'].includes(medium.type);

    const isCaptionableWithAudio = medium => ['images'].includes(medium.type);

    return pageMediaWithoutCaptions
      .map((medium, index, array) => {
        if (medium.type === 'texts') {
          const prevMedium = array[index - 1] || {};
          return isCaptionableWithText(prevMedium) ? null : medium;
        }
        if (medium.type === 'audios') {
          const prevMedium = array[index - 1] || {};
          return isCaptionableWithAudio(prevMedium) ? null : medium;
        }
        if (isCaptionableWithText(medium) || isCaptionableWithAudio(medium)) {
          const nextMedium = array[index + 1] || {};
          if (nextMedium.type === 'texts' && isCaptionableWithText(medium))
            return { ...medium, captionTextId: nextMedium.id };
          if (nextMedium.type === 'audios' && isCaptionableWithAudio(medium))
            return { ...medium, captionAudioId: nextMedium.id };
          return medium;
        }
        return medium;
      })
      .filter(medium => !!medium);
  }, [editing, page, pageMediaWithoutCaptions]);

  const photoVideoCount = useMemo(() => {
    return pageMedia.filter(medium => ['images', 'videos'].includes(medium.type)).length;
  }, [pageMedia]);

  const { moveStoryPageId } = useContext(MoveContext);
  if (!pageMedia.length) return false;
  const shouldShowOrientation = photoVideoCount > 1 && editing;
  const shouldCarousel = pageMedia.length > 1 && page['horizontal-orientation'] && !editing;

  const renderStoryPage = () =>
    pageMedia.map((medium, index) => (
      <Fragment key={`${page.id}-${medium.id}-media`}>
        <StoryMedium
          onClick={() => {
            if (!editing && medium.type === 'images') {
              replace(`/story/${storyId}/page/${page.id}`);
            }
          }}
          key={medium.id}
          id={medium.id}
          pageId={page.id}
          mediumType={medium.type}
          isGrouped={pageMedia.length > 1}
          isCarousel={shouldCarousel}
          lastItem={index === pageMedia.length - 1}
          itemPosition={`${index + 1}/${pageMedia.length}`}
          promptMove={moveStoryPageId === page.id}
          pagePosition={page.position}
          editing={editing}
          captionTextId={medium.captionTextId}
          captionAudioId={medium.captionAudioId}
        />
        {editing && (
          <UnlinkButton
            key={`unlink-${medium.id}`}
            mediaAfter={pageMedia.slice(index + 1)}
            storyId={storyId}
            page={page}
          />
        )}
      </Fragment>
    ));

  return (
    <>
      {!!moveStoryPageId && (
        <MoveBar
          pageId={page.id}
          storyId={storyId}
          position={page.position}
          prevPageId={prevPageId}
        />
      )}
      <Wrap my={editing && 0}>
        {shouldCarousel && <Carousel>{renderStoryPage()}</Carousel>}
        {!shouldCarousel && !shouldShowOrientation && renderStoryPage()}
        {!shouldCarousel && shouldShowOrientation && (
          <>
            <OrientationButton page={page} storyId={storyId} />
            {renderStoryPage()}
          </>
        )}
        {!editing && <StoryPageActivity id={page.id} storyId={storyId} />}
        <StoryMediumCaption
          enabled={editing && pageMedia.length === 1 && pageMedia[0].type !== 'texts'}
          hasAudio={pageMedia[0].type === 'images'}
          pageId={page.id}
          storyId={storyId}
        />
      </Wrap>
      {!moveStoryPageId && editing && (
        <ButtonBar
          storyId={storyId}
          pageIdBefore={page.id}
          pageIdAfter={nextPageId}
          position={page.position + 1}
          onSuccess={revalidate}
        />
      )}
      {!!moveStoryPageId && isLastPage && (
        <MoveBar storyId={storyId} position={page.position + 1} prevPageId={page.id} />
      )}
    </>
  );
};

StoryPage.propTypes = {
  id: PropTypes.string.isRequired,
  storyId: PropTypes.string.isRequired,
  editing: PropTypes.bool,
  nextPageId: PropTypes.string,
  prevPageId: PropTypes.string,
  isLastPage: PropTypes.bool,
};

StoryPage.defaultProps = {
  nextPageId: '',
  prevPageId: '',
  editing: false,
  isLastPage: false,
};

export default StoryPage;
