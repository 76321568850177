import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { pluralize } from 'inflected';

import { useHasStoryPermission } from 'stories/permission/index';
import { useScrollToItem } from 'contexts/scroll';
import {
  Activity,
  LikesCommentsAvatars,
  LikesButton,
  Separator,
  ActivityCommentsButton,
  CommentsIcon,
  Avatars,
} from './styled';
import { selectPageLikes, selectPageComments } from '../../story-pages/selectors';
import { useToggle } from '../../app/hooks';
import ActivityAvatars from './activity-like-avatars';
import LikeToggle from '../story-likes/toggle';
import StoryPageComments from '../story-page-comments';
import comment from './svg/comment.svg';

const StoryPageActivity = ({ id: pageId, likes, comments, storyId }) => {
  const [open, toggle] = useToggle(!!comments.length);
  const [userToggledComments, setUserToggledComments] = useState(false);
  const canViewActivity = useHasStoryPermission({ feature: 'VIEW_ACTIVITY', id: storyId });
  const [scrollRef] = useScrollToItem(likes.map(({ id }) => id));

  const handleCommentToggle = () => {
    toggle();
    setUserToggledComments(true);
  };

  if (!canViewActivity) return false;

  return (
    <Activity ref={scrollRef}>
      <LikesCommentsAvatars>
        <LikesButton>
          <LikeToggle {...{ storyId, pageId }}>
            {likes.length ? <div>{pluralize('Like', likes.length, true)}</div> : ''}
          </LikeToggle>
        </LikesButton>
        <Separator>|</Separator>
        <ActivityCommentsButton onClick={handleCommentToggle}>
          <CommentsIcon src={comment} alt="" />
          {(comments.length && pluralize('Comment', comments.length, true)) || ''}
          <Avatars>
            <ActivityAvatars id={pageId} />
          </Avatars>
          {open ? <FontAwesome name="chevron-up" /> : <FontAwesome name="chevron-down" />}
        </ActivityCommentsButton>
      </LikesCommentsAvatars>
      {open && (
        <StoryPageComments {...{ pageId, storyId }} userToggledComments={userToggledComments} />
      )}
    </Activity>
  );
};

StoryPageActivity.propTypes = {
  id: PropTypes.string.isRequired,
  likes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
    })
  ).isRequired,
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
    })
  ).isRequired,
  storyId: PropTypes.string.isRequired,
};

const mapStateToProps = (state, { id }) => {
  const page = state.entities.pages[id];
  const likes = selectPageLikes(state, page.id);
  const comments = selectPageComments(state, page.id);

  return {
    page,
    likes,
    comments,
  };
};

export default connect(mapStateToProps)(StoryPageActivity);
