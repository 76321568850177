import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { pluralize, underscore } from 'inflected';
import useSWR, { trigger } from 'swr';
import { postMemberShare } from '../members/connections/api';
import { getRouteFromEntityParams } from '../collections/hooks/route-from-entity-params';
import { post } from '../utils/api';
import Loading from '../app/loading';

const getShareTypePath = (shareType = '') => {
  switch (shareType) {
    case 'journal_share':
      return 'collection_shares';
    case 'member_share':
      return 'connections';
    default:
      return pluralize(underscore(shareType));
  }
};

const postPublicConnection = ({ shareType, shareId }) => {
  const typeNamePath = getShareTypePath(shareType);
  const version = ['story_share', 'member_share'].includes(shareType) ? 'v2' : 'v3';
  const path = `/${version}/public/${typeNamePath}`;
  return post(path, { data: { 'share-id': shareId } });
};
const getResourceName = (shareType = '') =>
  shareType === 'story_share' ? 'stories' : 'collections';

const PublicConnection = () => {
  const { id } = useParams();
  const match = useRouteMatch('/member/:memberShareId/:shareType/:shareId');
  const { shareType, shareId } = get(match, 'params', {});
  const { push } = useHistory();

  const [redirectRoute, setRedirectRoute] = useState('');
  const [postedConnection, setPostedConnection] = useState(false);

  useEffect(() => {
    if (id && !(shareType && shareId)) {
      postMemberShare(id).then(() => push('/shared-with-me'));
    }
    if (shareType && shareId) {
      postPublicConnection({ shareType, shareId }).then(() => {
        setPostedConnection(true);
      });
    }
  }, [id, shareType, shareId, push]);

  const path = shareType
    ? `/v3/public/${getResourceName(shareType)}/${shareId}`
    : `/v2/public/members/${id}`;
  const { data } = useSWR(path);

  useEffect(() => {
    if (data) {
      const { id: entityId, type } = data.data.data;
      const route = getRouteFromEntityParams({ id: entityId, type });
      setRedirectRoute(route);
    }
  }, [data]);

  useEffect(() => {
    if (redirectRoute && postedConnection) {
      push(redirectRoute);
    }
  }, [redirectRoute, postedConnection, push]);

  useEffect(() => {
    return () => {
      trigger(`/v3/my/chronicle_collection`);
    };
  }, []);

  return <Loading />;
};

export default PublicConnection;
