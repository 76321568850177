import { Link } from 'react-router-dom';
import { color, space } from 'styled-system';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { withProps } from 'recompose';

export const FixedOuterWrap = styled.div`
  position: fixed;
  background-color: ${props => props.theme.colours.white};
  ${color};
  height: 60px;
  margin-top: -60px;
  left: 0;
  width: 100%;
  z-index: 4;
  box-shadow: ${props => props.theme.shadows.navbar};
`;

export const FixedInnerWrap = styled.div`
  box-sizing: border-box;
  display: flex;
  height: 60px;
  padding: 1em;
  max-width: ${props => props.theme.keyedBreakpoints.xl};
  margin: 0 auto;
  align-items: center;
`;

export const HeaderButtonStyles = css`
  color: ${props => props.theme.colours.white};
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid ${props => props.theme.colours.white};
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1em;
  text-decoration: none;
  height: 44px;
  padding: 8px;
`;

export const DetailHeaderSpan = styled.span`
  ${HeaderButtonStyles};

  span {
    padding-left: 5px;
  }

  ${props =>
    props.fixed &&
    css`
      position: fixed;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
      width: 60px;
      bottom: 10px;
      right: 10px;
      color: ${props.theme.colours.defaultText};
      border-radius: 50%;
      background-color: ${props.theme.colours.background};
      box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.05);
      max-height: 60px;

      & > img {
        height: 24px;
        width: 24px;
      }
    `}

  ${props =>
    props.variant === 'destructive' &&
    css`
      color: ${props.theme.colours.red};
      background-color: ${props.theme.colours.white};
      box-shadow: none;
    `}

  ${props =>
    props.confirm &&
    css`
      color: ${props.theme.colours.brand};
      background-color: ${props.theme.colours.white};
      border: 1px solid ${props.theme.colours.brand};
      padding-right: 1em;
      margin-right: 0;
    `}
`;

export const EllipsisButton = styled.button`
  ${HeaderButtonStyles};
  height: 44px;
  margin-right: 0;
`;

export const ShareButton = styled(Link)`
  ${HeaderButtonStyles};
  background: ${props => props.theme.colours.brand};
  color: ${props => props.theme.colours.white};
  padding: 1em 1.5em;
`;

export const PageHeaderWrap = styled.div`
  max-width: ${props => props.theme.keyedBreakpoints.xl};
  margin: auto;
`;

export const HeaderBottomWrap = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: auto;
  margin-bottom: 20px;
  ${space};

  h1 {
    color: ${props => props.theme.colours.white};
    margin: 0;
    font-size: 24px;
    font-weight: normal;
    ${props => props.theme.media.tablet`
      font-size: 36px;
    `};
    ${props => props.theme.media.giant`
      font-size: 45px;
    `};
  }
`;

export const TooltipWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const TooltipStyles = css`
  text-decoration: none;
  text-align: left;
  color: black;
  ${props => props.theme.colours.mutedText};
  padding: 8px;
  font-size: 18px;
`;

export const TooltipButton = withProps({ type: 'button' })(styled.button`
  ${TooltipStyles};
  display: flex;
  align-items: center;
  ${props =>
    props.destructive &&
    css`
      color: ${props.theme.colours.red};
    `};
  background: transparent;

  svg {
    margin-right: 4px;
  }
`);

export const TooltipLink = styled(Link)`
  ${TooltipStyles};
  color: ${props =>
    props.destructive ? props.theme.colours.red : props.theme.colours.defaultText};
`;

export const BackButton = styled.button`
  margin-right: auto;
  text-decoration: none;
  font-size: 16px;
  font-family: ${props => props.theme.fonts.sanFrancisco};
  background: transparent;
  grid-column: 1;
`;
