import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FaFrown as InvalidIcon } from 'react-icons/fa';
import Button from '../../app/buttons';
import { Header, Title, Body } from '../../app/modal/v2/styled-info';

const emailLink = (
  <a
    href="mailto:team@lifetales.com"
    target="_blank"
    rel="noopener noreferrer"
  >
    team&#64;lifetales.com
  </a>
);

const InvalidEmail = ({ onDismiss }) => (
  <Fragment>
    <Header>
      <InvalidIcon size={52} color="#999" />
      <Title>Need a validated email</Title>
    </Header>
    <Body>
      <p>We need a validated email to send you the download link. Please validate your email.</p>
      <p>
        If you can&apos;t find the validation email, please contact
        {' '}
        {emailLink}
      </p>
      <Button
        onClick={onDismiss}
        style={{ width: '100%', marginTop: '20px' }}
        primary
      >
        Ok
      </Button>
    </Body>
  </Fragment>
);

InvalidEmail.defaultProps = {
  onDismiss: () => {},
};

InvalidEmail.propTypes = {
  onDismiss: PropTypes.func,
};

export default InvalidEmail;
