import styled from '@emotion/styled';

export const Close = styled.div`
  color: #fff;
  text-decoration: none;
  padding: 0.5em 2.25em;
  border-radius: 0.25em;
  background: #7a69bf;
  font-size: 1.5em;
  cursor: pointer;

  button {
    background: none;
    border: none;
    width: auto;
    margin-left: auto;
  }
`;

export default Close;
