import styled from '@emotion/styled';
import '../style.css';

import { Close } from '../styled';

export const Wrapper = styled.div`
  .modal {
    background: ${props => props.theme.colours.brand};
    text-align: center;
    padding-bottom: 2em;

    .modal__footer {
      padding: 0.5em 1em;

      & > button {
        font-size: 1.25em;
      }

      ${Close} {
        background: ${props => props.theme.colours.white};
        color: ${props => props.theme.colours.brand};
      }
    }

    .modal__header {
      display: none;
    }
  }
`;

export const HeaderLabel = styled.h1`
  margin-top: 1.5em;
  font-size: 24px;
  color: ${props => props.theme.colours.white};
`;

export const AvatarContainer = styled.div`
  margin-bottom: 4em;
  margin-top: 0.5em;
  border-style: solid;
  border-width: 2px;
  border-color: ${props => props.theme.colours.brandSubtle};
  border-radius: 50%;
  display: inline-block;
`;
