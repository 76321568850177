import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FaQuestionCircle as QuestionIcon } from 'react-icons/fa';
import Button from '../../app/buttons';
import { Header, Title, Body } from '../../app/modal/v2/styled-info';

const DownloadFinished = ({ onDismiss, onConfirm, email }) => (
  <Fragment>
    <Header>
      <QuestionIcon size={52} color="#999" />
      <Title>Download another one?</Title>
    </Header>
    <Body>
      <p>
        A download link was recently sent to
        {email}
      </p>
      <p>Are you sure you want to download another one?</p>
      <Button
        onClick={onConfirm}
        style={{
          width: '100%', marginTop: '20px', padding: 0, border: '1px solid #7a69bf',
        }}
      >
          Download Again
      </Button>
      <Button
        onClick={onDismiss}
        style={{ width: '100%', marginTop: '10px' }}
        primary
      >
        No, I&apos;ll check my email
      </Button>
    </Body>
  </Fragment>
);

DownloadFinished.defaultProps = {
  onDismiss: () => {},
  onConfirm: () => {},
  email: '',
};

DownloadFinished.propTypes = {
  onDismiss: PropTypes.func,
  onConfirm: PropTypes.func,
  email: PropTypes.string,
};

export default DownloadFinished;
