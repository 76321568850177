import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ size, fill }) => (
  <svg width={`${size}px`} height={`${size}px`} version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Upload-Files" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Edit-Story_File-Upload_3" transform="translate(-745.000000, -1254.000000)">
        <g id="Upload_Failed" transform="translate(440.000000, 1033.000000)">
          <g id="Progress" transform="translate(120.000000, 160.000000)">
            <g id="Group-2">
              <g id="button/primary" transform="translate(170.000000, 48.000000)">
                <path d="M16.65,23.28075 C16.753,23.68175 16.509,24.09075 16.108,24.19175 C16.046,24.20775 15.984,24.21475 15.922,24.21475 C15.588,24.21475 15.283,23.98875 15.197,23.64875 C15.066,23.13375 15,22.61775 15,22.11675 C15,18.62775 17.838,15.78875 21.328,15.78875 L23.236,15.78875 L21.727,14.28075 C21.434,13.98775 21.434,13.51275 21.727,13.21975 C22.02,12.92675 22.495,12.92675 22.788,13.21975 L25.965,16.39775 C26.044,16.47575 26.044,16.60275 25.965,16.68075 L22.788,19.85875 C22.641,20.00475 22.45,20.07775 22.257,20.07775 C22.065,20.07775 21.874,20.00475 21.727,19.85875 C21.434,19.56575 21.434,19.09075 21.727,18.79775 L23.236,17.28875 L21.328,17.28875 C18.666,17.28875 16.5,19.45475 16.5,22.11675 C16.5,22.49375 16.55,22.88575 16.65,23.28075 Z M27.4586,20.58545 C27.5896,21.10045 27.6556,21.61645 27.6556,22.11745 C27.6556,25.60645 24.8166,28.44545 21.3276,28.44545 L19.4196,28.44545 L20.9286,29.95445 C21.2216,30.24745 21.2216,30.72145 20.9286,31.01445 C20.7816,31.16145 20.5906,31.23445 20.3976,31.23445 C20.2056,31.23445 20.0146,31.16145 19.8676,31.01445 L16.6896,27.83645 C16.6116,27.75845 16.6116,27.63145 16.6896,27.55345 L19.8676,24.37645 C20.1606,24.08345 20.6356,24.08345 20.9286,24.37645 C21.2216,24.66845 21.2216,25.14345 20.9286,25.43645 L19.4196,26.94545 L21.3276,26.94545 C23.9896,26.94545 26.1556,24.77945 26.1556,22.11745 C26.1556,21.74045 26.1046,21.34845 26.0056,20.95345 C25.9026,20.55245 26.1466,20.14445 26.5476,20.04245 C26.9496,19.94145 27.3566,20.18345 27.4586,20.58545 Z" id="sync" fill={fill} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

Icon.defaultProps = {
  size: 17,
  fill: 'white',
};

Icon.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

export default Icon;
