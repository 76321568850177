import { useMemo, useEffect, useState } from 'react';
import truncate from 'truncate';
import { useWindowSize } from 'react-use';
import { useStripHTML } from 'app/hooks/index';
import UAParser from 'ua-parser-js';

const viewChangePixels = 1024;

export const checkIsIpadUser = () => {
  const parser = new UAParser();

  return typeof window !== 'undefined'
    ? parser.getDevice().model === 'iPad' ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints === 5)
    : false;
}

export const useCaptionText = (text = '', truncated = false, truncateLength = 75) => {
  const strippedText = useStripHTML(text);
  return useMemo(() => {
    if (!strippedText) return '';
    if (!truncated) return text;
    if (strippedText.length < truncateLength) return text;
    return `${truncate(strippedText, truncateLength)}...`;
  }, [strippedText, text, truncateLength, truncated]);
};

export const useCheckIsIosUser = () => {
  const parser = new UAParser();
  const osName = parser.getResult().os.name;
  return ['iOS'].includes(osName);
};

export const useIsSafariUser = () => {
  const parser = new UAParser();
  const browserName = parser.getResult().browser.name;
  return browserName?.includes('Safari');
};

export const useLandScapeCheckHook = () => {
  const [isLandscape, setIsLandscape] = useState(null);
  const [orientation, setOrientation] = useState(null);
  const [isBuffering, setIsBuffering] = useState(false);
  const iOSUser = useCheckIsIosUser();

  function changeEventListener(e) {
    setIsLandscape(!e.matches);
  }

  useEffect(() => {
    setTimeout(() => {
      setIsBuffering(false);
    }, 500);
  }, [orientation]);

  function orientationChange() {
    if (iOSUser) setIsBuffering(true);
    setOrientation(window.orientation);
  }

  useEffect(() => {
    const portrait = window.matchMedia('(orientation: portrait)');
    portrait.addEventListener('change', changeEventListener);
    window.addEventListener('orientationchange', orientationChange, false);
    setIsLandscape(!portrait.matches && window.navigator.maxTouchPoints !== 0);
    orientationChange();
  }, []);

  return useMemo(
    () => [isLandscape, orientation, isBuffering],
    [isLandscape, orientation, isBuffering]
  );
};

export const useMobileCheckHook = () => {
  const { width: asWidth } = useWindowSize();
  const parser = new UAParser();
  const osName = parser.getResult().os.name;
  const isIpadUser = checkIsIpadUser(parser);
  const isMobileUser = ['iOS', 'Android'].includes(osName) || isIpadUser;
  let width = asWidth;
  if (!isMobileUser) return false;
  if (window.screen.width) {
    width = window.screen.width;
    if (window.navigator.maxTouchPoints === 0) width = 1030;
  }
  return width < viewChangePixels || isIpadUser;
};

export const useHeightAndWidthOfRecorder = () => {
  const { width, height } = useWindowSize();
  const isFromMobile = useMobileCheckHook();

  return useMemo(() => {
    if (isFromMobile || width < 640) {
      return {
        width,
        height,
      };
    }

    if (width < 1025) {
      return {
        width: '640px',
        height: '360px',
      };
    }

    return {
      width: '800px',
      height: '450px',
    };
  }, [width, height, isFromMobile]);
};

export default () => { };
