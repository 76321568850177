import React, { useMemo } from 'react';

import { useActivities } from '../hooks';
import { useHistory, useLocation } from '../../app/hooks';
import NavigationItem from '../../app/navigation/item';
import NotificationsIcon from '../../app/navigation/icons/notifications';

const getUnreadActivityIds = activities =>
  Object.values(activities)
    .filter(activity => !activity.read)
    .map(activity => activity.id);

const NotificationsLink = () => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const isPageNotifications = pathname === '/notifications';

  const activities = useActivities();
  const unreadActivityIds = useMemo(() => getUnreadActivityIds(activities), [activities]);

  return (
    <NavigationItem
      indicatorCount={isPageNotifications ? 0 : unreadActivityIds.length}
      handleClick={() => push('/notifications')}
      label="Notifications"
      active={isPageNotifications}
    >
      <NotificationsIcon />
    </NavigationItem>
  );
};

export default NotificationsLink;
