import React, { useState } from 'react';
import pick from 'lodash/pick';
import { Formik } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import { Box } from 'rebass';
import { object, string } from 'yup';
import Form from '../form';
import { prepareAttrs, prepareErrors } from '../../../utils/api';
import { patchCollection } from '../../api';
import { useEntityActionCreators, useEntity } from '../../../entities/hooks/index';
import Modal from '../../../app/modal/v3/index';

const EditPersonalJournal = () => {
  const { id } = useParams();
  const { replace } = useHistory();
  const { receive } = useEntityActionCreators();
  const { entity } = useEntity({ type: 'personal-journals', id });
  const [initialErrors, setInitialErrors] = useState({});
  const onSubmit = async (values, { setSubmitting }) => {
    setInitialErrors({});
    const attributes = prepareAttrs(values);
    const type = 'personal-journals';
    try {
      const payload = await patchCollection({
        id,
        type,
        attributes,
      });
      receive({ payload });
      replace(`/${type}/${id}`);
    } catch (error) {
      setInitialErrors(prepareErrors(error));
    }
    setSubmitting(false);
  };

  const initialValues = pick(entity, ['name', 'slug', 'description']);

  const validationSchema = object().shape({
    name: string().required(),
    slug: string().required(),
    description: string().required(),
  });

  return (
    <Modal
      close={{ onClose: () => replace(`/personal-journals/${id}`) }}
      title="Edit Public Memoir"
    >
      <Box p={3}>
        <Formik
          enableReinitialize
          initialErrors={initialErrors}
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={onSubmit}
          component={Form}
        />
      </Box>
    </Modal>
  );
};

export default EditPersonalJournal;
