import { Route, Switch } from 'react-router-dom';
import React, { useContext, useEffect } from 'react';

import VideoEditPage from 'story-media/video-editor/VideoEditPage';
import { CurrentCollectionContext } from '../collections/current-collection-context';
import EditStory from './edit-story';
import NewStory from './new';
import StoryDetail from './story-detail';
import StoryChatPage from './chats';

const Stories = () => {
  const { resetCurrentCollection } = useContext(CurrentCollectionContext);
  // TODO add logic for showing on mobile

  useEffect(() => resetCurrentCollection, [resetCurrentCollection]);

  return (
    <Switch>
      {/* <Route exact path="/stories/:currentFilter/:storyId/download" component={StoriesList} /> */}
      {/* <Route exact path="/stories/:currentFilter?" component={StoriesList} /> */}
      <Route path="/stories/new/:id" component={NewStory} />
      <Route path="/story/:id/edit" component={EditStory} />
      <Route path="/story/:id/chats/:meetingId" component={StoryChatPage} />
      <Route exact path="/story/:id/:notificationSubjectId?" component={StoryDetail} />
      <Route exact path="/story/:id/delete" component={StoryDetail} />
      <Route exact path="/story/:id/shares" component={StoryDetail} />
      <Route path="/story/:id/video/:videoId" component={VideoEditPage} />
      <Route path="/story/:id" component={StoryDetail} />
    </Switch>
  );
};

export default Stories;
