import React from 'react';
import { get } from 'lodash';
import { useDispatch } from 'react-redux';
import CopyPublicUrl from '../../app/copy-public-url';
import { setCheckmark } from '../../ui/actions';
import { Wrap, Label, UrlButtonWrap } from './styled';
import { postMemberEvent } from '../../members/actions';
import { useGetMemberSWR } from '../../members/api';
import { useCurrentMemberId } from '../../members/hooks';

const PublicShareUrl = () => {
  const memberId = useCurrentMemberId();
  const dispatch = useDispatch();
  const { data } = useGetMemberSWR(memberId);

  const url = get(data, 'data.data.links["public-profile-url"]', '');

  return (
    <Wrap>
      <Label>
        <b>Your Personalized Link</b>
      </Label>
      <UrlButtonWrap>
        <CopyPublicUrl
          onCopy={() => {
            dispatch(postMemberEvent('click_add_friend_interaction'));
            dispatch(setCheckmark({ message: 'Link Copied' }));
          }}
          text={url}
        />
      </UrlButtonWrap>
    </Wrap>
  );
};

export default PublicShareUrl;
