import { Route, Switch } from 'react-router-dom';
import React from 'react';

import CustomName from './name';

const NewCustom = () => (
  <Switch>
    <Route path="/:type/new/:id?" component={CustomName} />
  </Switch>
);

export default NewCustom;
