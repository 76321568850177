import { Flex, Text } from 'rebass';
import { singularize } from 'inflected';
import { useImmerReducer } from 'use-immer';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import posed from 'react-pose';
import styled from '@emotion/styled';

import { newStoryRequestReducer } from '../new/reducer';
import { useCreateStoryRequests } from '../hooks';
import { useEntity } from '../../../../entities/hooks';
import { useMatch, useToggle } from '../../../../app/hooks';
import AddTitle from '../new/add-title';
import PlusIcon from '../../../../assets/svg/plus-icon';
import RecordButton from '../new/record-button';
import SliderWrap from '../../tag-filter-nav/slider-wrap';
import StoryRequestInvitee from '../invitee';
import SupportCopy from '../new/copy-wrap';

const PosedWrap = posed.div({
  open: { y: 0, opacity: 1, staggerChildren: 50 },
  closed: { y: -60, opacity: 0 },
});

const PosedItem = posed.div({
  open: { y: 0, opacity: 1 },
  closed: { y: -60, opacity: 0 },
});

const StoryRequestForm = ({ children, supportCopy, canHideInvitees }) => {
  const { params } = useMatch();
  const { entity } = useEntity(params);
  const [state, dispatch] = useImmerReducer(newStoryRequestReducer, {
    shares: [],
    title: '',
    file: null,
  });

  const [showInvitees, toggleShowInvitees] = useToggle(!canHideInvitees);
  const shareType = useMemo(() => `${singularize(params.type)}-shares`, [params.type]);

  const submitStoryRequests = useCreateStoryRequests(state);
  if (state.file)
    return (
      <Flex
        bg="bg"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        py={3}
        px={4}
        css="min-height: 400px"
      >
        <AddTitle
          onChangeText={e => dispatch({ type: 'SET_TITLE', title: e.target.value })}
          onSubmit={submitStoryRequests}
          disabled={!state.title}
        />
      </Flex>
    );

  return (
    <>
      <SupportCopy {...supportCopy}>
        <SliderWrap height={[120]} width="50px" bg="background">
          <Flex mr="auto" ml={3} alignItems="center" style={{ height: '120px' }}>
            {canHideInvitees && (
              <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                mr="16px"
                width={115}
              >
                <InviteeButton
                  type="button"
                  onClick={() => {
                    if (showInvitees) {
                      dispatch({ type: 'CLEAR_SHARES' });
                    }
                    toggleShowInvitees();
                  }}
                  rotate={showInvitees ? 'true' : ''}
                >
                  <PlusIcon />
                </InviteeButton>
                <Text color={showInvitees ? 'transparent' : 'mutedText'}>Add Request</Text>
              </Flex>
            )}
            <PosedWrap
              pose={showInvitees ? 'open' : 'closed'}
              style={{ display: 'flex', width: `${showInvitees ? 'auto' : '0'}` }}
            >
              {entity[shareType].map(share => (
                <PosedItem key={`story-request-${share.id}`}>
                  <StoryRequestInvitee
                    share={share}
                    onClick={memberId =>
                      dispatch({ type: 'TOGGLE_SHARE', share: { id: share.id, memberId } })
                    }
                    selected={state.shares.map(el => el.id).includes(share.id)}
                  />
                </PosedItem>
              ))}
            </PosedWrap>
          </Flex>
        </SliderWrap>
      </SupportCopy>
      {children}
      <Flex justifyContent="center" alignItems="center" my={3}>
        <RecordButton
          visible={!!state.shares.length}
          setFile={file => dispatch({ type: 'SET_FILE', file })}
        />
      </Flex>
    </>
  );
};

StoryRequestForm.propTypes = {
  children: PropTypes.node.isRequired,
  canHideInvitees: PropTypes.bool,
  supportCopy: PropTypes.shape({
    title: PropTypes.string,
    body: PropTypes.string,
  }),
};

StoryRequestForm.defaultProps = {
  canHideInvitees: true,
  supportCopy: { title: '', body: '' },
};

const InviteeButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.colours.brand};
  background: ${props => props.theme.colours.white};
  height: 60px;
  width: 60px;
  min-width: 60px;
  border-radius: 50%;
  margin-bottom: 4px;
  transition: transform 0.15s;
  transition-timing-function: ease-in-out;
  ${props => props.rotate === 'true' && 'transform: rotate(45deg)'};
`;

export default StoryRequestForm;
