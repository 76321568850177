import { space } from 'styled-system';
import { Button } from 'rebass';
import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';

import FriendProfileLink from 'friends/profile/link';
import { useToggle } from '../../app/hooks';
import AnimatedWrap from '../../app/modal/v2/animated-wrap';
import Avatar from '../../app/avatar';
import MemberAvatar from '../avatar';
import MembersOverlay from './members-overlay';

const Wrap = styled.div`
  all: unset;
  display: flex;
  flex-direction: row-reverse; /* row reverse allows the z-index to work automatically */
  justify-content: flex-end;
  padding: 0 4px;
  ${space};

  & > * {
    margin: 0 -4px;
  }
`;

const AvatarList = ({
  memberIds,
  truncateAt,
  avatarSize,
  keyPrefix,
  hasOverlay,
  overlayTitle,
  avatarProps,
  ...props
}) => {
  const visibleMembers = memberIds.slice(0, truncateAt).reverse();
  const additionalMembers = memberIds.slice(truncateAt).length;
  const [on, toggle] = useToggle();

  return (
    <>
      {hasOverlay && (
        <AnimatedWrap on={on} posedKey="avatar-list-members-overlay">
          <MembersOverlay memberIds={memberIds} title={overlayTitle} onClose={toggle} />
        </AnimatedWrap>
      )}
      <Wrap {...props}>
        <Button onClick={() => !on && toggle()} variant="unset">
          {!!additionalMembers && (
            <Avatar
              overflowCount={`+${additionalMembers}`}
              border
              size={avatarSize}
              {...avatarProps}
            >
              {additionalMembers}
            </Avatar>
          )}
        </Button>
        {visibleMembers.map(memberId => (
          <FriendProfileLink id={memberId} key={`${keyPrefix}-${memberId}`}>
            <MemberAvatar border id={memberId} size={avatarSize} {...avatarProps} />
          </FriendProfileLink>
        ))}
      </Wrap>
    </>
  );
};

AvatarList.propTypes = {
  memberIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  truncateAt: PropTypes.number.isRequired,
  avatarSize: PropTypes.number,
  keyPrefix: PropTypes.string.isRequired,
  hasOverlay: PropTypes.bool,
  overlayTitle: PropTypes.string,
  avatarProps: PropTypes.shape({}),
};

AvatarList.defaultProps = {
  avatarSize: 24,
  hasOverlay: true,
  overlayTitle: '',
  avatarProps: {},
};

export default AvatarList;
