import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useEntity } from '../../entities/hooks';

export const useCurrentMemberId = () => {
  const selectCurrentMember = useCallback(state => state.auth.currentMemberId, []);
  return useSelector(selectCurrentMember);
};

export const useCurrentMember = () => {
  const selectCurrentMember = useCallback(
    state => state.entities.members[state.auth.currentMemberId],
    []
  );
  return useSelector(selectCurrentMember);
};

export const useMemberById = id => {
  const { member } = useEntity({ id, type: 'members' });
  return member;
};

export const useMemberAvatarSrc = ({ id, size }) => {
  const { member } = useEntity({ id, type: 'members' });
  const { image } = useEntity(member['avatar-image']);
  return useMemo(() => {
    if (!image) return null;
    if (!image['image-versions']) return image['avatar-url'];
    return image['image-versions'][size || 8].url;
  }, [image, size]);
};

export { default as useMemberCollection } from './collection';

export default useCurrentMemberId;
