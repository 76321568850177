import React, { useState } from 'react';
import { trigger } from 'swr';
import { Flex, Text, Heading, Button } from 'rebass';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { IoIosArrowForward } from 'react-icons/io';
import { FaInfo } from 'react-icons/fa';
import Modal from '../../app/modal/v3';
import { ReactComponent as SmallShareIcon } from './assets/share_small.svg';
import Alias from './alias';
import ShareRowPublicLink from './share-row-public-link';
import { useEntity } from '../../entities/hooks';
import StoryShares from '../../story-shares';
import SharedMember from './shared-member';
import { ReactComponent as CollectionIcon } from './assets/rounded-journal.svg';
import CircleIcon from '../../app/icons/circle-icon';
import { ShareRow } from '../../app/share-row';
import StoryShareInfo from './share-info';
import { useSortedStoryShares } from './hooks';

const StoryShareOptions = ({ storyId, onBack, onClose }) => {
  const { story } = useEntity({ id: storyId, type: 'stories' });
  const storyShares = useSortedStoryShares(storyId);
  const [showMemberShares, setShowMemberShares] = useState(false);
  const [showAlias, setShowAlias] = useState(false);
  const [showShareInfo, setShowShareInfo] = useState(false);

  if (showMemberShares)
    return (
      <StoryShares
        storyId={storyId}
        onBack={() => {
          trigger(`/v3/stories/${storyId}/story_shares`);
          setShowMemberShares(false);
        }}
        onClose={onClose}
      />
    );

  if (showShareInfo) return <StoryShareInfo onBack={() => setShowShareInfo(false)} />;

  if (showAlias)
    return (
      <Alias
        storyId={storyId}
        onBack={() => setShowAlias(false)}
        onClose={onClose}
        onSuccess={() => {
          setShowAlias(false);
        }}
      />
    );

  return (
    <Modal close={{ onClose }} back={{ onBack }} title="Share a story">
      <Flex flexDirection="column" alignItems="stretch">
        <ShareRow
          renderIcon={() => (
            <CircleIcon bg="accent400">
              <FaInfo />
            </CircleIcon>
          )}
          label="Learn More About Sharing"
          onClick={() => setShowShareInfo(true)}
          bg="accentSubtle"
          css="cursor: pointer;"
        >
          <Button variant="unset" css="display: inline-flex; align-items: center;">
            Learn More
            <Text as={IoIosArrowForward} pl={1} color="mutedText" />
          </Button>
        </ShareRow>
        {/* <ShareRow icon={SmallShareIcon} label="Share with LifeTales Friends">
          <Button
            variant="unset"
            onClick={() => setShowMemberShares(true)}
            css="display: inline-flex; align-items: center;"
          >
            {pluralize('people', storyShares.length, true)}
            <Text as={IoIosArrowForward} pl={1} color="mutedText" />
          </Button>
        </ShareRow> */}
        <ShareRowPublicLink storyId={storyId} />
        {/* <ShareRow
          renderIcon={() => (
            <CircleIcon>
              <CollectionIcon />
            </CircleIcon>
          )}
          label="Share Via LifeTales Memoir"
          sublabel="Story visible to collection participants"
        >
          <Button
            variant="unset"
            onClick={() => setShowAlias(true)}
            css="display: inline-flex; align-items: center;"
          >
            {pluralize('collections', (story.journals || []).length, true)}
            <Text as={IoIosArrowForward} pl={1} color="mutedText" />
          </Button>
        </ShareRow> */}
        <Heading fontSize={2} p={2}>
          Participants:
        </Heading>
        <ShareRow css="border-bottom-left-radius: 8px;border-bottom-right-radius: 8px;overflow:hidden;">
          <Button
            variant="unset"
            onClick={() => setShowMemberShares(true)}
            css="display: flex; align-items: center; width: 100%"
          >
            <Flex as="span" mr="auto" flexWrap="wrap" mb={-2}>
              {storyShares.map(share => (
                <SharedMember shareId={share} mx={1} mb={2} key={share} />
              ))}
            </Flex>
            <Text color="mutedText" css="display: flex; align-items: center;">
              <Text as={IoIosArrowForward} pl={1} color="mutedText" />
            </Text>
          </Button>
        </ShareRow>
      </Flex>
    </Modal>
  );
};

StoryShareOptions.propTypes = {
  storyId: PropTypes.string.isRequired,
  onBack: PropTypes.func,
  onClose: PropTypes.func,
};

StoryShareOptions.defaultProps = {
  onBack: null,
  onClose: null,
};

export default StoryShareOptions;
