import PropTypes from 'prop-types';
import React from 'react';
import posed, { PoseGroup } from 'react-pose';

const PosedWrap = posed.div();

const AnimatedWrap = ({ on, posedKey, children }) => (
  <PoseGroup animateOnMount>
    {on && (
      <PosedWrap key={`child-${posedKey}`} style={{ position: 'absolute' }}>
        {children}
      </PosedWrap>
    )}
  </PoseGroup>
);

AnimatedWrap.propTypes = {
  on: PropTypes.bool.isRequired,
  posedKey: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.node]).isRequired,
};

export default AnimatedWrap;
