import { FaAngleRight } from 'react-icons/fa';
import { Flex } from 'rebass';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

import { CurrentCollectionContext } from '../../../collections/current-collection-context';
import { ShareButton } from './styled';
import { aliasStoryToCollection } from '../../../collections/api';
import { getStoryShares } from '../../../story-shares/api';
import { historyShape } from '../../../app/shapes';
import { useAllDirectStoryShares } from '../../../story-shares/form/hooks';
import { useCollections } from '../../../collections/hooks';
import { useQueryEntities } from '../../../entities/hooks';
import AliasCollection from './alias-collection';
import AvatarList from '../../../members/avatar-list';
import Main from '../main';
import NewStoryHeader from '../header';
import shareSvg from './assets/share.svg';

const SelectCollection = ({
  match: {
    params: { id },
  },
  history: { replace },
}) => {
  const [selected, setSelected] = useState([]);
  const [error, setError] = useState(false);
  const collections = useCollections({ noSamples: true, filterNoReadOnly: true });
  const { setCurrentCollection } = useContext(CurrentCollectionContext);
  const directShares = useAllDirectStoryShares(id);
  const directShareIds = (directShares || []).map(share => share['with-contact'].id);

  useQueryEntities({
    query: () => getStoryShares(id),
  });

  return (
    <>
      <NewStoryHeader
        onBack={() => replace(`/stories/new/${id}/cover`)}
        title="Save in a Memoir"
        nextLabel="Post"
        onNext={async () => {
          await Promise.all(
            selected.map(journal =>
              aliasStoryToCollection({
                id: journal.id,
                type: journal.type,
                storyId: id,
              })
            )
          );
          if (selected.length) {
            setCurrentCollection(selected[0]);
          }
          replace(`/story/${id}`);
        }}
      />
      <Main>
        <AliasCollection
          collections={collections}
          selected={selected}
          setSelected={setSelected}
          error={error}
          setError={setError}
        />
        <ShareButton onClick={() => replace(`/stories/new/${id}/collection/shares`)}>
          <img src={shareSvg} alt="share" />
          <Flex flexDirection="column">
            Also share with these friends
            <AvatarList
              memberIds={directShareIds}
              truncateAt={4}
              avatarSize={30}
              keyPrefix={id}
              hasOverlay={false}
              mr="auto"
            />
          </Flex>
          <FaAngleRight />
        </ShareButton>
      </Main>
    </>
  );
};

SelectCollection.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: historyShape.isRequired,
};

export default SelectCollection;
