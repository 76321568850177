import styled from '@emotion/styled';
import { withProps } from 'recompose';
import { Body } from '../../app/modal/v2/styled-info';

export const Wrap = styled(Body)`
  border-radius: 8px;
  h1 {
    margin-top: 0;
  }
`;

export const TopButtonWrap = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${props => props.theme.colours.border};
`;

export const TopButton = withProps({ type: 'button' })(styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  svg {
    height: 44px;
    width: 44px;
    margin-bottom: 8px;
  }
`);

export const RowButton = withProps({ type: 'button' })(styled.button`
  display: flex;
  align-items: center;
  padding: 10px 0;
  flex-wrap: wrap;
  svg {
    height: 40px;
    width: 40px;
    margin-right: 4px;
  }
  .icon {
    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: ${props => props.theme.colours.mutedText};
  }
`);

export const CoverPhoto = styled.img`
  height: 40px;
  width: 80px;
  border-radius: 8px;
  margin-right: 8px;
  object-fit: cover;
`;

export const LightText = styled.p`
  color: ${props => props.theme.colours.mutedText};
`;
