import { handle } from 'redux-pack';

import { LOGOUT } from '../auth/types';
import * as types from './types';

const initialState = {
  error: '',
  currentFilter: 'all',
  byPublicShareId: {},
  scrollPosition: 0,
  hasDownloadedStory: false,
  sharesOrder: [],
  newStoryVisible: false,
  newStoryRequestVisible: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.TOGGLE_NEW_STORY_VISIBLE:
      return { ...state, newStoryVisible: action.newStoryVisible };
    case types.TOGGLE_NEW_STORY_REQUEST_VISIBLE:
      return { ...state, newStoryRequestVisible: action.newStoryRequestVisible };
    case types.FILTER_STORY_LIST:
      return {
        ...state,
        currentFilter: action.filter || 'all',
      };
    case types.GET_PUBLIC_STORY:
      return handle(state, action, {
        start: prev => ({ ...prev, loading: true }),
        finish: prev => ({ ...prev, loading: false }),
        success: prev => {
          const { entities, result } = payload.data;
          const story = entities.stories[result.stories[0]];
          return {
            ...prev,
            byPublicShareId: {
              [story['share-id']]: story.id,
            },
          };
        },
      });
    case types.SET_SCROLL_POSITION:
      return {
        ...state,
        scrollPosition: action.scrollPosition,
      };
    case types.EXPORT_STORY:
      return handle(state, action, {
        finish: prev => ({ ...prev, hasDownloadedStory: true }),
      });
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};
