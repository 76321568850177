import { REQUEST_ENTITIES } from '../entities/types';
import { createMediumWithFile } from '../story-media/actions';
import { getCollection } from '../collections/actions';
import * as api from './api';

export const createStoryRequestsForShares = (
  memoirId,
  memoirShareId,
  currentMemberId,
  { shares, title, file }
) => async dispatch => {
  const storyRequestPromises = shares.map(share =>
    api.createStoryRequest({
      memoirId,
      memoirShareId,
      attributes: {
        title,
        'story-teller-id': share.memberId,
        'member-id': currentMemberId,
        'collaborate-enabled': true,
      },
    })
  );
  const storyRequestResponses = await Promise.all(storyRequestPromises);

  const storyRequestMediumPromises = storyRequestResponses.map(res => {
    const [id] = res.data.result['story-requests'];

    return dispatch(
      createMediumWithFile({ ownerType: 'StoryRequest', ownerId: id, mediumType: 'videos', file })
    );
  });
  await Promise.all(storyRequestMediumPromises);
  return dispatch(getCollection({ id: memoirId, type: 'memoirs' }));
};

export const convertStoryRequestToStory = id => ({
  type: REQUEST_ENTITIES,
  promise: api.convertStoryRequestToStory(id),
});

export const archiveStoryRequestShare = id => ({
  type: REQUEST_ENTITIES,
  promise: api.archiveStoryRequestShare(id),
});

export default createStoryRequestsForShares;
