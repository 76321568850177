import styled from '@emotion/styled';
import { withProps } from 'recompose';

export const CountIndicator = styled.span`
  position: absolute;
  top: -4px;
  left: calc(50% + 3px);
  background: ${props => props.theme.colours.red};
  border-radius: 50%;
  line-height: 16px;
  width: 16px;
  text-align: center;
  font-size: 12px;
  display: inline-block;
  color: ${props => props.theme.colours.white};
  -webkit-text-fill-color: ${props => props.theme.colours.white};

  ${props => props.theme.media.tablet`
    right: calc(50% - 20px);
  `}
`;

export const LinkInner = styled.span`
  color: ${props => (props.active ? props.theme.colours.brand : props.theme.colours.mutedText)};
  display: flex;
  flex-direction: column;
  align-items: center;

  &:hover {
    color: ${props => (props.active ? props.theme.colours.brand : props.theme.colours.defaultText)};
  }
`;

export const Label = styled.span`
  font-family: ${props => props.theme.fonts.sanFrancisco};
  font-size: 12px;
  padding-top: 2px;

  ${props => props.theme.media.tablet`
    margin: 2px auto auto auto;
    font-size: 14px;
    padding-top: 0;
  `}
`;

export const Button = withProps({ type: 'button' })(styled.button`
  all: unset;
  cursor: pointer;
  position: relative;
  background: transparent;
`);
