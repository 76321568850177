import { useMedia } from 'the-platform';
import { useIsNavigationVisible } from 'ui/hooks';
import { breakpoints } from '../styles/theme';

export const drawerBreakpoint = breakpoints.lg;
export const useHasDrawer = () => {
  const isVisible = useIsNavigationVisible();
  const isSize = useMedia({ maxWidth: drawerBreakpoint });
  if (!isVisible) return false;
  return isSize;
};
