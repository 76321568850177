import { Box, Button, Flex, Heading, Text } from 'rebass';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { toggleNewStoryVisible } from 'stories/actions';

const TagsEmpty = () => {
  const dispatch = useDispatch();
  const onClick = useCallback(() => {
    dispatch(toggleNewStoryVisible());
  }, [dispatch]);

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      m={3}
      color="mutedText"
    >
      <Heading mt={4} mb={3}>
        No stories here yet!
      </Heading>
      <Flex justifyContent="center">
        <Box flex="0 1 600px">
          <Text textAlign="center">
            Try adding some pictures already on your phone. Maybe even add some captions{' '}
            <span role="img" aria-label="grinning face">
              😀
            </span>{' '}
            I bet they’d make a great story!
          </Text>
        </Box>
      </Flex>
      <Button variant="primary" type="button" py={3} px={5} mt={4} onClick={onClick}>
        Add New Story
      </Button>
    </Flex>
  );
};

export default TagsEmpty;
