import PropTypes from 'prop-types';
import React, { useState } from 'react';
import isEqual from 'lodash/isEqual';
import { Button, Box } from 'rebass';

import { useOwnerSharesStoryShares, useOwnerSharesConnections } from './hooks';
import ConnectionRole from './connection-role';
import ShareRole from './share-role';
import ModalBottom from '../../app/modal/v3/bottom';
import { useRoleFormReducer, initialState } from './roleFormReducer';

const StorySharesForm = ({ storyId, updateStoryShares }) => {
  const storyShares = useOwnerSharesStoryShares(storyId);
  const connections = useOwnerSharesConnections(storyId);

  const [loading, setLoading] = useState(false);
  const [changes, dispatch] = useRoleFormReducer();

  return (
    <Box as="form" bg="white">
      {storyShares.map(share => (
        <ShareRole
          key={`share-role-${share.id}`}
          id={share.id}
          onClick={({ role }) => {
            if (!role) {
              return dispatch({ type: 'ADD_DELETION', id: share.id });
            }
            return dispatch({ type: 'ADD_UPDATE', id: share.id, role });
          }}
        />
      ))}
      {connections.map(connectionId => (
        <ConnectionRole
          key={`connection-role-${connectionId}`}
          id={connectionId}
          onClick={({ role, id }) => {
            return dispatch({ type: 'ADD_ADDITION', id, role });
          }}
          shareType="story-shares"
        />
      ))}
      <ModalBottom justifyContent="stretch" css="position: absolute; width: 100%;">
        <Button
          type="button"
          variant="primary"
          disabled={isEqual(initialState, changes) || loading}
          flex={1}
          onClick={() => {
            setLoading(true);
            updateStoryShares(changes);
          }}
        >
          Update
        </Button>
      </ModalBottom>
    </Box>
  );
};

StorySharesForm.propTypes = {
  updateStoryShares: PropTypes.func.isRequired,
  storyId: PropTypes.string.isRequired,
};

export default StorySharesForm;
