/* eslint-disable no-param-reassign */
import { handle } from 'redux-pack';
import { produce } from 'immer';

import { LOGOUT } from '../auth/types';
import * as types from './types';

const initialState = {
  loading: false,
  fetchedTrackingEvents: false,
};

export default (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case types.GET_MEMBER_EVENTS: {
      return handle(state, action, {
        success: prevState =>
          produce(prevState, draft => {
            draft.fetchedTrackingEvents = true;
          }),
      });
    }
    case types.GET_CURRENT_USER: {
      return handle(state, action, {
        start: prevState => ({ ...prevState, loading: true }),
        finish: prevState => ({ ...prevState, loading: false }),
      });
    }
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};
