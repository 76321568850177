import React, { useEffect, useState } from 'react';
import { Flex } from 'rebass';
import { keyframes } from '@emotion/react';
import { useUploadBatchCounts } from 'story-media/upload-manager/hooks';

const rotate = keyframes`
  0% { transform: translateX(100%); }
  100% { transform: translateX(0);  }
`;

const Wrapper = ({ children, noOfTransitions = 0, ...props }) => {
    const { success, error, uploading } = useUploadBatchCounts();
    const isUploading = !!uploading || !!success || !!error
    const [animationStyle, setAnimationStyle] = useState({});

    const animateElement = () => {
        setAnimationStyle((prevStyle) => ({
            ...prevStyle,
            opacity: 0,
            animation: 'unset',
        }));

        setTimeout(() => {
            setAnimationStyle((prevStyle) => ({
                ...prevStyle,
                opacity: 1,
                animation: `${rotate} 1s ease`,
            }));
        }, 500);
    };

    useEffect(() => {
        animateElement()
    }, [noOfTransitions])

    return (
        <Flex
            sx={animationStyle}
            flexDirection="column"
            maxWidth={500}
            m="auto"
            mt={isUploading ? ['70px', '170px'] : ['10px', '100px']}
            {...props}
        >
            {children}
        </Flex>
    );
};

export default Wrapper;
