export const noNavPaths = [
  '/stories/new/:id',
  '/child-journals/new/:id',
  '/family-journals/new/:id',
  '/memoirs/new/:id',
  '/custom/new/:id',
  '/personal-journals/new/:id',
];

export default noNavPaths;
