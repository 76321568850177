import React from 'react';
import styled from '@emotion/styled';
import { ReactComponent as SampleBadgeSvg } from './sample-badge.svg';

const Wrap = styled.div`
  position: absolute;
  top: 0;
  right: 16px;
`;

const SampleBadge = () => (
  <Wrap>
    <SampleBadgeSvg />
  </Wrap>
);

export default SampleBadge;
