import axios from 'axios';
import normalize from 'jsonapi-normalizer';
import store from 'store';

import { isNull, isUndefined } from 'lodash';
import apiBase from './api-base';

export const getMultipartHeaders = () => ({
  accept: 'application/json',
  'Content-Type': 'multipart/form-data',
  'X-LIFETALES-CLIENT': 'web-memoirs',
});

export const getJsonHeaders = () => ({
  accept: 'application/json',
  'Content-Type': 'application/json',
  'X-LIFETALES-CLIENT': 'web-memoirs',
});

export const getAuthorizedHeaders = () => {
  const token = store.get('auth_token');
  return {
    Authorization: `Token ${token}`,
    ...getJsonHeaders(),
  };
};

export const normalizeAxiosResponse = (data, headers) => {
  if (!data) return data;
  if (!headers['content-type'].includes('application/json')) return data;

  const nextData = JSON.parse(data);

  if (nextData.errors) return data;

  if (nextData.data && nextData.data.links) {
    nextData.data.attributes.links = nextData.data.links;
  }

  if (nextData.included) {
    nextData.included = nextData.included.map(inc => {
      if (!inc.links) return inc;
      return {
        ...inc,
        attributes: {
          ...inc.attributes,
          links: inc.links,
        },
      };
    });
  }

  if (Array.isArray(nextData.data)) {
    nextData.data = nextData.data.map(item => {
      if (item.links) {
        return {
          ...item,
          attributes: {
            ...item.attributes,
            links: item.links,
          },
        };
      }
      return item;
    });
  }

  const normData = normalize(nextData);
  return { ...normData, initial: JSON.parse(data) };
};

export const getAxiosInstance = (opts = { normalize: true }) => {
  const transformResponse = (opts.normalize && [normalizeAxiosResponse]) || null;
  const baseURL = new URL(apiBase).origin;
  return axios.create({
    headers: getAuthorizedHeaders(),
    baseURL: `${baseURL}/api`,
    transformResponse,
  });
};

export const getSWR = url => {
  const ax = getAxiosInstance({ normalize: false });
  return ax.get(url);
};

export const get = url => {
  const ax = getAxiosInstance();
  return ax.get(url);
};

export const post = (url, body, opts) => {
  const ax = getAxiosInstance(opts);
  return ax.post(url, body);
};

export const patch = (url, body) => {
  const ax = getAxiosInstance();
  return ax.patch(url, body);
};

export const destroy = url => {
  const baseURL = new URL(apiBase).origin;
  const ax = axios.create({
    headers: getAuthorizedHeaders(),
    baseURL: `${baseURL}/api`,
  });
  return ax.delete(url);
};

export const prepareAttrs = values =>
  Object.keys(values).reduce((acc, key) => {
    if (isUndefined(values[key]) || isNull(values[key])) return acc;
    return {
      ...acc,
      [key.replace('-noac', '')]: values[key],
    };
  }, {});

export const prepareErrors = error => {
  const { errors } = JSON.parse(error.response.data);
  if (!errors) return {};
  const formikStatus = errors.reduce(
    (acc, el) => ({
      ...acc,
      [el.field]: el.detail,
    }),
    {}
  );
  return formikStatus;
};
