import { Route, useLocation } from 'react-router-dom';
import React, { useMemo } from 'react';

import { useCurrentMemberId } from 'members/hooks/index';
import { Wrap } from './styled';
import AddFriend from './add-friend';
import AnimatedNavWrap from '../app/navigation/animated-nav-wrap';
import FriendDeleteConfirm from './connection-avatar/delete/modal';
import FriendRequests from './friend-requests';
import FriendSuggestions from './friend-suggestions';
import Loading from '../app/loading';
import MyFriends from './my-friends';
import NoFriends from './no-friends';
import NotificationModal from '../app/modal/notification-modal';
import FriendProfile from './profile';
import { useMemberConnectionsSWR } from '../members/api';

const Friends = () => {
  const memberId = useCurrentMemberId();
  const location = useLocation();
  const { data } = useMemberConnectionsSWR({ memberId });

  const connections = useMemo(() => {
    if (!data) return [];
    return data.data.data;
  }, [data]);

  if (!data && !connections.length) return <Loading />;

  return (
    <Wrap>
      <NoFriends count={connections.length} />
      <FriendRequests />
      <FriendSuggestions />
      <MyFriends />
      <AnimatedNavWrap location={location}>
        <Route path="/friends/add" component={AddFriend} key="add-friend" />
        <Route
          exact
          path="/friends/:id/delete"
          component={FriendDeleteConfirm}
          key="friend-delete-confirm"
        />
        <Route
          path="/friends/new-connection/:connectionId"
          component={NotificationModal}
          key="notification=modal"
        />
        <Route path="/friends/:id" component={FriendProfile} key="friend-profile" />
      </AnimatedNavWrap>
    </Wrap>
  );
};

export default Friends;
