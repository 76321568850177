import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import ChatPage from 'chats/chat-page';

const NewStoryChat = () => {
  const { id, meetingId } = useParams();
  const { replace } = useHistory();
  return <ChatPage meetingId={meetingId} onExit={() => replace(`/stories/new/${id}`)} />;
};

export default NewStoryChat;
