import createCachedSelector from 're-reselect';
import get from 'lodash/get';

import { selectMember } from '../members/selectors';
import { selectStorySharesForStory } from '../story-shares/selectors';

export const selectStory = (state, storyId) => state.entities.stories[storyId] || {};

export const selectStoryCover = (state, storyId) => {
  const story = selectStory(state, storyId);
  if (story['cover-image']) {
    const { id: imageId } = story['cover-image'];
    return get(state, `entities.images[${imageId}]`, {});
  }
  return story['image-url'] || {};
};

export const selectStoryFromShareId = (state, storyShareId) =>
  state.entities.stories[state.story.byPublicShareId[storyShareId]] || {};

export const selectStoryPublicShareUrl = (state, storyId) =>
  get(state.entities.stories[storyId], 'links["story-share-url"]', '');

export const selectStoryCollaborationRole = (state, storyId) => {
  const story = selectStory(state, storyId);
  const currentUserId = state.auth.currentMemberId;
  if (story.member.id === currentUserId) {
    return 'owner';
  }

  const storyShares = state.storyShares[storyId] || [];
  const storyShare = storyShares.find(share => share.withContactId === currentUserId) || {};
  return get(state.entities['story-shares'], `[${storyShare.shareId}]['contact-role']`, 'viewer');
};

export const selectStoryOwner = (state, storyId) => {
  const story = selectStory(state, storyId);
  if (!story.member) {
    return undefined;
  }
  const owner = selectMember(state, story.member.id);
  return owner;
};

export const selectStoryOwnerId = (state, storyId) => {
  const owner = selectStoryOwner(state, storyId);
  return (owner && owner.id) || '';
};

export const selectIsStoryOwner = ({ state, storyId }) => {
  const storyOwnerId = selectStoryOwnerId(state, storyId);
  const { currentMemberId } = state.auth;
  return currentMemberId === storyOwnerId;
};

export const selectStoryLoading = state => state.story.loading;

export const selectStoryPages = (state, storyId) => {
  const story = selectStory(state, storyId) || {};
  const { pages } = story;
  if (!pages) return undefined;
  return pages.map(page => page.id);
};

const selectIsStoryOwnerOrCollaborator = createCachedSelector(
  [
    ({ state, storyId }) => state.entities.stories[storyId],
    ({ state, storyId }) => selectStorySharesForStory(state, storyId),
    ({ state }) => state.entities['story-shares'],
    ({ state }) => state.auth.currentMemberId,
  ],
  (story, storyShares, entities, currentMemberId) => {
    if (!story) return undefined;
    const {
      member: { id: ownerId },
    } = story;
    const collaborators = (storyShares || [])
      .filter(share => entities[share]['contact-role'] === 'collaborator')
      .filter(share => !!entities[share]['with-contact'])
      .map(share => entities[share]['with-contact'].id);
    return [ownerId, ...collaborators].includes(currentMemberId);
  }
)(({ storyId }) => `${storyId}-current-member-is-owner-or-collaborator`);

export const selectCanShareStory = createCachedSelector(
  [
    ({ state, storyId }) => state.entities.stories[storyId],
    ({ state, storyId }) => selectStorySharesForStory(state, storyId),
    selectIsStoryOwnerOrCollaborator,
  ],
  (story, storyShares, isOwnerOrCollaborator) => {
    if (!story) return undefined;
    return !!storyShares.length && isOwnerOrCollaborator;
  }
)(({ storyId }) => `${storyId}-can-share`);

export const selectStoryChronicle = ({ state, storyId }) => {
  const { 'child-journals': childJournals } = state.entities;
  if (!childJournals) return {};
  const cjId = Object.keys(childJournals).find(cj =>
    (childJournals[cj]['story-shares'] || []).find(storyShare => {
      const shareEntity = state.entities[storyShare.type][storyShare.id];
      return shareEntity && shareEntity.story.id === storyId;
    })
  );
  if (!cjId) return {};
  return childJournals[cjId];
};
