import React from 'react';
import CircleCheckmark from '../../assets/svg/circle-checkmark';
import { List } from './styled';
import { useEntities } from '../../entities/hooks/index';

const FeatureList = () => {
  const allFeatures = useEntities('features');

  return (
    <List>
      {Object.values(allFeatures).map(feature => (
        <li key={feature.id}>
          <CircleCheckmark />
          &nbsp;{feature.name}{' '}
          <span className={`badge ${feature.label}`}>{(feature.label || '').toUpperCase()}</span>
        </li>
      ))}
    </List>
  );
};

export default FeatureList;
