
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'rebass';
import { useIsVisible } from 'react-is-visible';

const LoadMoreButton = ({ loadMore, disabled }) => {
  const buttonRef = useRef(null);
  const isVisible = useIsVisible(buttonRef);

  useEffect(() => {
    if (isVisible && !disabled) {
      loadMore();
    }
  }, [isVisible, disabled, loadMore]);

  return (
    <Button
      ref={buttonRef}
      type="button"
      onClick={loadMore}
      disabled={disabled}
      css="width: 100%;"
      bg="bgGrey"
      border="1px solid"
      borderColor="bgGrey"
      color="mutedText"
      m={2}
      mt={3}
      py={3}
    >
      Load More
    </Button>
  );
};

LoadMoreButton.propTypes = {
  loadMore: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default LoadMoreButton;
