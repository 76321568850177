import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import PropTypes from 'prop-types';

const AutoGrowTextArea = ({ field, ...props }) => (
  <TextareaAutosize
    {...field}
    placeholder={props.placeholder}
    style={props.style}
    type={field.value}
  />
);

AutoGrowTextArea.defaultProps = {
  style: {
    minHeight: '20px',
    maxHeight: '80px',
    resize: 'none',
  },
  autoFocus: false,
  placeholder: 'Enter a value',
};

AutoGrowTextArea.propTypes = {
  field: PropTypes.shape({
  }).isRequired,
  style: PropTypes.shape({
  }),
  autoFocus: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default AutoGrowTextArea;
