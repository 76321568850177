import React, { createContext, useState } from 'react';

export const CurrentModalContext = createContext();

const CurrentModal = props => {
  const [currentModal, setCurrentModal] = useState('');
  const [revalidateSidebar, setRevalidateSidebar] = useState(false);

  return <CurrentModalContext.Provider value={{ currentModal, setCurrentModal, revalidateSidebar, setRevalidateSidebar }} {...props} />;
};

export default CurrentModal;
