const mergeEntity = (newEntity, prevEntity = {}) => ({
  ...prevEntity,
  ...newEntity,
});

export const mergeEntities = (normPayload, prevState) =>
  Object.keys(normPayload.entities).reduce((acc, entityType) => {
    const prevEntities = prevState[entityType] || {};
    const newEntityIds = Object.keys(normPayload.entities[entityType]);
    const newEntities = newEntityIds.reduce(
      (typeAcc, entityId) => ({
        ...typeAcc,
        [entityId]: mergeEntity(normPayload.entities[entityType][entityId], prevEntities[entityId]),
      }),
      {}
    );
    const nextState = {
      ...acc,
      [entityType]: {
        ...prevEntities,
        ...newEntities,
      },
    };
    return nextState;
  }, prevState);

export default mergeEntities;
