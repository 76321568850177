import { useSelector } from 'react-redux';
import { selectEntity } from 'entities/selectors';
import { useCollectionShares } from '.';
import useIsCurrentUserLifetales from '../../entities/hooks/is-current-user-lifetales';

const useCollections = ({
  filterIsOwner,
  noSamples,
  filterIsNotLifetales,
  filterIsPaid,
  filterNoReadOnly,
} = {}) => {
  const shares = useCollectionShares({ filterIsOwner, filterIsNotLifetales, filterNoReadOnly });
  const isCurrentUserLifetales = useIsCurrentUserLifetales();

  return useSelector(state =>
    (shares || [])
      .map(({ id, type }) => {
        const share = selectEntity({ state, id, type });
        if (!share) return null;
        const journal = selectEntity({ state, ...share.journal });
        return { ...journal, type: share.journal.type };
      })
      .filter(collection => !!collection)
      .filter(collection => {
        if (noSamples && !isCurrentUserLifetales && collection['is-a-sample']) return false;
        if (filterIsPaid && !collection['owner-subscription-enabled']) return false;
        return true;
      })
  );
};

export default useCollections;
