import { Form, Field } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Box, Flex } from 'rebass';

// import { useSelector } from 'react-redux';
import DateField from '../../app/form/formik/date';
import TextField from '../../app/form/formik/text';
import ModalBottom from '../../app/modal/v3/bottom';
import CoverPicker from './cover';
// import CoverThumbnails from './cover/thumbnails';
import { useEntity } from '../../entities/hooks';
// import { selectStoryImageEntities } from '../../story-pages/selectors';

const StorySettingsForm = ({ storyId, values, handleCancel }) => {
  const { story } = useEntity({ type: 'stories', id: storyId });

  return (
    <Form>
      <Flex flexDirection="column" alignItems="stretch">
        <Box mb={3}>
          <Field name="cover" component={CoverPicker} initialCover={story['cover-image']} />
        </Box>
        <Field
          placeholder="Christmas Dinner, Trip to Cuba, Baking Contest"
          name="title"
          label="Title"
          showError
          component={TextField}
          mb={3}
          width="100%"
        />
        <Flex flexDirection={['column', 'row']} alignItems={[null, 'flex-end']}>
          <Field
            name="began-on"
            label="Date"
            placeholder="Start Date"
            maxDate={values['ended-on']}
            component={DateField}
            width="100%"
            mr={1}
            mb={3}
            pickerProps={{ popperPlacement: 'top' }}
          />
          <Field
            name="ended-on"
            placeholder="End Date"
            minDate={values['began-on']}
            component={DateField}
            width="100%"
            ml={1}
            mb={3}
            pickerProps={{ popperPlacement: 'top' }}
          />
        </Flex>
        <ModalBottom justifyContent="flex-end">
          <Button variant="muted" type="button" onClick={handleCancel} mr={2}>
            Cancel
          </Button>
          <Button variant="primary" type="submit">
            Save
          </Button>
        </ModalBottom>
      </Flex>
    </Form>
  );
};

StorySettingsForm.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  values: PropTypes.shape({}),
  collection: PropTypes.shape({
    type: PropTypes.string,
    id: PropTypes.string,
  }),
  storyId: PropTypes.string,
};

StorySettingsForm.defaultProps = {
  values: {},
  collection: {
    type: '',
    id: '',
  },
  storyId: null,
};

export default StorySettingsForm;
