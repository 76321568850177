import { useMemo } from 'react';

import { useCurrentMemberId } from '../../members/hooks';
import { useEntities } from '../../entities/hooks';

const useCurrentMemberShares = ({ type, filterIsOwner }) => {
  const currentMemberId = useCurrentMemberId();
  const shares = useEntities(type);

  return useMemo(() => {
    return Object.keys(shares || {})
      .filter(
        shareId =>
          shares[shareId]['with-contact'] && shares[shareId]['with-contact'].id === currentMemberId
      )
      .filter(shareId => (filterIsOwner ? shares[shareId]['contact-role'] === 'owner' : true))
      .map(id => ({ type, id }));
  }, [currentMemberId, filterIsOwner, shares, type]);
};

export default useCurrentMemberShares;
