

import { Heading, Flex, Button } from 'rebass';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const DeleteCoverImage = ({ onClick, onCancel, text, isVisible }) => {
  const [submitting, setSubmitting] = useState(false);
  if (!isVisible) return false;
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      css="background-image: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,255,.85) 30%, rgba(255,255,255,.5) 100%); z-index: 2; position: absolute; top: 0; bottom: 0; left: 0; right: 0;"
    >
      <Heading mb={3} textAlign="center">
        {text}
      </Heading>
      <Flex>
        <Button
          m={1}
          disabled={submitting}
          type="button"
          onClick={() => {
            setSubmitting(true);
            onClick();
          }}
          variant="destructive"
        >
          Delete
        </Button>
        <Button m={1} disabled={submitting} type="button" variant="muted" onClick={onCancel}>
          Cancel
        </Button>
      </Flex>
    </Flex>
  );
};

DeleteCoverImage.propTypes = {
  text: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
};

export default DeleteCoverImage;
