import { Box, Button, Flex, Heading } from 'rebass';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import { Form, Submit } from '../../../collections/new/form-styled';
import { relationshipOptions } from './options';
import OptionButton from '../../../collections/new/gender/option-button';

const RelationshipForm = ({ isValid, isSubmitting, isPublicRoute }) => (
  <Form>
    <Box width="100%" my="auto" pb={50} mt={isPublicRoute && "70px"}>
      <Heading textAlign="center" fontSize={24} fontWeight="normal" py={14}>
        Who is the memoir about?
      </Heading>
      <Flex flexDirection="column" alignItems="stretch">
        <Field
          name="subject-relation-noac"
          render={({ field, form }) =>
            relationshipOptions.map(option => (
              <OptionButton
                key={`new-cj-${option.value}`}
                selected={field.value === option.value}
                onClick={() => form.setFieldValue(field.name, option.value)}
              >
                {option.label}
              </OptionButton>
            ))
          }
        />
      </Flex>
    </Box>
    <Submit as={Button} variant="primary" fontSize={20} disabled={!isValid && !isSubmitting}>
      Next
    </Submit>
  </Form>
);

RelationshipForm.propTypes = {
  isValid: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default RelationshipForm;
