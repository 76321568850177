import { classify, singularize, underscore } from 'inflected';

import { REQUEST_ENTITIES } from '../entities/types';
import { postMedium } from '../story-media/api';
import { receiveEntity, updateEntity } from '../entities/actions';
import { uploadMediumFile } from '../story-media/actions';
import * as api from './api';
import * as types from './types';

export const setCurrentCollectionTag = (currentTag = '') => ({
  type: types.SET_CURRENT_COLLECTION_TAG,
  currentTag,
});

export const getCollections = ({ type }) => ({
  type: REQUEST_ENTITIES,
  promise: api.getCollections({ type }),
});

export const getAllCollections = () => ({
  type: REQUEST_ENTITIES,
  promise: api.getAllCollections(),
});

export const getCollection = ({ type, id }) => ({
  type: REQUEST_ENTITIES,
  promise: api.getCollection({ id, type }),
});

export const createCollectionCoverMedium = ({ id, type, cover }) => dispatch => {
  const ownerType = classify(singularize(underscore(type)));
  // // eslint-disable-next-line
  // debugger;
  postMedium({
    attrs: {
      type: 'Image',
      ownerId: id,
      filename: cover.name || 'cover',
      previewFilename: ' ',
      ownerType,
    },
  }).then(payload => {
    // receive medium
    dispatch(receiveEntity({ payload }));

    // add tmp URL to cover, update collection to point to it
    const { entities, result } = payload.data;
    const image = entities.images[result.images[0]];
    const tmpUrl = URL.createObjectURL(cover);
    dispatch(
      updateEntity({
        entityType: 'images',
        id: image.id,
        attrs: { tmpUrl },
      })
    );

    const attrs =
      type === 'child-journals'
        ? { 'subject-avatar-id': image.id, 'subject-avatar': { id: image.id, type: 'images' } }
        : { 'cover-image': { id: image.id, type: 'images' } };

    dispatch(
      updateEntity({
        entityType: type,
        attrs,
        id,
      })
    );

    // don't return the upload file promise so that it doesn't block
    dispatch(uploadMediumFile(image['upload-url'], image, cover));
    return image;
  });
};
