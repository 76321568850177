import React from 'react';
import useSWR from 'swr';

import { CurrentCollectionProvider } from '../collections/current-collection-context';
import { ScrollProvider } from './scroll';
import { TagContextProvider } from '../collections/detail/tag-context';
import NotificationsWrap from '../notifications/wrap';
import MemberSubscription from '../members/profile/subscription';
import CurrentModal from '../app/modal/context';
import { useMemberConnectionsSWR, useMemberEventsSWR } from '../members/api';
import { useCurrentMemberId } from '../members/hooks/index';
import { getSWR } from '../utils/api';

const AuthorizedContext = props => {
  const memberId = useCurrentMemberId();
  useMemberConnectionsSWR({ memberId });
  useSWR(`/v2/members/${memberId}/identifiers`, getSWR, {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
  });
  useMemberEventsSWR({});

  return (
    <NotificationsWrap>
      <CurrentModal>
        <ScrollProvider>
          <CurrentCollectionProvider>
            <MemberSubscription>
              <TagContextProvider {...props} />
            </MemberSubscription>
          </CurrentCollectionProvider>
        </ScrollProvider>
      </CurrentModal>
    </NotificationsWrap>
  );
};

export default AuthorizedContext;
