import styled from '@emotion/styled';
import { fontSize, color, space } from 'styled-system';

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
`;

export const Label = styled.span`
  ${color};
  ${space};
  ${fontSize};
  text-align: right;
`;

export const CircleShareButton = styled.button`
  background: ${props => props.theme.colours.brand};
  cursor: pointer;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  color: ${props => props.theme.colours.white};
  font-size: 16px;
`;

export const RemainderLabel = styled.span`
  grid-area: 1 / 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 36px;
  width: 36px;
  background-color: ${props => props.theme.colours.photoGradientEnd};
  pointer-events: none;
  text-decoration: none;
  color: ${props => props.theme.colours.white};
  font-size: 18px;
  line-height: 1;
`;

export const RemainderWrap = styled.div`
  display: grid;
`;

export const OverflowLabel = styled.span`
  grid-area: 1 / 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 36px;
  width: 36px;
  background-color: ${props => props.theme.colours.photoGradientEnd};
  margin-left: 4px;
  pointer-events: none;
  text-decoration: none;
  color: ${props => props.theme.colours.white};
  font-size: 18px;
  line-height: 1;
`;

export const MemberOverflow = styled.div`
  display: grid;
`;
