import { Box, Flex, Heading } from 'rebass';
import React from 'react';
import { useQueryEntities } from '../entities/hooks';

import { getNotifications } from './api';
import { useActivities } from './hooks';
import DismissButton from './dropdown/dismiss-button';
import Notification from './notification';
import EmptyNotifications from './dropdown/empty';
import PushPrompt from './push-prompt/index';
import InitialAskModal from './initial-ask-modal';

const PageWrap = props => (
  <Flex justifyContent="center">
    <Box m={[2, 3]} flex="0 1 1200px" {...props} />
  </Flex>
);

const Notifications = () => {
  const { loading } = useQueryEntities({
    query: getNotifications,
  });
  const activities = useActivities();
  const activityList = Object.keys(activities).sort(
    (a, b) => new Date(activities[b]['created-at']) - new Date(activities[a]['created-at'])
  );

  const hasNotifications = !!activityList.length;

  return (
    <PageWrap>
      <InitialAskModal />
      <Flex justifyContent="space-between">
        <Heading color="mutedText" mb={[2, 3]} ml={[1, 2]}>
          Notifications
        </Heading>
        {hasNotifications && <DismissButton />}
      </Flex>
      <PushPrompt />
      {!hasNotifications && !loading && <EmptyNotifications />}
      <Box sx={{ boxShadow: 'small' }} borderRadius={8}>
        {activityList.map(id => (
          <Notification key={id} id={id} />
        ))}
      </Box>
    </PageWrap>
  );
};

export default Notifications;
