import axios from 'axios';

import { post, patch, destroy } from '../utils/api';

export const createStoryMedium = (storyId, body) => post(`/v2/stories/${storyId}/media`, body);
export const createStoryCoverMedium = (storyId, filename) =>
  post('/v2/media', {
    type: 'Image',
    data: {
      attributes: {
        type: 'Image',
        'owner-type': 'Story',
        'owner-id': storyId,
        filename,
      },
    },
  });
export const postExternalMedium = ({ externalURL, storyId, position }) =>
  post(`/v2/stories/${storyId}/media`, {
    type: 'ExternalMedia',
    data: {
      attributes: {
        type: 'ExternalMedia',
        'external-url': externalURL,
        filename: 'nonse.nse',
        'page-position': position,
      },
    },
  });

export const postMedium = ({
  attrs: { type, ownerType, ownerId, filename, preview_filename: previewFilename, ...attrs },
}) =>
  post('/v2/media', {
    type,
    data: {
      attributes: {
        type,
        filename,
        'owner-type': ownerType,
        'owner-id': ownerId,
        'preview-filename': previewFilename || attrs.previewFilename || ' ',
      },
    },
  });
export const postStoryMedium = ({ id, attributes }) =>
  post(`/v2/stories/${id}/media`, { type: attributes.type, data: { attributes } });
export const updateTextMedium = (mediumId, body) => patch(`/v2/media/${mediumId}`, body);
export const destroyStoryMedium = mediumId => destroy(`/v2/media/${mediumId}`);
export const uploadMediumFile = (url, file, config = {}) =>
  axios.put(url, file, { headers: { 'Content-Type': file.type }, ...config });

export default createStoryMedium;
