import { IoIosCheckmarkCircleOutline, IoIosCheckmarkCircle } from 'react-icons/io';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { withProps } from 'recompose';

import { EntityAttr } from '../../entities';
import { useEntity } from '../../entities/hooks';

export const Wrap = withProps({ type: 'button' })(styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  border-top: 1px solid ${props => props.theme.colours.border};
  font-family: ${props => props.theme.fonts.sanFrancisco};
  width: 100%;
  padding: 0 20px;
  cursor: pointer;
  opacity: ${props => (props.disabled ? '0.5' : '1')};
  background: white;

  &:first-child {
    margin-top: 20px;
  }

  svg {
    color: ${props => (props.checked ? props.theme.colours.brand : props.theme.colours.grey80)};
  }
`);

const ListItem = ({ id, type, currentValue, setCollection }) => {
  const { entity: share } = useEntity({ id, type });

  const collectionParams = useMemo(() => (share ? share.journal || share['child-journal'] : null), [
    share,
  ]);

  const isChecked = isEqual(currentValue, collectionParams);

  return (
    <Wrap
      checked={isChecked}
      onClick={() => (isChecked ? setCollection(null) : setCollection(collectionParams))}
    >
      <span>
        <EntityAttr {...collectionParams} attr="name" />
      </span>
      {isChecked ? <IoIosCheckmarkCircle size={30} /> : <IoIosCheckmarkCircleOutline size={30} />}
    </Wrap>
  );
};

ListItem.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  currentValue: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
  }),
  setCollection: PropTypes.func.isRequired,
};

ListItem.defaultProps = {
  currentValue: null,
};

export default ListItem;
