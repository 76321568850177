import React from 'react';

import { useHistory } from 'react-router-dom';
import CollectionOnboardCarousel from '../carousel';
import CollectionOnboardSlide from '../slide';
import Modal from '../../../app/modal/v2';
import bg1 from '../assets/bg-1.png';
import img1 from './assets/img-1.png';

const MemoirsOnboard = () => {
  const { push } = useHistory();

  return (
    <Modal onClose={() => push('/collections/new')} fullscreenMobile closeButton>
      <CollectionOnboardCarousel
        ctaText="Start Your Custom Memoir"
        onClose={() => push('/custom/new')}
      >
        <CollectionOnboardSlide
          header="Total Flexibility"
          body="You can create a collection of stories on anything and share it with the people involved: book club, friend group, or a hobby project etc."
          bg={bg1}
          image={img1}
        />
      </CollectionOnboardCarousel>
    </Modal>
  );
};

export default MemoirsOnboard;
