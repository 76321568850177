import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { trigger } from 'swr';
import DeleteModal from '../../../app/delete-modal';
import { useEntity } from '../../../entities/hooks';
import { useCurrentUserStoryRequestShare } from './hooks';
import { archiveStoryRequestShare } from '../../../story-requests/actions';
import { destroyStory } from '../../../stories/api';
import { setCheckmark } from '../../../ui/actions';

const StoryRequestDelete = () => {
  const dispatch = useDispatch();
  const { replace } = useHistory();
  const { type, id, storyRequestId } = useParams();
  const { isOwner } = useEntity({
    type: 'story-requests',
    id: storyRequestId,
  });

  const storyRequestShare = useCurrentUserStoryRequestShare(storyRequestId);

  const cancelRoute = `/${type}/${id}/story-requests/${storyRequestId}`;

  const handleDelete = useCallback(() => {
    if (!isOwner) {
      dispatch(archiveStoryRequestShare(storyRequestShare.id)).then(() => {
        trigger('/v3/my/chronicle_collection');
        replace(`/${type}/${id}`);
        dispatch(setCheckmark({ message: 'Deleted' }));
      });
    } else {
      Promise.all([destroyStory(storyRequestId, id)]).then(() => {
        trigger('/v3/my/chronicle_collection');
        replace(`/${type}/${id}`);
        dispatch(setCheckmark({ message: 'Deleted' }));
      });
    }
  }, [dispatch, id, isOwner, replace, storyRequestId, storyRequestShare.id, type]);

  return (
    <DeleteModal
      message="Are you sure you want to delete this story request?"
      deleteLabel="Delete Request"
      onCancel={() => replace(cancelRoute)}
      onConfirm={handleDelete}
    />
  );
};

export default StoryRequestDelete;
