import FontAwesome from 'react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';

import { gutter } from '../../../app/styles/constants';

const Wrap = styled.div`
  color: #999;
  font-family: sans-serif;
  font-size: 12px;
  text-align: center;
  margin-top: ${gutter};

  .fa {
    margin-right: 0.5em;
  }
`;

const FormPageHint = ({ iconName, children }) => (
  <Wrap>
    {iconName && <FontAwesome name={iconName} />}
    {children}
  </Wrap>
);

FormPageHint.propTypes = {
  iconName: PropTypes.string,
  children: PropTypes.node.isRequired,
};

FormPageHint.defaultProps = {
  iconName: null,
};

export default FormPageHint;
