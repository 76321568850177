import PropTypes from 'prop-types';
import { useCurrentMember } from '../hooks';
import { useEntity } from '../../entities/hooks';

const MemberName = ({ id }) => {
  const { member } = useEntity({ id, type: 'members' });
  const currentMemberId = useCurrentMember();
  if (!member.id) return '';
  return currentMemberId === member.id ? 'You' : member['full-name'];
};

MemberName.propTypes = {
  id: PropTypes.string.isRequired,
};

export default MemberName;
