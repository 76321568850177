import { createSelector } from 'reselect';

export const selectMedium = (state, id, type) => {
  if (type) return state.entities[type] && state.entities[type][id];

  const media = ['texts', 'images', 'audios', 'videos'].reduce((acc, el) => {
    if (!state.entities[el]) return acc;

    return {
      ...acc,
      ...state.entities[el],
    };
  }, {});
  return media[id];
};

export const selectVideo = (state, id) => state.entities.videos[id];

export const selectIsUploadUnderway = state =>
  !!state.storyMedia.uploadBatch.filter(item => item.status === 'uploading').length;

export const selectUploadProgress = (state, mediumId) => {
  const progressItem = state.storyMedia.uploadBatch.find(item => item.id === mediumId);
  return progressItem.uploadProgress;
};

export const selectMediumError = createSelector(
  [selectMedium, (state, id) => state.storyMedia.uploadBatch.find(item => item.id === id)],
  (medium, progressItem) => {
    if (medium['error-message-cache']) return 'processing';
    if (progressItem && progressItem.status === 'error') return 'upload failed';
    return '';
  }
);

export const selectRejectedMedia = state => state.storyMedia.rejectedMedia;

export default selectMedium;
