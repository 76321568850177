

import { Button } from 'rebass';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import PropTypes from 'prop-types';
import React from 'react';

import Absolute from '../../../app/layout/absolute';

const OptionButton = ({ selected, children, ...props }) => (
  <Button
    variant={selected ? 'outline' : 'mutedOutline'}
    mb={10}
    fontSize={20}
    css="position: relative;"
    type="button"
    {...props}
  >
    {children}
    {selected && (
      <Absolute right={5} top="calc(50% - 11px)">
        <IoIosCheckmarkCircle size={22} />
      </Absolute>
    )}
  </Button>
);

OptionButton.propTypes = {
  selected: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

export default OptionButton;
