import { useContext, useEffect, useMemo, useState } from 'react';

import { QueryContext } from '../../contexts/query';
import { useEntity, useEntityActionCreators } from '.';

const useQueryEntities = ({ query, queries, reEntity }) => {
  const {
    state: { currentQueries },
    addCurrentQuery,
    removeCurrentQuery,
  } = useContext(QueryContext);
  const { receive } = useEntityActionCreators();
  const { isPresent } = useEntity(reEntity) || {};
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [hasEntity, setHasEntity] = useState(isPresent);
  // const [state, setState] = useSpreadState({
  //   loading: true,
  //   error: null,
  //   data: null,
  //   hasEntity,
  // });

  useEffect(() => setHasEntity(hasEntity), [hasEntity, isPresent]);

  useEffect(() => {
    if (query) {
      if (!query.name || !currentQueries.includes(query.name)) {
        addCurrentQuery(query.name);
        query()
          .then(payload => {
            removeCurrentQuery(query.name);
            receive({ payload });
            setLoading(false);
            setData(payload.data);
            // setState({ loading: false, data: payload.data });
          })
          .catch(err => {
            setError(err);
            setLoading(false);
            setData({});
            // setState({ loading: false, error, data: {} });
            removeCurrentQuery(query.name);
          });
      }
    }

    if ((queries || []).length) {
      const queryNames = queries.map(q => q.name);
      Promise.all(
        queries
          .filter(fn => !fn.name || !currentQueries.includes(fn.name))
          .map(fn => {
            addCurrentQuery(fn.name);
            return fn();
          })
      )
        .then(payloads => {
          setLoading(false);
          payloads.forEach(payload => receive({ payload }));
          queryNames.forEach(queryName => removeCurrentQuery(queryName));
        })
        .catch(err => {
          setLoading(false);
          setError(err);
          queryNames.forEach(queryName => removeCurrentQuery(queryName));
        });
    }
    // eslint-disable-next-line
  }, []);

  return useMemo(() => ({ loading, error, data, hasEntity, currentQueries }), [
    loading,
    error,
    data,
    hasEntity,
    currentQueries,
  ]);
};

export default useQueryEntities;
