import { selectMember, selectMemberName, selectCurrentMember } from '../selectors';

export const selectConnection = (state, connectionId) => state.entities.connections[connectionId];

export const selectConnectionMember = (state, connectionId) => {
  const connection = selectConnection(state, connectionId);
  return selectMember(state, connection.contact.id);
};

export const selectConnectionFromMember = (state, memberId) => {
  const { connections } = state.entities;
  const connectionId = Object.keys(connections).find(id => connections[id].contact.id === memberId);
  return connections[connectionId];
};

export const selectConnectionIds = (state) => {
  const { id: currentMemberId } = selectCurrentMember(state);
  return Object.keys(state.entities.connections)
    .filter((id) => {
      const { id: memberId } = selectConnectionMember(state, id);
      return memberId !== currentMemberId;
    });
};

export const selectConnectionsMemberAlphabetical = state => (selectConnectionIds(state) || [])
  .sort((a, b) => {
    const connectionA = state.entities.connections[a];
    const connectionB = state.entities.connections[b];
    const nameA = selectMemberName(state, connectionA.contact.id);
    const nameB = selectMemberName(state, connectionB.contact.id);
    return nameA < nameB ? -1 : 1;
  });

export const selectApprovedConnections = ({ entities: { connections } }) => Object.keys(connections).filter(connection => connections[connection].status === 'approved');
