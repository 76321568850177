import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { withProps } from 'recompose';

export const Header = styled.header`
  background: ${props => props.theme.colours.background};
  min-height: 125px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px 8px 0 0;
  padding: 10px;
`;

export const CloseButton = styled(Link)`
  color: ${props => props.theme.colours.mutedText};
  display: flex;
  align-self: flex-end;
  width: 44px;
  height: 44px;
  justify-content: flex-end;
`;

export const BackButton = withProps({ type: 'button' })(styled.button`
  all: 'unset';
  margin: 6px;
  color: ${props => props.theme.colours.mutedText};
  align-self: flex-start;
`);

export const Title = styled.h2`
  font-family: ${props => props.theme.fonts.quicksand};
  font-size: 20px;
  font-weight: 300;
  text-align: center;
  color: ${props => props.theme.colours.mutedText};
  margin: 5px 0 ${props => props.theme.gutter};
  ${props => props.theme.media.phone`
    font-size: 24px;
  `};
`;

export const Body = styled.div`
  padding: 20px;
  background: ${props => props.theme.colours.white};
  border-radius: 0 0 8px 8px;
  ${props =>
    props.centered &&
    `
    display: flex;
    flex-direction: column;
    text-align: center;
  `}
`;

export const Icon = styled.i``;

export const SectionTitle = styled.h4`
  font-family: ${props => props.theme.fonts.sanFrancisco};
  font-size: 16px;
  margin: 0 0 1rem 0;
`;

export const Copy = styled.p`
  font-size: 14px;
  line-height: 1.6em;
  margin: 0 0 1rem 0;
`;
