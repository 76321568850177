import React, { createContext, useContext, useMemo, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

export const JSONAPIClientContext = createContext({});
const { Provider } = JSONAPIClientContext;

const getCacheKey = (method, path) => `${method}:${path}`;

const JSONAPIClient = ({ children, baseURL, onSuccess, onError, config }) => {
  const value = useMemo(() => {
    const requestCache = {};
    const makeRequest = ({
      method = 'get',
      path = '/',
      body = null,
      onSuccess: instanceSuccess = () => { },
      onError: instanceError = () => { },
      preflight,
      showError,
      opts,
    }) => {
      const { CancelToken } = axios;
      const source = CancelToken.source();

      const { headers } = config;
      if (preflight) {
        headers.PREFLIGHT_ONLY = true;
      }
      headers['X-LIFETALES-CLIENT'] = 'web-memoirs';

      const key = getCacheKey(method, path);
      const requestConfig = {
        ...{ method, url: `${baseURL}${path}`, source, ...config, headers, ...opts },
      };

      if (body) {
        requestConfig.data = {
          data: {
            ...body,
          },
        };
      }

      return axios(requestConfig)
        .then(payload => {
          requestCache[key] = payload;
          instanceSuccess(payload);
          onSuccess(payload);
          return payload;
        })
        .catch(error => {
          instanceError(error);
          onError(error, showError);
          return error;
        });
    };

    return {
      makeRequest,
      requestCache,
    };
  }, [baseURL, config, onError, onSuccess]);

  return <Provider value={value}>{children}</Provider>;
};

export const useJSONAPIRequest = (requestConfig = {}) => {
  const method = (requestConfig.method && requestConfig.method.toLowerCase()) || 'get';
  const isGet = method === 'get';
  const { makeRequest, requestCache } = useContext(JSONAPIClientContext);
  const [response, setResponse] = useState({
    requested: false,
    loading: false,
    data: isGet ? requestCache[getCacheKey('get', requestConfig.path)] : null,
    error: null,
  });
  const [shouldRequest, setShouldRequest] = useState(isGet && !requestCache[requestConfig.path]);
  const [body, setBody] = useState(null);
  const [path, setPath] = useState(null);

  useEffect(() => {
    // let source;
    const query = () => {
      setResponse(state => ({
        ...state,
        loading: true,
        requested: true,
        success: null,
        error: null,
        complete: null,
      }));
      makeRequest({
        path,
        ...requestConfig,
        body,
      })
        .then(payload => {
          if (payload.response && payload.response.status >= 400) {
            setResponse(prev => ({
              ...prev,
              error: payload.response,
              loading: false,
              payload,
              complete: true,
            }));
            return;
          }
          setResponse(prev => ({
            ...prev,
            data: payload.data,
            loading: false,
            payload,
            complete: true,
            success: true,
          }));
        })
        .catch(err => {
          setResponse(prev => ({
            ...prev,
            error: err.response,
            loading: false,
            payload: err.response,
            complete: true,
          }));
        });
    };

    if (shouldRequest) {
      setShouldRequest(false);
      query();
    }

    // return () => {
    //   if (source) {
    //     source.cancel();
    //   }
    // };
  }, [body, makeRequest, path, requestConfig, shouldRequest]);

  const mutate = useCallback(
    (requestBody = {}, requestPath = '') => {
      if (requestPath) {
        setPath(requestPath);
      }
      setBody(requestBody);
      setShouldRequest(true);
    },
    [setShouldRequest]
  );

  return useMemo(() => ({ ...response, mutate }), [mutate, response]);
};

JSONAPIClient.propTypes = {
  baseURL: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  children: PropTypes.node.isRequired,
  config: PropTypes.shape({}),
};

JSONAPIClient.defaultProps = {
  onSuccess: () => { },
  onError: () => { },
  config: {},
};

export default JSONAPIClient;
