import '../../registerServiceWorker';
import { postPushSubscription } from './api';

function urlB64ToUint8Array(base64String = '') {
  if (base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, '+')
      .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }
  return [];
}

const applicationServerKey = urlB64ToUint8Array(process.env.REACT_APP_WEB_PUSH_PUBLIC_KEY);

export function subscribeUser() {
  const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

  if ('serviceWorker' in navigator && process.env.REACT_APP_WEB_PUSH_PUBLIC_KEY) {
    navigator.serviceWorker.register(swUrl);
    navigator.serviceWorker.ready.then((registration) => {
      registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey,
      })
        .then((subscription) => {
          const { endpoint } = subscription;
          const authKey = subscription.getKey('auth');
          const p256Key = subscription.getKey('p256dh');
          const decodedAuthKey = btoa(String.fromCharCode.apply(null, new Uint8Array(authKey)));
          const decodedP256Key = btoa(String.fromCharCode.apply(null, new Uint8Array(p256Key)));
          postPushSubscription(endpoint, decodedP256Key, decodedAuthKey);
        })
        .catch((err) => {
          // add code for handling subscription denied
          console.log('subscription failed', err);
        });
    });
  }
}

export function getSubscriptionStatus() {
  if ('serviceWorker' in navigator) {
    return navigator.serviceWorker.ready.then((registration) => {
      registration.pushManager.permissionState({
        userVisibleOnly: true,
        applicationServerKey,
      })
        .then(subscription => subscription);
    });
  }
  return '';
}
