import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { receiveEntity, receiveEntityDeletion, updateEntity } from './actions';

const Receiver = ({
  children, ...props
}) => children({
  receiveEntity: props.receiveEntity,
  receiveEntityDeletion: props.receiveEntityDeletion,
  updateEntity: props.updateEntity,
});

Receiver.propTypes = {
  receiveEntity: PropTypes.func.isRequired,
  receiveEntityDeletion: PropTypes.func.isRequired,
  updateEntity: PropTypes.func.isRequired,
};

export default connect(null, { receiveEntity, receiveEntityDeletion, updateEntity })(Receiver);
