import { boxShadow, space, border, borderColor } from 'styled-system';
import styled from '@emotion/styled';

export const StyledAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e6e6e6;
  background-size: cover;
  background-position: center center;
  height: ${props => props.size}px;
  width: ${props => props.size}px;
  min-width: ${props => props.size}px;
  border-radius: 50%;
  font-weight: bold;
  text-transform: uppercase;
  box-shadow: ${props => props.shadow && props.theme.shadows.small};
  /* safari was rendering wrong using the border shorthand' */
  border-width: ${props => (typeof props.border === 'boolean' && props.border ? '3px' : '0px')};
  border-style: solid;
  border-color: ${props => props.theme.colours.white};
  font-size: ${props => props.size / 3}px;
  ${space};
  ${border};
  ${boxShadow};
  ${borderColor};

  .fa {
    font-size: 2em;
  }
`;

export const Initials = styled.div`
  color: ${props => props.theme.colours.mutedText};
`;

export const EditOverlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  border-radius: 50%;
  transition: background 0.5s;

  &:hover {
    background: rgba(0, 0, 0, 0.75);
  }
`;
