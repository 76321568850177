import React, { createContext, useMemo, useState } from 'react';

export const StoryPageContext = createContext({});

export const StoryPageContextProvider = props => {
  const [currentMedia, setCurrentMedia] = useState(null);

  const value = useMemo(() => {
    return {
      currentMedia,
      setCurrentMedia,
    };
  }, [currentMedia]);

  return <StoryPageContext.Provider value={value} {...props} />;
};
