import { Button, Flex, Box } from 'rebass';
import { FaHeart, FaRegHeart } from 'react-icons/fa';
import { transparentize } from 'polished';
import PropTypes from 'prop-types';
import React from 'react';

const TagButton = ({ selected, name, ...props }) => (
  <Button
    type="button"
    fontSize={16}
    color={selected ? 'brand' : 'mutedText'}
    borderRadius={17}
    fontWeight="normal"
    bg={selected ? transparentize(0.65, '#BFB3FF') : 'white'}
    {...props}
  >
    <Flex alignItems="center">
      <Box as="span" mr="4px">
        {name}
      </Box>
      {selected ? <FaHeart /> : <FaRegHeart />}
    </Flex>
  </Button>
);

TagButton.propTypes = {
  selected: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
};

export default TagButton;
