import PropTypes from 'prop-types';
import React from 'react';

import { useTimeFromNow } from '../hooks';

const TimeAgo = React.memo(({ date }) => {
  return useTimeFromNow(date);
});

TimeAgo.propTypes = {
  date: PropTypes.string.isRequired,
};

export default TimeAgo;
