import { get } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { createStoryRequestsForShares } from '../../../story-requests/actions';
import { useCurrentMemberId } from '../../../members/hooks';
import { useEntities, useEntity, useRelatedEntities } from '../../../entities/hooks';
import { useMatch } from '../../../app/hooks';
import { useHistory } from 'react-router-dom';

export const useCollectionStoryRequests = (params, filter = '') => {
  const allStoryRequestShares = useRelatedEntities({
    ...params,
    relatedType: 'story-request-shares',
  });
  const currentMemberId = useCurrentMemberId();

  const activeStoryRequestShares = allStoryRequestShares.filter(share => share.archived === false);

  return useMemo(() => {
    if (filter === 'sharedWithMe') {
      return activeStoryRequestShares
        .filter(share => {
          return (
            share['contact-role'] === 'collaborator' && share['with-contact-id'] === currentMemberId
          );
        })
        .map(({ id }) => ({ id, type: 'story-request-shares' }));
    }
    return activeStoryRequestShares
      .filter(share => {
        return (
          share['contact-role'] === 'collaborator' && share['with-contact-id'] !== currentMemberId
        );
      })
      .map(({ id }) => ({ id, type: 'story-request-shares' }));
  }, [activeStoryRequestShares, currentMemberId, filter]);
};

const useCurrentUserMemoirShare = id => {
  const currentMemberId = useCurrentMemberId();
  const memoirShares = useRelatedEntities({ id, type: 'memoirs', relatedType: 'memoir-shares' });
  return useMemo(
    () => memoirShares.find(elem => elem['with-contact'].id === currentMemberId) || {},
    [currentMemberId, memoirShares]
  );
};

export const useCurrentUserStoryRequestShare = id => {
  const allStoryRequestShares = useEntities('story-request-shares');
  return useMemo(
    () => Object.values(allStoryRequestShares).find(elem => elem['story-request'].id === id),
    [allStoryRequestShares, id]
  );
};

export const useCreateStoryRequests = state => {
  const { params } = useMatch();
  const { push } = useHistory();
  const dispatch = useDispatch();
  const currentMemberId = useCurrentMemberId();

  const memoirShare = useCurrentUserMemoirShare(params.id);

  return useCallback(
    () =>
      dispatch(
        createStoryRequestsForShares(params.id, memoirShare.id, currentMemberId, state)
      ).then(() => push(`/${params.type}/${params.id}`)),
    [currentMemberId, dispatch, memoirShare.id, params.id, params.type, push, state]
  );
};

export const useVideoMediumFromStoryRequest = storyRequestId => {
  const allPages = useEntities('pages');
  const allVideos = useEntities('videos');
  const { entity: storyRequest } = useEntity({ type: 'story-requests', id: storyRequestId });
  const pageId = get(storyRequest, `pages[0].id`, '');
  const page = Object.values(allPages).find(p => p.id === pageId);
  const videoId = get(page, `media[0].id`, '');
  const video = Object.values(allVideos).find(v => v.id === videoId);

  return video;
};

export default useCollectionStoryRequests;
