import * as types from './types';

export const setAuth = ({ token, memberId }) => ({
  type: types.SET_AUTH,
  token,
  memberId,
});

export const logout = () => ({
  type: types.LOGOUT,
});
