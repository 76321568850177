

import { Box, Button, Card, Flex, Heading } from 'rebass';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';

import { clearUploadBatch } from '../actions';
import { useUploadBatch } from './hooks';
import Modal from '../../app/modal/v2';
import UploadMediumStatus from './medium-status';

const UploadManagerModal = ({ onClose }) => {
  const uploadBatch = useUploadBatch();
  const dispatch = useDispatch();

  return (
    <Modal>
      <Card borderTopLeftRadius={8} borderTopRightRadius={8} bg="bgGrey" p={4}>
        <Heading fontFamily="quicksand" fontSize={4}>
          Upload Details
        </Heading>
      </Card>
      <Box css="overflow: auto; max-height: 40vh;">
        {uploadBatch.map(item => (
          <UploadMediumStatus key={`uploading-${item.id}`} {...item} />
        ))}
      </Box>
      <Card
        as={Flex}
        justifyContent="flex-end"
        borderBottomLeftRadius={8}
        borderBottomRightRadius={8}
        bg="bgGrey"
        p={3}
      >
        <Button
          variant="primary"
          type="button"
          onClick={() => {
            dispatch(clearUploadBatch());
            onClose();
          }}
        >
          Close
        </Button>
      </Card>
    </Modal>
  );
};

UploadManagerModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default UploadManagerModal;
