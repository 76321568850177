import React from 'react';

const NotificationsIcon = () => (
  <svg width="19" height="22" viewBox="0 0 19 22">
    <g fill="none">
      <g fill="currentColor">
        <path d="M9.2 0C8.3 0 7.5 0.8 7.5 1.8 7.5 2.7 8.3 3.5 9.2 3.5 10.2 3.5 11 2.7 11 1.8 11 0.8 10.2 0 9.2 0ZM6.8 2.7C4.8 3.5 3.5 5.5 3.5 7.9 3.5 12.8 1.8 14 0.9 14.7 0.4 15 0 15.3 0 15.8 0 17.7 2.8 18.5 9.2 18.5 15.7 18.5 18.5 17.7 18.5 15.8 18.5 15.3 18.1 15 17.6 14.7 16.6 14 15 12.8 15 7.9 15 5.5 13.7 3.5 11.7 2.7 11.3 3.7 10.4 4.4 9.2 4.4 8.1 4.4 7.1 3.7 6.8 2.7ZM6.6 19.3C6.6 19.3 6.6 19.3 6.6 19.4 6.6 20.8 7.8 22 9.2 22 10.7 22 11.9 20.8 11.9 19.4 11.9 19.3 11.9 19.3 11.9 19.3 11.1 19.3 10.2 19.4 9.2 19.4 8.3 19.4 7.4 19.3 6.6 19.3Z" />
      </g>
    </g>
  </svg>
);

export default NotificationsIcon;
