import { createSelector } from 'reselect';
import difference from 'lodash/difference';
import get from 'lodash/get';

import {
  selectConnectionFromMember,
  selectConnectionsMemberAlphabetical,
  selectApprovedConnections,
  selectConnectionMember,
} from '../members/connections/selectors';
import { selectMember, selectMemberName } from '../members/selectors';

export const selectStoryShare = (state, shareId) => state.entities['story-shares'][shareId];
export const selectStoryShareMember = (state, shareId) => {
  const storyShare = selectStoryShare(state, shareId);
  const memberId = get(storyShare, '["with-contact"].id', '');
  return selectMember(state, memberId);
};

export const selectStorySharesForStoryAlphabetical = (state, storyId) => {
  const shares = state.entities['story-shares'];
  return Object.keys(shares)
    .filter(id => get(shares[id], 'story.id', '') === storyId)
    .sort((a, b) => {
      if (
        get(shares, `[${a}]['with-contact']`, true) ||
        get(shares, `[${b}]['with-contact']`, true)
      )
        return 0;
      const nameA = selectMemberName(state, shares[a]['with-contact'].id);
      const nameB = selectMemberName(state, shares[b]['with-contact'].id);
      return nameA.toLowerCase() < nameB.toLowerCase() ? -1 : 1;
    });
};

export const selectStorySharesForStory = (state, storyId) => {
  const shares = state.entities['story-shares'];
  const storyShares = selectStorySharesForStoryAlphabetical(state, storyId);
  const collaborators = storyShares.filter(id => shares[id]['contact-role'] === 'collaborator');
  const viewers = storyShares.filter(id => shares[id]['contact-role'] === 'viewer');
  return [...collaborators, ...viewers];
};

export const selectStorySharesCollectionForStory = (state, storyId) => {
  const shareIds = selectStorySharesForStory(state, storyId);
  return shareIds.map(id => selectStoryShare(state, id));
};

export const selectMemberIdsforStory = (state, storyId) => {
  const shareIds = selectStorySharesForStory(state, storyId);
  const storyShares = shareIds.map(id => selectStoryShare(state, id));
  const memberIds =
    storyShares && storyShares.map(share => share['with-contact'] && share['with-contact'].id);
  return memberIds;
};

const selectStoryShareConnections = (state, storyId) => {
  const connections = selectConnectionsMemberAlphabetical(state);
  if (!connections.length) return undefined;
  return (selectStorySharesForStory(state, storyId) || [])
    .map(shareId => {
      const { id: memberId } = selectStoryShareMember(state, shareId) || {};
      const { id: connectionId } = selectConnectionFromMember(state, memberId) || {};
      return connectionId;
    })
    .filter(el => !!el);
};

const selectNotSharedWithStoryConnections = (state, storyId) => {
  const connections = selectConnectionsMemberAlphabetical(state);
  const storyConnections = selectStoryShareConnections(state, storyId);
  return difference(connections, storyConnections);
};

export const selectOrderedConnectionsForStory = (state, storyId) => [
  ...selectStoryShareConnections(state, storyId),
  ...selectNotSharedWithStoryConnections(state, storyId),
];

export const selectStoryShareIdByMember = (state, memberId, storyId) => {
  const storyShares = selectStorySharesForStory(state, storyId) || [];
  return storyShares.find(id => {
    const storyShare = selectStoryShare(state, id);
    if (!storyShare || !storyShare['with-contact']) return undefined;
    return storyShare['with-contact'].id === memberId;
  });
};

export const selectStoryShareByMember = (state, memberId, storyId) => {
  const shareId = selectStoryShareIdByMember(state, memberId, storyId);
  if (!shareId) return undefined;
  return selectStoryShare(state, shareId);
};

export const selectMembersForStoryShareForm = (state, storyId) => {
  const connections = selectApprovedConnections(state);
  return connections
    .map(connection => selectConnectionMember(state, connection))
    .sort((a, b) =>
      (a['display-name'] || '').toLowerCase() < (b['display-name'] || '').toLowerCase() ? -1 : 1
    )
    .map(({ id: memberId }) => {
      const { 'contact-role': role } = selectStoryShareByMember(state, memberId, storyId) || {};

      return { memberId, role };
    })
    .sort((a, b) => !!b.role - !!a.role)
    .reduce((acc, el) => ({ ...acc, [el.memberId]: el.role }), {});
};

export const selectMemberStoryShares = createSelector(
  [
    (state, filter) => filter || state.story.currentFilter,
    state => Object.values(state.entities.collections)[0],
    state => state.entities['story-shares'],
  ],
  (filter, collection, shares) => {
    if (!collection) return [];

    const shareOrder = (collection.shares || [])
      .filter(share => share.type === 'story-shares')
      .map(share => share.id);

    if (!shareOrder) return [];

    switch (filter) {
      case 'archived': {
        return shareOrder.filter(id => shares[id].archived);
      }
      case 'my-stories':
        return shareOrder.filter(
          id => !shares[id].archived && shares[id]['contact-role'] === 'owner'
        );
      case 'shared-with-me':
        return shareOrder.filter(
          id => !shares[id].archived && shares[id]['contact-role'] !== 'owner'
        );
      default:
        return shareOrder.filter(id => !shares[id].archived);
    }
  }
);

export const selectIsStoryShared = ({ state, storyId }) =>
  !!(selectStorySharesForStory(state, storyId) || []).length;
