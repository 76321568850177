import { useHistory } from 'react-router-dom';
import React from 'react';

import { SlideOne, SlideTwo, SlideThree, SlideFour } from './slides';
import CollectionOnboardCarousel from '../carousel';

const FamilyOnboard = () => {
  const { push } = useHistory();

  return (
    <CollectionOnboardCarousel
      ctaText="Start Your Family Memoir"
      onClose={() => push('/family-journals/new')}
    >
      <SlideOne />
      <SlideTwo />
      <SlideThree />
      <SlideFour />
    </CollectionOnboardCarousel>
  );
};

export default FamilyOnboard;
