import { combineReducers } from 'redux';

import auth from '../auth/reducer';
import entities from '../entities/reducer';
import member from '../members/reducer';
import story from '../stories/reducer';
import collection from '../collections/reducer';
import storyMedia from '../story-media/reducer';
import storyPages from '../story-pages/reducer';
import ui from '../ui/reducer';

const rootReducer = combineReducers({
  auth,
  collection,
  story,
  storyMedia,
  storyPages,
  member,
  entities,
  ui,
});

export default rootReducer;
