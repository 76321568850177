import { css } from '@emotion/core';

import * as utils from './utils';

export const sizes = {
  giant: 1200,
  desktop: 992,
  tablet: 768,
  phone: 576,
  smallPhone: 414,
  iphone6: 375,
};

// iterate through the sizes and create a media template
export const media = Object.keys(sizes).reduce((acc, label) => {
  // use em in breakpoints to work properly cross-browser and support users
  // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
  const emSize = sizes[label] / 16;
  return {
    ...acc,
    [label]: (...args) => css`
      @media (min-width: ${emSize}em) {
        ${css(...args)}
      }
    `,
  };
}, {});

export const breakpoints = {
  xs: 0,
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
};

export const styledSystemBreakpoints = [...Object.values(breakpoints).slice(1)];

export const colours = {
  bgGrey: '#e6e6e6',
  brand: '#7a69bf',
  brandSubtle: '#bfb3ff',
  alertText: '#856404',
  accent: '#ffd326',
  accent800: '#FFD73B',
  accent700: '#FFDB50',
  accent600: '#FFE066',
  accent500: '#FFE47B',
  accent400: '#FFE890',
  accent300: '#FFECA5',
  accent200: '#FFF1BB',
  accent100: '#FFF5D0',
  accentSubtle: '#fff9e5',
  defaultText: '#333',
  mutedText: '#666',
  lightText: '#999',
  border: '#e6e6e6',
  background: '#f8f8f8',
  white: '#fff',
  black: '#000',
  photoGradientStart: 'rgba(0, 0, 0, 0)',
  photoGradientEnd: 'rgba(0, 0, 0, 0.4)',
  red: '#e74c3c',
  errorSubtle: '#fdbfc6',
  memoirBlue: '#015F9B',
  grey80: '#CCCACC',
  grey90: '#f2f2f2',
  grey60: '#999',
  grey20: '#333333',
  blueBackground: '#0067CC',
  darkBlue: '#36247a',
  green: '#2ECC71',
  noticeIntense: '#FFD326',
  childJournal: '#1AAFD0',
  memoir: '#5B5F7B',
  // refactoring UI attempt
  'cyan-050': '#E0FCFF',
  'cyan-100': '#BEF8FD',
  'cyan-200': '#87EAF2',
  'cyan-300': '#54D1DB',
  'cyan-400': '#38BEC9',
  'cyan-500': '#2CB1BC',
  'cyan-600': '#14919B',
  'cyan-700': '#0E7C86',
  'cyan-800': '#0A6C74',
  'cyan-900': '#044E54',
  // drawer
  drawerBg: 'hsl(251, 23%, 34%)',
  drawerSubtle: 'hsl(251, 8%, 75%)',
  drawerBasicallyWhite: 'hsl(251, 85%, 96%)',
  // colours from the new style guide:
  // https://www.lifetales.com/style-guide
  jPrimary1: '#7a68bf',
  jPrimary2: '#ffa96a',
  jPrimary3: '#001E42',
  special: 'linear-gradient(90deg, #3866F4 0%, #7B33FB 48.96%, #BD00FF 100%)',
};

export const fonts = {
  sanFrancisco: '"SF UI Text", "Roboto", sans-serif',
  quicksand: '"Quicksand", sans-serif',
  sans:
    '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;',
};
fonts.heading = fonts.quicksand;
fonts.body = fonts.sanFrancisco;

export const shadows = {
  small: '0 2px 4px rgba(0, 0, 0, 0.15)',
  container: '0 2px 10px 0 rgba(0,0,0,0.15)',
  navbar: '0 2px 0 0 #e6e6e6',
  // taken from refactoring UI elevation with ambient light
  lowest: '0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24)',
  low: '0 3px 6px rgba(0,0,0,.15), 0 2px 4px rgba(0,0,0,.12)',
  medium: '0 10px 20px rgba(0,0,0,.15), 0 3px 6px rgba(0,0,0,.10)',
  high: '0 15px 25px rgba(0,0,0,.15), 0 5px 10px rgba(0,0,0,.05)',
  highest: '0 20px 40px rgba(0,0,0,.2)',
};

export const borderRadii = {
  large: '8px',
  normal: '6px',
  small: '3px',
  container: '15px',
};

export const gutter = '20px';

export const blur = 'blur(4px)';

const transition = 'all 0.175s ease';

const outlineStyles = {
  backgroundColor: colours.white,
  color: colours.brand,
  border: `1px solid ${colours.brand}`,
  transition,
  fontWeight: 'normal',
};

const disabledStyle = {
  '&:disabled': {
    opacity: 0.5,
    cursor: 'no-drop',
  },
};

const hoverStyle = {
  '&:hover': {
    boxShadow: 'inset 0 0 100px 100px rgba(0, 0, 0, 0.1)',
  },
};

const buttons = {
  link: {
    background: 'none',
    padding: 0,
    fontWeight: 'normal',
    color: colours.brand,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  special: {
    variant: 'buttons.primary',
    background: colours.special,
    boxShadow: '0px 10px 22px 4px rgba(99, 105, 239, 0.2)',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '120%',
  },
  primary: {
    ...disabledStyle,
    ...hoverStyle,
    background: colours.brand,
    color: colours.white,
    border: `1px solid ${colours.brand}`,
    transition,
  },
  outline: {
    ...disabledStyle,
    ...outlineStyles,
  },
  muted: {
    ...disabledStyle,
    ...hoverStyle,
    transition,
    backgroundColor: colours.grey90,
    color: colours.mutedText,
  },
  mutedOutline: {
    ...disabledStyle,
    ...outlineStyles,
    color: colours.mutedText,
    border: `1px solid ${colours.border}`,
    '&:hover': {
      border: `1px solid ${colours.brand}`,
      color: colours.brand,
    },
  },
  destructiveOutline: {
    ...disabledStyle,
    ...outlineStyles,
    color: colours.red,
    border: `1px solid ${colours.red}`,
    '&:hover': {
      border: `1px solid ${colours.red}`,
      color: colours.red,
    },
  },
  destructive: {
    ...disabledStyle,
    color: colours.white,
    backgroundColor: colours.red,
    border: `1px solid ${colours.red}`,
    fontWeight: 'normal',
    transition,
  },
  dark: {
    ...disabledStyle,
    color: colours.white,
    backgroundColor: 'rgba(0,0,0,.6)',
  },
  darkIconCircle: {
    ...disabledStyle,
    display: 'inline-flex',
    color: colours.white,
    backgroundColor: 'rgba(0,0,0,.6)',
    padding: '.5em',
    borderRadius: '50%',
    transition,
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,.8)',
    },
  },
  iconCircle: {
    ...disabledStyle,
    display: 'inline-flex',
    color: colours.brand,
    backgroundColor: colours.white,
    padding: '.5em',
    borderRadius: '50%',
    transition,
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,.8)',
    },
  },
  transparent: {
    backgroundColor: 'transparent',
    border: '1px solid transparent',
    color: 'inherit',
    fontWeight: 'normal',
  },
  unset: {
    all: 'unset',
    padding: 0,
    border: 'none',
    background: 'none',
    fontWeight: 'inherit',
    cursor: 'pointer',
  },
  videoEdit: {
    bg: '#1A1A1A',
    color: 'white',
    border: '1px solid',
    borderColor: 'rgba(255, 255, 255, 0.2)',
    '&:disabled': { color: '#595959' },
  },
};

const cards = {
  error: {
    background: colours.errorSubtle,
    color: colours.red,
  },
};

export default {
  breakpoints: styledSystemBreakpoints,
  keyedBreakpoints: breakpoints,
  buttons,
  colours,
  colors: colours,
  fonts,
  shadows,
  borderRadii,
  media,
  gutter,
  blur,
  utils,
  cards,
  space: [0, 8, 16, 24, 32, 48, 64, 72, 128, 256],
};
