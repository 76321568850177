import { Form as FForm } from 'formik';
import { Link } from 'react-router-dom';
import { height } from 'styled-system';
import styled from '@emotion/styled';
import { withProps } from 'recompose';

export const Form = styled(FForm)`
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;
  max-height: 100%;
  ${height};
`;

export const Input = withProps({ type: 'text' })(styled.input`
  height: 55px;
  background: ${props => props.theme.colours.white};
  width: 100%;
  border-radius: 8px;
  text-align: center;
  line-height: 55px;
  font-size: 20px;
  color: ${props => props.theme.colours.defaultText};
  border: 1px solid ${props => props.theme.colours.border};
  outline: none;
  transition: all 0.175s ease-in-out;

  &:focus {
    border: 1px solid ${props => props.theme.colours.brandSubtle};
  }

  &:placeholder {
    color: ${props => props.theme.colours.mutedText};
  }
`);

export const Submit = withProps({ type: 'submit' })(styled.input`
  padding: 16px;
`);

export const SkipLink = styled(Link)`
  color: ${props => props.theme.colours.mutedText};
  display: inline-block;
  font-size: 14px;
  margin: 1em auto 0;
  text-decoration: underline;
`;
