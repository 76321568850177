import { Formik } from 'formik';
import { Route } from 'react-router-dom';
import { object, string } from 'yup';
import PropTypes from 'prop-types';
import React from 'react';

import { Entity } from '../../entities';
import { getCreateCollectionTitle } from './helper';
import { patchCollection, postCollection } from '../api';
import { prepareAttrs } from '../../utils/api';
import { useEntityActionCreators } from '../../entities/hooks';
import FullPageForm from '../../app/full-page-form';
import NewCollectionName from './name';

const BasicNewCollection = ({ titleAttr, placeholder }) => {
  const noACAttr = `${titleAttr}-noac`;
  const { receive } = useEntityActionCreators();

  return (
    <Route
      path="/:type/new/:id?"
      render={({ history: { push }, match: { params } }) => {
        return (
          <Entity {...params}>
            {({ entity }) => {
              const initialValues = {
                [noACAttr]: (entity && entity[titleAttr]) || '',
              };
              const validationSchema = object().shape({
                [noACAttr]: string().required(),
              });
              const isInitialValid = validationSchema.isValidSync(initialValues);

              return (
                <FullPageForm
                  onExitClick={() => push('/my-stories')}
                  title={getCreateCollectionTitle(params.type)}
                >
                  <Formik
                    {...{ initialValues, validationSchema, isInitialValid }}
                    onSubmit={async values => {
                      const attributes = prepareAttrs(values);
                      // this attribute is required because of the way that the iOS app set stuff up

                      // POST child journal
                      let collectionResponse;
                      if (params.id) {
                        collectionResponse = await patchCollection({ ...params, attributes });
                      } else {
                        collectionResponse = await postCollection({ ...params, attributes });
                      }
                      const [id] = collectionResponse.data.result[params.type];
                      receive({ payload: collectionResponse });
                      push(`/${params.type}/new/${id}/tags`);
                    }}
                    render={props => (
                      <NewCollectionName
                        attr={noACAttr}
                        label="Memoir Name"
                        placeholder={placeholder}
                        {...props}
                      />
                    )}
                  />
                </FullPageForm>
              );
            }}
          </Entity>
        );
      }}
    />
  );
};

BasicNewCollection.propTypes = {
  titleAttr: PropTypes.string,
  placeholder: PropTypes.string,
};

BasicNewCollection.defaultProps = {
  titleAttr: 'name',
  placeholder: '',
};

export default BasicNewCollection;
