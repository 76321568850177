import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Label = styled.span`
  font-size: 14px;
  color: ${props => props.theme.colours.mutedText};
`;

const ButtonLabelWrap = ({ label, children }) => (
  <Wrap>
    {children}
    <Label>{label}</Label>
  </Wrap>
);

ButtonLabelWrap.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default ButtonLabelWrap;
